import {creditLinks} from "../../models/credit_links";

export const footerNavigationLinks: creditLinks[] =
  [{
    "title": "Acuerdo de usuario y Declaración de privacidad del sitio web",
    "id": "web_user_privacy_statement",
    "ariaLabel":"Acuerdo de usuario y Declaración de privacidad del sitio web",
    "link": "/legal/user-agreement-and-privacy"
    },
    {
      "title": "NASDAQ: CACC",
      "id": "nasdaq_cacc",
      "link": "https://www.google.com/search?q=nasdaq:cacc",
      "ariaLabel":"NASDAQ: CACC",
      "isExternalLink":true,
      "target": true
    },
    {
      "title": "Política de Accesibilidad del sitio Web",
      "id": "web_accessibility_policy",
      "link": "/legal/accessibility",
      "ariaLabel": "Política de Accesibilidad del sitio Web"
    },
    {
      "title": "NMLS ID: 3023",
      "id" : "nmls_id",
      "ariaLabel": "NMLS ID: 3023"
    },
    {
      "title": "Privacidad del Consumidor",
      "id" : "consumer_privacy",
      "link": "/privacy-policies",
      "ariaLabel":"Privacidad del Consumidor",
      "isCustomerLink": true,
      "target": true
    },
    {
      "title": "Servicios lingüísticos para residentes de NYC",
      "id" : "lang_services_for_nyc_Residents",
      "ariaLabel":"Servicios lingüísticos para residentes de NYC",
      "link": "/legal/language-services-for-nyc-residents"

    },
    {
      "title": "Su Crédito y su Privacidad",
      "id" : "your_credit_and_privacy",
      "ariaLabel":"Su Crédito y su Privacidad",
      "link": "/legal/privacy"
    }
  ];
