
export class numbersSpeak {
  heading!: string;
  headingId!: string;
  numDetailsValueDisplaySize?: string;
  numDetails!: numberSpeakDetails[]
}

export class numberSpeakDetails {
  title!: string;
  value!: string;
}
