import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DEALER_REVIEWS_JSON_URL } from 'app/constants/aws.constants';
import {AWSService} from 'app/services/aws.service';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import Player from '@vimeo/player';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-dealer-reviews',
  templateUrl: './dealer-reviews.component.html',
  styleUrls: ['./dealer-reviews.component.scss']
})
export class DealerReviewsComponent implements OnInit {
  subscription: Subscription[] = [];

  url = DEALER_REVIEWS_JSON_URL;
  dealerReviewsData : any = {};
  heroImageInfo : any ;
  contentBlock : any ;
  faqs : any
  //video data
  videoComponentTitle: string = '';
  videoData!: SafeResourceUrl;
  videoTitle: string = '';
  videoDescription: string = '';

  mobileView : boolean = false;
  isMobileViewDisplay : boolean = false;
  isBrowser : boolean = false;
  leftToRight: boolean = false;
  isDefaultColor : boolean = true
  overLineBgColor: string = "#0F7D9C";
  bottonBgColor: string = "#C4EDFA";
  headerColor: string = "#FCFCFA";
  isCurvedImage = true;


  constructor(private http: HttpClient, private responsive: BreakpointObserver, private cdr: ChangeDetectorRef, private awsService : AWSService, private sanitizer: DomSanitizer,@Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {

    if(this.isBrowser){
      
      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);
        this.dealerReviewsData = json;
        this.heroImageInfo = this.dealerReviewsData.CacHeroImageComponent;
        this.contentBlock = this.dealerReviewsData.CacContentBlockComponent;
        this.faqs = this.dealerReviewsData.CacFaqsComponent;
        this.videoData = this.sanitizer.bypassSecurityTrustResourceUrl(this.dealerReviewsData.VideoComponent.address);
        this.videoComponentTitle = this.dealerReviewsData.VideoComponent.title;
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }
  //remove urls from description
  sanitizeDescription(description: string): string {
    // Match URLs starting with http, https, www, or a domain name with .com, .net, etc., and remove paths as well
    const urlPattern = /(?:https?:\/\/|www\.)[^\s]+|(?:[a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}\/[^\s]*/g;
    return description.replace(urlPattern, '');
  }
  loadVimeoPlayer(player: Player) {
    player.on('loaded', () => {
      player.getVideoId().then(videoId => {
        fetch(`https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${videoId}`)
          .then(response => response.json())
          .then(data => {
              this.videoTitle =  data.title,
              this.videoDescription =  data.description
          });
      });
    });
  }
  
  loadIframe(id: string) {
    const iframe = document.getElementById(id) as HTMLIFrameElement;
    // Check iframe src 
    if (iframe.src === '') {
      return;
    }
    const player = new Player(iframe);
    this.loadVimeoPlayer(player);
  }
}