<div class="py-3">
  <span class="body-description-heading"><span aria-label="'The Deal Optimizer in CAPS'">Estructure el mejor trato posible tanto para usted como para su cliente.</span></span>
</div>
<div class="row p-3">
  <div [ngClass]="(isMobileView$ | async) ? 'col-12 py-2' : 'col-8 px-0'">
    <span class="d-flex justify-content-between">
      <span class="mx-2 body-description-sub-heading">
        <span class="fw-bold">Distribuidor:</span><span> Ganancia inicial, ganancia total y precio de venta</span>
      </span>
      <span class="mt-2">
        <img class="px-2" src="assets/images/common/icon_plus_sign.svg" alt="Plus Icon'" loading="lazy">
      </span>
      <span class="mx-2 body-description-sub-heading">
        <span class="fw-bold">Cliente:</span><span> Pagos mensuales y pago inicial</span>
      </span>
    </span>
  </div>
  <div class="py-3" *ngIf="(isMobileView$ | async)">
    <mat-divider></mat-divider>
  </div>
  <div [ngClass]="(isMobileView$ | async) ? 'col-12' : 'col-4 px-0'">
    <span class="d-flex" [ngClass]="(isMobileView$ | async) ? 'justify-content-center px-5 mt-3' : 'justify-content-between'">
      <span class="mt-2">
        <img class="mx-2" src="assets/images/common/icon_equal_sign.svg" alt="Equal Icon'" loading="lazy">
      </span>
      <span class="mx-2 body-description-sub-heading">
        <span class="fw-bold">Mejor trato:</span><span> Optimizado para el concesionario y el cliente</span>
      </span>
    </span>
  </div>
</div>


