export const footerDisclaimer = [{
  disc_text: "Préstamos concedidos o aprobados conformen con la ley de Prestamistas Financieros de California",
  id:"cali_reserve_disc"
}, {
  disc_text: "© DYANAMIC_CURRENT_YEAR Credit Acceptance Corporation. Todos los derechos reservados.",
  id:"rights_reserve_disc",
}
]

export const DealerPoolDisclaimer = {
  disc_text: "*Cada pool representa un subconjunto de su portfolio total de préstamos y funciona como un mecanismo que ayuda a acelerar el Portfolio Profit proyectado, y le da la oportunidad de recibir un anticipo de esa ganancia futura en forma de pago de PPE. En ciertas circunstancias, algunos concesionarios pueden reunir las condiciones necesarias para recibir un cheque PPE más rápidamente.",
  id:"dealer_pool_disc"
}

export const eligibleDealerDisclaimer = {
  disc_text: "**Para concesionarios que reúnen las condiciones necesarias.",
  id:"eligible_dealer_disc",
}
