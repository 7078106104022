import { bannerImage } from "app/models/bannerImage";

export const dealerGrowBanner: bannerImage = {
  "imgUrl": "assets/images/grow-your-dealership/banner-image/banner.jpg",
  "mobileImgurl":"assets/images/common/orange-shape-bg-mobile@2x.webp",
  "imgAlt": "Banner Image",
  "buttonName": "INICIAR SESIÓN EN CAPS",
  "buttonId":"grow-your_dealer_login_caps",
  "overlineTitle":"Bienvenido/a a Credit Acceptance.",
  "heading": ["Si vende más CARROS. Gana.", "Si gana más dinero. Gana el doble. "],
  "link": "/dealers/caps-redirect"
}
