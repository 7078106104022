<header *ngIf="isScullyGenerated" class="bgc-tertiary-white" id="header" aria-label="Page Header Section" #outsideClick>
  <div class="container" id="header_section">
    <div *ngIf="!showOnlyLogo">
      <div class="py-2" *ngIf="mobileView">
        <div class="d-flex" [ngClass]="showHamburger ? 'justify-content-between' :'justify-content-end'">
          <a role="link" class="pt-2" *ngIf="showHamburger" attr.aria-label="{{signInLink.ariaLabel}}"
            id="{{signInLink.id}}" appAnchorClick [linkInfo]="signInLink">
            <img class="float-left" src="assets/images/header/user.svg" alt="User" height="21px">
          </a>
          <ng-container *ngIf="showHamburger" >
            <ng-container *ngTemplateOutlet="headerMainLogo;"></ng-container>
          </ng-container>
          <img class="pt-2 float-right"
            [src]="showHamburger ? 'assets/images/header/hamburger.svg' : 'assets/images/header/cross.svg'"
            [alt]="showHamburger ? 'hamburger' : 'Cross Icon'" height="30px" (click)="showNavItems()">
        </div>
        <div class="row mobile-view" *ngIf="showNavMenuItems">
          <app-quick-links [isMobileView]="mobileView"></app-quick-links>
          <app-accordion [accordionItems]="topNavigationLinks" [accordionId]="'headerMenuSection'"
            [borderType]="'bb-dotted-secondary-brown'"
            [titleClass]="'header-main-links text-decoration-none'"></app-accordion>
        </div>
        <div class="px-4" *ngIf="showNavMenuItems">
          <ng-container *ngTemplateOutlet="getTopSidesHeaderLinks;"></ng-container>
        </div>
      </div>
      <div class="row mx-0" *ngIf="!mobileView">
        <div class="col-6 col-md-2 menu-align">
          <span class="d-flex align-items-center text-white text-decoration-none">
              <ng-container *ngTemplateOutlet="headerMainLogo;"></ng-container>
          </span>
        </div>
        <div class="col-6 col-md-10">
          <div class="row right-menu">
            <div *ngIf="topNavigationLinks" class="col-6">
              <ng-container *ngFor="let menuItem of topNavigationLinks;let i = index">
                <ng-container *ngIf="!menuItem?.childMenu">
                  <a class="py-2 px-3 btn btn-light text-decoration-none" mat-button id="Pre_qualied_btn" role="button"
                    appAnchorClick [linkInfo]="menuItem" attr.aria-label="{{menuItem?.ariaLabel}}">
                    <span class="header-main-links">{{menuItem?.title}}</span>
                  </a>
                </ng-container>

                <ng-container *ngIf="menuItem?.childMenu">
                  <button class="headerMenu py-2" #button mat-button [matMenuTriggerFor]="childMenu" type="button"
                    attr.aria-label="{{menuItem?.ariaLabel}}" #levelOneTrigger="matMenuTrigger"
                    (mouseenter)="buttonEnter(levelOneTrigger)" (mouseleave)="buttonLeave(levelOneTrigger, button)"
                    id="{{menuItem.id + 'menuItem' + i}}">
                    <nav>
                      <ul class="mb-0 list-unstyled">
                        <li>
                          <a role="button" class="p-2" attr.aria-labelledby="{{menuItem.id + 'menuItem' + i}}"
                            id="{{menuItem?.id}}">{{menuItem?.title}}
                            <img  src="assets/images/header/angle-down-solid.svg" height="12px"> </a>
                        </li>
                      </ul>
                    </nav>
                  </button>

                  <mat-menu #childMenu="matMenu" direction="down" yPosition="below" >
                    <div [ngClass]="i === 0 ? 'mat-sub-menu www-menu-columns-2' : 'mat-sub-menu'">
                    <span (mouseenter)="menuAction(true)" (mouseleave)="menuAction(false)">
                      <ng-container *ngFor="let child of menuItem?.childMenu">
                        <nav *ngIf="!mobileView && !child.showInMobileOnly" >
                          <ul class="mb-0 list-unstyled">
                            <li id="{{child?.id}}">
                              <a mat-menu-item role="menuitem" title="{{child?.ariaLabel}}" attr.aria-labelledby="{{child?.id}}"
                                class="header-main-links p-0 px-3 lh-sm" appAnchorClick [linkInfo]="child">
                                <div class="py-2">{{child?.title}}</div>
                                <div class="bb-dotted-black"></div>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </ng-container>
                    </span>
                  </div>
                  </mat-menu>

                </ng-container>

              </ng-container>
            </div>
            <div class="col-6">
              <ng-container *ngTemplateOutlet="getTopSidesHeaderLinks;"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showOnlyLogo">
      <div class="py-2" [ngClass]="mobileView ? 'text-center' : 'ps-2'">
        <ng-container *ngTemplateOutlet="headerMainLogo;"></ng-container>
      </div>
    </div>
  </div>
</header>
<ng-template #getTopSidesHeaderLinks>
  <nav *ngIf="getTopSideHeaderLinks" section="side Navigation Links">
    <ul class="list-unstyled" [ngClass]="mobileView ? '' : 'nav'">
      <li *ngFor="let topSideLink of getTopSideHeaderLinks;let last= last;"  [ngClass]="mobileView ? 'pt-3' : ''" >
        <a  *ngIf='!mobileView || (mobileView && !last)' role="link" [ngClass]="last? 'get-prequal-button btn btn-primary' : ''" class="side-nav-links pb-3" attr.aria-label="{{topSideLink?.ariaLabel}}" id="{{topSideLink?.id}}"
          appAnchorClick [linkInfo]="topSideLink">{{topSideLink?.title}}</a>
      </li>

    </ul>
  </nav>
</ng-template>
<ng-template #headerMainLogo>
  <span id="heading">
    <a role="link" href="/" class="logo">
      <img id="acceptance_logo" src="assets/images/header/CA_Logo.svg" alt="Credit Acceptance"
      height="35px">
    </a>
  </span>
</ng-template>
