import { cardDetails } from "app/models/cardDetails";

export const hearFromDealers: cardDetails[] = [
  {
    "avatarIcon":"assets/images/grow-your-dealership/hear-from-dealers/user1.webp",
    "imgAlt":"Austin Vann Dealer Avatar Icon",
    "userDetails": {
      "name":"Austin Vann",
      "position":"General Sales Manager",
      "corporateName":"Steve Landers Auto",
      "Address": "Little Rock, AR"
    },
    "link":"/dealer-review-steve-landers-auto",
    "buttonName":"more >",
    "description":["We have found tremendous success with Credit Acceptance. With it, our auto group has thrived and we have increased our used car sales by over 10% because of the amount of deals that we are able to do now with Credit Acceptance."],
  },
  {
    "avatarIcon":"assets/images/grow-your-dealership/hear-from-dealers/user2.webp",
    "imgAlt":"Credit FAQ",
    "userDetails": {
      "name":"Dan Reel",
      "position":"Owner",
      "corporateName":"Reel's Auto Sales, LLC",
      "Address": "Orwell, OH"
    },
    "link":"/dealer-review-reels-auto-sales",
    "buttonName":"more >",
    "description":["Since we’ve enrolled with Credit Acceptance, we’ve seen substantial growth in our business - 25% per year. We’ve also been able to double the size of our dealership."],
  },
  {
    "avatarIcon":"assets/images/grow-your-dealership/hear-from-dealers/user3.webp",
    "imgAlt":"Credit FAQ",
    "userDetails": {
      "name":"Joshua Gomez",
      "position":"Owner",
      "corporateName":"Joshua Motors",
      "Address": "Vineland, NJ"
    },
    "link":"/dealer-review-joshua-motors",
    "buttonName":"more >",
    "description":["We use Credit Acceptance for the majority of our customers. I didn't realize the impact of Credit Acceptance until I received a half of a million dollars and my lot went from four cars to about 240 cars and 11 employees. We can sell cars because Credit Acceptance gives me the opportunity. The customer is getting a good car and establishing credit, and we are changing lives. This program is the opportunity of a lifetime and it is the best decision I ever made."]
  }
]