import { inject, Injectable, RendererFactory2 } from '@angular/core';
import {
  BehaviorSubject,
  from,
  Observable,
  of,
  Subject,
  throwError,
  timeout,
  TimeoutError,
} from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { FeatureFlagService } from './feature-flag.service';
import { EFeatureFlag } from './feature-flags.types';
import {isScullyGenerated} from "@scullyio/ng-lib";

@Injectable({
  providedIn: 'root',
})
export class RecaptchaV3Service {
  private readonly document = inject(DOCUMENT);
  private readonly featureFlagService = inject(FeatureFlagService);
  private readonly renderer = inject(RendererFactory2).createRenderer(
    null,
    null
  );
  private scriptFailedToLoad = false;
  private readonly siteKey = this.getRecaptchaSiteKey();
  private readonly scriptLoadedSubject = new BehaviorSubject(false);
  private readonly recaptchaErrorSubject: Subject<string> = new Subject();

  readonly ready$ = this.scriptLoadedSubject
    .asObservable()
    .pipe(filter((isLoaded) => isLoaded));
  readonly recaptchaError$ = this.recaptchaErrorSubject.asObservable();

  loadRecaptchaScript(): void {
    if (document.getElementById('recaptcha-script')) {
      this.scriptLoadedSubject.next(true);
      return;
    }

    (<any>this.document.defaultView).recaptchaLoaded = () =>
      this.scriptLoadedSubject.next(true);

    if(isScullyGenerated()) {
      const script: HTMLScriptElement = this.renderer.createElement('script');
      this.renderer.setAttribute(script, 'id', 'recaptcha-script');
      this.renderer.setAttribute(
        script,
        'src',
        `https://www.google.com/recaptcha/api.js?render=${this.siteKey}&onload=recaptchaLoaded&trustedtypes=true`
      );
      this.renderer.setAttribute(script, 'async', 'true');
      this.renderer.setAttribute(script, 'defer', 'true');

      // Error callback in case the script fails to load
      script.onerror = (error) => {
        console.error(`Failed to load reCAPTCHA script due to ${error}`);
        this.scriptFailedToLoad = true;
      };
      this.renderer.appendChild(this.document.head, script);
    }
  }

  execute(action: string): Observable<string | null> {
    return from(
      this.featureFlagService.isEnabled(EFeatureFlag.RECAPTCHA_ENABLED, true)
    ).pipe(
      switchMap((isEnabled) =>
        isEnabled
          ? this.ready$.pipe(
              take(1),
              timeout(5000),
              switchMap(() =>
                from(grecaptcha.execute(this.siteKey, { action }))
              ),
              catchError((err) => {
                const errorMessage = 'reCAPTCHA execution failed';
                const error = err as Error;
                let failureReason = error.message;
                if (err instanceof TimeoutError) {
                  failureReason = this.scriptFailedToLoad
                    ? 'script failed to load'
                    : 'script failed to initialize properly';
                }

                console.error(
                  `reCAPTCHA execution failed due to ${failureReason}\n${error.stack}`
                );
                this.setRecaptchaError(errorMessage);
                return throwError(() => new Error(errorMessage));
              })
            )
          : of(null)
      )
    );
  }

  removeRecaptcha(): void {
    // Setting scriptLoadedSubject to false in order to prevent the execute() method from triggering a request to the
    // reCAPTCHA api before the script is reloaded properly if the page is navigated to again.
    this.scriptLoadedSubject.next(false);

    // Remove the reCAPTCHA badge element from the DOM
    const recaptchaBadge =
      this.document.getElementsByClassName('grecaptcha-badge')[0];
    if (recaptchaBadge) {
      this.renderer.removeChild(this.document.body, recaptchaBadge);
    }

    // Remove all scripts associated with reCAPTCHA
    Array.from(this.document.querySelectorAll('script[src]'))
      .filter(
        (element) =>
          (element as HTMLScriptElement).src.includes(
            'https://www.google.com/recaptcha'
          ) ||
          (element as HTMLScriptElement).src.includes(
            'https://www.gstatic.com/recaptcha'
          )
      )
      .forEach((script) => {
        this.renderer.removeChild(this.document.head, script);
      });
  }

  setRecaptchaError(errorMessage: string): void {
    this.recaptchaErrorSubject.next(errorMessage);
  }

  private getRecaptchaSiteKey(): string {
    const hostName = window.location.hostname;

    if (hostName.includes('localhost')) {
      return "6Ldn4HwqAAAAAHEq1ljvKhufolWD4eBWqkUCe2ae";
    } else if (hostName.includes('dev')) {
      return "6Ldn4HwqAAAAAHEq1ljvKhufolWD4eBWqkUCe2ae";
    } else if (hostName.includes('test')) {
      return "6Ldn4HwqAAAAAHEq1ljvKhufolWD4eBWqkUCe2ae";
    } else if (hostName.includes('qa')) {
      return "6Lc-A-0qAAAAAIkrbBvgksSL0tI6gg6joEeJioLy";
    } else {
      return "6Lc5A4oqAAAAAOjYRi14HsD-0AyzprIIl7MNXTZ9"; // production
    }
  }
}
