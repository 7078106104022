import { cardDetails } from "app/models/cardDetails";

export const FullSpectrum: cardDetails = {
  "title":["Generamos posibilidades para todos: ", "financiamiento de amplio espectro."],
  "id":"make_thigns_full_spectrum_tab",
  "sectionName":"make things full spectrum tab",
  "description": [
    "Con la gama completa de financiamiento de Credit Acceptance, los concesionarios registrados pueden ofrecer opciones de financiamiento para vehículos nuevos y usados y asegurarse de que el cliente salga ganando a largo plazo."
  ],
  "points": [
    'Opciones de financiamiento para clientes sin crédito a clientes que gozan de un buen historial crédito.',
    'La oportunidad de incrementar ingresos a través de avances más altos.',
    'Capacidad de estructurar ventas fácilmente y de modo que satisfacen los requisitos del cliente con relación a pagos iniciales y pagos mensuales.'
  ],
  "buttonName": "INICIAR SESIÓN EN CAPS",
  "imgUrl":"assets/images/grow-your-dealership/users-toggle/make-things-posisble/full-spectrum.svg",
  "imgAlt":"Dealer Approvals",
  "ariaLabel":"Dealer Full Spectrum Section",
  "buttonId": "dealer_appr_login_caps",
  "link":"/dealers/caps-redirect"
}
