import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DEALER_FAQS_JSON_URL } from 'app/constants/aws.constants';
import { AWSService } from 'app/services/aws.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-dealer-faqs',
  templateUrl: './dealer-faqs.component.html',
  styleUrls: ['./dealer-faqs.component.scss']
})
export class DealerFaqsComponent implements OnInit {
  subscription: Subscription[] = [];

  url = DEALER_FAQS_JSON_URL;
  dealerFaqsData : any = {};
  heroImageInfo : any ;
  tabbedContentBlock : any ;
  searchKeyword: string = '';
  searchDisplayKeyword: string = '';
  showResults: boolean = false;
  faqData : any;
  filteredFaqs: any;
  filteredFaqData: any;
  isMobileViewDisplay : boolean = false;
  isBrowser : boolean = false;
  overLineBgColor: string = "#b34700";
  bottonBgColor: string = "#FFB800";
  headerColor: string = "#171714";
  disclaimerColor: string = '#171714';
  isCurvedImage = true;


  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {

    if(this.isBrowser){
      
      this.awsService.getAWSData(this.url).then(json => {
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);
        this.dealerFaqsData = json;
        
        this.faqData = this.dealerFaqsData.CacFaqsComponent;
        this.filteredFaqs = { ...this.faqData };
        this.heroImageInfo = this.dealerFaqsData.CacHeroImageComponent;
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }

  selectText(el: HTMLInputElement){
		el.select();
	}

  search() {
    if (this.searchKeyword.trim() === '') {
      this.showResults = false;
      // If the search keyword is empty, show all FAQs
      this.filteredFaqs = { ...this.faqData };
    } else {
      // Filter FAQs based on the search keyword
      const keyword = this.searchKeyword.toLowerCase().trim();
      var filteredFaqData = this.faqData.faqs.filter((faq:any) =>
        faq.question.toLowerCase().includes(keyword) ||
        faq.answer.toLowerCase().includes(keyword)
      );

      this.filteredFaqs = {
          ...this.faqData,
          "header": "",
         "faqs": filteredFaqData.map((faq:any, index:number) => ({
            ...faq,
            "open": index === 0
          }))
      };
      this.searchDisplayKeyword = this.searchKeyword;

      this.showResults = true;
    }
  }

  checkEmptyKeyword() {
    if (this.searchKeyword.trim() === '') {
      this.showResults = false;
      this.filteredFaqs = { ...this.faqData };
    }
  }
}
