import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { REELS_AUTO_SALES_JSON_URL } from 'app/constants/aws.constants';
import {AWSService} from 'app/services/aws.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-reels-auto-sales',
  templateUrl: './reels-auto-sales.component.html',
  styleUrls: ['./reels-auto-sales.component.scss']
})
export class ReelsAutoSalesComponent implements OnInit{

  subscription: Subscription[] = [];

  url = REELS_AUTO_SALES_JSON_URL;

  ReelsJSONData:any;
  headerData:any;
  isBrowser : boolean = false;
  isMobileViewDisplay : boolean = false;
  introData : any;
  videoData : any;

  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if(this.isBrowser){

      this.awsService.getAWSData(this.url).then(json => {

        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);

        this.ReelsJSONData = json;
        this.headerData = this.ReelsJSONData.CacVideoReview.header;
        this.introData = this.ReelsJSONData.CacVideoReview.CacCreditIntroductionReview;
        this.videoData = this.ReelsJSONData.CacVideoComponent;

      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
        this.isMobileViewDisplay = result.matches ? true : false;
      })
    );
  }

}