<ng-container *ngIf="data | async as data">
  <app-header></app-header>
  <div
    [ngClass]="
      data.isMobile
        ? 'sub-components-margin mob-height'
        : 'sub-components-margin'
    "
  >
    <cac-hero-image
      [heroImageInfo]="data.cacHeroImage"
      [headerColor]="headerColor"
    ></cac-hero-image>
  </div>
  <cac-credit-introduction
    [information]="data.portfolioCreditIntroduction"
  ></cac-credit-introduction>
  <div [ngClass]="data.isMobile ? 'mobile-container' : 'container'">
    <div *ngIf="data.tabbedContent">
      <cac-tabbed-content [data]="data.tabbedContent"></cac-tabbed-content>
    </div>
  </div>
  <div
    [ngClass]="
      data.isMobile
        ? 'mobile-container components-margin'
        : 'container components-margin'
    "
  >
    <cac-grid-program [columnGridData]="data.gridProgram"></cac-grid-program>
  </div>
  <div>
    <div
      [ngClass]="!data.isMobile ? 'container mb-30' : 'mobile-container mb-30'"
    >
      <cac-acceptance-card
        [data]="data.cacCard"
        [displayStacked]="true"
      ></cac-acceptance-card>
    </div>
  </div>
  <app-sitemap [isMobileView]="data.isMobile"></app-sitemap>
  <app-footer></app-footer>
</ng-container>
