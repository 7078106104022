<app-header></app-header>
<div [ngClass]="{'mob-height': isMobileViewDisplay}" class="sub-components-margin">
  <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [bottonBgColor]="bottonBgColor"
    [overLineBgColor]="overLineBgColor" [overLineTextColor]="overLineTextColor" [isCurvedImage]="isCurvedImage"
    [disclaimerColor]='disclaimerColor'></cac-hero-image>
</div>
<div [ngClass]="!isMobileViewDisplay ? 'container' : 'mobile-container'">
    <div class='sub-components-margin'>
        <app-quick-links [isMobileView]="isMobileViewDisplay" [quickLinks]="quickLinks404" [quickLinksId]="quickLinksId404"></app-quick-links>
    </div>
</div>
<div [ngClass]="!isMobileViewDisplay ? 'container sub-components-margin' : 'mobile-container sub-components-margin'">
    <cac-acceptance-card *ngIf="cardData" [data]="cardData" [displayStacked]="true"></cac-acceptance-card>
</div>
<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>
