<div class="search sub-components-margin">
  <h6 class="search__label">
    {{ content?.searchLabel }}
  </h6>
  <div class="search__autocomplete">
    <div class="search__autocomplete-button">
      <div style="width: 100%">
        <div class="search__autocomplete-text-box-container">
          <input
            #userInput
            [formControl]="autoCompleteFormControl"
            type="text"
            [attr.aria-label]="content?.searchLabel"
            [title]="content?.searchLabel"
            name="addressLine"
            class="search__autocomplete-text-box"
            [class.has-error]="
              autoCompleteFormControl.invalid &&
              (autoCompleteFormControl.dirty || autoCompleteFormControl.touched)
            "
            placeholder=""
            addressRestrictSpecialChars
          />
          <button *ngIf="autoCompleteFormControl.value" class="search__autocomplete-clear" (click)="clearInput()" id="clearButton" type="button">&times;</button>
        </div>
        <div
          *ngIf="
            autoCompleteFormControl.invalid &&
            (autoCompleteFormControl.dirty || autoCompleteFormControl.touched)
          "
          class="validation-error"
        >

        </div>
      </div>
      <button
        (click)="searchDealers(userInput.value)"
        [disabled]="
          autoCompleteFormControl.invalid || autoCompleteFormControl.disabled
        "
        class="btn btn-primary search__autocomplete-submit"
      >
        <div class="search__autocomplete-submit-icon">
          <img src="/assets/images/magnifying-glass-solid.svg" alt="icon" />
          <span class="search__autocomplete-submit-icon-span">Search</span>
        </div>

      </button>
    </div>
    <p class="search__recaptcha-notice">
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy" target="_blank"
        >Privacy Policy</a
      >
      and
      <a href="https://policies.google.com/terms" target="_blank"
        >Terms of Service</a
      >
      apply.
    </p>
    <div class="search__service-error">
      <span *ngIf="serviceErrorDetected" class="search__system-error">{{
        errors?.serviceFailure
      }}</span>
    </div>
  </div>
</div>
