<app-header></app-header>
<div [ngClass]="{'mob-height': isMobileViewDisplay}" class="sub-components-margin">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [disclaimerColor]='disclaimerColor'
        [bottonBgColor]="bottonBgColor" [overLineBgColor]="overLineBgColor" [isCurvedImage]="isCurvedImage">
    </cac-hero-image>
</div>

<div [ngClass]="!isMobileViewDisplay ? 'container' : 'mobile-container mobile-background bottom-shadow'">
    <div class="components-margin">
        <cac-key-points-two [data]="keyPointsTwo"></cac-key-points-two>
    </div>
</div>

<div [ngClass]="!isMobileViewDisplay ? '' : 'mobile-container'">
    <div class='components-margin'>
        <cac-content-block [data]="contentBlock" [leftToRight]="true" [isDefaultColor]="true"></cac-content-block>
    </div>
</div>

<div *ngIf='departments' [ngClass]="!isMobileViewDisplay ? 'container' : 'mobile-container'">
    <div class="components-margin">
        <cac-departments [data]='departments'> </cac-departments>
    </div>
</div>

<div class="components-margin perks-shadow">
    <cac-perks *ngIf='perksData' [data]="perksData" [leftToRight]="false" [displayBtn]='true' [headerCenter]='perksData.headerCenter' [defaultColor]='perksData.defaultColor'></cac-perks>
</div>

<div id="videos" *ngIf="videoData" class="components-margin top-bottom-shadow">
    <video-block [data]="videoData"></video-block>
</div>

<div [ngClass]="!isMobileViewDisplay ? 'container' : 'mobile-container'">
    <div class='components-margin'>
        <div id='awards' class='pb-5'>
            <cac-header data='Awards'></cac-header>
        </div>
        <cac-award [data]="awardData"></cac-award>
    </div>
</div>

<div
  class="static-page sub-components-margin"
  [ngClass]="isMobileViewDisplay ? 'mobile-container' : 'container'"
>
<div [innerHTML]="content">
</div>
</div>

<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>