import { cardDetails } from "app/models/cardDetails";

export const workDealDealerReviews: cardDetails[] = [
  {
    "avatarIcon":"assets/images/work-the-deal/hear-from-dealers/user1.webp",
    "imgAlt":"Austin Vann Dealer Avatar Icon",
    "userDetails": {
      "name":"Lonzell Webb",
      "position":"Sales Manager",
      "corporateName":"Mt. Moriah Auto Sales",
      "Address": "Memphis, TN"
    },
    "link":"/dealer-review-lonzell-webb",
    "buttonName":"more >",
    "description":["Working with Credit Acceptance has grown Mt. Moriah Auto Sales to a point where we’re buying more cars and getting more customers."],
  },
  {
    "avatarIcon":"assets/images/work-the-deal/hear-from-dealers/user2.webp",
    "imgAlt":"Credit FAQ",
    "userDetails": {
      "name":"Chris & Tameka Buben",
      "position":"Owners",
      "corporateName":"Ride Rite",
      "Address": "Memphis, TN"
    },
    "link":"/ride-rite-memphis-dealer-review",
    "buttonName":"more >",
    "description":["We started out with about 15 cars on our lot, four employees counting myself, and it wasn't working. We weren't bringing in any money. I wanted to get into the subprime business, and I thought if I coupled Credit Acceptance with a really great customer experience and a good, quality product, I'd be able to stand out from our competition. I just went all in."],
  },
  {
    "avatarIcon":"assets/images/work-the-deal/hear-from-dealers/user3.webp",
    "imgAlt":"Credit FAQ",
    "userDetails": {
      "name":"Mike Griffin",
      "position":"Used Car Manager",
      "corporateName":"Zeigler Auto Group",
      "Address": "Elkhart, IN"
    },
    "link":"/dealer-review-zeigler-auto",
    "buttonName":"more >",
    "description":["I would recommend Credit Acceptance to almost any size dealership. We can offer financing to all of our customers with most resulting in incremental sales."]
  }
]