import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { GLOSSARY_JSON_URL } from 'app/constants/aws.constants';
import { AWSService } from 'app/services/aws.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.scss']
})
export class GlossaryComponent {

  
  isMobileViewDisplay : boolean = false;
  isBrowser : boolean = false;
  heroImageInfo : any ;
  subscription: Subscription[] = [];
  url = GLOSSARY_JSON_URL;
  overLineBgColor: string = "#FA6400";
  bottonBgColor: string = "#FFB800";
  headerColor: string = "#F4F4EC";
  disclaimerColor: string = '#F4F4EC';
  overLineTextColor: string = "#171714";
  isCurvedImage = false;
  glossaryData: any;
  faqData : any;
  filteredFaqs: any;
  filteredFaqData: any;
  searchKeyword: string = '';
  searchDisplayKeyword: string = '';
  showResults: boolean = false;


  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);

    let meta = document.createElement('meta');
    meta.name = "robots";
    meta.content = "noindex";
    document.getElementsByTagName('head')[0].appendChild(meta);
  }

  ngOnInit(): void {
    
    if(this.isBrowser){
      
      this.awsService.getAWSData(this.url).then(json => {
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        
        json = JSON.parse(json);
        this.glossaryData = json;

        this.heroImageInfo = this.glossaryData.CacHeroImageComponent;
        this.faqData = this.glossaryData.CacGlossaryFaqsData;
        this.filteredFaqs = { ...this.faqData };
        
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }

  selectText(el: HTMLInputElement){
		el.select();
	}

  search() {
    if (this.searchKeyword.trim() === '') {
      this.showResults = false;
      // If the search keyword is empty, show all FAQs
      this.filteredFaqs = { ...this.faqData };
    } else {
      // Filter FAQs based on the search keyword
      const keyword = this.searchKeyword.toLowerCase().trim();
      var filteredFaqData = this.faqData.faqs.filter((faq:any) =>
        faq.question.toLowerCase().includes(keyword) ||
        faq.answer.toLowerCase().includes(keyword)
      );

      this.filteredFaqs = {
          ...this.faqData,
          "header": "",
         "faqs": filteredFaqData.map((faq:any, index:number) => ({
            ...faq,
            "open": index === 0
          }))
      };
      this.searchDisplayKeyword = this.searchKeyword;

      this.showResults = true;
    }
  }

  checkEmptyKeyword() {
    if (this.searchKeyword.trim() === '') {
      this.showResults = false;
      this.filteredFaqs = { ...this.faqData };
    }
  }

}
