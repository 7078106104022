import { bannerImage } from "app/models/bannerImage";

export const workTheDealBannerText: bannerImage = {
  "imgUrl": "assets/images/work-the-deal/banner-image/banner.jpg",
  "mobileImgurl":"assets/images/common/orange-shape-bg-mobile@2x.webp",
  "imgAlt": "Banner Image",
  "buttonName": "Login to Caps",
  "buttonId":"work_deal_login_caps",
  "overlineTitle":"Welcome to Credit Acceptance.",
  "heading": ["You can’t grow by telling people “no.”"],
  "link": "/dealers/caps-redirect"
}