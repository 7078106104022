<div class="prequalified sub-components-margin">
  <div class="prequalified__faqs-wrapper" *ngIf="websiteLinksAndDisclosureEnabled && totalCount > 0">
    <cac-faqs
      [data]="awsS3Disclaimer"
      [isFaqQuestionBold]="true"
    ></cac-faqs>
  </div>
  <div class="prequalified__btn">
    <a
      id="prequal"
      type="button"
      role="link"
      href="{{awsS3Content?.getPreQualifiedButton.url}}"
      class="btn btn-primary"
      aria-label="GET PREQUALIFIED"
      (click)="onClickEvent('', 'prequal')"
      >{{ awsS3Content?.getPreQualifiedButton?.text }}</a
    >
  </div>
</div>
