import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MAKE_PAYMENT_JSON_URL } from 'app/constants/aws.constants';
import {AWSService} from 'app/services/aws.service';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-make-a-payment',
  templateUrl: './make-a-payment.component.html',
  styleUrls: ['./make-a-payment.component.scss']
})
export class MakeAPaymentComponent implements OnInit {

  subscription: Subscription[] = [];

  url = MAKE_PAYMENT_JSON_URL;

  MakePaymentJSONData:any;

  mobileView : boolean = false;
  heroImageInfo : any = {};
  bottonBgColor: string = "#C4EDFA";
  overLineBgColor: string = "#0F7D9C";
  headerColor: string = "#FCFCFA";
  isCurvedImage = true;
  perksData : any = {};
  leftToRight = true;
  displayStoreImg = true;
  faqData : any = {};
  makePaymentData : any = {};
  cardData : any = {};
  isBrowser : boolean = false;

  constructor(private http: HttpClient, private responsive: BreakpointObserver,private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
   
    if(this.isBrowser){

      this.awsService.getAWSData(this.url).then(json => {

        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);
        
        this.MakePaymentJSONData = json;
        
        this.heroImageInfo = this.MakePaymentJSONData.CacHeroImageComponent;
        this.cardData = this.MakePaymentJSONData.CacCardComponent;
        this.perksData = this.MakePaymentJSONData.CacPerksComponent;
        this.faqData = this.MakePaymentJSONData.CacFaqsComponent;
        this.makePaymentData = this.MakePaymentJSONData.CacMakePaymentData;
      });
    }
    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.mobileView = result.matches ? true : false;
        })
    );
  }

 
}
