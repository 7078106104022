import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ESG_JSON_URL } from 'app/constants/aws.constants';
import { AWSService } from 'app/services/aws.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'esg',
  templateUrl: './esg.component.html',
  styleUrls: ['./esg.component.scss']
})
export class EsgComponent implements OnInit {
  subscription: Subscription[] = [];

  url = ESG_JSON_URL;
  esgData : any = {};
  heroImageInfo : any ;
  contentBlock : any[] = [];
  isMobileViewDisplay : boolean = false;
  isBrowser : boolean = false;
  isCurvedImage = true;
  cardData : any = {};
  overLineBgColor: string = "#0F7D9C";
  bottonBgColor: string = "#ffb800";
  headerColor: string = "#171714";


  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {

    if(this.isBrowser){
      
      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);
        this.esgData = json;
        
        this.heroImageInfo = this.esgData.CacHeroImageComponent;
        this.contentBlock = this.esgData.CacContentBlockComponents;
        this.cardData = this.esgData.CacCardComponent;
        
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }

}
