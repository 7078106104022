<app-header></app-header>
<div class='bottom-shadow sub-components-margin' [ngClass]="{'mob-height': isMobileViewDisplay}">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [disclaimerColor]='disclaimerColor'
        [bottonBgColor]="bottonBgColor" [overLineBgColor]="overLineBgColor" [isCurvedImage]="isCurvedImage">
    </cac-hero-image>
</div>


<div id='enrollment' *ngIf='stepsData' class='components-margin'>
    <div [ngClass]="!isMobileViewDisplay ? 'row' : ''">
        <div *ngIf='!isMobileViewDisplay' class='col-6'>
            <img alt="{{stepsData.imageAlt}}" src="{{stepsData.imageUrl}}" class='w-100'>
        </div>
        <div [ngClass]="!isMobileViewDisplay ? 'col-6 px-5' : 'col-12'">
            <cac-steps [data]="stepsData"></cac-steps>
        </div>
    </div>
</div>

<div *ngIf='perksData' [ngClass]="!isMobileViewDisplay ?  'components-margin perks-shadow' : ''">
    <cac-perks [data]="perksData" [defaultColor]='false' [leftToRight]="false" [displayStoreImg]="false"></cac-perks>
</div>

<div *ngIf='dataProofPoint' [ngClass]="!isMobileViewDisplay ?  'components-margin perks-shadow' : ''" class='bottom-shadow'>
    <cac-proofpoint-module [data]="dataProofPoint"></cac-proofpoint-module>
</div>

<div *ngIf='perksTwoData' [ngClass]="!isMobileViewDisplay ?  'components-margin perks-shadow' : ''">
    <cac-perks [data]="perksTwoData" [leftToRight]="true" [displayStoreImg]="true"></cac-perks>
</div>

<div [ngClass]="!isMobileViewDisplay ? 'container' : 'mobile-container'">
    <div id="articles" *ngIf="articleData" class="components-margin">
        <cac-header [data]='articleData.header'></cac-header>
        <div class='components-margin'>
            <cac-articles [articleData]="articleData" [displayStacked]='false'></cac-articles>
        </div>
    </div>
</div>

<div class="components-margin">
    <app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
</div>

<app-footer></app-footer>