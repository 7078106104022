import { AfterViewInit, Component, Input } from '@angular/core';
import { cardDetails } from 'app/models/cardDetails';
import Swiper, { Navigation, Pagination } from 'swiper';
import { CacCard } from './cac-acceptance-card.model';
Swiper.use([Navigation, Pagination]);

@Component({
  selector: 'app-carousel-impl',
  templateUrl: './carousel-impl.component.html',
  styleUrls: ['./carousel-impl.component.scss']
})

export class CarouselImplComponent implements AfterViewInit {
  @Input() slidesData: cardDetails[] = [];
  @Input() sectionName: string = '';
  @Input() btnClass: string = '';
  @Input() isPillTypeBtn: boolean = false;
  @Input() isMobileView: boolean = false;
  @Input() pointsClass: string = '';
  @Input() showReusalbeModule: boolean = false;

  // used for reusable module for make a payment page
  @Input() data: CacCard  = {};


  constructor() { }

  ngAfterViewInit(): void {
    if(!this.showReusalbeModule && this.slidesData  && this.slidesData.length > 0) {
      const swiperInstance =  new Swiper('.swiper-container', {
        slidesPerView: 1.125,      
        speed: 50,
        spaceBetween: 20,
        resizeObserver: true,
        updateOnWindowResize: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletActiveClass:'carousel-btn-active',
        }
      });
      swiperInstance ? swiperInstance.slideReset : '';
    }

    if(this.data && this.showReusalbeModule) {
      const swiperInstance =  new Swiper('.swiper-container', {
        slidesPerView: 1.125,      
        speed: 50,
        spaceBetween: 20,
        resizeObserver: true,
        updateOnWindowResize: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletActiveClass:'carousel-btn-active',
        }
      });
      swiperInstance ? swiperInstance.slideReset : '';
    }
    
  }
}
