import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {AWSService} from 'app/services/aws.service';
import { isPlatformBrowser } from '@angular/common';
import { AUTH_EXCEPTION_JSON_URL } from 'app/constants/aws.constants';

@Component({
  selector: 'app-auth-exception',
  templateUrl: './auth-exception.component.html',
  styleUrls: ['./auth-exception.component.scss']
})
export class AuthExceptionComponent  implements OnInit{
  subscription: Subscription[] = [];
  isBrowser : boolean = false;
  isMobileViewDisplay : boolean = false;

  url = AUTH_EXCEPTION_JSON_URL;
  authExceptionJSONData:any;
  headerData: string = ""
  linksData: any
  
  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);

        //Add no robots meta tag
    let meta = document.createElement('meta');
    meta.name = "robots";
    meta.content = "noindex";
    document.getElementsByTagName('head')[0].appendChild(meta);
    
  }
  

  ngOnInit(): void {
    if(this.isBrowser){

      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);

        this.authExceptionJSONData = json;
        this.headerData = this.authExceptionJSONData.header;
        this.linksData = this.authExceptionJSONData.links;
      });
    }
    
    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }
}
