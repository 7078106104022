import { Component, Input, OnInit } from '@angular/core';
import { creditLinks } from 'app/models/credit_links';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {
  @Input() accordionId: string = 'accordionId';
  @Input() accordionItems: creditLinks[] = [];
  @Input() borderType: string= '';
  @Input() titleClass: string= '';
  @Input() linksClass: string = '';
  @Input() isMobileView: boolean = false;

  isExpanded: boolean[] = [];
  constructor() { }

  ngOnInit(): void {
    this.isExpanded = Array(this.accordionItems.length).fill(false);
  }

  toggleAccordion(i: number) {
    this.isExpanded[i] = !this.isExpanded[i];
  }
}
