import { TabToggleInfo } from "app/models/toggle_tabs"
import { Approval } from "./approval"
import { FullSpectrum } from "./full-spectrum"
import { Speed } from "./speed"

export const makeThingsPossible: TabToggleInfo = {
  heading:["Here’s how we make it all possible."],
  tabInformation: [
    {
      tabTitle: "Approval",
      active: true,
      tabId:"make_things_approval_tab",
      tabContent: Approval
    },
    {
      tabTitle: "Full-spectrum",
      active: false,
      tabId:"make_things_full_spectrum_tab",
      tabContent: FullSpectrum
    },
    {
      tabTitle: "Speed",
      active: false,
      tabId:"make_things_speed_tab",
      tabContent: Speed
    }
  ]
}
