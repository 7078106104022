import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { catchError, Observable, Observer, retry, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {
  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {}

  /***
   * Method to load a script dynamically using an observable
   * @param src - The URL of the script to load
   * @returns Observable that emits when the script is loaded or errors if it fails
   */
  loadScript(src: string): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      // Check if the script is already present in the document
      const existingScript = this.document.querySelector(`script[src="${src}"]`);

      if (existingScript) {
        observer.next(true);
        observer.complete();
        return;
      }

      // Create a new script element
      const script: HTMLScriptElement = this.document.createElement('script');
      script.src = src;
      script.async = false;

      // On successful script load
      script.onload = () => {
        console.log('Dealer Finder Google Script loaded');
        observer.next(true);
        observer.complete();
      };

      // On script loading error
      script.onerror = () => {
        console.error('Failed to load Google Script tag');
        observer.error(`Failed to load script ${src}`);
      };

      // Add the script to the <head> section only on subscription
      this.document.head.appendChild(script);
    }).pipe(
      retry(3), // Retry loading the script three times
      catchError((error) => {
        console.error(error);
        return throwError(() => new Error(`Script loading failed after 3 retries ${error}`));
      })
    );
  }

}
