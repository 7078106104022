<app-header [showOnlyLogo]="true"></app-header>
<app-hero-image [heroImageInfo]="heroImageInfo" [isMobileView]="mobileView"></app-hero-image>
<app-credit-introduction [information]="intro" [isMobileView]="mobileView"></app-credit-introduction>
<app-users-toggle [tabInfo]="makeThingsPossible" [tabContent]="makeThingContent" [isMobileView]="mobileView"></app-users-toggle>
<app-image-text-overlay [details]="carImgTextOverlayRewards" [isMobileView]="mobileView"></app-image-text-overlay>
<div *ngIf="capsLoginGetStarted" class="text-center p-4">
  <a class="indigo-link" attr.aria-label="{{capsLoginGetStarted.ariaLabel}}" role="link"
  id="{{capsLoginGetStarted.id}}" appAnchorClick [linkInfo]="capsLoginGetStarted">{{capsLoginGetStarted.title}}</a>
</div>
<app-users-toggle [tabInfo]="waysBusinessGrow" [tabContent]="waysGrowContent" [isMobileView]="mobileView"></app-users-toggle>
<app-portfolio-purchase [details]="portFolioPurchaseDetails" [isMobileView]="mobileView"></app-portfolio-purchase>
<app-hear-from-people [hearFromPeople]="hearFromDealers" [hearFromPeoplebottonBtnLink]="hearInfo" [hearFromPeoplesectionTitle]="hearInfoSectionTitle" [isMobileView]="mobileView"></app-hear-from-people>
<app-numbers-speak [creditNumSpeak]="dealerNumSpeak" [isMobileView]="mobileView"></app-numbers-speak>
<div *ngIf="dealerPoolDisc.disc_text" class="container">
  <p role="note" attr.aria-label="{{dealerPoolDisc.disc_text}}" class="footer-links text-decoration-none text-transform-none m-0 pt-3">
    {{dealerPoolDisc.disc_text}}
  </p>
</div>
<div *ngIf="eligibleDisc.disc_text" class="container">
  <p role="note" attr.aria-label="{{eligibleDisc.disc_text}}" class="footer-links text-decoration-none text-transform-none m-0 pt-3">
    {{eligibleDisc.disc_text}}
  </p>
</div>
<div *ngIf="remoteDisc.disc_text" class="container">
  <p role="note" attr.aria-label="{{remoteDisc.disc_text}}" class="footer-links text-decoration-none text-transform-none m-0 pt-3">
    {{remoteDisc.disc_text}}
  </p>
</div>
<app-footer></app-footer>
