
<div *ngIf="hearFromPeople" aria-label="Page hear from People Section">
  <div class="py-3 container">
    <div [ngClass]= "{ 'secondary-heading' : isMobileView}">
      <span class="h3" [ngClass]= "{ 'secondary-heading' : !isMobileView}"><span  attr.aria-label="{{hearFromPeoplesectionTitle}}">{{hearFromPeoplesectionTitle}}</span></span>
    </div>  
  </div>
  <div class="container">
    <div *ngIf="!isMobileView" class="pt-3">
      <app-acceptance-card [cardDetails]="hearFromPeople" [sectionName]="hearFromPeoplesectionTitle" [buttonClass]="'btn btn-pill'" [isPillTypeBtn]="true" [isMobileView]="isMobileView" [cardClasses]="'card card-expands'"></app-acceptance-card>
    </div>
  </div>
  <div class="pt-4" *ngIf="isMobileView">
    <app-carousel-impl [slidesData]="hearFromPeople" [sectionName]="hearFromPeoplesectionTitle" [btnClass]="'btn btn-pill'" [isPillTypeBtn]="true" [isMobileView]="isMobileView"></app-carousel-impl>
  </div>
  <div *ngIf="hearFromPeoplebottonBtnLink" class="text-center p-4">
    <a class="indigo-link" attr.aria-label="{{hearFromPeoplebottonBtnLink.ariaLabel}}" role="link"
    id="{{hearFromPeoplebottonBtnLink.id}}" appAnchorClick [linkInfo]="hearFromPeoplebottonBtnLink">{{hearFromPeoplebottonBtnLink.title}}</a>
  </div>
</div>

