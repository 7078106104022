import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Component, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AWSService } from 'app/services/aws.service';
import { combineLatest, map, Observable, of } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LegacyBlogArticleData, PageData } from './data.model';

@Component({
  selector: 'legacy-blog-article',
  templateUrl: './legacy-blog-article.component.html',
  styleUrls: ['./legacy-blog-article.component.scss'],
})
export class LegacyBlogArticleComponent implements OnInit {
  private readonly routeData = inject(ActivatedRoute).snapshot.data;
  private readonly responsive = inject(BreakpointObserver);
  private readonly awsService = inject(AWSService);
  private readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  private readonly metaService = inject(Meta);

  data!: Observable<PageData | null>;

  ngOnInit(): void {
    const { awsUrl, customStyles } = this.routeData;
    this.data = this.isBrowser
      ? combineLatest({
          pageData: this.awsService
            .getAWSData(awsUrl)
            .then(
              (json) =>
                JSON.parse(
                  JSON.stringify(json).replace(
                    /AWS_BUCKET_URL/g,
                    this.awsService.getAWSBucketURL(),
                  ),
                ) as LegacyBlogArticleData,
            ),
          breakpointState: this.responsive.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
          ]),
        }).pipe(
          map(
            ({ pageData, breakpointState: { matches: isMobile } }) => ({
              isMobile,
              ...pageData,
            }),
          ),
        )
      : of(null);
  }
}
