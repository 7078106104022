import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { adobeAnalytics } from 'app/models/adobe_analytics';
import { bannerImage } from 'app/models/bannerImage';
import { cardDetails } from 'app/models/cardDetails';
import { creditLinks } from 'app/models/credit_links';

@Directive({
  selector: '[appAnchorClick]',
})
export class AnchorClickDirective {
  @Input() linkInfo: cardDetails | creditLinks | bannerImage = {};
  constructor(
    private router: Router,
    private elRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    const anchorTags = document.querySelectorAll('[appAnchorClick]');
    anchorTags.forEach((elem) => {
      elem.setAttribute('tabindex', '0');
    });
  }

  handleRelativeLink(link: string): void {
    const currentRoutePath = new URL(link, window.location.origin).pathname.slice(1);
    this.router.config.find(({ path }) =>
      path === currentRoutePath)
      ? this.router.navigateByUrl(link)
      : window.open(link, '_self');
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.preventDefault();
    if (this.linkInfo && this.linkInfo?.link) {
      const { link } = this.linkInfo;
      if (link.charAt(0) === '/') {
        this.handleRelativeLink(link);
      } else if (this.router.config.find((route) => route.path === link)) {
        this.handleRelativeLink(link);
      } else {
        window.open(link, '_blank');
      }
    }
  }

  @HostListener('mouseover')
  @HostListener('touchstart')
  @HostListener('focus')
  onMouseOver() {
    if (this.linkInfo) {
      this.linkInfo?.isExternalLink || this.linkInfo?.target
        ? this.renderer.setAttribute(
            this.elRef.nativeElement,
            'target',
            '_blank'
          )
        : '';
      this.renderer.setAttribute(
        this.elRef.nativeElement,
        'href',
        this.linkInfo.isCustomerLink
          ? this.gotoCustomerLink(this.linkInfo)
          : this.buildRouteUrl(this.linkInfo)
      );
    }
  }

  buildRouteUrl(linkInfo: creditLinks | cardDetails | bannerImage): string {
    if (!linkInfo?.link) {
      return '';
    }
    if (!linkInfo?.isExternalLink && linkInfo?.link) {
      if (
        localStorage.getItem('utm_source') &&
        localStorage.getItem('utm_medium') &&
        localStorage.getItem('utm_campaign') &&
        localStorage.getItem('utm_content')
      ) {
        linkInfo.link = linkInfo.link + '?';
        linkInfo.link =
          linkInfo.link + 'utm_source=' + localStorage.getItem('utm_source');
        linkInfo.link =
          linkInfo.link + '&utm_medium=' + localStorage.getItem('utm_medium');
        linkInfo.link =
          linkInfo.link +
          '&utm_campaign=' +
          localStorage.getItem('utm_campaign');
        linkInfo.link =
          linkInfo.link + '&utm_content=' + localStorage.getItem('utm_content');
      }
    }
    return linkInfo.link;
  }

  gotoCustomerLink(linkInfo: creditLinks | cardDetails | bannerImage): string {
    const hostLocation = window.location.host;
    if (hostLocation.indexOf('wwwtest') != -1) {
      return (
        'https://customertest3.creditacceptance.com' +
        this.buildRouteUrl(linkInfo)
      );
    } else if (hostLocation.indexOf('wwwqa') != -1) {
      return (
        'https://customerqa.creditacceptance.com' + this.buildRouteUrl(linkInfo)
      );
    }
    return (
      'https://customer.creditacceptance.com' + this.buildRouteUrl(linkInfo)
    );
  }
}
