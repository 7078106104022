import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './page-component/home-page/home-page.component';
import { WorkTheDealComponent } from './page-component/work-the-deal/work-the-deal.component';
import { PromoComponent } from './page-component/promo/promo.component';
import { PromoRewardsComponent } from './page-component/promo-rewards/promo-rewards.component';
import { GrowYourDealershipComponent } from './page-component/grow-your-dealership/grow-your-dealership.component';
import { CacCustomersHomeComponent } from './page-component/cac-customers-home/cac-customers-home.component';
import { GuestPaymentComponent } from './page-component/guest-payment/guest-payment.component';
import { MakeAPaymentComponent } from './page-component/make-a-payment/make-a-payment.component';
import { ContactUsComponent } from './page-component/contact-us/contact-us.component';
import { DealerReviewsComponent } from './page-component/dealer-reviews/dealer-reviews.component'
import { CustomerEducation } from './page-component/customer-education/customer-education.component';
import { MonthlyCalculatorComponent } from './page-component/monthly-payment-calculator/monthly-payment-calculator.component';
import { AffordabilityCalculatorComponent } from './page-component/affordability-calculator/affordability-calculator.component';
import { CustomerReviewsComponent } from './page-component/customer-reviews/customer-reviews.component';
import { EsgComponent } from './page-component/esg/esg.component';
import { AboutUsComponent } from './page-component/about-us/about-us.component';
import { DealerBlogsComponent } from './page-component/dealer-blogs/dealer-blogs.component';
import { CustomerBlogsComponent } from './page-component/customer-blogs/customer-blogs.component';
import { LicenseToDriverLandingComponent } from './page-component/license-to-driver-landing/license-to-driver-landing.component';
import { DealerEducation } from './page-component/dealer-education/dealer-education.component';
import { DealerFaqsComponent } from './page-component/dealer-faqs/dealer-faqs.component';
import { CustomerFaqs } from './page-component/customer-faqs/customer-faqs.component';
import { CareersCorporateLegalComponent } from './page-component/careers-corporate-legal/careers-corporate-legal.component';
import { CareersSalesComponent } from './page-component/careers-sales/careers-sales.component';
import { CareersCallCenterComponent } from './page-component/careers-call-center/careers-call-center.component';
import { CareersFinanceComponent } from './page-component/careers-finance/careers-finance.component';
import { CareersProductComponent } from './page-component/careers-product/careers-productcomponent';
import { CareersMarketingComponent } from './page-component/careers-marketing/careers-marketing.component';
import { CareersEngineeringComponent } from './page-component/careers-engineering/careers-engineering.component';
import { CareersLandingComponent } from './page-component/careers-landing/careers-landing.component';
import { CareersHrComponent } from './page-component/careers-hr/careers-hr.component';
import { CareersAnalyticsComponent } from './page-component/careers-analytics/careers-analytics.component';
import { CareersStrategicComponent } from './page-component/careers-stategic/careers-strategic.component';
import { DealerHowItWorksComponent } from './page-component/dealer-how-it-works/dealer-how-it-works.component';
import { StartMyJourneyComponent } from './page-component/start-my-journey/start-my-journey.component';
import { DealerHome } from './page-component/dealers/dealer-home.component';
import { CustomerHowItWorksComponent } from './page-component/customer-how-it-works/customer-how-it-works.component';
import { YourCreditApprovalComponent } from './page-component/your-credit-approval/your-credit-approval.component';
import { DealersPurchaseProgramComponent } from './page-component/dealers-purchase-program/dealers-purchase-program.component';
import { PageNotFoundComponent } from './page-component/page-not-found/page-not-found.component';
import { MaintenanceComponent } from './page-component/maintenance/maintenance.component';
import { WebAccessibilityComponent } from './page-component/web-accessibility/web-accessibility.component';
import { NycLanguageComponent } from './page-component/nyc-language/nyc-language.component';
import { TermsOfUseComponent } from './page-component/terms-of-use/terms-of-use.component';
import { DealerEsignatureComponent } from './page-component/dealer-esignature/dealer-esignature.component';
import { PrivacyComponent } from './page-component/privacy/privacy.component';
import { WebsiteUserAgreementComponent } from './page-component/website-user-agreement/website-user-agreement.component';
import { PortfolioLandingComponent } from './page-component/portfolio-landing/portfolio-landing.component';
import { GlossaryComponent } from './page-component/glossary/glossary.component';
import { CommunityComponent } from './page-component/community/community.component';
import { CarBuyersResourceComponent } from './page-component/car-buyers-resource/car-buyes-resource.component';
import { OnlineVerificationComponent } from './page-component/online-verification/online-verification.component';
import { JoshuaMotorsComponent } from './page-component/joshua-motors/joshua-motors.component';
import { SteveLandersComponent } from './page-component/steve-landers-auto/steve-landers-auto.component';
import { MagicCityMotorsComponent } from './page-component/magic-city-motors/magic-city-motors.component';
import { BellAutoSalesComponent } from './page-component/bell-auto/bell-auto.component';
import { AutoMaxComponent } from './page-component/automax/automax.component';
import { ConsumerVideosComponent } from './page-component/consumer-videos/consumer-videos.component';
import { DealerVideosComponent } from './page-component/dealer-videos/dealer-videos.component';
import { LonzellWebbComponent } from './page-component/lonzell-webb/lonzell-webb.component';
import { JoinNetworkComponent } from './page-component/join-our-network/join-our-network.component';
import { JoinNetworkThankYouComponent } from './page-component/join-our-network-thank-you/join-our-network-thank-you.component';
import { ReelsAutoSalesComponent } from './page-component/reels-auto-sales/reels-auto-sales.component';
import { PremierAutomotiveComponent } from './page-component/premier-automotive/premier-automotive.component';
import { ZeiglerAutoComponent } from './page-component/zeigler-auto/zeigler-auto.component';
import { MooreStewartFordComponent } from './page-component/moore-stewart-ford/moore-stewart-ford.component';
import { WebsmartAutoComponent } from './page-component/websmart-auto/websmart-auto.component';
import { RideRiteAutoComponent } from './page-component/ride-rite-auto/ride-rite-auto.component';
import { CapsMaintenanceComponent } from './page-component/caps-maintenance/caps-maintenance.component';
import { TwinsAutoSalesComponent } from './page-component/twins-auto-sales/twins-auto-sales.component';
import { AuthExceptionComponent } from './page-component/auth-exception/auth-exception.component';
import { CovidResponseComponent } from './page-component/covid-response/covid-response.component';
import { CapsRedirectComponent } from './page-component/caps-redirect/caps-redirect.component';
import {
  GrowYourDealershipSpComponent
} from "./page-component/grow-your-dealership-sp/grow-your-dealership-sp.component";
import { BigCheckCampaignComponent } from "./page-component/big-check-campaign/big-check-campaign.component";
import { BuyHerePayHereCampaignComponent } from './page-component/buy-here-pay-here-campaign/buy-here-pay-here-campaign.component';
import { BlogArticleComponent } from "./page-component/blogs/blog-article/blog-article.component";
import { DealerFinderComponent } from './page-component/dealer-finder/dealer-finder.component';
import {
  ADVANTAGES_OF_HYBRID_CARS_BLOG_JSON_URL,
  UNDERSTANDING_SIMPLE_INTREST_BLOG_JSON_URL,
  AUTO_SALES_BLOG_JSON_URL,
  BUILD_CREDIT_HISTORY_BLOG_JSON_URL,
  CAR_BUYING_MISTAKES_BLOG_JSON_URL,
  CREDIT_SCORE_MYTHS_BLOG_JSON_URL,
  FACTORS_TO_CONSIDER_BLOG_JSON_URL,
  FIRST_TIME_DRIVERS_BLOG_JSON_URL,
  QUESTIONS_TO_ASK_WHEN_BUYING_BLOG_JSON_URL,
  THINGS_TO_KNOW_BLOG_JSON_URL,
  WHY_CHOOSE_AN_ENROLLED_DEALER_JSON_URL,
  GET_APPROVED_FOR_NEXT_CAR_JSON_URL,
  CAR_GIFT_IDEAS_JSON_URL,
  FOODS_IN_CAR_WITHOUT_MESS_JSON_URL,
  SIX_ROAD_TRIP_ROUTES_FOR_THE_SUMMER_JSON_URL,
  CAR_FINANCING_TIPS_JSON_URL,
  BEST_TIMES_OF_THE_YEAR_TO_PURCHASE_A_VEHICLE_JSON_URL,
  BUYING_A_CAR_OUT_OF_STATE_WHAT_TO_KNOW_JSON_URL,
  BUYING_A_CAR_WITH_BAD_CREDIT_OR_NO_CREDIT_JSON_URL,
  BUYING_A_CAR_AFTER_BANKRUPTCY_JSON_URL,
  BUYING_CAR_WITH_MULTIPLE_REPOSSESSIONS_JSON_URL,
  BUYING_CAR_NO_DOWN_PAYMENT_JSON_URL,
  CAR_BUYING_DOS_AND_DONTS_JSON_URL,
  CHECKING_HISTORY_REPORT_ON_A_USED_CAR_JSON_URL,
  COSIGNER_JSON_URL,
  CAR_LOAN_PROOF_OF_INCOME_JSON_URL,
  DO_TAXES_AFFECT_YOUR_CREDIT_SCORE_JSON_URL,
  GAS_VS_ELECTRIC_CAR_FACTS_JSON_URL,
  GOOD_CREDIT_HABITS_JSON_URL,
  CAN_YOU_FINANCE_VEHICLE_WITH_NO_HARD_INQUIRY_JSON_URL,
  HOW_TO_FIX_RUST_ON_CAR_JSON_URL,
  HOW_TO_CHECK_CREDIT_SCORE_JSON_URL,
  DOES_CAR_INSURANCE_GO_UP_AFTER_ACCIDENT_JSON_URL,
  HOW_MUCH_TO_SPEND_ON_A_CAR_JSON_URL,
  HOW_OFTEN_SHOULD_YOU_CHANGE_YOUR_OIL_JSON_URL,
  HOW_OFTEN_SHOULD_YOU_GET_A_CAR_WASH_JSON_URL,
  HOW_OFTEN_SHOULD_YOU_GET_YOUR_CAR_DETAILED_JSON_URL,
  HOW_OFTEN_SHOULD_YOU_GET_YOUR_TIRES_ROTATED_JSON_URL,
  CREDIT_FRAUD_DETECTION_JSON_URL,
  HOW_TO_BUILD_YOUR_CREDIT_HISTORY_AFTER_COLLEGE_JSON_URL,
  HOW_TO_CALCULATE_MPG_JSON_URL,
  HOW_TO_CHANGE_A_FLAT_TIRE_JSON_URL,
  HOW_TO_CLEAN_ENGINE_BAY_JSON_URL,
  HOW_TO_DETERMINE_YOUR_CAR_BUDGET_JSON_URL,
  WHAT_CARS_CAN_I_AFFORD_JSON_URL,
  ESTIMATING_CAR_INSURANCE_JSON_URL,
  USED_CAR_FINANCING_JSON_URL,
  BUDGETING_AND_CREDIT_SCORE_JSON_URL,
  HOW_TO_IMPROVE_YOUR_CREDIT_ESTABLISHING_CREDIT_HISTORY_JSON_URL,
  HOW_TO_IMPROVE_YOUR_CREDIT_FIXING_ERRORS_ON_YOUR_CREDIT_REPORT_JSON_URL,
  HOW_TO_IMPROVE_YOUR_CREDIT_PAYING_DOWN_DEBT_JSON_URL,
  HOW_TO_INCREASE_YOUR_CHANCES_OF_GETTING_APPROVED_FOR_AUTO_FINANCING_JSON_URL,
  PROTECTING_YOUR_CAR_FROM_SUN_JSON_URL,
  PREVENTING_FROST_ON_WINDSHIELD_JSON_URL,
  WHAT_TO_BRING_WHEN_TAILGATING_JSON_URL,
  HOW_TO_NARROW_DOWN_CAR_CHOICES_JSON_URL,
  HOW_TO_PARALLEL_PARK_JSON_URL,
  HOW_TO_PAY_OFF_YOUR_CAR_FASTER_JSON_URL,
  PREPARING_CARS_FOR_WINTER_JSON_URL,
  HOW_TO_PROTECT_YOUR_CAR_FROM_GETTING_STOLEN_JSON_URL,
  HOW_TO_REBUILD_YOUR_CREDIT_AFTER_BANKRUPTCY_JSON_URL,
  HOW_TO_REBUILD_YOUR_CREDIT_AFTER_REPOSSESSION_JSON_URL,
  HOW_TO_MAINTAIN_A_CAR_JSON_URL,
  HOW_TO_REGISTER_YOUR_VEHICLE_JSON_URL,
  REMOVE_DENT_FROM_CAR_JSON_URL,
  HOW_TO_SET_REALISTIC_FINANCIAL_GOALS_THAT_MAY_HELP_IMPROVE_YOUR_CREDIT_JSON_URL,
  HOW_TO_TRADE_IN_YOUR_CAR_JSON_URL,
  IS_NOW_THE_RIGHT_TIME_FOR_YOU_TO_BUY_A_CAR_JSON_URL,
  KEY_INFORMATION_YOU_LL_FIND_IN_YOUR_CAR_MANUAL_JSON_URL,
  LONGEST_LASTING_VEHICLES_JSON_URL,
  MAINTAIN_OR_TRADE_EVALUATING_THE_COST_OF_REPAIRING_VS_TRADING_IN_YOUR_CAR_JSON_URL,
  ONLINE_CAR_SHOPPING_JSON_URL,
  REBUILDING_YOUR_CREDIT_WITH_BAD_CREDIT_HISTORY_JSON_URL,
  SHOULD_I_BUY_A_NEW_OR_USED_CAR_JSON_URL,
  SHOULD_YOU_CHECK_YOUR_CREDIT_SCORE_BEFORE_PURCHASING_A_VEHICLE__JSON_URL,
  SIGNS_YOU_RE_FALLING_INTO_A_BAD_CREDIT_CYCLE_JSON_URL,
  WEIRD_CARS_JSON_URL,
  HOW_CREDIT_WORKS_JSON_URL,
  THE_DIFFERENCE_BETWEEN_DIRECT_AND_INDIRECT_AUTO_FINANCING_JSON_URL,
  THE_DIFFERENCE_BETWEEN_LEASING_AND_AUTO_FINANCI_1_JSON_URL,
  WINNING_A_CAR_AND_TAXES_JSON_URL,
  THE_RIGHT_CAR_FOR_YOUR_LIFESTY_JSON_URL,
  THE_ULTIMATE_GUIDE_TO_BUY_A_CAR_AND_IMPROVE_YOUR_CREDIT_JSON_URL,
  THINKING_OF_BUYING_A_NEW_OR_USED_CAR_LET_S_WEIGH_YOUR_OPTIONS_JSON_URL,
  TOP_CAR_SAFETY_FEATURES_FOR_TEEN_DRIVERS_JSON_URL,
  DOG_FRIENDLY_CARS_JSON_URL,
  TOP_ECO_FRIENDLY_CARS_JSON_URL,
  TOP_FAMILY_CARS_AND_SUVS_JSON_URL,
  TRUNK_OR_TREAT_IDEAS_JSON_URL,
  UNDERSTANDING_YOUR_CAR_PAYMENT_INTEREST_BEARING_JSON_URL,
  UNDERSTANDING_YOUR_CAR_PAYMENT_PRECOMPUTED_JSON_URL,
  CAR_BUYING_RESOURCES_JSON_URL,
  GOOD_FIRST_TIME_CARSS_JSON_URL,
  WHAT_DOCUMENTS_DO_I_NEED_TO_PURCHASE_A_VEHICLE_JSON_URL,
  WHAT_FACTORS_AFFECT_MY_CAR_PAYMENTS_JSON_URL,
  WHAT_HAPPENS_IF_YOU_CRASH_A_CAR_DURING_A_TEST_DRIVE_JSON_URL,
  DIFFERENCE_BETWEEN_CREDIT_REPORT_AND_CREDIT_SCORE_JSON_URL,
  LENGTH_OF_EMPLOYMENT_FOR_CAR_FINANCING_JSON_URL,
  DENIED_AUTO_FINANCING_WHAT_TO_DO_JSON_URL,
  ALL_WHEEL_OR_FRONT_WHEEL_DRIVE_JSON_URL,
  DIFFERENCE_BETWEEN_CAR_LOAN_AND_FINANCING_JSON_URL,
  WHAT_TO_KNOW_BEFORE_BUYING_A_CAR_JSON_URL,
  WHAT_IS_A_CAR_TITLE_JSON_URL,
  HOW_TO_CHECK_MILEAGE_OF_A_CAR_JSON_URL,
  CREDIT_HISTORY_JSON_URL,
  SELF_DRIVING_CARS_JSON_URL,
  THREE_EFFECTIVE_WAYS_TO_IMPROVE_YOUR_CAR_SALES_JSON_URL,
  THREE_WAYS_TO_IMPROVE_YOUR_CAR_DEALERSHIP_S_CASH_FLOW_JSON_URL,
  FIVE_KEYS_TO_HAVING_A_SUCCESSFUL_INVENTORY_RECONDITIONING_PROCESS_JSON_URL,
  BENEFITS_OF_HAVING_AN_AUTOMOTIVE_CRM_SYSTEM_AT_YOUR_DEALERSHIP_JSON_URL,
  BENEFITS_OF_WORKING_WITH_A_SUBPRIME_FINANCE_COMPANY_JSON_URL,
  CAR_BUYING_TRENDS_FOR_GEN_Z_JSON_URL,
  CAR_BUYING_TRENDS_FOR_MILLENNIALS_1_JSON_URL,
  CAR_BUYING_TRENDS_FOR_RECENT_COLLEGE_GRADS_AND_STUDENTS_JSON_URL,
  CAR_SELLING_101_HOW_TO_MAINTAIN_REPEAT_AND_REFERRAL_BUSINESS_FOR_YOUR_DEALERSH_1_JSON_URL,
  CREATIVE_WAYS_TO_BUILD_BRAND_AWARENESS_FOR_YOUR_DEALERSHIP_JSON_URL,
  DIFFERENT_TRENDS_OF_MALE_AND_FEMALE_CAR_BUYERS_JSON_URL,
  HOW_CAR_DEALERS_CAN_APPROVE_CUSTOMERS_WITH_BAD_CREDIT_OR_NO_CREDIT_JSON_URL,
  HOW_CAR_DEALERSHIPS_CAN_APPROVE_CAR_BUYERS_WITH_MULTIPLE_OPEN_AUTOS_OR_REPOSSESSIONS_JSON_URL,
  HOW_DEALERSHIPS_CAN_APPROVE_CAR_BUYERS_WITH_BANKRUPTCY_OR_FORECLOSURE_JSON_URL,
  HOW_DEALERSHIPS_CAN_APPROVE_CAR_BUYERS_WITH_UNEMPLOYMENT_OR_FIXED_INCOME_JSON_URL,
  HOW_DOES_THE_CREDIT_ACCEPTANCE_PROGRAM_WORK__JSON_URL,
  HOW_OFFERING_SUBPRIME_AUTO_FINANCING_CAN_BENEFIT_BUY_HERE_PAY_HERE_DEALERS_JSON_URL,
  HOW_THE_CREDIT_ACCEPTANCE_PORTFOLIO_PROGRAM_CAN_HELP_YOUR_BHPH_DEALERSHIP_JSON_URL,
  HOW_THE_CREDIT_ACCEPTANCE_PORTFOLIO_PROGRAM_CAN_HELP_YOUR_FRANCHISE_DEALERSHIP_JSON_URL,
  HOW_THE_CREDIT_ACCEPTANCE_PORTFOLIO_PROGRAM_CAN_HELP_YOUR_INDEPENDENT_DEALERSHIP_JSON_URL,
  HOW_TO_EQUIP_YOUR_CAR_DEALERSHIP_TO_MAINTAIN_COMPLIANCE_JSON_URL,
  HOW_TO_HELP_CAR_BUYERS_WITH_AUTO_FINANCING_JSON_URL,
  HOW_TO_SECURE_FINANCING_OPTIONS_FOR_YOUR_CAR_DEALERSHIP_JSON_URL,
  IMPROVING_CUSTOMER_RETENTION_AT_YOUR_DEALERSHIP_JSON_URL,
  INCREASING_USED_CAR_SALES_AT_YOUR_FRANCHISE_DEALERSHIP_JSON_URL,
  LISTENING_TO_UNDERSTAND_YOUR_CUSTOMERS_VS_LISTENING_TO_SELL_JSON_URL,
  PREPARING_YOUR_CAR_DEALERSHIP_FOR_TAX_SEASON_IN_3_SIMPLE_STE_1_JSON_URL,
  PREPARING_YOUR_CAR_DEALERSHIP_FOR_TAX_SEASON_IN_3_SIMPLE_STEPS_JSON_URL,
  QUESTIONS_TO_ASK_CUSTOMERS_LOOKING_TO_BUY_A_CAR_JSON_URL,
  TOP_5_WAYS_YOUR_CAR_DEALERSHIP_CAN_GENERATE_LEADS_JSON_URL,
  WHAT_BHPH_DEALERS_SHOULD_KNOW_ABOUT_SUBPRIME_FINANCING_JSON_URL,
  WHY_LEAD_GENERATION_IS_IMPORTANT_FOR_YOUR_CAR_DEALERSHIP_JSON_URL,
  WHY_YOUR_DEALERSHIP_WEBSITE_SHOULD_BE_MOBILE_FRIENDLY_JSON_URL,
  WORKING_WITH_FIRST_TIME_CAR_BUYERS_WITH_NO_CREDIT_JSON_URL,
  WORKING_WITH_SELF_EMPLOYED_CAR_BUYERS_JSON_URL,
  CUSTOMER_CENTRIC_BLOG_JSON_URL,
  CAR_INSURANCE_JSON_URL,
  FINANCING_CAR_HELP_REBUILD_CREDIT_JSON_URL,
  HOW_IT_WORKS_GETTING_FINANCED_THROUGH_A_DEALER_ENROLLED_WITH_CREDITACCEPTANCE_JSON_URL,
  POSITIVE_TEAM_ATMOSPHERE_JSON_URL,
  SUBPRIME_CAR_FINANCING_JSON_URL,
  TRAIN_CAR_DEALERSHIP_STAFF_JSON_URL,
  WHAT_TO_KNOW_JSON_URL,
} from "./constants/aws.constants";
import {
  ConsumerPaidChannelLandingComponent
} from "./page-component/consumer-paid-channel-landing/consumer-paid-channel-landing.component";
import {LegacyBlogArticleComponent} from "./page-component/legacy-blog-article/legacy-blog-article.component";
import { NoSsnNoItinCampaignComponent } from './page-component/no-ssn-no-itin-campaign/no-ssn-no-itin-campaign.component';
import { dealerFinderRedirectGuard } from './guards/dealer-finder-redirect.guard';
import { DealersPurchaseProgramFlatComponent } from './page-component/dealers-purchase-program-flat/dealers-purchase-program-flat.component';

const routes: Routes = [
  {path: 'customers', component: CacCustomersHomeComponent},
  {path: 'customers/make-a-payment', component: MakeAPaymentComponent},
  {path: 'customers/make-a-payment/guest-payment', component: GuestPaymentComponent},
  {path: 'car-buyers/monthly-payment-calculator', component: MonthlyCalculatorComponent},
  {path: 'car-buyers/car-price-calculator', component: AffordabilityCalculatorComponent},
  {path: 'car-buyers/dealer-finder', component: DealerFinderComponent, canActivate: [dealerFinderRedirectGuard]},
  {path: 'car-buyers/dealer-finder/:location', component: DealerFinderComponent, canActivate: [dealerFinderRedirectGuard]},
  {path: 'about/contact-us', component: ContactUsComponent},
  {path: 'campaign/grow-your-dealership', component: GrowYourDealershipComponent},
  {path: 'campaign/grow-your-dealership/sp', component: GrowYourDealershipSpComponent},
  {path: 'campaign/work-the-deal', component: WorkTheDealComponent},
  {path: 'campaign/200-promo', component: PromoComponent},
  {path: 'campaign/license-to-drive', component: LicenseToDriverLandingComponent},
  {path: 'campaign/200-promo-rewards', component: PromoRewardsComponent},
  {path: 'campaign/big-check', component: BigCheckCampaignComponent },
  {path: 'campaign/bhph', component: BuyHerePayHereCampaignComponent },
  {path: 'campaign/prequalify', component: ConsumerPaidChannelLandingComponent },
  {path: '', component: HomePageComponent},
  {path: 'dealers/reviews', component: DealerReviewsComponent},
  {path: 'dealers/the-lot', component: DealerBlogsComponent},
  {path: 'customers/reviews', component: CustomerReviewsComponent},
  {path: 'car-buyers/express-lane', component: CustomerBlogsComponent},
  {path: 'car-buyers/express-lane/financial-education', component: CustomerEducation},
  {path: 'about/esg', component: EsgComponent},
  {path: 'about', component: AboutUsComponent},
  {path: 'dealers/resources', component: DealerEducation},
  {path: 'dealers/faq', component: DealerFaqsComponent},
  {path: 'customers/faq', component: CustomerFaqs},
  {path: 'careers/legal', component: CareersCorporateLegalComponent},
  {path: 'careers/sales', component: CareersSalesComponent},
  {path: 'careers/business-operations', component: CareersCallCenterComponent},
  {path: 'careers/finance', component: CareersFinanceComponent},
  {path: 'careers/engineering', component: CareersEngineeringComponent},
  {path: 'careers/marketing', component: CareersMarketingComponent},
  {path: 'careers/product-management', component: CareersProductComponent},
  {path: 'careers', component: CareersLandingComponent},
  {path: 'careers/hr', component: CareersHrComponent},
  {path: 'careers/analytics', component: CareersAnalyticsComponent},
  {path: 'careers/strategic-alignment', component: CareersStrategicComponent},
  {path: 'car-buyers', component: StartMyJourneyComponent},
  {path: 'dealers', component: DealerHome},
  {path: 'dealers/how-it-works', component: DealerHowItWorksComponent},
  {path: 'car-buyers/how-it-works', component: CustomerHowItWorksComponent},
  {path: 'your-credit-approval', component: YourCreditApprovalComponent},
  {path: 'dealers/purchase-program', component: DealersPurchaseProgramComponent},
  {path: 'legal/accessibility', component: WebAccessibilityComponent},
  {path: 'legal/language-services-for-nyc-residents', component: NycLanguageComponent},
  {path: 'legal/terms-of-use', component: TermsOfUseComponent},
  {path: 'legal/dealer-electronic-signatures-consent', component: DealerEsignatureComponent},
  {path: 'legal/privacy', component: PrivacyComponent},
  {path: 'legal/user-agreement-and-privacy', component: WebsiteUserAgreementComponent},
  {path: 'cp-maintenance', component: MaintenanceComponent},
  {path: 'dealers/portfolio-program/details-50', component: PortfolioLandingComponent},
  {path: 'dealers/portfolio-program/details-100', component: PortfolioLandingComponent},
  {path: 'glossary', component: GlossaryComponent},
  {path: 'careers/community', component: CommunityComponent},
  {path: 'car-buyers/resources', component: CarBuyersResourceComponent},
  {path: 'online-verification', component: OnlineVerificationComponent},
  {path: 'dealers/reviews/joshua-motors', component: JoshuaMotorsComponent},
  {path: 'dealers/reviews/steve-landers-auto', component: SteveLandersComponent},
  {path: 'dealers/reviews/magic-city-motorcars', component: MagicCityMotorsComponent},
  {path: 'dealers/reviews/bells-auto-sales', component: BellAutoSalesComponent},
  {path: 'car-buyers/videos', component: ConsumerVideosComponent},
  {path: 'dealers/videos', component: DealerVideosComponent},
  {path: 'dealers/reviews/automax-inc', component: AutoMaxComponent},
  {path: 'dealers/reviews/mt-moriah-auto-sales', component: LonzellWebbComponent},
  {path: 'dealers/reviews/reels-auto-sales', component: ReelsAutoSalesComponent},
  {path: 'dealers/reviews/premier-automotive', component: PremierAutomotiveComponent},
  {path: 'dealers/reviews/moore-stewart-ford', component: MooreStewartFordComponent},
  {path: 'dealers/join-our-network', component: JoinNetworkComponent},
  {path: 'dealers/reviews/zeigler-auto', component: ZeiglerAutoComponent },
  {path: 'dealers/reviews/websmart-auto', component: WebsmartAutoComponent },
  {path: 'dealers/reviews/ride-rite', component: RideRiteAutoComponent },
  {path: 'dealers/caps-maintenance', component: CapsMaintenanceComponent},
  {path: 'dealers/reviews/twins-auto-sales', component: TwinsAutoSalesComponent },
  {path: 'dealers/join-our-network/confirmation-thank-you', component: JoinNetworkThankYouComponent},
  {path: 'dealers/caps-auth-exception', component: AuthExceptionComponent},
  {path: 'coronavirus-response', component: CovidResponseComponent},
  {path: 'dealers/caps-redirect', component: CapsRedirectComponent},
  {path: 'dealers/the-lot/auto-industry-trends-for-new-and-used-cars', component: BlogArticleComponent, data: {awsUrl: AUTO_SALES_BLOG_JSON_URL}},
  {path: 'dealers/the-lot/guide-customer-centric-dealerships', component: BlogArticleComponent, data: {awsUrl: CUSTOMER_CENTRIC_BLOG_JSON_URL}},
  // TODO: uncomment these dealer blog post articles once the static content has been approved and uploaded.
  // {path: '/dealers/the-lot/creative-ways-to-build-brand-awareness-for-your-dealership', component: BlogArticleComponent, data: {awsUrl: BOOST_AUTO_SALES_BLOG_JSON_URL}},
  // {path: '/dealers/the-lot/benefits-of-having-an-automotive-crm-system-at-your-dealership', component: BlogArticleComponent, data: {awsUrl: AUTOMOTIVE_CRM_BLOG_JSON_URL}},
  // {path: '/dealers/the-lot/5-keys-to-having-a-successful-inventory-reconditioning-process', component: BlogArticleComponent, data: {awsUrl: RECONDITIONING_PROCESS_BLOG_JSON_URL}},
  // {path: '/dealers/the-lot/3-effective-ways-to-improve-your-car-sales', component: BlogArticleComponent, data: {awsUrl: IMPROVE_CAR_SALES_BLOG_JSON_URL}},
  {path: 'car-buyers/express-lane/factors-when-choosing-a-car', component: BlogArticleComponent, data: {awsUrl: FACTORS_TO_CONSIDER_BLOG_JSON_URL}},
  {path: 'car-buyers/express-lane/first-time-car-buyer-what-you-should-know', component: BlogArticleComponent, data: {awsUrl: FIRST_TIME_DRIVERS_BLOG_JSON_URL}},
  {path: 'car-buyers/express-lane/credit-myths-debunked', component: BlogArticleComponent, data: {awsUrl: CREDIT_SCORE_MYTHS_BLOG_JSON_URL}},
  {path: 'car-buyers/express-lane/first-time-car-buyer-mistakes', component: BlogArticleComponent, data: {awsUrl: CAR_BUYING_MISTAKES_BLOG_JSON_URL}},
  {path: 'car-buyers/express-lane/questions-for-car-dealers', component: BlogArticleComponent, data: {awsUrl: QUESTIONS_TO_ASK_WHEN_BUYING_BLOG_JSON_URL}},
  {path: 'car-buyers/express-lane/5-things-to-know-when-visiting-the-dealership', component: BlogArticleComponent, data: {awsUrl: THINGS_TO_KNOW_BLOG_JSON_URL}},
  {path: 'car-buyers/express-lane/5-ways-to-build-or-reestablish-your-credit-history', component: BlogArticleComponent, data: {awsUrl: BUILD_CREDIT_HISTORY_BLOG_JSON_URL}},
  {path: 'car-buyers/express-lane/benefits-of-gas-hybrid-and-electric-vehicles', component: BlogArticleComponent, data: {awsUrl: ADVANTAGES_OF_HYBRID_CARS_BLOG_JSON_URL}},
  {path: 'car-buyers/express-lane/simple-interest-financing', component: BlogArticleComponent, data: {awsUrl: UNDERSTANDING_SIMPLE_INTREST_BLOG_JSON_URL}},
  {path: 'car-buyers/express-lane/why-choose-an-enrolled-dealer', component: LegacyBlogArticleComponent, data: {awsUrl: WHY_CHOOSE_AN_ENROLLED_DEALER_JSON_URL}},
  {path: 'car-buyers/express-lane/get-approved-for-next-car', component: LegacyBlogArticleComponent, data: {awsUrl: GET_APPROVED_FOR_NEXT_CAR_JSON_URL}},
  {path: 'car-buyers/express-lane/car-gift-ideas', component: LegacyBlogArticleComponent, data: {awsUrl: CAR_GIFT_IDEAS_JSON_URL}},
  {path: 'car-buyers/express-lane/foods-in-car-without-mess', component: LegacyBlogArticleComponent, data: {awsUrl: FOODS_IN_CAR_WITHOUT_MESS_JSON_URL}},
  {path: 'car-buyers/express-lane/6-road-trip-routes-for-the-summer', component: LegacyBlogArticleComponent, data: {awsUrl: SIX_ROAD_TRIP_ROUTES_FOR_THE_SUMMER_JSON_URL}},
  {path: 'car-buyers/express-lane/car-financing-tips', component: LegacyBlogArticleComponent, data: {awsUrl: CAR_FINANCING_TIPS_JSON_URL}},
  {path: 'car-buyers/express-lane/best-times-of-the-year-to-purchase-a-vehicle', component: LegacyBlogArticleComponent, data: {awsUrl: BEST_TIMES_OF_THE_YEAR_TO_PURCHASE_A_VEHICLE_JSON_URL}},
  {path: 'car-buyers/express-lane/buying-a-car-out-of-state-what-to-know', component: LegacyBlogArticleComponent, data: {awsUrl: BUYING_A_CAR_OUT_OF_STATE_WHAT_TO_KNOW_JSON_URL}},
  {path: 'car-buyers/express-lane/buying-a-car-with-bad-credit-or-no-credit', component: LegacyBlogArticleComponent, data: {awsUrl: BUYING_A_CAR_WITH_BAD_CREDIT_OR_NO_CREDIT_JSON_URL}},
  {path: 'car-buyers/express-lane/buying-a-car-after-bankruptcy', component: LegacyBlogArticleComponent, data: {awsUrl: BUYING_A_CAR_AFTER_BANKRUPTCY_JSON_URL}},
  {path: 'car-buyers/express-lane/buying-car-with-multiple-repossessions', component: LegacyBlogArticleComponent, data: {awsUrl: BUYING_CAR_WITH_MULTIPLE_REPOSSESSIONS_JSON_URL}},
  {path: 'car-buyers/express-lane/buying-car-no-down-payment', component: LegacyBlogArticleComponent, data: {awsUrl: BUYING_CAR_NO_DOWN_PAYMENT_JSON_URL}},
  {path: 'car-buyers/express-lane/car-buying-dos-and-donts', component: LegacyBlogArticleComponent, data: {awsUrl: CAR_BUYING_DOS_AND_DONTS_JSON_URL}},
  {path: 'car-buyers/express-lane/checking-history-report-on-a-used-car', component: LegacyBlogArticleComponent, data: {awsUrl: CHECKING_HISTORY_REPORT_ON_A_USED_CAR_JSON_URL}},
  {path: 'car-buyers/express-lane/cosigner', component: LegacyBlogArticleComponent, data: {awsUrl: COSIGNER_JSON_URL}},
  {path: 'car-buyers/express-lane/car-loan-proof-of-income', component: LegacyBlogArticleComponent, data: {awsUrl: CAR_LOAN_PROOF_OF_INCOME_JSON_URL}},
  {path: 'car-buyers/express-lane/do-taxes-affect-your-credit-score', component: LegacyBlogArticleComponent, data: {awsUrl: DO_TAXES_AFFECT_YOUR_CREDIT_SCORE_JSON_URL}},
  {path: 'car-buyers/express-lane/gas-vs-electric-car-facts', component: LegacyBlogArticleComponent, data: {awsUrl: GAS_VS_ELECTRIC_CAR_FACTS_JSON_URL}},
  {path: 'car-buyers/express-lane/good-credit-habits', component: LegacyBlogArticleComponent, data: {awsUrl: GOOD_CREDIT_HABITS_JSON_URL}},
  {path: 'car-buyers/express-lane/can-you-finance-vehicle-with-no-hard-inquiry', component: LegacyBlogArticleComponent, data: {awsUrl: CAN_YOU_FINANCE_VEHICLE_WITH_NO_HARD_INQUIRY_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-fix-rust-on-car', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_FIX_RUST_ON_CAR_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-check-credit-score', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_CHECK_CREDIT_SCORE_JSON_URL}},
  {path: 'car-buyers/express-lane/does-car-insurance-go-up-after-accident', component: LegacyBlogArticleComponent, data: {awsUrl: DOES_CAR_INSURANCE_GO_UP_AFTER_ACCIDENT_JSON_URL}},
  {path: 'car-buyers/express-lane/how-much-to-spend-on-a-car', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_MUCH_TO_SPEND_ON_A_CAR_JSON_URL}},
  {path: 'car-buyers/express-lane/how-often-should-you-change-your-oil', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_OFTEN_SHOULD_YOU_CHANGE_YOUR_OIL_JSON_URL}},
  {path: 'car-buyers/express-lane/how-often-should-you-get-a-car-wash', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_OFTEN_SHOULD_YOU_GET_A_CAR_WASH_JSON_URL}},
  {path: 'car-buyers/express-lane/how-often-should-you-get-your-car-detailed', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_OFTEN_SHOULD_YOU_GET_YOUR_CAR_DETAILED_JSON_URL}},
  {path: 'car-buyers/express-lane/how-often-should-you-get-your-tires-rotated', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_OFTEN_SHOULD_YOU_GET_YOUR_TIRES_ROTATED_JSON_URL}},
  {path: 'car-buyers/express-lane/credit-fraud-detection', component: LegacyBlogArticleComponent, data: {awsUrl: CREDIT_FRAUD_DETECTION_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-build-your-credit-history-after-college', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_BUILD_YOUR_CREDIT_HISTORY_AFTER_COLLEGE_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-calculate-mpg', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_CALCULATE_MPG_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-change-a-flat-tire', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_CHANGE_A_FLAT_TIRE_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-clean-engine-bay', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_CLEAN_ENGINE_BAY_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-determine-your-car-budget', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_DETERMINE_YOUR_CAR_BUDGET_JSON_URL}},
  {path: 'car-buyers/express-lane/what-cars-can-I-afford', component: LegacyBlogArticleComponent, data: {awsUrl: WHAT_CARS_CAN_I_AFFORD_JSON_URL}},
  {path: 'car-buyers/express-lane/estimating-car-insurance', component: LegacyBlogArticleComponent, data: {awsUrl: ESTIMATING_CAR_INSURANCE_JSON_URL}},
  {path: 'car-buyers/express-lane/used-car-financing', component: LegacyBlogArticleComponent, data: {awsUrl: USED_CAR_FINANCING_JSON_URL}},
  {path: 'car-buyers/express-lane/budgeting-and-credit-score', component: LegacyBlogArticleComponent, data: {awsUrl: BUDGETING_AND_CREDIT_SCORE_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-improve-your-credit-establishing-credit-history', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_IMPROVE_YOUR_CREDIT_ESTABLISHING_CREDIT_HISTORY_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-improve-your-credit-fixing-errors-on-your-credit-report', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_IMPROVE_YOUR_CREDIT_FIXING_ERRORS_ON_YOUR_CREDIT_REPORT_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-improve-your-credit-paying-down-debt', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_IMPROVE_YOUR_CREDIT_PAYING_DOWN_DEBT_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-increase-your-chances-of-getting-approved-for-auto-financing', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_INCREASE_YOUR_CHANCES_OF_GETTING_APPROVED_FOR_AUTO_FINANCING_JSON_URL}},
  {path: 'car-buyers/express-lane/protecting-your-car-from-sun', component: LegacyBlogArticleComponent, data: {awsUrl: PROTECTING_YOUR_CAR_FROM_SUN_JSON_URL}},
  {path: 'car-buyers/express-lane/preventing-frost-on-windshield', component: LegacyBlogArticleComponent, data: {awsUrl: PREVENTING_FROST_ON_WINDSHIELD_JSON_URL}},
  {path: 'car-buyers/express-lane/what-to-bring-when-tailgating', component: LegacyBlogArticleComponent, data: {awsUrl: WHAT_TO_BRING_WHEN_TAILGATING_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-narrow-down-car-choices', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_NARROW_DOWN_CAR_CHOICES_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-parallel-park', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_PARALLEL_PARK_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-pay-off-your-car-faster', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_PAY_OFF_YOUR_CAR_FASTER_JSON_URL}},
  {path: 'car-buyers/express-lane/preparing-cars-for-winter', component: LegacyBlogArticleComponent, data: {awsUrl: PREPARING_CARS_FOR_WINTER_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-protect-your-car-from-getting-stolen', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_PROTECT_YOUR_CAR_FROM_GETTING_STOLEN_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-rebuild-your-credit-after-bankruptcy', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_REBUILD_YOUR_CREDIT_AFTER_BANKRUPTCY_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-rebuild-your-credit-after-repossession', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_REBUILD_YOUR_CREDIT_AFTER_REPOSSESSION_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-maintain-a-car', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_MAINTAIN_A_CAR_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-register-your-vehicle', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_REGISTER_YOUR_VEHICLE_JSON_URL}},
  {path: 'car-buyers/express-lane/remove-dent-from-car', component: LegacyBlogArticleComponent, data: {awsUrl: REMOVE_DENT_FROM_CAR_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-set-realistic-financial-goals-that-may-help-improve-your-credit', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_SET_REALISTIC_FINANCIAL_GOALS_THAT_MAY_HELP_IMPROVE_YOUR_CREDIT_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-trade-in-your-car', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_TRADE_IN_YOUR_CAR_JSON_URL}},
  {path: 'car-buyers/express-lane/is-now-the-right-time-for-you-to-buy-a-car', component: LegacyBlogArticleComponent, data: {awsUrl: IS_NOW_THE_RIGHT_TIME_FOR_YOU_TO_BUY_A_CAR_JSON_URL}},
  {path: 'car-buyers/express-lane/key-information-you-ll-find-in-your-car-manual', component: LegacyBlogArticleComponent, data: {awsUrl: KEY_INFORMATION_YOU_LL_FIND_IN_YOUR_CAR_MANUAL_JSON_URL}},
  {path: 'car-buyers/express-lane/longest-lasting-vehicles', component: LegacyBlogArticleComponent, data: {awsUrl: LONGEST_LASTING_VEHICLES_JSON_URL}},
  {path: 'car-buyers/express-lane/maintain-or-trade-evaluating-the-cost-of-repairing-vs-trading-in-your-car', component: LegacyBlogArticleComponent, data: {awsUrl: MAINTAIN_OR_TRADE_EVALUATING_THE_COST_OF_REPAIRING_VS_TRADING_IN_YOUR_CAR_JSON_URL}},
  {path: 'car-buyers/express-lane/online-car-shopping', component: LegacyBlogArticleComponent, data: {awsUrl: ONLINE_CAR_SHOPPING_JSON_URL}},
  {path: 'car-buyers/express-lane/rebuilding-your-credit-with-bad-credit-history', component: LegacyBlogArticleComponent, data: {awsUrl: REBUILDING_YOUR_CREDIT_WITH_BAD_CREDIT_HISTORY_JSON_URL}},
  {path: 'car-buyers/express-lane/should-i-buy-a-new-or-used-car', component: LegacyBlogArticleComponent, data: {awsUrl: SHOULD_I_BUY_A_NEW_OR_USED_CAR_JSON_URL}},
  {path: 'car-buyers/express-lane/should-you-check-your-credit-score-before-purchasing-a-vehicle-', component: LegacyBlogArticleComponent, data: {awsUrl: SHOULD_YOU_CHECK_YOUR_CREDIT_SCORE_BEFORE_PURCHASING_A_VEHICLE__JSON_URL}},
  {path: 'car-buyers/express-lane/signs-you-re-falling-into-a-bad-credit-cycle', component: LegacyBlogArticleComponent, data: {awsUrl: SIGNS_YOU_RE_FALLING_INTO_A_BAD_CREDIT_CYCLE_JSON_URL}},
  {path: 'car-buyers/express-lane/weird-cars', component: LegacyBlogArticleComponent, data: {awsUrl: WEIRD_CARS_JSON_URL}},
  {path: 'car-buyers/express-lane/how-credit-works', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_CREDIT_WORKS_JSON_URL}},
  {path: 'car-buyers/express-lane/the-difference-between-direct-and-indirect-auto-financing', component: LegacyBlogArticleComponent, data: {awsUrl: THE_DIFFERENCE_BETWEEN_DIRECT_AND_INDIRECT_AUTO_FINANCING_JSON_URL}},
  {path: 'car-buyers/express-lane/the-difference-between-leasing-and-auto-financi-1', component: LegacyBlogArticleComponent, data: {awsUrl: THE_DIFFERENCE_BETWEEN_LEASING_AND_AUTO_FINANCI_1_JSON_URL}},
  {path: 'car-buyers/express-lane/winning-a-car-and-taxes', component: LegacyBlogArticleComponent, data: {awsUrl: WINNING_A_CAR_AND_TAXES_JSON_URL}},
  {path: 'car-buyers/express-lane/the-right-car-for-your-lifesty', component: LegacyBlogArticleComponent, data: {awsUrl: THE_RIGHT_CAR_FOR_YOUR_LIFESTY_JSON_URL}},
  {path: 'car-buyers/express-lane/the-ultimate-guide-to-buy-a-car-and-improve-your-credit', component: LegacyBlogArticleComponent, data: {awsUrl: THE_ULTIMATE_GUIDE_TO_BUY_A_CAR_AND_IMPROVE_YOUR_CREDIT_JSON_URL}},
  {path: 'car-buyers/express-lane/thinking-of-buying-a-new-or-used-car-let-s-weigh-your-options', component: LegacyBlogArticleComponent, data: {awsUrl: THINKING_OF_BUYING_A_NEW_OR_USED_CAR_LET_S_WEIGH_YOUR_OPTIONS_JSON_URL}},
  {path: 'car-buyers/express-lane/top-car-safety-features-for-teen-drivers', component: LegacyBlogArticleComponent, data: {awsUrl: TOP_CAR_SAFETY_FEATURES_FOR_TEEN_DRIVERS_JSON_URL}},
  {path: 'car-buyers/express-lane/dog-friendly-cars', component: LegacyBlogArticleComponent, data: {awsUrl: DOG_FRIENDLY_CARS_JSON_URL}},
  {path: 'car-buyers/express-lane/top-eco-friendly-cars', component: LegacyBlogArticleComponent, data: {awsUrl: TOP_ECO_FRIENDLY_CARS_JSON_URL}},
  {path: 'car-buyers/express-lane/top-family-cars-and-suvs', component: LegacyBlogArticleComponent, data: {awsUrl: TOP_FAMILY_CARS_AND_SUVS_JSON_URL}},
  {path: 'car-buyers/express-lane/trunk-or-treat-ideas', component: LegacyBlogArticleComponent, data: {awsUrl: TRUNK_OR_TREAT_IDEAS_JSON_URL}},
  {path: 'car-buyers/express-lane/understanding-your-car-payment-interest-bearing', component: LegacyBlogArticleComponent, data: {awsUrl: UNDERSTANDING_YOUR_CAR_PAYMENT_INTEREST_BEARING_JSON_URL}},
  {path: 'car-buyers/express-lane/understanding-your-car-payment-precomputed', component: LegacyBlogArticleComponent, data: {awsUrl: UNDERSTANDING_YOUR_CAR_PAYMENT_PRECOMPUTED_JSON_URL}},
  {path: 'car-buyers/express-lane/car-buying-resources', component: LegacyBlogArticleComponent, data: {awsUrl: CAR_BUYING_RESOURCES_JSON_URL}},
  {path: 'car-buyers/express-lane/good-first-time-carss', component: LegacyBlogArticleComponent, data: {awsUrl: GOOD_FIRST_TIME_CARSS_JSON_URL}},
  {path: 'car-buyers/express-lane/what-documents-do-i-need-to-purchase-a-vehicle', component: LegacyBlogArticleComponent, data: {awsUrl: WHAT_DOCUMENTS_DO_I_NEED_TO_PURCHASE_A_VEHICLE_JSON_URL}},
  {path: 'car-buyers/express-lane/what-factors-affect-my-car-payments', component: LegacyBlogArticleComponent, data: {awsUrl: WHAT_FACTORS_AFFECT_MY_CAR_PAYMENTS_JSON_URL}},
  {path: 'car-buyers/express-lane/what-happens-if-you-crash-a-car-during-a-test-drive', component: LegacyBlogArticleComponent, data: {awsUrl: WHAT_HAPPENS_IF_YOU_CRASH_A_CAR_DURING_A_TEST_DRIVE_JSON_URL}},
  {path: 'car-buyers/express-lane/difference-between-credit-report-and-credit-score', component: LegacyBlogArticleComponent, data: {awsUrl: DIFFERENCE_BETWEEN_CREDIT_REPORT_AND_CREDIT_SCORE_JSON_URL}},
  {path: 'car-buyers/express-lane/length-of-employment-for-car-financing', component: LegacyBlogArticleComponent, data: {awsUrl: LENGTH_OF_EMPLOYMENT_FOR_CAR_FINANCING_JSON_URL}},
  {path: 'car-buyers/express-lane/denied-auto-financing-what-to-do', component: LegacyBlogArticleComponent, data: {awsUrl: DENIED_AUTO_FINANCING_WHAT_TO_DO_JSON_URL}},
  {path: 'car-buyers/express-lane/all-wheel-or-front-wheel-drive', component: LegacyBlogArticleComponent, data: {awsUrl: ALL_WHEEL_OR_FRONT_WHEEL_DRIVE_JSON_URL}},
  {path: 'car-buyers/express-lane/difference-between-car-loan-and-financing', component: LegacyBlogArticleComponent, data: {awsUrl: DIFFERENCE_BETWEEN_CAR_LOAN_AND_FINANCING_JSON_URL}},
  {path: 'car-buyers/express-lane/what-to-know-before-buying-a-car', component: LegacyBlogArticleComponent, data: {awsUrl: WHAT_TO_KNOW_BEFORE_BUYING_A_CAR_JSON_URL}},
  {path: 'car-buyers/express-lane/what-is-a-car-title', component: LegacyBlogArticleComponent, data: {awsUrl: WHAT_IS_A_CAR_TITLE_JSON_URL}},
  {path: 'car-buyers/express-lane/how-to-check-mileage-of-a-car', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_CHECK_MILEAGE_OF_A_CAR_JSON_URL}},
  {path: 'car-buyers/express-lane/credit-history', component: LegacyBlogArticleComponent, data: {awsUrl: CREDIT_HISTORY_JSON_URL}},
  {path: 'car-buyers/express-lane/self-driving-cars', component: LegacyBlogArticleComponent, data: {awsUrl: SELF_DRIVING_CARS_JSON_URL}},
  {path: 'dealers/the-lot/3-effective-ways-to-improve-your-car-sales', component: LegacyBlogArticleComponent, data: {awsUrl: THREE_EFFECTIVE_WAYS_TO_IMPROVE_YOUR_CAR_SALES_JSON_URL}},
  {path: 'dealers/the-lot/3-ways-to-improve-your-car-dealership-s-cash-flow', component: LegacyBlogArticleComponent, data: {awsUrl: THREE_WAYS_TO_IMPROVE_YOUR_CAR_DEALERSHIP_S_CASH_FLOW_JSON_URL}},
  {path: 'dealers/the-lot/5-keys-to-having-a-successful-inventory-reconditioning-process', component: LegacyBlogArticleComponent, data: {awsUrl: FIVE_KEYS_TO_HAVING_A_SUCCESSFUL_INVENTORY_RECONDITIONING_PROCESS_JSON_URL}},
  {path: 'dealers/the-lot/benefits-of-having-an-automotive-crm-system-at-your-dealership', component: LegacyBlogArticleComponent, data: {awsUrl: BENEFITS_OF_HAVING_AN_AUTOMOTIVE_CRM_SYSTEM_AT_YOUR_DEALERSHIP_JSON_URL}},
  {path: 'dealers/the-lot/benefits-of-working-with-a-subprime-finance-company', component: LegacyBlogArticleComponent, data: {awsUrl: BENEFITS_OF_WORKING_WITH_A_SUBPRIME_FINANCE_COMPANY_JSON_URL}},
  {path: 'dealers/the-lot/car-buying-trends-for-gen-z', component: LegacyBlogArticleComponent, data: {awsUrl: CAR_BUYING_TRENDS_FOR_GEN_Z_JSON_URL}},
  {path: 'dealers/the-lot/car-buying-trends-for-millennials-1', component: LegacyBlogArticleComponent, data: {awsUrl: CAR_BUYING_TRENDS_FOR_MILLENNIALS_1_JSON_URL}},
  {path: 'blog/dealer/car-buying-trends-for-recent-college-grads-and-students', component: LegacyBlogArticleComponent, data: {awsUrl: CAR_BUYING_TRENDS_FOR_RECENT_COLLEGE_GRADS_AND_STUDENTS_JSON_URL}},
  {path: 'dealers/the-lot/car-selling-101-how-to-maintain-repeat-and-referral-business-for-your-dealersh-1', component: LegacyBlogArticleComponent, data: {awsUrl: CAR_SELLING_101_HOW_TO_MAINTAIN_REPEAT_AND_REFERRAL_BUSINESS_FOR_YOUR_DEALERSH_1_JSON_URL}},
  {path: 'dealers/the-lot/creative-ways-to-build-brand-awareness-for-your-dealership', component: LegacyBlogArticleComponent, data: {awsUrl: CREATIVE_WAYS_TO_BUILD_BRAND_AWARENESS_FOR_YOUR_DEALERSHIP_JSON_URL}},
  {path: 'dealers/the-lot/different-trends-of-male-and-female-car-buyers', component: LegacyBlogArticleComponent, data: {awsUrl: DIFFERENT_TRENDS_OF_MALE_AND_FEMALE_CAR_BUYERS_JSON_URL}},
  {path: 'blog/dealer/how-car-dealers-can-approve-customers-with-bad-credit-or-no-credit', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_CAR_DEALERS_CAN_APPROVE_CUSTOMERS_WITH_BAD_CREDIT_OR_NO_CREDIT_JSON_URL}},
  {path: 'blog/dealer/how-car-dealerships-can-approve-car-buyers-with-multiple-open-autos-or-repossessions', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_CAR_DEALERSHIPS_CAN_APPROVE_CAR_BUYERS_WITH_MULTIPLE_OPEN_AUTOS_OR_REPOSSESSIONS_JSON_URL}},
  {path: 'blog/dealer/how-dealerships-can-approve-car-buyers-with-bankruptcy-or-foreclosure', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_DEALERSHIPS_CAN_APPROVE_CAR_BUYERS_WITH_BANKRUPTCY_OR_FORECLOSURE_JSON_URL}},
  {path: 'blog/dealer/how-dealerships-can-approve-car-buyers-with-unemployment-or-fixed-income', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_DEALERSHIPS_CAN_APPROVE_CAR_BUYERS_WITH_UNEMPLOYMENT_OR_FIXED_INCOME_JSON_URL}},
  {path: 'dealers/the-lot/how-does-the-credit-acceptance-program-work-', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_DOES_THE_CREDIT_ACCEPTANCE_PROGRAM_WORK__JSON_URL}},
  {path: 'blog/dealer/how-offering-subprime-auto-financing-can-benefit-buy-here-pay-here-dealers', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_OFFERING_SUBPRIME_AUTO_FINANCING_CAN_BENEFIT_BUY_HERE_PAY_HERE_DEALERS_JSON_URL}},
  {path: 'dealers/the-lot/how-the-credit-acceptance-portfolio-program-can-help-your-bhph-dealership', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_THE_CREDIT_ACCEPTANCE_PORTFOLIO_PROGRAM_CAN_HELP_YOUR_BHPH_DEALERSHIP_JSON_URL}},
  {path: 'dealers/the-lot/how-the-credit-acceptance-portfolio-program-can-help-your-franchise-dealership', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_THE_CREDIT_ACCEPTANCE_PORTFOLIO_PROGRAM_CAN_HELP_YOUR_FRANCHISE_DEALERSHIP_JSON_URL}},
  {path: 'dealers/the-lot/how-the-credit-acceptance-portfolio-program-can-help-your-independent-dealership', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_THE_CREDIT_ACCEPTANCE_PORTFOLIO_PROGRAM_CAN_HELP_YOUR_INDEPENDENT_DEALERSHIP_JSON_URL}},
  {path: 'dealers/the-lot/how-to-equip-your-car-dealership-to-maintain-compliance', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_EQUIP_YOUR_CAR_DEALERSHIP_TO_MAINTAIN_COMPLIANCE_JSON_URL}},
  {path: 'dealers/the-lot/how-to-help-car-buyers-with-auto-financing', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_HELP_CAR_BUYERS_WITH_AUTO_FINANCING_JSON_URL}},
  {path: 'dealers/the-lot/how-to-secure-financing-options-for-your-car-dealership', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_TO_SECURE_FINANCING_OPTIONS_FOR_YOUR_CAR_DEALERSHIP_JSON_URL}},
  {path: 'dealers/the-lot/improving-customer-retention-at-your-dealership', component: LegacyBlogArticleComponent, data: {awsUrl: IMPROVING_CUSTOMER_RETENTION_AT_YOUR_DEALERSHIP_JSON_URL}},
  {path: 'dealers/the-lot/increasing-used-car-sales-at-your-franchise-dealership', component: LegacyBlogArticleComponent, data: {awsUrl: INCREASING_USED_CAR_SALES_AT_YOUR_FRANCHISE_DEALERSHIP_JSON_URL}},
  {path: 'dealers/the-lot/listening-to-understand-your-customers-vs-listening-to-sell', component: LegacyBlogArticleComponent, data: {awsUrl: LISTENING_TO_UNDERSTAND_YOUR_CUSTOMERS_VS_LISTENING_TO_SELL_JSON_URL}},
  {path: 'dealers/the-lot/preparing-your-car-dealership-for-tax-season-in-3-simple-ste-1', component: LegacyBlogArticleComponent, data: {awsUrl: PREPARING_YOUR_CAR_DEALERSHIP_FOR_TAX_SEASON_IN_3_SIMPLE_STE_1_JSON_URL}},
  {path: 'dealers/the-lot/preparing-your-car-dealership-for-tax-season-in-3-simple-steps', component: LegacyBlogArticleComponent, data: {awsUrl: PREPARING_YOUR_CAR_DEALERSHIP_FOR_TAX_SEASON_IN_3_SIMPLE_STEPS_JSON_URL}},
  {path: 'dealers/the-lot/questions-to-ask-customers-looking-to-buy-a-car', component: LegacyBlogArticleComponent, data: {awsUrl: QUESTIONS_TO_ASK_CUSTOMERS_LOOKING_TO_BUY_A_CAR_JSON_URL}},
  {path: 'dealers/the-lot/top-5-ways-your-car-dealership-can-generate-leads', component: LegacyBlogArticleComponent, data: {awsUrl: TOP_5_WAYS_YOUR_CAR_DEALERSHIP_CAN_GENERATE_LEADS_JSON_URL}},
  {path: 'dealers/the-lot/what-bhph-dealers-should-know-about-subprime-financing', component: LegacyBlogArticleComponent, data: {awsUrl: WHAT_BHPH_DEALERS_SHOULD_KNOW_ABOUT_SUBPRIME_FINANCING_JSON_URL}},
  {path: 'dealers/the-lot/why-lead-generation-is-important-for-your-car-dealership', component: LegacyBlogArticleComponent, data: {awsUrl: WHY_LEAD_GENERATION_IS_IMPORTANT_FOR_YOUR_CAR_DEALERSHIP_JSON_URL}},
  {path: 'dealers/the-lot/why-your-dealership-website-should-be-mobile-friendly', component: LegacyBlogArticleComponent, data: {awsUrl: WHY_YOUR_DEALERSHIP_WEBSITE_SHOULD_BE_MOBILE_FRIENDLY_JSON_URL}},
  {path: 'dealers/the-lot/working-with-first-time-car-buyers-with-no-credit', component: LegacyBlogArticleComponent, data: {awsUrl: WORKING_WITH_FIRST_TIME_CAR_BUYERS_WITH_NO_CREDIT_JSON_URL}},
  {path: 'blog/dealer/working-with-self-employed-car-buyers', component: LegacyBlogArticleComponent, data: {awsUrl: WORKING_WITH_SELF_EMPLOYED_CAR_BUYERS_JSON_URL}},
  {path: 'car-buyers/express-lane/car-insurance', component: LegacyBlogArticleComponent, data: {awsUrl: CAR_INSURANCE_JSON_URL}},
  {path: 'car-buyers/express-lane/financing-car-can-help-rebuild-credit', component: LegacyBlogArticleComponent, data: {awsUrl: FINANCING_CAR_HELP_REBUILD_CREDIT_JSON_URL}},
  {path: 'blog/consumer/how-it-works-getting-financed-through-a-dealer-enrolled-with-credit-acceptance', component: LegacyBlogArticleComponent, data: {awsUrl: HOW_IT_WORKS_GETTING_FINANCED_THROUGH_A_DEALER_ENROLLED_WITH_CREDITACCEPTANCE_JSON_URL}},
  {path: 'dealers/the-lot/positive-team-atmosphere', component: LegacyBlogArticleComponent, data: {awsUrl: POSITIVE_TEAM_ATMOSPHERE_JSON_URL}},
  {path: 'dealers/the-lot/subprime-car-financing', component: LegacyBlogArticleComponent, data: {awsUrl: SUBPRIME_CAR_FINANCING_JSON_URL}},
  {path: 'dealers/the-lot/train-car-dealership-staff', component: LegacyBlogArticleComponent, data: {awsUrl: TRAIN_CAR_DEALERSHIP_STAFF_JSON_URL}},
  {path: 'car-buyers/express-lane/what-to-know', component: LegacyBlogArticleComponent, data: {awsUrl: WHAT_TO_KNOW_JSON_URL}},
  {path: 'campaign/no-ssn-no-itin', component: NoSsnNoItinCampaignComponent},
  {path: 'campaign/purchase-program-purchase-flat', component: DealersPurchaseProgramFlatComponent},
  {path: 'page-not-found', component: PageNotFoundComponent},
  {path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: true,
    initialNavigation : 'enabledBlocking',
    // Enable Angular's built-in, automated page scrolling capabilities
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
