import { inject, Injectable } from '@angular/core';
import { DealerManagementService } from 'app/services/dealer-management.service';
import { GoogleService } from 'app/services/google.service';
import { DealerFinderSearchResponse } from 'app/page-component/dealer-finder/interfaces/dealer-finder-search-response';
import { Observable, Subject, switchMap, tap } from 'rxjs';
import { ScriptLoaderService } from 'app/services/script-loader.service';
import { RecaptchaV3Service } from '../../services/recaptcha-v3.service';

@Injectable({
  providedIn: 'root',
})
export class DealerFinderService {
  // Inject services
  private readonly googleService: GoogleService = inject(GoogleService);
  private readonly dealerManagementService: DealerManagementService = inject(
    DealerManagementService
  );
  private readonly scriptLoaderService: ScriptLoaderService =
    inject(ScriptLoaderService);
  private readonly recaptchaV3Service = inject(RecaptchaV3Service);

  // Create subjects to hold the event data.
  private mouseCardEventSubject = new Subject<{
    event: 'mouseenter' | 'mouseleave';
    index: number;
  }>();
  private markerClickedEventSubject = new Subject<number>();

  // Observables to be used by components to subscribe.
  searchListItemEvent$ = this.mouseCardEventSubject.asObservable();
  markerIconClicked$ = this.markerClickedEventSubject.asObservable();

  // Methods to emit data
  emitSearchListEventChanged(
    event: 'mouseenter' | 'mouseleave',
    index: number
  ) {
    this.mouseCardEventSubject.next({ event, index });
  }

  emitMarkerIconClicked(index: number) {
    this.markerClickedEventSubject.next(index);
  }

  // Observable methods used to combine requests
  searchDealers(fullAddress: string): Observable<DealerFinderSearchResponse> {
    return this.recaptchaV3Service
      .execute('search_for_dealers')
      .pipe(
        switchMap((recaptchaToken) =>
          this.dealerManagementService.searchDealers(
            { address: fullAddress },
            recaptchaToken
          )
        ),
      );
  }

  setDealerFinderGoogleAPIScript(): string {
    return this.googleService.getGoogleMapAPIUrl();
  }
}
