import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[addressRestrictSpecialChars]'
})
export class AddressRestriction {
  private regex: RegExp = /[^a-zA-Z0-9\s\-#,.]/g
  @HostListener('input', ['$event']) onInputChange(event: InputEvent) {
    const input = event.target as HTMLInputElement;
    const initialValue = input.value;
    // Remove the restricted characters
    input.value = initialValue.replace(this.regex, '');
    if (initialValue !== input.value) {
      event.stopPropagation(); // Stop the propagation if value was changed
    }
    if (!input.value.trim()) {
      input.value = '';
      event.stopPropagation(); 
    }
  }
}