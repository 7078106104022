import { Component, OnInit, PLATFORM_ID, Inject  } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { CONTACT_US_JSON_URL } from 'app/constants/aws.constants';
import {AWSService} from 'app/services/aws.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';

const DATA_KEY = makeStateKey('contact-us-data');

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  
  subscription: Subscription[] = [];

  url = CONTACT_US_JSON_URL;
  
  ContactUsJSONData:any;
  
  quickLinksCustomer: any = []
  quickLinksDealer: any = [];
  heroImageInfo : any = {};
  bottonBgColor: string = "#C4EDFA";
  overLineBgColor: string = "#0f7d9c";
  headerColor: string = "#171714";
  iframeHeight: string = "435px";
  iframeWidth: string = "100%";
  iframeSrc: string = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2941.5968327218375!2d-83.29914114901406!3d42.500123134135286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824b7771a4924a9%3A0xcffeb3c1b429ef99!2s25505+Twelve+Mile+Rd%2C+Southfield%2C+MI+48034!5e0!3m2!1sen!2sus!4v1464365421039";
  ariaLabel: string = "Contact Us";
  isMobileViewDisplay : boolean = false;
  isCurvedImage = true;
  customerContactData : any ;
  dealerContactData : any ;
  holidayData : any = {};
  mediaContactData : any ;
  isBrowser : boolean = false;
  isServer : boolean = false;
  data : any = {};
  title: string = 'Location & Holiday Closures:';
  quickLinksIdCustomer : string = "quickLinksIdCustomer";
  quickLinksIdDealer : string = "quickLinksIdDealer";
  
  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, private transferState : TransferState , @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }

  ngOnInit(): void {
  
    /*this.data = this.transferState.get(DATA_KEY, null);

    if(!this.data){
      this.awsService.fetchAWSData(this.url).subscribe(json => {
        this.data = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, AWS_BASE_URL);

        this.ContactUsJSONData = JSON.parse(this.data);
        
        this.quickLinksCustomer = this.ContactUsJSONData.quickLinksCustomerComponent;
        this.quickLinksDealer = this.ContactUsJSONData.quickLinksDealerComponent;
        this.heroImageInfo = this.ContactUsJSONData.CacHeroImageComponent;
        this.customerContactData = this.ContactUsJSONData.CacCustomerContactComponent;
        this.dealerContactData = this.ContactUsJSONData.CacDealerContactComponent;
        this.mediaContactData = this.ContactUsJSONData.CacMediaContactComponent;
        this.holidayData = this.ContactUsJSONData.CacHolidayComponent;

        this.transferState.set(DATA_KEY, this.ContactUsJSONData);
      });
    }*/
    if(this.isBrowser){
    
      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);
        
        this.ContactUsJSONData = json;
        this.quickLinksCustomer = this.ContactUsJSONData.quickLinksCustomerComponent;
        this.quickLinksDealer = this.ContactUsJSONData.quickLinksDealerComponent;
        this.heroImageInfo = this.ContactUsJSONData.CacHeroImageComponent;
        this.customerContactData = this.ContactUsJSONData.CacCustomerContactComponent;
        this.dealerContactData = this.ContactUsJSONData.CacDealerContactComponent;
        this.mediaContactData = this.ContactUsJSONData.CacMediaContactComponent;
        this.holidayData = this.ContactUsJSONData.CacHolidayComponent;
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }

}
