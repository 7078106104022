import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AWSService } from 'app/services/aws.service';
import { Subscription } from 'rxjs';
import { DEALER_VIDEOS_JSON_URL } from 'app/constants/aws.constants';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import Player from '@vimeo/player';

@Component({
  selector: 'app-dealer-videos',
  templateUrl: './dealer-videos.component.html',
  styleUrls: ['./dealer-videos.component.scss']
})
export class DealerVideosComponent implements OnInit{

  subscription: Subscription[] = [];

  url = DEALER_VIDEOS_JSON_URL;
  dealerVideoJSONData: any;

  isMobileViewDisplay : boolean = false;
  isBrowser : boolean = false;

  // for hero component
  heroImageInfo : any = {};
  headerColor: string = "#FFFFFF";
  bottonBgColor: string = "#ffb800";
  overLineBgColor: string = "#FA6400";
  overLineTextColor: string = "#171714";
  disclaimerColor: string = "#FFFFFF";
  isCurvedImage = false;

  // for video component
  educationVideo: any;
  financingVideo: any;
  singleVideo: any;

  // Titles and descriptions for the videos
  videoTitleEducation: string = '';
  videoTitleFinance: string = '';
  videoDescriptionEducation: string = '';
  videoDescriptionFinance: string = '';
  singleVideoTitle : string = ''
  singleVideoParagraphTitle : string = ''
  singleVideoDescriptions : string = ''

  // for introduction component
  educationIntro: any;
  financingIntro: any;

  constructor(
    private http: HttpClient,
    private responsive: BreakpointObserver,
    private awsService: AWSService,
    @Inject(PLATFORM_ID) platformId: Object,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {
    // Check if the platform is a browser
    this.isBrowser = isPlatformBrowser(platformId);
  }
  ngOnInit(): void {

    if(this.isBrowser){
      this.awsService.getAWSData(this.url).then(json => {
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);

        this.dealerVideoJSONData = json;
        this.heroImageInfo = this.dealerVideoJSONData.CacHeroImageComponent;
        this.educationIntro = this.dealerVideoJSONData.CacCreditIntroductionCarBuyerComponent;
        this.financingIntro = this.dealerVideoJSONData.CacCreditIntroductionCarBuyerComponent2;
        this.educationVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.dealerVideoJSONData.Videoaddress.address);
        this.financingVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.dealerVideoJSONData.Videoaddress2.address);
        this.singleVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.dealerVideoJSONData.Videoaddress3.address);
        this.singleVideoTitle = this.dealerVideoJSONData.singleVideoComponent.videoTitle;
        this.singleVideoParagraphTitle = this.dealerVideoJSONData.singleVideoComponent.paragraphTitle;
        this.singleVideoDescriptions  = this.dealerVideoJSONData.singleVideoComponent.descriptions;
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
          this.applyTruncation();
        })
    );
  }
  applyTruncation() {
    //const maxLength = 140;
    //this.videoDescriptionEducation = this.truncateDescription(this.videoDescriptionEducation, maxLength);
    //this.videoDescriptionFinance = this.truncateDescription(this.videoDescriptionFinance, maxLength);
  }

  // Remove URLs from the description
  sanitizeDescription(description: string): string {
    // Match URLs starting with http, https, www, or a domain name with .com, .net, etc., and remove paths as well
    const urlPattern = /(?:https?:\/\/|www\.)[^\s]+|(?:[a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}\/[^\s]*/g;
    return description.replace(urlPattern, '');
  }
  truncateDescription(description: string, maxLength: number): string {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + '...';
    }
    return description;
  }

  loadVimeoPlayer(player: Player, isEducation: boolean) {
    player.on('loaded', () => {
      player.getVideoId().then(videoId => {
        fetch(`https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${videoId}`).then(response => response.json()).then(data => {
          if(isEducation){
            this.videoTitleEducation = data.title;
            this.videoDescriptionEducation = data.description;
          } else {
            this.videoTitleFinance = data.title;
            this.videoDescriptionFinance = data.description;
          }
        });
      });
    });
  }

  loadIframe(id: string, isEducation: boolean) {
      const iframe = document.getElementById(id) as HTMLIFrameElement;
      //Check iframe src 
      if(iframe.src === ''){
        return;
      }
      const player = new Player(iframe);
      this.loadVimeoPlayer(player, isEducation);
  }
}
