import { cardDetails } from "app/models/cardDetails";

export const PortFolioPurchseDetails: cardDetails[] = [
  {
    title: ["Portfolio Program gives you an opportunity for future cash flow."],
    description: ['We offer you three ways to get paid:'],
    points: [
      'Front-end advance for every funded deal',
      'Portfolio Profit Express (PPE): Cap a pool* at 50 or 100 contracts—receive a PPE payment',
      'Portfolio Profit: an opportunity to receive 80% of the net collections on all contracts'
    ],
    sectionName: "PortFolio Program"
  },
  {
    title: ["Purchase Program** offers a one-time higher upfront advance:"],
    description: ['Dealers may use this program when a higher upfront advance is desired at time of funding, or in environments with more traditional pay plans:'],
    points: [
      'Competitive new vehicle financing options',
      'Up to 84-months for new vehicles'
    ],
    sectionName: "Purchase Program"
  }
]