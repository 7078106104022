import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { GUEST_PAYMENT_JSON_URL } from 'app/constants/aws.constants';
import { AWSService } from 'app/services/aws.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-guest-payment',
  templateUrl: './guest-payment.component.html',
  styleUrls: ['./guest-payment.component.scss'],
})
export class GuestPaymentComponent implements OnInit {
  subscription: Subscription[] = [];

  url = GUEST_PAYMENT_JSON_URL;
  GuestPaymentJSONData: any;
  heroImageInfo: any = {};
  overLineBgColor: string = '#0F7D9C';
  bottonBgColor: string = '#C4EDFA';
  headerColor: string = '#FCFCFA';
  isCurvedImage = true;
  iframeHeight: string = '735px';
  iframeWidth: string = '100%';
  iframeSrc: string;
  ariaLabel: string = 'Guest Payment';
  keypointData: any = {};
  isMobileViewDisplay: boolean = false;
  keypointHeader: string = '';
  isBrowser: boolean = false;

  constructor(
    private http: HttpClient,
    private responsive: BreakpointObserver,
    private awsService: AWSService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.iframeSrc = this.awsService.getGuestPaymentURL();
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.awsService.getAWSData(this.url).then((json) => {
        json = JSON.stringify(json).replace(
          /AWS_BUCKET_URL/g,
          this.awsService.getAWSBucketURL()
        );
        json = JSON.parse(json);

        this.GuestPaymentJSONData = json;
        this.heroImageInfo = this.GuestPaymentJSONData.CacHeroImageComponent;
        this.keypointData = this.GuestPaymentJSONData.CacKeyPointsComponent;
        this.keypointHeader =
          this.GuestPaymentJSONData.CacKeyPointsComponent.heading;
      });
    }

    this.subscription.push(
      this.responsive
        .observe([Breakpoints.XSmall, Breakpoints.Small])
        .subscribe((result) => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }
}
