import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CUSTOMER_BLOGS_JSON_URL, DEALER_BLOGS_JSON_URL } from 'app/constants/aws.constants';
import { AWSService } from 'app/services/aws.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customer-blogs',
  templateUrl: './customer-blogs.component.html',
  styleUrls: ['./customer-blogs.component.scss']
})
export class CustomerBlogsComponent implements OnInit {
  subscription: Subscription[] = [];

  url = CUSTOMER_BLOGS_JSON_URL;
  customerBlogsData : any = {};
  heroImageInfo : any ;
  tabbedContentBlock : any ;

  isMobileViewDisplay : boolean = false;
  isBrowser : boolean = false;
  overLineBgColor: string = "#0D5D73";
  bottonBgColor: string = "#FFB800";
  headerColor: string = "#000";
  disclaimerColor: string = '#171714';
  isCurvedImage = true;


  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {

    if(this.isBrowser){
      
      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);
        this.customerBlogsData = json;
        
        this.heroImageInfo = this.customerBlogsData.CacHeroImageComponent;
        this.tabbedContentBlock = this.customerBlogsData.CacTabbbdContentComponent;
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }
}
