<app-header></app-header>
<div class='container px-4 pb-3'>
<app-quick-links [isMobileView]="mobileView" [quickLinksId]="quickLinksId" *ngIf="!mobileView"></app-quick-links>
</div>
<app-hero-image [heroImageInfo]="heroImageInfo" [isMobileView]="mobileView"></app-hero-image>
<app-credit-introduction [information]="homePageIntro" [isMobileView]="mobileView"></app-credit-introduction>
<app-users-toggle [tabInfo]="tabInfo" [tabContent]="tabContent" [isMobileView]="mobileView"></app-users-toggle>
<div *ngIf="resources" class="bgc-credit-white">
  <div class="container px-4 pb-5" aria-label="Page Resources Info Section">
    <div class="text-center" [ngClass]="mobileView ? 'p-3' : 'p-5'">
      <span class="h3"><span aria-label="'Resources'" class="secondary-heading">Resources</span></span>
    </div>
    <app-acceptance-card [cardDetails]="resources" [isMobileView]="mobileView" [sectionName]="'resources'" [cardClasses]="'card card-expands rounded-0'" [buttonClass]="'btn btn-primary'"></app-acceptance-card>
  </div>
</div>
<app-image-text-overlay [details]="infoEducationDetails" [isMobileView]="mobileView"></app-image-text-overlay>
<app-numbers-speak [creditNumSpeak]="creditHomeNumSpeak" [isMobileView]="mobileView"></app-numbers-speak>
<app-hear-from-people [hearFromPeople]="hearFromCustomers" [hearFromPeoplebottonBtnLink]="hearInfo" [hearFromPeoplesectionTitle]="hearInfoSectionTitle" [isMobileView]="mobileView"></app-hear-from-people>
<div *ngIf="articles" class="bgc-light-blue py-2" aria-label="Page Articles Section">
  <div class="container pb-5">
    <div class="py-3" [ngClass]= "{ 'secondary-heading' : mobileView}">
      <span class="h3" [ngClass]= "{ 'secondary-heading' : !mobileView}"><span attr.aria-label="{{articlesTitle}}">{{articlesTitle}}</span></span>
    </div>
    <div class="pt-4">
      <app-acceptance-card [cardDetails]="articles" [isMobileView]="mobileView" [sectionName]="'article'" [cardClasses]="'card card-expands'" [buttonClass]="'btn btn-pill'" [isPillTypeBtn]="true"></app-acceptance-card>
    </div>
  </div>
</div>
<app-sitemap [isMobileView]="mobileView"></app-sitemap>
<app-footer></app-footer>
