import { HowItWorksComponent } from "app/components/how-it-works/how-it-works.component";
import { cardDetails } from "app/models/cardDetails";

export const carImgTextOverlayRewards: cardDetails[] = [
  {
    "imgUrl":"assets/images/common/blue-shape-bg@2x.webp",
    "hideImgOnMobile": true,
    "imgAlt":"Benefits Blue Background Image",
    "title":["We make it possible to submit more contracts:"],
    "points":[
      'Approve every customer in 30 seconds or less; less time = more deals',
      'CAPS helps you quickly structure a deal that’s best for you and your customers 24/7/365',
      'Full-spectrum financing to meet the needs of your customers with no credit to prime'
    ],
    "buttonName": 'Login to Caps',
    "buttonId": "promo_rewards_card_img_make_more_caps_login",
    "link": '/dealers/caps-redirect',
    "cardHeight": "370px",
    "cardMobileHeight":"500px"
  },
  {
    "imgUrl":"assets/images/common/yellow-shape-bg@2x.webp",
    "hideImgOnMobile": true,
    "imgAlt":"Inventory Yellow Background Image",
    "title":["What happens after 90 days? You’re automatically enrolled in Dealer Rewards."],
    "description": ["After 90 days is up, you will have the opportunity to get paid an additional $150 Loyalty Advance in Dealer Rewards:"],
    "outletComponent":HowItWorksComponent,
    "cardHeight": "370px",
    "cardMobileHeight":"500px"
  }
]