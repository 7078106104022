<ng-container *ngIf="details">
  <div class="row mx-0  w-100 image-overlay-container" *ngIf="!isMobileView">
    <div  id="px-0 col-6 order-2 ng-star-inserted informative" class='image-overlay-box image-overlay-width' >
      <img *ngIf="details[0]?.imgUrl" alt="informative" class="image-overlay-box-right" height="463px" data-src="{{details[0].imgUrl}}" loading="lazy" />
      <div class='image-overlay-box-right image-left-overlay'>
      <div class=" pt-3 pb-0 " class='image-overlay-box-right image-left-text'>
        <div class="bgc-credit-white ps-3 pe-2 py-3" [ngStyle]="{'height': details[0].cardHeight ? details[0].cardHeight : 'unset'}">
          <ng-container *ngTemplateOutlet="detailTemp; context: {info: details[0]}"></ng-container>
        </div>
      </div>
      </div>
    </div>
    <div  id="px-0 col-6 order-2 ng-star-inserted finacial"  class='image-overlay-width' >
      <img *ngIf="details[1]?.imgUrl" alt="finacial" class="position-absolute" height="463px" data-src="{{details[1].imgUrl}}" loading="lazy" />
      <div class='image-overlay-box-right image-left-overlay' >
        <div class="pt-3 pb-0 " class="image-right-text">
          <div class="bgc-credit-white ps-3 pe-2 py-3" [ngStyle]="{'height': details[1].cardHeight ? details[1].cardHeight : 'unset'}">
            <ng-container *ngTemplateOutlet="detailTemp; context: {info: details[1]}"></ng-container>
          </div>
        </div>
        </div>
    </div>
  </div>
  <div class="bgc-credit-white py-3" *ngIf="isMobileView">
    <app-carousel-impl  
    [slidesData]="details"
    [sectionName]="'info-education'"
    [btnClass]="'btn btn-primary'"
    [isMobileView]="isMobileView">
    </app-carousel-impl>
  </div>
</ng-container>

<ng-template #detailTemp let-info="info">
  <ng-container *ngFor="let infoTitle of info?.title">
    <div *ngIf="infoTitle">
      <span class="h4 d-block mb-0" attr.aria-label="{{infoTitle}}" [innerHTML]="infoTitle"></span>
    </div>
  </ng-container>

  <ng-container *ngFor="let desc of info?.description">
    <div *ngIf="desc" class="py-2 body-description mb-1">
      <span attr.aria-label="{{desc}}" [innerHTML]="desc"></span>
    </div>
  </ng-container>

  <nav class="pt-2 body-description mb-1" *ngIf="info?.points">
    <ul class="ps-3">
      <li *ngFor="let pts of info?.points" class="py-1">
        <span [innerHTML]="pts"></span>
      </li>
    </ul>
  </nav>

  <ng-container *ngIf="info.outletComponent">
    <ng-container [ngComponentOutlet]="info.outletComponent"></ng-container>
  </ng-container>

  <div *ngIf="info?.buttonName" class="card-actions">
    <a role="button" type=button class="btn btn-primary" appAnchorClick id="{{info.id}}"
     attr.aria-label="{{info?.buttonName}}" [linkInfo]="info">{{info?.buttonName}}</a>
  </div>
</ng-template>


