import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Component, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AWSService } from 'app/services/aws.service';
import { combineLatest, map, Observable, of } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogArticleData, PageData } from './data.model';

@Component({
  selector: 'blog-article',
  templateUrl: './blog-article.component.html',
  styleUrls: ['./blog-article.component.scss'],
})
export class BlogArticleComponent implements OnInit {
  private readonly isDealerBlog = inject(Router).url.includes('dealers/');
  private readonly routeData = inject(ActivatedRoute).snapshot.data;
  private readonly responsive = inject(BreakpointObserver);
  private readonly awsService = inject(AWSService);
  private readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  private readonly metaService = inject(Meta);

  data!: Observable<PageData | null>;

  ngOnInit(): void {
    const { awsUrl, customStyles } = this.routeData;
    this.data = this.isBrowser
      ? combineLatest({
          pageData: this.awsService
            .getAWSData(awsUrl)
            .then(
              (json) =>
                JSON.parse(
                  JSON.stringify(json).replace(
                    /AWS_BUCKET_URL/g,
                    this.awsService.getAWSBucketURL(),
                  ),
                ) as BlogArticleData,
            )
            .then((parsedJson) => {
              const {
                CacHeroImageComponent: { ogImageURL },
              } = parsedJson;
              // Add OG tags in header
              this.metaService.addTag({
                property: 'og:image',
                content: ogImageURL,
              });
              this.metaService.addTag({
                property: 'og:url',
                content: window.location.href,
              });
              this.metaService.addTag({
                property: 'og:type',
                content: 'article',
              });
              this.metaService.addTag({
                property: 'og:locale',
                content: 'en_US',
              });

              return parsedJson;
            }),
          styles: of({
            isCurvedImage: false,
            headerColor: '#FCFCFA',
            // apply custom styles if present
            ...(customStyles
              ? {
                  overLineBgColor: customStyles.overLineBgColor,
                  overLineTextColor: customStyles.overLineTextColor,
                  // check blog type and set default banner colors if custom styles are not present
                }
              : {
                  overLineBgColor: this.isDealerBlog ? '#FA6400' : '#2B4361',
                  overLineTextColor: this.isDealerBlog ? '#171714' : '#FCFCFA',
                }),
          }),
          breakpointState: this.responsive.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
          ]),
        }).pipe(
          map(
            ({ pageData, styles, breakpointState: { matches: isMobile } }) => ({
              isMobile,
              styles,
              ...pageData,
            }),
          ),
        )
      : of(null);
  }
}
