<div
  class="locations"
>
  <div
    class="locations__card"
    #card
    *ngFor="let dealerFinderResult of dealerFinderResultsList; index as i"
    (mouseenter)="onCardMouseEnter(i)"
    (mouseleave)="onCardMouseLeave()"
    [tabindex]="i"
  >
    <div class="locations__card-display">
      <div class="locations__card-title">
        {{ dealerFinderResult.doingBusinessAs ? dealerFinderResult.doingBusinessAs : dealerFinderResult.dealerName }}
      </div>
    </div>
    <div class="locations__card-description">
      <div class="locations__card-address">
        <div class="locations__card-addressLine">
          {{ dealerFinderResult.addressLine1 }} <br />
          {{ dealerFinderResult.city }} {{ dealerFinderResult.state }},
          {{ dealerFinderResult.zip }} <br />
        </div>
        <div class="locations__card-geo">
          <span class="locations__card-gmarker">{{ i + 1 }}</span>
          <span class="locations__card-distance">
            {{ dealerFinderResult.geoDistance | number : "1.1-1" }} mi
          </span>
        </div>
      </div>
      <div class="locations__card-link">
        <div *ngIf="dealerFinderResult.phoneNumber">
          <a
            id="phone{{ dealerFinderResult.dealerId }}"
            class="inline-hyper-link locations__card-phone"
            [attr.aria-label]="dealerFinderResult.phoneNumber +' for '+ dealerFinderResult.dealerName"
            href="tel:{{ dealerFinderResult.phoneNumber }}"
            (click)="onClickEvent(dealerFinderResult.dealerId, 'phone')"
            >{{ dealerFinderResult.phoneNumber }}</a
          >
        </div>
        <!-- Separator between phone and direction -->
        <div
          *ngIf="
            dealerFinderResult.phoneNumber &&
            (dealerFinderResult.addressLine1 || dealerFinderResult.website)
          "
        >
          &nbsp;|&nbsp;
        </div>
        <div *ngIf="dealerFinderResult.addressLine1">
          <a
            class="inline-hyper-link locations__card-direction"
            target="_blank"
            [attr.aria-label]="'directions to ' + dealerFinderResult.dealerName "
            id="direction{{ dealerFinderResult.dealerId }}"
            href="https://www.google.com/maps/dir/?api=1&travelmode=highways&zoom=20&destination={{
              dealerFinderResult.addressLine1
            }},{{ dealerFinderResult.city }},{{ dealerFinderResult.state }},{{
              dealerFinderResult.zip
            }}"
            (click)="
              onClickEvent(dealerFinderResult.dealerId, 'direction')
            "
            >{{ awsS3content?.card?.direction }}</a>
        </div>
        <ng-container *ngIf="websiteLinksAndDisclosureEnabled">
          <div
            *ngIf="dealerFinderResult.addressLine1 && dealerFinderResult.website"
          >
            &nbsp;|&nbsp;
          </div>
          <div *ngIf="dealerFinderResult.website">
            <a
              class="inline-hyper-link locations__card-website"
              target="_blank"
              [attr.aria-label]="'website for ' + dealerFinderResult.dealerName "
              id="website{{ dealerFinderResult.dealerId }}"
              href="{{ dealerFinderResult.website }}"
              (click)="onClickEvent(dealerFinderResult.dealerId, 'website')"
            >{{ awsS3content?.card?.website }}</a
            >
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
