import { numbersSpeak } from "app/models/numbersSpeak";

export const dealerHomeNumberSpeak: numbersSpeak = {
  heading: 'Las cifras hablan por sí solas.',
  headingId: 'nums_home_heading',
  numDetailsValueDisplaySize: 'h4',
  numDetails: [
    {
      "value": "Más de 4 millones",
      "title": "de consumidores financiados"
    },
    {
      "value": "Más de 15 000",
      "title": "concesionarios en nuestra red"
    },
    {
      "value": "Más de 280 millones",
      "title": "de Portfolio Profit pagados a concesionarios en el año pasado"
    }
  ]
}
