import { Component, Input, OnInit } from '@angular/core';
import { footerDisclaimer } from 'app/data/footer/footer_disclaimer';
import { creditLinks } from 'app/models/credit_links';
import { ContentService } from 'app/services/content.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerNavigationLinks: creditLinks[]=[];
  footerImages: creditLinks[]=[];
  currentYear: string = "";
  @Input() footerDisclaimer: any = footerDisclaimer;

  constructor(private contentService: ContentService) { }

  ngOnInit(): void {
    this.footerNavigationLinks  = this.contentService.getFooterLinks();
    this.footerImages = this.contentService.getFooterImages();
    this.currentYear = new Date().getFullYear().toString();
  }

}
