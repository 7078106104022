import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DEALER_PURCHASE_PROGRAM_FLAT_JSON_URL } from 'app/constants/aws.constants';
import { AWSService } from 'app/services/aws.service';
import { SEOService } from 'app/services/seoService';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-dealers-purchase-program-flat',
  templateUrl: './dealers-purchase-program-flat.component.html',
  styleUrls: ['./dealers-purchase-program-flat.component.scss']
})
export class DealersPurchaseProgramFlatComponent implements OnInit {

  subscription: Subscription[] = [];
  url = DEALER_PURCHASE_PROGRAM_FLAT_JSON_URL;
  isBrowser: boolean = false;
  isMobileViewDisplay: boolean = false;
  heroImageInfo: any = {};
  purchaseProgamFlatsData: any = {};
  bottonBgColor: string = "#FFB800";
  overLineBgColor: string = "#C75000";
  headerColor: string = "#FA6400";
  overLineTextColor: string = "#FFFFFF";
  bottonBgColorToggle: string = "#FFB800";
  overLineBgColorToggle: string = "#FCFCFC";
  headerColorToggle: string = "#FFFFFF";
  overLineTextColorToggle: string = "#171714";
  introData: any = {};
  introData2: any = {};
  iframeContent: any = {};
  faqData: any = {};
  flatsContent: any = {};

  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService: AWSService, private seoService: SEOService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.seoService.setNoRobotsSEODataTag();

    if (this.isBrowser) {
      this.awsService.getAWSData(this.url).then(json => {
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);
        this.purchaseProgamFlatsData = json;

        this.heroImageInfo = this.purchaseProgamFlatsData.CacAnimatedHeroImageComponent;
        this.iframeContent = this.purchaseProgamFlatsData.CacIframeComponent;
        this.introData = this.purchaseProgamFlatsData.CacCreditIntroductionPurchaseProgram1;
        this.introData2 = this.purchaseProgamFlatsData.CacCreditIntroductionPurchaseProgram2;
        this.faqData = this.purchaseProgamFlatsData.CacFaqsComponent;
        this.flatsContent = this.purchaseProgamFlatsData.EarnFlatsOnDeals;
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
        this.isMobileViewDisplay = result.matches ? true : false;
      })
    );
  }
}
