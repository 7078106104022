<div class="bgc-primary-white py-4">
  <div class="container px-4">
    <div [ngClass]="isMobileView ? 'w-100' : 'w-75'">
      <div *ngIf="information.heading" class="py-1 pe-2" [ngClass]= "{ 'me-5': !isMobileView}">
        <ng-container *ngFor="let heading of information.heading">
          <div class="h3" attr.aria-label="{{heading}}" [innerHTML]="heading"></div>
        </ng-container>
      </div>
      <nav role="navigation" class="pt-3 body-description" *ngIf="information?.points">
        <ul class="ps-3 mb-0">
          <li role="menuitem" class="py-2" *ngFor="let pts of information?.points">
            <span attr.aria-label="{{pts}}" [innerHTML]="pts"></span>
          </li>
        </ul>
      </nav>
      <div *ngIf="information.content" class="body-description h-auto py-3 pe-5 mb-0">
        <ng-container *ngFor="let content of information.content">
          <div class="pb-2" attr.aria-label="{{content}}">
            <span [innerHTML]="content"></span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
