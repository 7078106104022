import {
  Component,
  inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { AWSService } from 'app/services/aws.service';
import { combineLatest, map, Observable } from 'rxjs';
import {
  PORTFOLIO_LANDING_100_JSON_URL,
  PORTFOLIO_LANDING_50_JSON_URL,
} from 'app/constants/aws.constants';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import {
  PorfolioLandingPageModel,
  PortfolioLandingJSONData,
} from './data.model';
import { BannerImage } from '@credit-acceptance/cac-angular-shared-components/lib/cac-hero-image/cac-hero-image.model';
import { IntroDescription } from '@credit-acceptance/cac-angular-shared-components/lib/cac-credit-introduction/cac-credit-introduction.model';
import { TabToggleInfo } from '../../models/toggle_tabs';
import { GridProgram } from '@credit-acceptance/cac-angular-shared-components/lib/cac-grid-program/cac-grid-program.model';
import { CacCard } from '@credit-acceptance/cac-angular-shared-components/lib/cac-acceptance-card/cac-acceptance-card.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-portfolio-landing',
  templateUrl: './portfolio-landing.component.html',
  styleUrls: ['./portfolio-landing.component.scss'],
})
export class PortfolioLandingComponent implements OnInit {
  private readonly renderer = inject(Renderer2);
  private readonly document = inject(DOCUMENT);
  private readonly responsive = inject(BreakpointObserver);
  private readonly awsService = inject(AWSService);
  private readonly metaService = inject(Meta);
  private readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  private readonly isPortfolioLanding100 =
    inject(Router).url.split('?')[0] === '/dealers/portfolio-program/details-100';

  readonly headerColor: string = '#FFFFFF';
  data!: Observable<PorfolioLandingPageModel>;

  ngOnInit(): void {
    if (this.isBrowser) {
      this.metaService.addTag({ name: 'robots', content: 'noindex' });
      this.data = combineLatest({
        portfolioLandingResource: this.awsService
          .getAWSData(
            this.isPortfolioLanding100
              ? PORTFOLIO_LANDING_100_JSON_URL
              : PORTFOLIO_LANDING_50_JSON_URL,
          )
          .then((json) =>
            JSON.parse(
              JSON.stringify(json).replace(
                /AWS_BUCKET_URL/g,
                this.awsService.getAWSBucketURL(),
              ),
            ),
          ) as Promise<PortfolioLandingJSONData>,
        breakpointState: this.responsive.observe([
          Breakpoints.XSmall,
          Breakpoints.Small,
        ]),
      }).pipe(
        map(
          ({
            portfolioLandingResource,
            breakpointState: { matches: isMobile },
          }) => ({
            isMobile,
            cacHeroImage:
              portfolioLandingResource.CacHeroImageComponent as BannerImage,
            portfolioCreditIntroduction:
              portfolioLandingResource.CacCreditIntroductionPortfolio as IntroDescription,
            tabbedContent:
              portfolioLandingResource.CacTabbedContentComponent as TabToggleInfo,
            gridProgram:
              portfolioLandingResource.CacPorfolioGrid as GridProgram,
            cacCard: portfolioLandingResource.CacCardComponent as CacCard,
          }),
        ),
      );

      if (this.isPortfolioLanding100) {
        const appComponentElement = this.document.getElementsByTagName(
          'app-portfolio-landing',
        )?.[0];
        if (appComponentElement) {
          this.renderer.addClass(appComponentElement, 'portfolio-landing-100');
        }
      }
    }
  }
}
