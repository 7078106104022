<div *ngIf="creditNumSpeak" class="bgc-tertiary-white">
  <div class="container py-4">
    <div *ngIf="creditNumSpeak.heading" class="py-3" [ngClass]= "{ 'secondary-heading' : isMobileView}">
      <span class="h3" [ngClass]= "{ 'secondary-heading' : !isMobileView}"><span [id]="creditNumSpeak.headingId">{{creditNumSpeak.heading}}</span></span>
    </div>
    <div *ngIf="creditNumSpeak.numDetails" class="row">
      <div [ngClass]="isMobileView ? 'col-12' : 'col-4'" class="text-center px-0 py-5" *ngFor="let speak of creditNumSpeak.numDetails; let first=first; let last= last; let i = index" id="numb_speak_{{i}}">
        <div [ngClass]="{'br-dotted-yellow' : !first && !isMobileView }">
          <div *ngIf="speak?.value">
            <span class="{{creditNumSpeak.numDetailsValueDisplaySize ?? 'h2'}} sky-blue-text">{{speak?.value}}</span>
          </div>
          <span *ngIf="speak?.title" class="body-description" [ngClass]="!last && isMobileView ? 'bb-dotted-yellow py-4' : ''">
            {{speak?.title}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
