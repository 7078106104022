import { cardDetails } from "app/models/cardDetails";

export const growYOurDealerCardTxtImg: cardDetails[] = [
  {
    "imgUrl":"assets/images/common/blue-shape-bg@2x.webp",
    "hideImgOnMobile": true,
    "imgAlt":"Benefits Blue Background Image",
    "title":["Solo será un trato ideal si le beneficia tanto a usted como a su cliente."],
    "points":[
      'CAPS le permite encontrar la mejor solución más rápidamente.',
      'Criterios de aprobación flexibles para poder cerrar todas las ventas.',
      'Apruebe financiamiento para todos sus clientes en 30 segundos o menos: menos tiempo = más ventas.',
      'Opciones de financiamiento de amplio espectro para vehículos nuevos y usados.'
    ],
    "cardHeight": "320px",
    "cardMobileHeight":"520px"
  },
  {
    "imgUrl":"assets/images/common/yellow-shape-bg@2x.webp",
    "hideImgOnMobile": true,
    "imgAlt":"Inventory Yellow Background Image",
    "title":["Renueve su inventario y póngase más dinero en el bolsillo."],
    "points":[
      'Financiamiento el mismo día con eContracting',
      '<span class="fw-bold">El Portfolio Program</span> le brinda la oportunidad de ganar de 3 modos diferentes.',
      '<span class="fw-bold">El Purchase Program</span> le brinda la posibilidad de recibir avances más altos en el momento del financiamiento.',
      'Diga “sí” a todos los clientes.'
    ],
    "cardHeight": "320px",
    "cardMobileHeight":"520px"
  }
]
