import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { TransferState } from '@angular/platform-browser';
import { CUSTOMERS_HOME_JSON_URL } from 'app/constants/aws.constants';
import { AWSService } from 'app/services/aws.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cac-customers-home',
  templateUrl: './cac-customers-home.component.html',
  styleUrls: ['./cac-customers-home.component.scss']
})
export class CacCustomersHomeComponent implements OnInit {

  subscription: Subscription[] = [];

  url = CUSTOMERS_HOME_JSON_URL;
  
  CustomerHomeJSONData:any;
  
  heroImageInfo : any = {};
  bottonBgColor: string = "#ffb800";
  overLineTextColor: string = "#1b1b1b";
  headerColor: string = "#FFFFFF";
  ariaLabel: string = "Customer Home";
  isMobileViewDisplay : boolean = false;
  isCurvedImage = false;
  isBrowser : boolean = false;
  isServer : boolean = false;
  contentBlockData : any = {};
  anchorDeviders : any = {};
  dataProofPoint : any;
  perksData : any = {};

  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, private transferState : TransferState , @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }

  ngOnInit(): void {
  
    if(this.isBrowser){
    
      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);
        
        this.CustomerHomeJSONData = json;
        this.heroImageInfo = this.CustomerHomeJSONData.CacHeroImageComponent;
        this.anchorDeviders = this.CustomerHomeJSONData.CacJumpLinkComponent;
        this.dataProofPoint = this.CustomerHomeJSONData.CacProofpointContentComponent;
        this.perksData = this.CustomerHomeJSONData.CacPerksComponent;
        this.contentBlockData = this.CustomerHomeJSONData.CacContentBlockComponent;
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          if (result.matches) {
            this.isMobileViewDisplay = true;
          }
        })
    );
  }

}
