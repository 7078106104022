<div *ngIf="!showReusalbeModule">
  <div *ngIf="slidesData" class="container">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let info of slidesData; let i = index">
          <app-acceptance-card [oneCardDetail]="info" [loadOneCardSection]="true" [loadOneCardSectionIndex]="i"
            [sectionName]="sectionName" [buttonClass]="btnClass" [isPillTypeBtn]="isPillTypeBtn"
            [isMobileView]="isMobileView" [cardClasses]="'card card-expands rounded-0'" [pointsClass]="pointsClass">
          </app-acceptance-card>
        </div>
      </div>
      <div class="swiper-pagination position-relative py-4"></div>
    </div>
  </div>
</div>

<div *ngIf="showReusalbeModule">
        <div  class="cac-header-container">
          <span class="header-border">
              <h2 class="header" attr.aria-label="{{data.header}}">
                  {{data.header}}
              </h2>
          </span>
      </div>
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let cardContent of data?.cardData">
          <div class="cac-card-box">
            <div class="cac-card-icon">
              <img src="{{cardContent.imgURL}}" alt='{{cardContent.imgURL}}' />
            </div>
            <div class="h4 cac-card-title">
              {{cardContent?.title}}
            </div>
            <div class="body-description cac-card-description">
              {{cardContent?.description}}
            </div>
            <div class='cac-card-button-container'>
              <span class='cac-card-button' *ngFor="let cardButtons of cardContent.ctaButton; let i = index"
                [attr.id]="'buttonUrl' + i + cardButtons.text">
                <!-- <cac-cta-button [data]="cardButtons" class="center"
                                  [ngClass]="i==1 ? 'cac-card-button-secondary' : ''"></cac-cta-button>  -->
                <a type="button" role="link" href="{{cardButtons?.url}}" class="btn"
                  [ngClass]="i==1 ? 'cac-card-button-secondary' : 'btn-primary'"
                  [attr.id]="'buttonUrl' + cardButtons?.text" attr.aria-label="{{cardButtons?.label}}"
                  tabindex="0">{{cardButtons?.text}}</a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination position-relative py-4"></div>
    </div>
</div>