<app-header></app-header>
<div [ngClass]="isMobileViewDisplay ? 'sub-components-margin mob-height bottom-shadow' : 'sub-components-margin bottom-shadow'" >
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [bottonBgColor]="bottonBgColor"
        [overLineBgColor]="overLineBgColor" [isCurvedImage]="isCurvedImage" [disclaimerColor]='disclaimerColor'></cac-hero-image>
</div>
<div [ngClass]="!isMobileViewDisplay ? 'container components-margin' : 'mobile-container sub-components-margin'">
    <cac-anchor-devider *ngIf="anchorDeviders" [data]="anchorDeviders"></cac-anchor-devider>
</div>
<div id="dealerVideos" *ngIf="videoData" class="components-margin top-bottom-shadow">
    <video-block [data]="videoData"></video-block>
</div>
<div class="bottom-shadow">
    <div [ngClass]="!isMobileViewDisplay ? 'container' : 'mobile-container'" >
        <div id="dealerArticles" *ngIf="articleData" class="components-margin">
            <cac-header [data]='articleData.header'></cac-header>
            <cac-articles [articleData]="articleData" [displayStacked]='false'></cac-articles>
        </div>
        <div class="mt-1">
            <a class="review-button" [href]="dealerReviewsButton.buttonLink"><span [innerHTML]="dealerReviewsButton.buttonTxt"></span> </a>
        </div>
        <div id="dealerReviews" class="components-margin pb-50">
            <cac-testimonial [data]="testimonialData"></cac-testimonial>
        </div>
    </div>
</div>
<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>