import { numbersSpeak } from "app/models/numbersSpeak";

export const dealerHomeNumberSpeak: numbersSpeak = {
  heading: 'The numbers speak for themselves.',
  headingId: 'nums_home_heading',
  numDetails: [
    {
      "value": "4MM+",
      "title": "Consumers financed"
    },
    {
      "value": "15K+",
      "title": "Dealers in our network"
    },
    {
      "value": "$280MM+",
      "title": "Portfolio Profit paid to dealers last year"
    }
  ]
}
