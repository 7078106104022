import { cardDetails } from "app/models/cardDetails";

export const resources: cardDetails[] = [
  {
    "imgIcon":"assets/images/resources/calculator.svg",
    "imgAlt":"calculators",
    "title":["Calculators"],
    "description":["Our monthly payment and auto price calculators can help you estimate a monthly payment or the purchase price of a vehicle that fits your budget."],
    "buttonName":"Learn More",
    "ariaLabel":"Learn More on auto-loan-calculator Link",
    "link":"/car-buyers/monthly-payment-calculator"
  },
  {
    "imgIcon":"assets/images/resources/question-mark.svg",
    "imgAlt":"Customer FAQ",
    "title":["Customer FAQ"],
    "ariaLabel":"Find Answers Link",
    "description":["Whether it’s about making payments, managing text alerts, or using the Customer Portal, here are the answers to your most common questions."],
    "buttonName":"Find Answers",
    "link":"/customers/faq"
  }
]