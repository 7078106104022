import { creditLinks } from "app/models/credit_links";

export const footerLinks: creditLinks[] =
  [{
    "title": "Website User Agreement and Privacy Statement",
    "id": "web_user_privacy_statement",
    "ariaLabel":"Website User Agreement and Privacy Statement Link",
    "link": "/legal/user-agreement-and-privacy"
  },
  {
    "title": "NASDAQ: CACC",
    "id": "nasdaq_cacc",
    "link": "https://www.google.com/search?q=nasdaq:cacc",
    "ariaLabel":"NASDAQ: CACC",
    "isExternalLink":true,
    "target": true
  },
  {
    "title": "Web Accessibility Policy",
    "id": "web_accessibility_policy",
    "link": "/legal/accessibility",
    "ariaLabel": "Web Accessibility Policy"
  },
  {
    "title": "NMLS ID: 3023",
    "id" : "nmls_id",
    "ariaLabel": "NMLS ID: 3023"
  },
  {
    "title": "Consumer Privacy",
    "id" : "consumer_privacy",
    "link": "/privacy-policies",
    "ariaLabel":"Consumer Privacy",
    "isCustomerLink": true,
    "target": true
  },
  {
    "title": "Language Services for NYC Residents",
    "id" : "lang_services_for_nyc_Residents",
    "ariaLabel":"Language Services for NYC Residents",
    "link": "/legal/language-services-for-nyc-residents"
  
  },
  {
    "title": "Your Credit and Privacy",
    "id" : "your_credit_and_privacy",
    "ariaLabel":"Your Credit and Privacy",
    "link": "/legal/privacy"
  }

  
];