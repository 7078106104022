import { cardDetails } from "app/models/cardDetails";

export const WorkDealCardImgDetails: cardDetails[] = [
  {
    "imgUrl":"assets/images/common/blue-shape-bg@2x.webp",
    "hideImgOnMobile": true,
    "imgAlt":"Benefits Blue Background Image",
    "title":["It’s a great deal only if it benefits you and your customer."],
    "points":[
      'CAPS allows you to  find the best solution quickly',
      'Flexible approval criteria to get every deal done',
      'Approve every customer in 30 seconds or less; less time = more deals',
      'Full-spectrum financing options on new and used vehicles'
    ],
    "cardHeight": "320px",
    "cardMobileHeight":"450px"
  },
  {
    "imgUrl":"assets/images/common/yellow-shape-bg@2x.webp",
    "hideImgOnMobile": true,
    "imgAlt":"Inventory Yellow Background Image",
    "title":["Move your inventory and put more money in your hands."],
    "points":[
      'Same day funding with eContracting',
      '<span class="fw-bold">Portfolio Program</span> provides an opportunity to be paid three ways',
      '<span class="fw-bold">Purchase Program</span> offers a higher front-end advance at time of funding',
      'Say “yes” to every customer'
    ],
    "cardHeight": "320px",
    "cardMobileHeight":"450px"
  }
]