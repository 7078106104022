import { Component, OnInit, PLATFORM_ID, Inject, Renderer2, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { CUSTOMER_FAQS_JSON_URL } from 'app/constants/aws.constants';
import { AWSService } from 'app/services/aws.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';



@Component({
  selector: 'app-customer-faqs',
  templateUrl: './customer-faqs.component.html',
  styleUrls: ['./customer-faqs.component.scss']
})
export class CustomerFaqs implements OnInit  {

  subscription: Subscription[] = [];
  searchKeyword: string = '';
  searchDisplayKeyword: string = '';
  showResults: boolean = false;
  url = CUSTOMER_FAQS_JSON_URL;
  CustomerFaqsJSONData: any;

  unfilteredFaqs = {
    "header": "Output",
    "faqs": [] as Array<{
      header?: string;
      id: string;
      question: string;
      answer: string;
      open: boolean;
      ctaButton: {
        text: string;
        url: string;
        label: string;
      };
    }>
  };

  filteredFaqs: any;

  heroImageInfo: any = {};
  bottonBgColor: string = "#C4EDFA";
  overLineBgColor: string = "#b34700";
  headerColor: string = "#171714";
  disclaimerColor: string = '#171714';
  isMobileViewDisplay: boolean = false;
  isCurvedImage = true;
  isBrowser: boolean = false;
  isServer: boolean = false;
  customerFaqsData: any;
  currentUrl: string = '';
  currentFragment: string= '';

  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService: AWSService,  @Inject(PLATFORM_ID) platformId: Object, private route: ActivatedRoute, private router: Router, private elRef: ElementRef) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }

  ngOnInit(): void {

    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = this.router.url;
        this.currentFragment = this.extractFragment(this.currentUrl);
        if (this.currentFragment && this.currentFragment.startsWith('faq')) {
          this.searchKeyword = '';
          this.showResults = false;
          this.scrollToFragment();
        }
      }
    });

    if (this.isBrowser) {
      this.awsService.getAWSData(this.url).then(json => {

        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);

        this.CustomerFaqsJSONData = json;
        this.heroImageInfo = this.CustomerFaqsJSONData.CacHeroImageComponent;
        this.customerFaqsData = this.CustomerFaqsJSONData.CacCustomerFaqsData;

        this.customerFaqsData.forEach((item: any) => {
          if (item.CacFaqsComponent.faqs) {
            this.unfilteredFaqs.faqs.push(...item.CacFaqsComponent.faqs);
          }
        });

        this.filteredFaqs = this.unfilteredFaqs;
        setTimeout(() => {
          this.checkForFragment();
        }, 0);
      });
    }

    
    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
        this.isMobileViewDisplay = result.matches ? true : false;
      })
    );
  }


  selectText(el: HTMLInputElement) {
    el.select();
  }

  search() {
    if (this.searchKeyword.trim() === '') {
      this.showResults = false;
    } else {
      // Filter FAQs based on the search keyword
      const keyword = this.searchKeyword.toLowerCase().trim();
      var filteredFaqData = this.unfilteredFaqs.faqs.filter((faq: any) =>
        faq.question.toLowerCase().includes(keyword) ||
        faq.answer.toLowerCase().includes(keyword)
      );

      this.filteredFaqs = {
        "header": "",
        "faqs": filteredFaqData.map((faq: any, index: number) => ({
          ...faq,
          "open": index === 0
        }))
      };

      this.searchDisplayKeyword = this.searchKeyword;
      this.showResults = true;
    }
  }

  checkEmptyKeyword() {
    if (this.searchKeyword.trim() === '') {
      this.showResults = false;
    }
  }


  checkForFragment(): void {
    this.route.fragment.subscribe(faqId => {
      if (faqId && faqId.startsWith('faq')) {
        const element = document.getElementById(faqId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    });
  }

  private scrollToFragment(): void {
    setTimeout(() => { 
    if (this.currentFragment) {
      const element = this.elRef.nativeElement.querySelector(`#${this.currentFragment}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    }, 0);
  }

  private extractFragment(url: string): string {
    const fragmentIndex = url.indexOf('#');
    return fragmentIndex !== -1 ? url.substring(fragmentIndex + 1) : '';
  }


}
