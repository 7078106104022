<app-header></app-header>
<div [ngClass]= "{'mob-height': isMobileViewDisplay}" class="sub-components-margin">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [bottonBgColor]="bottonBgColor"
        [overLineBgColor]="overLineBgColor" [overLineTextColor]="overLineTextColor" [isCurvedImage]="isCurvedImage" [disclaimerColor]="disclaimerColor" ></cac-hero-image>
</div>
<cac-credit-introduction [information]="introData"></cac-credit-introduction>
<div class="skyColor">
    <div class="container" *ngIf="artileDataTwo">
        <cac-header [data]='artileDataTwo.header'></cac-header>
        <cac-articles [articleData]="artileDataTwo" [displayStacked]='true'></cac-articles>
    </div>
</div>
<div *ngIf="videoData">
    <video-block *ngIf='videoData' [data]="videoData"></video-block>
</div>
<div class='sub-components-margin"'>
    <video-two-col [data]="videoDataThree"></video-two-col>
</div>
<div class="container sub-components-margin">
    <cac-acceptance-card [data]="cardData"></cac-acceptance-card>
</div>
<div class="container sub-components-margin mb-30" *ngIf="articleData">
    <cac-header [data]='articleData.header'></cac-header>
    <cac-articles [articleData]="articleData" [displayStacked]='false' swipperId="articleDataSwipper"></cac-articles>
</div>
<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>