import { Component, Input, OnInit } from '@angular/core';
import { cardDetails } from 'app/models/cardDetails';
import { creditLinks } from 'app/models/credit_links';

@Component({
  selector: 'app-portfolio-purchase',
  templateUrl: './portfolio-purchase.component.html',
  styleUrls: ['./portfolio-purchase.component.scss']
})
export class PortfolioPurchaseComponent implements OnInit {
  private capsLoginGetStartedDefault: creditLinks= {
    id: "login_to_caps_portfoilo_btn",
    title: "Login to CAPS to get started >",
    link: "/dealers/caps-redirect",
    ariaLabel: "Login to CAPS to get started"
  };

  @Input() isMobileView: boolean = false;
  @Input() details: cardDetails[] = [];
  @Input() set capsLoginGetStarted(value: Partial<creditLinks> | undefined) {
    this.capsLoginGetStartedConfig = { ...this.capsLoginGetStartedDefault, ...value };
  }
  titles: string[] = ['Portfolio Program', 'Purchase Program'];
  capsLoginGetStartedConfig: creditLinks = this.capsLoginGetStartedDefault;

  constructor() { }

  ngOnInit(): void {
  }

}
