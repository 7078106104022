import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { GeoCenter } from 'app/shared/interfaces/geo-center.interface';
import { GoogleMapMarker } from 'app/shared/interfaces/google-map-marker.interface';
import { DealerFinderService } from '../dealer-finder.service';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'app-dealer-finder-map',
  template: `
    <google-map
      #map
      height="500px"
      width="100%"
      [center]="geoCenter"
      [zoom]="zoom"
      [options]="mapOptions"
    >
      <map-marker
        #markerElem="mapMarker"
        *ngFor="let marker of markers"
        [position]="marker.position"
        [label]="marker.label"
        [title]="marker.title"
        [options]="marker.options"
        [clickable]="true"
        (mapClick)="onMarkerClick(marker)"
      >
      </map-marker>
    </google-map>
  `,
})
export class DealerFinderMapComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() geoCenter: GeoCenter;
  @Input() zoom: number;
  @Input() markers: GoogleMapMarker[];

  @ViewChild('map') map!: GoogleMap;

  mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  constructor(private dealerFinderService: DealerFinderService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['markers'] && !changes['markers'].firstChange) {
      if (this.markers.length > 1) {
        this.setBounds();
      }
      
    }
  }

  ngOnInit(): void {
    this.dealerFinderService.searchListItemEvent$.subscribe(
      ({ event, index }) => {
        if (event === 'mouseenter') {
          //Reset all markers to default color
          this.resetAllMarkers();

          //Get marker index from i
          const marker = this.markers[index];

          this.changeMarker(marker);
        } else {
          this.resetAllMarkers();
        }
      }
    );
  }

  ngAfterViewInit(): void {
    if (this.markers.length > 1) {
      this.setBounds();
    }
  }

  setBounds() {
    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach((marker) =>
      bounds.extend(
        new google.maps.LatLng(marker.position.lat, marker.position.lng)
      )
    );

    // Delay execution to ensure markers are rendered
    setTimeout(() => {
      this.map.googleMap?.fitBounds(bounds, { top: 50, bottom: 50, left: 50, right: 50 });
    }, 500);
  }

  onMarkerClick(marker: GoogleMapMarker): void {
    this.resetAllMarkers();

    this.changeMarker(marker, 'green', { width: 35, height: 45 });

    // Scroll to the corresponding card
    const index = this.markers.indexOf(marker);

    this.dealerFinderService.emitMarkerIconClicked(index);
  }

  resetAllMarkers(): void {
    this.markers.forEach((marker) => {
      this.changeMarker(marker, 'red', { width: 26, height: 36 });
    });
  }

  changeMarker(
    marker: GoogleMapMarker,
    color = 'green',
    size = { width: 35, height: 45 }
  ): void {
    marker.options = {
      ...marker.options,
      icon: {
        url: `assets/images/googleMarker/${color}.svg`,
        size: {
          width: size.width,
          height: size.height,
          equals: (other: google.maps.Size) => {
            return other.width === size.width && other.height === size.height;
          },
        },
        scaledSize: {
          width: size.width,
          height: size.height,
          equals: (other: google.maps.Size) => {
            return other.width === size.width && other.height === size.height;
          },
        },
      },
    };
  }
}
