<app-header></app-header>
<div
  class="static-page sub-components-margin"
  [ngClass]="isMobileViewDisplay ? 'mobile-container' : 'container'"
>
<div [innerHTML]="content">
</div>
</div>

<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>
