<app-header></app-header>
<div [ngClass]="isMobileViewDisplay ? 'sub-components-margin mob-height' : 'sub-components-margin'" >
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [bottonBgColor]="bottonBgColor"
        [overLineBgColor]="overLineBgColor" [isCurvedImage]="isCurvedImage"></cac-hero-image>
</div>
<div [ngClass]="!isMobileViewDisplay ? 'container' : 'mobile-container'">
    <div class="components-margin">
        <cac-anchor-devider *ngIf="anchorDeviders" [data]="anchorDeviders"></cac-anchor-devider>
    </div>
    <div id="finacialEducation" class="pt-75">
        <cac-education-career [data]="educationCorusalData"></cac-education-career> 
    </div>
</div>
<div id="videos" *ngIf="videoData" class="components-margin top-bottom-shadow">
    <video-block [data]="videoData"></video-block>
</div>
<div class="bottom-shadow">
    <div [ngClass]="!isMobileViewDisplay ? 'container' : 'mobile-container'" >
        <div id="articles" *ngIf="articleData" class="components-margin">
            <cac-header [data]='articleData.header'></cac-header>
            <cac-articles [articleData]="articleData" [displayStacked]='false'></cac-articles>
        </div>
        <div class="components-margin">
            <cac-acceptance-card [data]="cardData"></cac-acceptance-card>
        </div>
        <div id="customerReviews" class="components-margin pb-50">
            <cac-testimonial [data]="testimonialData"></cac-testimonial>
        </div>
    </div>
</div>
<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>