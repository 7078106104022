import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DEALERS_PURCHASE_PROGRAM_JSON_URL, ESG_JSON_URL, YOUR_CREDIT_APPROVAL_JSON_URL } from 'app/constants/aws.constants';
import {AWSService} from 'app/services/aws.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-dealers-purchase-program',
  templateUrl: './dealers-purchase-program.component.html',
  styleUrls: ['./dealers-purchase-program.component.scss']
})
export class DealersPurchaseProgramComponent implements OnInit {

  subscription: Subscription[] = [];
  url = DEALERS_PURCHASE_PROGRAM_JSON_URL;
  isBrowser : boolean = false;
  isMobileViewDisplay : boolean = false;
  heroImageInfo: any = {};
  purchaseProgramData : any = {};
  bottonBgColor: string = "#FFB800";
  overLineBgColor: string = "#C75000";
  headerColor: string = "#FA6400";
  overLineTextColor: string = "#FFFFFF";
  bottonBgColorToggle: string = "#FFB800";
  overLineBgColorToggle: string = "#FCFCFC";
  headerColorToggle: string = "#FFFFFF";
  overLineTextColorToggle: string = "#171714";
  introData: any = {}
  introData2: any = {};
  iframeContent: any = {};
  faqData : any = {};

  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    
    this.isBrowser = isPlatformBrowser(platformId);

     //Add no robots meta tag
     let meta = document.createElement('meta');
     meta.name = "robots";
     meta.content = "noindex";
     document.getElementsByTagName('head')[0].appendChild(meta);
  }

  ngOnInit(): void {

    if(this.isBrowser){
      
      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);
        this.purchaseProgramData = json;
        
        this.heroImageInfo = this.purchaseProgramData.CacAnimatedHeroImageComponent;
        this.iframeContent = this.purchaseProgramData.CacIframeComponent;
        this.introData = this.purchaseProgramData.CacCreditIntroductionPurchaseProgram1;
        this.introData2 = this.purchaseProgramData.CacCreditIntroductionPurchaseProgram2;
        this.faqData = this.purchaseProgramData.CacFaqsComponent;
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }

}
