import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalculatorConstants } from 'app/constants/calculator';
import { Subscription } from 'rxjs';
import { ObjectType } from 'typescript';

@Component({
	selector: 'monthly-calculator',
	templateUrl: './monthly-payment-calculator.component.html',
	styleUrls: ['./monthly-payment-calculator.component.scss']
})
export class MonthlyCalculatorComponent implements OnInit {

	@Input() monthly: boolean = true;
	subscription: Subscription[] = [];
	calculatorForm: FormGroup;
	submitted = false;
	calculatedPrice: number = 0;
	monthlyPrice: number = 0;
	interestRate: number = 0;
	isEnabled = false;
	mobileView: boolean = false;
	auto: boolean = false;
	calculatorTitle: string = 'Monthly Car Payment Calculator';
	calculatorSubTitle: string = 'Enter the information to get your monthly car payment estimate.';
	paymentLabel: string = 'Estimated Monthly Payment'
	calculatorTxt: string = 'Vehicle price';
	validationErrorText: string = 'Please fill in vehicle price';
	invalidValueErrorText: string = 'Please enter valid auto price';

	usStates = CalculatorConstants.usStates;
	creditScores = CalculatorConstants.creditScores;
	loanLengths = CalculatorConstants.loanLengths;
	data1: any = CalculatorConstants.data1;

	constructor(@Inject(FormBuilder) private fb: FormBuilder, private responsive: BreakpointObserver) {

		


		this.calculatorForm = this.fb.group({
			typeOfAuto: ['Used', Validators.required],
			autoPrice: ['15,000', Validators.required],
			preferredMonthlyPayment: ['352', Validators.required],
			downPayment: ['2,500', Validators.required],
			state: ['MI', Validators.required],
			creditScore: ['range1', Validators.required],
			interestRate: ['22.99%', Validators.required],
			lengthOfLoan: ['60', Validators.required]
		});
	}

	ngOnInit() {

		this.subscription.push(
			this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
				this.mobileView = result.matches ? true : false;
			})
		);

		//this.onChanges();
		if (!this.monthly) {
			this.calculatorForm = this.fb.group({
				typeOfAuto: ['Used', Validators.required],
				autoPrice: ['352', Validators.required],
				downPayment: ['2,500', Validators.required],
				state: ['MI', Validators.required],
				creditScore: ['range1', Validators.required],
				interestRate: ['22.99%', Validators.required],
				lengthOfLoan: ['60', Validators.required]
			});
			this.paymentLabel = 'Car Price Estimate';
			this.calculatorTitle = 'Calculate an Estimated Car Price';
			this.calculatorTxt = 'Preferred monthly payment';
			this.validationErrorText='Please fill in preferred monthly payment';
			this.invalidValueErrorText='Please enter valid preferred monthly payment'
			this.calculatorSubTitle='Enter the information to estimate a car price that may work with your preferred monthly payment.'
		}
		if(this.monthly) {
			this.calculateMonthlyPayment();
		} else {
			this.calculateAutoPrice();
		}
	}


	ngAfterViewInit() {

		// track initial load event for analytics 
		this.trackInitialAnalytics();
		this.calculatorForm.get('typeOfAuto')?.valueChanges.subscribe(val => {
			const state = this.data1[this.formValues.creditScore as keyof any][this.formValues.state] ? this.data1[this.formValues.creditScore][this.formValues.state] : this.data1[this.formValues.creditScore]["Other"];
			const rateOfInterest = state[this.formValues.typeOfAuto];
			this.calculatorForm.get("interestRate")?.setValue(rateOfInterest + '%');

			if (this.monthly)
				this.calculateMonthlyPayment();
			else
				this.calculateAutoPrice();
		});
		this.calculatorForm.get('lengthOfLoan')?.valueChanges.subscribe(val => {

			if (this.monthly)
				this.calculateMonthlyPayment();
			else this.calculateAutoPrice();
		});
		this.calculatorForm.get('autoPrice')?.valueChanges.subscribe(val => {

			if (this.monthly) {
				this.calculateMonthlyPayment();
			} else {
				this.calculateAutoPrice();
			}
		});

		this.calculatorForm.get('downPayment')?.valueChanges.subscribe(val => {
			if (this.monthly)
				this.calculateMonthlyPayment();
			else
				this.calculateAutoPrice();
		});

		this.calculatorForm.get('state')?.valueChanges.subscribe(val => {
			const state = this.data1[this.formValues.creditScore][this.formValues.state] ? this.data1[this.formValues.creditScore][this.formValues.state] : this.data1[this.formValues.creditScore]["Other"];
			const rateOfInterest = state[this.formValues.typeOfAuto];
			this.calculatorForm.get("interestRate")?.setValue(rateOfInterest + '%');
			if (this.monthly)
				this.calculateMonthlyPayment();
			else
				this.calculateAutoPrice();
		});

		this.calculatorForm.get('interestRate')?.valueChanges.subscribe(val => {

			let interestRate = this.formValues.interestRate.toString().replace('%', '');
			//interestRate = interestRate.replace(new RegExp('^[a-zA-Z]+$'),'');
			//this.calculatorForm.get("interestRate").setValue(interestRate + '%');
			let interestArray = interestRate.split('.');
			let part1 = '', part2 = '';

			if (Number(interestRate) >= 100) {
				if (interestArray[0] !== undefined && interestArray[0] !== '') {
					if (interestArray[0].length > 2) {
						part1 = interestArray[0].substring(0, 2)
					}
				}
				if (interestArray.length == 2 && interestArray[1] !== undefined && interestArray[1] !== '') {
					if (interestArray[1].length > 2) {
						part2 = '.' + interestArray[1].substring(0, 2);

					}
				}
				this.calculatorForm.get("interestRate")?.setValue(part1 + part2 + '%');
			} else if (interestArray.length == 2 && interestArray[1] !== undefined && interestArray[1] !== '' && interestArray[1].length > 2) {
				part1 = interestArray[0]
				part2 = '.' + interestArray[1].substring(0, 2);
				this.calculatorForm.get("interestRate")?.setValue(part1 + part2 + '%');
			}

			if (this.monthly)
				this.calculateMonthlyPayment();
			else
				this.calculateAutoPrice();
		});
	}

	transformCredit(event: any) {
		this.calculatorForm.get("creditScore")?.setValue("range6");
		let interestRate = this.formValues.interestRate.toString();
		if (interestRate === '') {
			interestRate = '0';
		} else if (interestRate.endsWith('.')) {
			interestRate = interestRate.substring(0, interestRate.length - 1);
		}
		let rounded = interestRate.toString().replace('%', '');
		rounded = rounded.replace(new RegExp('^[a-zA-Z]+$'), '');
		rounded = rounded.replace('%', '') + '%';

		this.calculatorForm.get("interestRate")?.setValue(rounded);
	}

	transformAmount(element: any) {
		const val = this.calculatorForm.get(element)?.value.replace(/,/g, "");
		const transformedAmount = (Number(val)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

		let transformed = transformedAmount.replace('.00', '')
		if (transformed !== '0.00') {
			this.calculatorForm.get(element)?.setValue(transformed);
		}
	}
	onFocusOutCreditScore(event: any) {

		if (this.formValues.creditScore === 'range6') {
			this.calculatorForm.get("interestRate")?.setValue(0 + '%');
		} else {
			const state = this.data1[this.formValues.creditScore][this.formValues.state] ? this.data1[this.formValues.creditScore][this.formValues.state] : this.data1[this.formValues.creditScore]["Other"];
			const rateOfInterest = state[this.formValues.typeOfAuto];
			this.calculatorForm.get("interestRate")?.setValue(rateOfInterest + '%');
		}

		if (this.monthly)
			this.calculateMonthlyPayment();
		else
			this.calculateAutoPrice();
	}

	onFocusOutInterestRate(event: any) {
		let interestRate = this.formValues.interestRate.toString();
		if (interestRate === '') {
			interestRate = '0';
		} else if (interestRate.endsWith('.')) {
			interestRate = interestRate.substring(0, interestRate.length - 1);
		}
		this.calculatorForm.get("interestRate")?.setValue(interestRate + '%');
	}

	onFocusOutDownPayment(event: any) {
		if (this.formValues.downPayment === '') {
			this.calculatorForm.get("downPayment")?.setValue(0);
		}
	}
	onFocusOutAutoPrice(event: any) {
		if (this.formValues.autoPrice === '') {
			this.calculatorForm.get("autoPrice")?.setValue(0);
		}
	}

	onFocusOutMonthlyPayment(event: any) {
		if (this.formValues.preferredMonthlyPayment === '') {
			this.calculatorForm.get("preferredMonthlyPayment")?.setValue(0);
		}
	}

	get formValues() { return this.calculatorForm.getRawValue() };

	// convenience getter for easy access to form fields
	get f() { return this.calculatorForm.controls; }


	calculateMonthlyPayment() {
		let auto = this.formValues.autoPrice;
		auto = auto.toString().replace(',', '');
		let down = this.formValues.downPayment;
		down = down.toString().replace(',', '');
		let interestRate = this.formValues.interestRate.toString().replace('%', '');

		const loanAmount = Number(auto) - Number(down);
		const n = this.formValues.lengthOfLoan;
		if (interestRate > 0) {
			const aprRate = interestRate / 100;
			const apprdiv12 = aprRate / 12;

			this.monthlyPrice = (loanAmount) * (apprdiv12 * Math.pow(1 + apprdiv12, n)) / ((Math.pow(1 + apprdiv12, n) - 1));
		} else {
			this.monthlyPrice = loanAmount / n;
		}

		this.calculatedPrice = Math.round(this.monthlyPrice);
		this.calculatedPrice = this.calculatedPrice > 0 ? this.calculatedPrice : 0;
		//this.calculatorForm.get("preferredMonthlyPayment")?.setValue(this.calculatedPrice);

	}

	calculateAutoPrice() {

		let monthly = this.formValues.autoPrice;
		//let monthly = this.formValues.ok;
		monthly = monthly.toString().replace(',', '');
		let down = this.formValues.downPayment;
		down = down.toString().replace(',', '');

		let interestRate = this.formValues.interestRate.toString().replace('%', '');
		const aprRate = interestRate / 100;
		const apprdiv12 = aprRate / 12;
		const loanLength = this.formValues.lengthOfLoan;
		const monthlyPayment = Number(monthly);
		const downPayment = Number(down);

		if (interestRate > 0) {
			this.calculatedPrice = Number(monthlyPayment * (Math.pow((1 + apprdiv12), loanLength) - 1) / (apprdiv12 * Math.pow((1 + apprdiv12), loanLength))) + Number(downPayment);
		} else {
			this.calculatedPrice = Number(monthlyPayment * loanLength) + Number(downPayment);
		}
		this.calculatedPrice = Math.round(this.calculatedPrice);
		this.calculatedPrice = this.calculatedPrice > 0 ? this.calculatedPrice : 0;
	}

	onReset() {
		this.submitted = false;
		this.calculatorForm.reset();
	}

	onScrollClick() {
		document.getElementById('calc-summary')?.scrollIntoView();
	}

	selectText(el: HTMLInputElement){
		el.select();
	}

	trackInitialAnalytics() {
		(window as any).adobeDataLayer = (window as any).adobeDataLayer || [];
		let cacAnalytics: { [key: string]: any } = {};
		if(this.monthly) {
			cacAnalytics.tool_name = "monthly payment calculator";
			cacAnalytics.vehicle_price = "15000";
			cacAnalytics.monthly_payment_estimate = "352";
		} else {
			cacAnalytics.tool_name = "auto affordability calculator";
			cacAnalytics.preferred_monthly_payment  = "352";
			cacAnalytics.auto_affordability_estimate = "14989";
		}
		
		cacAnalytics.vehicle_type = "Used";
		
		cacAnalytics.user_state = "MI";
		cacAnalytics.interest_rate = "22.99";
		cacAnalytics.down_payment = "2500";
		cacAnalytics.credit_score_range = "Less than 600 (Poor)";
		cacAnalytics.length_of_loan = "60";
		cacAnalytics.event_type = "cac-calculator-event";
		cacAnalytics.event = "cac-calculator-event";
		cacAnalytics.field_label= "";
		(window as any).adobeDataLayer.push(cacAnalytics);
	}

	updateAdobeAnalytics(event: Event) {

		let fieldId = (event.target as HTMLInputElement).id;
		let fieldValue = (event.target as HTMLInputElement).value;
		let cacAnalytics: { [key: string]: any } = {};

		if(fieldId=='typeOfAuto') {
			cacAnalytics.vehicle_type = fieldValue;
		} else if(fieldId=='state') {
			cacAnalytics.user_state  = fieldValue;
		}
		else if(fieldId=='creditScore') {
			cacAnalytics.credit_score_range = this.creditScores.find(score=> score.range==fieldValue)?.label;
		}
		else if(fieldId=='lengthOfLoan') {
			cacAnalytics.length_of_loan  = fieldValue;
		}
	
		else if(fieldId=='downPayment') {
			cacAnalytics.down_payment  = fieldValue.replace(',', '');
		}
		else if(fieldId=='interestRate') {
			cacAnalytics.interest_rate  = fieldValue.replace('%', '');
		}

		cacAnalytics.field_label = fieldId;
		if(this.monthly) {
			cacAnalytics.tool_name = "monthly payment calculator";
			cacAnalytics.monthly_payment_estimate = this.calculatedPrice.toString().replace(',', '');
			if(fieldId=='autoPrice') {
				cacAnalytics.vehicle_price  = fieldValue.replace(',', '');
			}
		  } else {
			cacAnalytics.tool_name = "auto affordability calculator";
			cacAnalytics.auto_affordability_estimate = this.calculatedPrice.toString().replace(',', '');
			if(fieldId=='autoPrice') {
				cacAnalytics.preferred_monthly_payment  = fieldValue.replace(',', '');
			}
		  }
		  cacAnalytics.event = "cac-calculator-event";
		(window as any).adobeDataLayer.push(cacAnalytics);

	}
}