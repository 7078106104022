<ng-container *ngIf="data | async as data">
  <app-header></app-header>
  <div class="sub-components-margin" >
    <div class="container">
      <h1><span class="header-title"> {{ data.header }}</span></h1>
    </div>

  </div>

  <div [ngClass]="data.isMobile ? 'mobile-container' : 'container'">
    <div [innerHTML]="data.content" class="body-description legacy-img"></div>
  </div>

  <div class="container sub-components-margin mb-30">
    <cac-acceptance-card [data]="data.CacCardComponent"></cac-acceptance-card>
  </div>
  <app-sitemap [isMobileView]="data.isMobile"></app-sitemap>
  <app-footer></app-footer>
</ng-container>
