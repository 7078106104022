import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FeatureFlagService } from 'app/services/feature-flag.service';
import { EFeatureFlag } from 'app/services/feature-flags.types';
import { map } from 'rxjs';

export const dealerFinderRedirectGuard: CanActivateFn = () => {
  const router: Router = inject(Router);
  const featureFlagService: FeatureFlagService = inject(FeatureFlagService);

  return featureFlagService.isEnabled(EFeatureFlag.DEALER_FINDER_PAGE_ENABLED).pipe(
    map((isEnabled) => {
      if (isEnabled) return true;
      else {
        router.navigate(['/page-not-found']);
        return false;
      }
    })
  );
};
