import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { JOIN_OUR_NETWORK_JSON_URL } from 'app/constants/aws.constants';
import { AWSService } from 'app/services/aws.service';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { ContentService } from 'app/services/content.service';
import { isScullyGenerated } from '@scullyio/ng-lib';

@Component({
  selector: 'join-our-network',
  templateUrl: './join-our-network.component.html',
  styleUrls: ['./join-our-network.component.scss'],
})
export class JoinNetworkComponent implements OnInit {
  subscription: Subscription[] = [];
  

  url = JOIN_OUR_NETWORK_JSON_URL;
  becomeADealerData: any;
  keypointData: any = {};
  heroImgURL = '/assets/images/join-our-network/HeroImg.webp'
  mobileView: boolean = false;
  isMobileViewDisplay: boolean = false;
  isBrowser: boolean = false;
  environment: string = ''  
  dataAvailable : boolean = false;

  constructor(
    private responsive: BreakpointObserver,
    private awsService: AWSService,
    @Inject(PLATFORM_ID) platformId: Object,
    private router: Router,
    private contentService: ContentService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.environment = this.awsService.getJONEnv();
        if(!this.contentService.isWidgetLoaded()) {
          this.loadWidget();
          this.dataAvailable=true;
        } else {
            this.dataAvailable=true;
        }

      this.awsService.getAWSData(this.url).then((json) => {
        json = JSON.stringify(json).replace(
          /AWS_BUCKET_URL/g,
          this.awsService.getAWSBucketURL()
        );
        json = JSON.parse(json);
        
        
        this.becomeADealerData = json;
        this.keypointData = this.becomeADealerData.CacKeyPointsComponent;
      });
    }

    this.subscription.push(
      this.responsive
        .observe([Breakpoints.XSmall, Breakpoints.Small])
        .subscribe((result) => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }

  loadWidget() {

    if (isScullyGenerated()) {
    let widgetElement = document.createElement("script");
    widgetElement.type = "text/javascript";
    widgetElement.async = false;

    let  hostname = window.location.hostname;
    let url: string = '';
        
    if( hostname.includes('localhost')){
      url = "https://cac-lab-portal-code-rebranding-use2.s3.us-east-2.amazonaws.com";
    }else if( hostname.includes('test')){
      url = "https://s3.us-east-2.amazonaws.com/wwwbucket-join-network.teststatic.creditacceptance.com";
    }else if( hostname.includes('qa')){
      url = "https://wwwbucket-join-network.qastatic.creditacceptance.com";
    }else if( hostname.includes('dr')){
      url = "https://wwwbucket-join-network-dr.static.creditacceptance.com";
    }else {
      url = "https://wwwbucket-join-network.static.creditacceptance.com";
    }
    if(this.environment && this.environment!='') {
      (window as any).jonEnv = this.environment;
      widgetElement.src = url + "/join-our-network-widget.js";
      document.head.appendChild(widgetElement);
      this.contentService.setWidgetLoaded();
      this.dataAvailable = true;
    }
  }

  }
  
  handleSuccess(event: Event) {
    const customEvent = event as CustomEvent;
    this.contentService.setBecomeADealerReponse(customEvent.detail);
    this.router.navigate(['/dealers/join-our-network/confirmation-thank-you']);
  }

}
