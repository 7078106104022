import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { articles } from 'app/data/articles/articles';
import { hearFromCustomers } from 'app/data/hear-from-customers/hear-from-customers';
import { heroImageContent } from 'app/data/hero_image';
import { infoEducationInfo } from 'app/data/info-education/info-education';
import { HomePageIntro } from 'app/data/introduction';
import { creditHomeNumberSpeak } from 'app/data/numbers-speak/numbersspeak';
import { resources } from 'app/data/resources/resources';
import { customerTabInfo } from 'app/data/toggle-data/customers';
import { toggleTabsData } from 'app/data/toggle-data/tabInfo';
import { bannerImage } from 'app/models/bannerImage';
import { cardDetails } from 'app/models/cardDetails';
import { IntroDescription } from 'app/models/credit_accp_Intro_desc';
import { creditLinks } from 'app/models/credit_links';
import { numbersSpeak } from 'app/models/numbersSpeak';
import { TabToggleInfo } from 'app/models/toggle_tabs';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  subscription: Subscription[] = [];
  mobileView: boolean = false;
  creditHomeNumSpeak: numbersSpeak = creditHomeNumberSpeak;
  tabInfo: TabToggleInfo = toggleTabsData;
  tabContent: cardDetails = customerTabInfo;
  homePageIntro: IntroDescription = HomePageIntro;
  infoEducationDetails: cardDetails[] = infoEducationInfo;
  hearFromCustomers: cardDetails[] = hearFromCustomers;
  hearInfo: creditLinks= {
    id: "hear_from_more_customers",
    title: "Hear from more customers >",
    link: "/customers/reviews",
    ariaLabel: "Hear From More Customers"
  };
  hearInfoSectionTitle: string = 'What drives customers to Credit Acceptance?';
  heroImageInfo: bannerImage = heroImageContent;
  resources: cardDetails[]= resources;
  articles: cardDetails[] = articles;
  articlesTitle: string = 'Next stop: articles from our Express Lane blog.';
  quickLinksId : string = "quick-link-section";
  constructor(private responsive: BreakpointObserver) { }

  ngOnInit(): void {
    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.mobileView = result.matches ? true : false;
        })
    );
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
