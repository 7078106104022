<app-header></app-header>
<div [ngClass]="isMobileViewDisplay ? 'sub-components-margin mob-height' : 'sub-components-margin'">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [bottonBgColor]="bottonBgColor"
        [overLineBgColor]="overLineBgColor" [isCurvedImage]="isCurvedImage"></cac-hero-image>
</div>

<div [ngClass]="isMobileViewDisplay ? 'container bottom-shadow' : 'container'">
    <div class="row guest-payment-key-points">
        <div [ngClass]="isMobileViewDisplay ? 'col-12' : 'col-6 mt-60'">
            <cac-iframe [sourceUrl]='iframeSrc' [height]='iframeHeight' [width]='iframeWidth'
                [ariaLabel]="ariaLabel"></cac-iframe>
        </div>

        <div *ngIf="!isMobileViewDisplay" class="col-1">
        </div>
        <div [ngClass]="isMobileViewDisplay ? 'col-12 components-margin ' : 'col-5 mt-60'">
            <h4 class="benefits-of-register">{{keypointHeader}}</h4>
            <cac-key-points [data]="keypointData"></cac-key-points>
        </div>

    </div>
</div>
<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>