import { numbersSpeak } from "app/models/numbersSpeak";

export const creditHomeNumberSpeak: numbersSpeak = {
  heading: 'The numbers speak for themselves.',
  headingId: 'nums_home_heading',
  numDetails: [
    {
      "value": "15K+",
      "title": "Dealers in our network"
    },
    {
      "value": "4MM+",
      "title": "Consumers financed"
    },
    {
      "value": "#39",
      "title": "Fortune 100 Best Companies to work for"
    }
  ]
}
