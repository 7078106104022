<div class="py-3">
  <span class="body-description-heading"><span aria-label="'The Deal Optimizer in CAPS'">Structure the best deal for you and your customer.</span></span>
</div>
<div class="row p-3">
  <div [ngClass]="isMobileView ? 'col-12 py-2' : 'col-8 px-0'">
    <span class="d-flex justify-content-between">
      <span class="mx-2 body-description-sub-heading">
        <span class="fw-bold">Dealer:</span><span> Initial profit, total profit and selling price</span>
      </span>
      <span class="mt-2">
        <img class="px-2" src="assets/images/common/icon_plus_sign.svg" alt="Plus Icon'" loading="lazy">
      </span>
      <span class="mx-2 body-description-sub-heading">
        <span class="fw-bold">Customer:</span><span> Monthly payment and cash down</span>
      </span>
    </span>
  </div>
  <div class="py-3" *ngIf="isMobileView">
    <mat-divider></mat-divider>
  </div>
  <div [ngClass]="isMobileView ? 'col-12' : 'col-4 px-0'">
    <span class="d-flex" [ngClass]="isMobileView ? 'justify-content-center px-5 mt-3' : 'justify-content-between'">
      <span class="mt-2">
        <img class="mx-2" src="assets/images/common/icon_equal_sign.svg" alt="Equal Icon'" loading="lazy">
      </span>
      <span class="mx-2 body-description-sub-heading">
        <span class="fw-bold">Best deal:</span><span> Optimized for the dealer and customer</span>
      </span>
    </span>
  </div>
</div>


