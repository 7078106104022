import { Component, OnInit } from '@angular/core';
import { creditLinks } from 'app/models/credit_links';
import { ContentService } from 'app/services/content.service';
import { FeatureFlagService } from 'app/services/feature-flag.service';
import { EFeatureFlag } from 'app/services/feature-flags.types';
import { take } from 'rxjs';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss'],
})
export class SitemapComponent implements OnInit {
  siteMapAllLinks: creditLinks[] = [];

  constructor(
    private readonly contentService: ContentService,
    private readonly featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.featureFlagService
      .isEnabled(EFeatureFlag.DEALER_FINDER_PAGE_ENABLED)
      .pipe(take(1))
      .subscribe((isEnabled) => {
        this.siteMapAllLinks = this.contentService.getSiteMapLinks();

        const customerLinks = this.siteMapAllLinks[0];

        if (isEnabled && customerLinks) {
          const dealerFinder = {
            title: 'See Dealers Near You',
            id: 'site_map_layout_956',
            ariaLabel: 'See Dealers Near You',
            link: '/car-buyers/dealer-finder',
          };

          const exists = customerLinks.childMenu?.find(link => link.title === dealerFinder.title);

          if (!exists) {
            customerLinks.childMenu?.push(dealerFinder);
          }
        }
      });
  }
}
