import { TabToggleInfo } from "app/models/toggle_tabs"
import { customerTabInfo } from "./customers"
import { dealersTabInfo } from "./dealers"

export const toggleTabsData: TabToggleInfo = {
  tabInformation: [
    {
      tabTitle: "Customers",
      active: true,
      tabId:"customer_tab",
      tabContent: customerTabInfo
    },
    {
      tabTitle: "Dealers",
      active: false,
      tabId:"dealer_tab",
      tabContent: dealersTabInfo
    }
  ]
}
