import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-caps-deal-optimizer',
  templateUrl: './caps-deal-optimizer.component.html',
  styleUrls: ['./caps-deal-optimizer.component.scss']
})
export class CapsDealOptimizerComponent implements OnInit {
  isMobileView: boolean = false;
  subscription: Subscription[] = [];

  constructor(private responsive: BreakpointObserver) { }
  
  ngOnInit(): void {
    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileView = result.matches ? true : false;
        })
    );
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
