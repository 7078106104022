import { cardDetails } from "app/models/cardDetails";

export const hearFromCustomers: cardDetails[] = [
  {
    "avatarIcon":"assets/images/hear-from/lauren@2x.webp",
    "imgAlt":"calculator",
    "userDetails": {
      "name":"Lauren",
      "Address": "Cleveland Heights, OH"
    },
    "link":"/customers/reviews?videoId=486961411#videoReviews",
    "buttonName":"more >",
    "description":["Early in life when I was 18 I got offered a lot of credit cards, and I didn't really understand the importance of credit and the role it would play in my life today and ultimately destroyed my credit. The work that Credit Acceptance does is truly making a big impact on people's lives. It definitely made a big impact on mine."],
  },
  {
    "avatarIcon":"assets/images/hear-from/joewendy@2x.webp",
    "imgAlt":"Credit FAQ",
    "userDetails": {
      "name":"Joe and Wendy",
      "Address": "GrassLake, MI"
    },
    "link":"/customers/reviews?videoId=166371331#videoReviews",
    "buttonName":"more >",
    "description":["We needed a new car for our family, but didn’t believe anyone would give us credit. It meant a lot when Credit Acceptance gave us an approval for a reliable vehicle. Credit Acceptance treated us respectfully and with understanding to help us improve our financial (and emotional) situation."],
  },
  {
    "avatarIcon":"assets/images/hear-from/Floyd-Cust-Review.webp",
    "imgAlt":"Credit FAQ",
    "userDetails": {
      "name":"Floyd",
      "Address": "Redford, MI"
    },
    "link":"/customers/reviews?videoId=185683669#videoReviews",
    "buttonName":"more >",
    "description":["Nobody really understands the struggle until you have to personally go through it. If it wasn’t for Credit Acceptance, I can’t say that I would be where I am. At one point in time, I did have halfway decent credit but during the divorce and changing over to getting custody of my kids it sort of hit me financially hard. I couldn’t get financed at a bank or anywhere else. The other dealerships that I went to, they had already denied me or they wanted an even larger down payment. I was getting discouraged, I really was…very discouraged."]
  }
]