<ng-container *ngIf="data | async as data">
  <app-header></app-header>
  <div
    [ngClass]="{ 'mob-height': data.isMobile }"
    class="sub-components-margin"
  >
    <cac-hero-image
      [heroImageInfo]="data.CacHeroImageComponent"
      [headerColor]="data.styles.headerColor"
      [overLineBgColor]="data.styles.overLineBgColor"
      [overLineTextColor]="data.styles.overLineTextColor"
      [isCurvedImage]="data.styles.isCurvedImage"
    ></cac-hero-image>
  </div>
  <div class="container-fluid background-skyblue">
    <div [ngClass]="data.isMobile ? 'mobile-container' : 'container'">
      <div [innerHTML]="data.Content_1"></div>
    </div>
  </div>
  <div
    class="components-margin"
    [ngClass]="data.isMobile ? 'mobile-container' : 'container'"
  >
    <div [innerHTML]="data.Content_2"></div>
  </div>
  <div class="container sub-components-margin mb-30">
    <cac-acceptance-card [data]="data.CacCardComponent"></cac-acceptance-card>
  </div>
  <app-sitemap [isMobileView]="data.isMobile"></app-sitemap>
  <app-footer></app-footer>
</ng-container>
