<ng-container *ngIf="data | async as data">
  <app-header [showOnlyLogo]="true"></app-header>
  <!-- hero banner start -->
  <div
    class="hero-image d-flex align-items-center"
    [ngStyle]="{
      'background-image':
        'url(' +
        (!data.isMobile
          ? data.heroBanner.image.src
          : data.heroBanner.image.mobileSrc) +
        ')',
    }"
  >
    <div class="container mt-5">
      <div class="row mb-4">
        <div [class]="data.isMobile ? 'col-9 ps-4' : 'col-6'">
          <h1 class="text-white">{{ data.heroBanner.heading }}</h1>
        </div>
      </div>
      <div *ngIf="!data.isMobile" class="row">
        <ng-container *ngIf="!data.isMobile; then getPrequalAndDisclaimer" />
      </div>
    </div>
  </div>
  <div
    *ngIf="data.isMobile"
    class="mobile-container d-flex flex-column align-items-center justify-content-center my-5"
  >
    <ng-container *ngIf="data.isMobile; then getPrequalAndDisclaimer" />
  </div>
  <ng-template #getPrequalAndDisclaimer>
    <div
      class="d-flex"
      [class]="
        data.isMobile
          ? 'mb-5 justify-content-center'
          : 'col-4 align-items-center justify-content-left'
      "
    >
      <ng-container *ngTemplateOutlet="getPrequalifiedButton" />
    </div>
    <div [class]="data.isMobile ? 'd-flex justify-content-center' : 'col-8'">
      <p [class]="data.isMobile ? 'text-center' : 'text-white'">
        {{ data.heroBanner.disclaimer }}
      </p>
    </div>
  </ng-template>
  <!-- hero banner end -->

  <!-- prequal steps start  -->
  <div
    [class]="data.isMobile ? 'flex-column' : 'flex-row'"
    class="d-flex flex-fill"
  >
    <div
      *ngFor="let step of data.prequalSteps"
      class="prequal-steps-container d-flex flex-column align-items-center justify-content-start pb-4"
      [class.px-5]="!data.isMobile"
      [ngClass]="{
        'indigo-background': step.backgroundColor === 'indigo',
        'orange-background': step.backgroundColor === 'orange',
        'yellow-background': step.backgroundColor === 'yellow',
      }"
    >
      <img
        [src]="step.iconSrc"
        alt="step number icon"
        [class]="!data.isMobile ? 'icon-desktop mb-5' : 'my-4'"
      />
      <h5
        [class]="step.whiteText ? 'text-white' : 'text-black'"
        class="mb-4 text-center fw-bold"
        *ngIf="!step.instruction.includes('no impact')"
      >
        {{ step.instruction }}
      </h5>
      <p [class.text-white]="step.whiteText" class="text-center">
        {{ step.description }}
      </p>
    </div>
  </div>
  <!-- prequal steps end  -->

  <!-- main body start -->
  <div
    class="background-image"
    [ngStyle]="{
      'background-image':
        'url(' +
        (data.isMobile
          ? data.bodyBackgroundImg.mobileSrc
          : data.bodyBackgroundImg.src) +
        ')',
    }"
  >
    <div class="main-body" [class.container]="!data.isMobile">
      <!-- body top section start -->
      <div class="row autoFinanceForEveryone components-padding">
        <div class="col-12">
          <h2>{{ data.autoFinanceForEveryone.heading }}</h2>
          <p class="mb-4">{{ data.autoFinanceForEveryone.description }}</p>
        </div>
        <div class="row">
          <ng-container
            *ngIf="
              data.isMobile;
              then bodyTopMobileView;
              else bodyTopDesktopView
            "
          />
          <ng-template #bodyTopDesktopView>
            <div
              *ngFor="
                let columnOptions of data.autoFinanceForEveryone.options;
                let last = last
              "
              class="col-6"
            >
              <ng-container
                *ngTemplateOutlet="
                  bodyTopList;
                  context: { options: columnOptions, last }
                "
              />
            </div>
          </ng-template>
          <ng-template #bodyTopMobileView>
            <div class="d-flex flex-column align-items-start">
              <ng-container
                *ngTemplateOutlet="
                  bodyTopList;
                  context: {
                    options: data.autoFinanceForEveryone.options.flat(),
                  }
                "
              />
            </div>
          </ng-template>
          <ng-template #bodyTopList let-options="options" let-last="last">
            <ul class="list-unstyled" [class.ps-5]="last">
              <li
                *ngFor="let option of options; let last = last"
                [class.border-bottom-dark]="!last"
                class="pb-3 mb-3 d-flex align-items-center"
              >
                <img
                  [src]="data.autoFinanceForEveryone.checkboxSrc"
                  alt="checkbox"
                  class="me-3"
                />
                <span class="mb-0">{{ option }}</span>
              </li>
            </ul>
          </ng-template>
        </div>
      </div>
      <!-- body top section end -->

      <!-- 4 million financed start -->
      <div class="row four-million-financed components-padding">
        <div
          *ngIf="!data.isMobile"
          class="col-6 d-flex justify-content-center align-items-center pe-5"
        >
          <img
            [src]="data.fourMillionFinanced.image.src"
            [alt]="data.fourMillionFinanced.image.alt"
            class="img-fluid"
          />
        </div>
        <div [class]="!data.isMobile ? 'col-6 ps-5' : 'col-12'">
          <h2 class="mb-5">{{ data.fourMillionFinanced.heading }}</h2>
          <ng-container
            *ngTemplateOutlet="
              unstyledList;
              context: { listItems: data.fourMillionFinanced.reasons }
            "
          ></ng-container>
        </div>
      </div>
      <!-- 4 million financed end -->

      <!-- what do I need start -->
      <div class="row what-do-I-need components-padding">
        <div [class]="data.isMobile ? 'col-12 mb-5' : 'col-6 pe-5'">
          <ng-container
            *ngTemplateOutlet="
              getPrequalifiedButton;
              context: { class: 'mb-5' }
            "
          />
          <h2>{{ data.whatDoINeed.heading }}</h2>
        </div>
        <div [class]="!data.isMobile ? 'col-6 ps-5' : 'col-12'">
          <ng-container
            *ngTemplateOutlet="
              unstyledList;
              context: { listItems: data.whatDoINeed.needs }
            "
          ></ng-container>
        </div>
      </div>
      <!-- what do I need end -->

      <!-- customer testimonies start -->
      <div class="components-margin">
        <cac-testimonial [data]="data.testimonials"></cac-testimonial>
      </div>
      <!-- customer testimonies end -->
    </div>

    <!-- main body templates -->
    <ng-template #unstyledList let-listItems="listItems">
      <ul class="list-unstyled">
        <li
          *ngFor="let item of listItems; let last = last"
          class="pb-5 mb-5 d-flex align-items-center"
          [class.border-bottom-dark]="!last"
        >
          <img [src]="item.iconSrc" alt="icon image" class="me-3" />
          <span class="mb-0">{{ item.text }}</span>
        </li>
      </ul>
    </ng-template>
  </div>
  <!-- main body end -->

  <!-- see your prequalification start -->
  <div
    class="see-your-prequalification d-flex flex-column align-items-center py-5"
    [ngStyle]="{
      'background-image':
        'url(' +
        (!data.isMobile
          ? data.seeYourPrequal.image.src
          : data.seeYourPrequal.image.mobileSrc) +
        ')',
    }"
  >
    <h3
      class="text-center pb-3"
      [ngStyle]="{ 'max-width': data.isMobile ? '66%' : '33%' }"
    >
      {{ data.seeYourPrequal.heading }}
    </h3>
    <ng-container *ngTemplateOutlet="getPrequalifiedButton" />
  </div>
  <!-- see your prequalification end -->

  <!-- FAQ section start -->
  <div class="faqs components-padding pb-5">
    <div [class]="data.isMobile ? 'mobile-container' : 'container'">
      <cac-faqs [data]="data.pageFaqs" [isFaqQuestionBold]="true"></cac-faqs>
    </div>
  </div>
  <!-- FAQ section end -->

  <!-- Sitemap and Footer Section -->
  <div class="row">
    <app-sitemap [isMobileView]="data.isMobile"></app-sitemap>
    <app-footer></app-footer>
  </div>

  <!-- universal templates -->
  <ng-template #getPrequalifiedButton let-class="class">
    <a
      appAnchorClick
      [linkInfo]="{ link: data.getPrequalifiedButton.href }"
      [href]="data.getPrequalifiedButton.href"
      [class]="'btn btn-primary' + (class ? ' ' + class : '')"
      [attr.aria-label]="data.getPrequalifiedButton.text"
      >{{ data.getPrequalifiedButton.text }}</a
    >
  </ng-template>
</ng-container>
