import { Component, OnInit } from '@angular/core';
import { dealerHomeNumberSpeak } from 'app/data/grow-your-dealership/dealersNumSpeak';
import { hearFromDealers } from 'app/data/grow-your-dealership/hear-from-dealers';
import { Approval } from 'app/data/grow-your-dealership/how-make-things-possible/approval';
import { makeThingsPossible } from 'app/data/grow-your-dealership/how-make-things-possible/tabInfo';
import { DealOptimizer } from 'app/data/grow-your-dealership/ways-for-business-grow/deal-optimizer';
import { waysBusinessGrow } from 'app/data/grow-your-dealership/ways-for-business-grow/tabInfo';
import { PromoIntroduction } from 'app/data/promo/introduction';
import { promoBannerDetails } from 'app/data/promo/banner-image';
import { bannerImage } from 'app/models/bannerImage';
import { cardDetails } from 'app/models/cardDetails';
import { IntroDescription } from 'app/models/credit_accp_Intro_desc';
import { creditLinks } from 'app/models/credit_links';
import { numbersSpeak } from 'app/models/numbersSpeak';
import { TabToggleInfo } from 'app/models/toggle_tabs';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { promoCardImgDetail } from 'app/data/promo/card-text-img-overlay';
import { PortFolioPurchseDetails } from 'app/data/portfolio-purchase-details';
import { DealerPoolDisclaimer, eligibleDealerDisclaimer, remoteDealerDisclaimer } from 'app/data/footer/footer_disclaimer';

@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.scss']
})
export class PromoComponent implements OnInit {
  subscription: Subscription[] = [];
  mobileView: boolean = false;
  dealerNumSpeak: numbersSpeak =  dealerHomeNumberSpeak;
  waysBusinessGrow: TabToggleInfo = waysBusinessGrow;
  makeThingsPossible: TabToggleInfo = makeThingsPossible;
  introduction: IntroDescription = PromoIntroduction;
  waysGrowContent: cardDetails = DealOptimizer;
  makeThingContent: cardDetails = Approval;
  cardImgDetail: cardDetails[] = promoCardImgDetail;
  hearFromDealers: cardDetails[] = hearFromDealers;
  portFoiloPurchaseDetails:cardDetails[] = PortFolioPurchseDetails;
  hearInfo: creditLinks= {
    id: "hear_from_more_dealers",
    title: "Hear from more dealers >",
    link: "/dealers/reviews",
    ariaLabel: "Hear From More Dealers"
  };
  capsLoginGetStarted: creditLinks= {
    id: "login_to_caps_work_the_deal_btn",
    title: "Login to CAPS to get started >",
    link: "/dealers/caps-redirect",
    ariaLabel: "Login to CAPS to get started"
  };
  hearInfoSectionTitle: string = 'See why dealers like working with us.';
  heroImageInfo:bannerImage = promoBannerDetails;
  dealerPoolDisc: any = DealerPoolDisclaimer;
  eligibleDisc: any = eligibleDealerDisclaimer;
  remoteDisc: any = remoteDealerDisclaimer;
  
  constructor(private responsive: BreakpointObserver) {
    //Add no robots meta tag
    let meta = document.createElement('meta');
    meta.name = "robots";
    meta.content = "noindex";
    document.getElementsByTagName('head')[0].appendChild(meta);
   }
  ngOnInit(): void {
    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.mobileView = result.matches ? true : false;
        })
    );
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }
}
