<app-header></app-header>
<div [ngClass]= "{'mob-height': isMobileViewDisplay}" class="sub-components-margin">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [bottonBgColor]="bottonBgColor"
        [overLineBgColor]="overLineBgColor" [isCurvedImage]="isCurvedImage"></cac-hero-image>
</div>
<div [ngClass]="isMobileViewDisplay ? 'mobile-container' : ''">
    <div class='components-margin'>
        <cac-content-block [data]="contentBlock" [leftToRight]="true" [isDefaultColor]="true"></cac-content-block>
    </div>
</div>
<div class='components-margin' id='videoReviews'>
    <video-block *ngIf='videoBlock' [data]="videoBlock"></video-block>
</div>
<div [ngClass]="isMobileViewDisplay ? 'mobile-container' : 'container'">
    <div class='components-margin'>
        <cac-testimonial [data]="testimonials"></cac-testimonial>
    </div>
</div>
<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>