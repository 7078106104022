import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AWSService {

  hostname = '';
  awsURL = 'https://wwwbucket.static.creditacceptance.com';
  guestPaymentURL = 'https://consumer-payments-fe-qa.creditacceptance.com/'; // QA As default
  jonEnvironment: string = '';

  // TODO: we probably should be using the HttpClient here instead of fetch for better native support
  //  of observables but it appears that there is an issue with retrieving content from S3 due to a CORS
  //  error when using the HttpClient as result of the HttpClient sending an OPTIONS request so we need
  //  to look into adjusting our S3 bucket config to handle this before we can start using the HttpClient.
  constructor(private http: HttpClient, @Inject(PLATFORM_ID) platformId: Object) {

    if(isPlatformBrowser(platformId)){

        this.hostname = window.location.hostname;

        if( this.hostname.includes('localhost') || this.hostname.includes('cac-dev-portal-code-www-use2.s3-website.us-east-2.amazonaws.com')){
            this.awsURL = 'https://cac-dev-portal-code-www-use2.s3.us-east-2.amazonaws.com';
            this.guestPaymentURL = 'https://consumer-payments-fe.dev-k8s.cac.cloud/';
            this.jonEnvironment= 'lab';
        }else if( this.hostname.includes('test')){
            this.awsURL = 'https://wwwbucket.teststatic.creditacceptance.com';
            this.guestPaymentURL = 'https://consumer-payments-fe.test-k8s.cac.cloud/';
            this.jonEnvironment= 'test';
        }else if( this.hostname.includes('qa')){
            this.awsURL = 'https://wwwbucket.qastatic.creditacceptance.com';
            this.guestPaymentURL = 'https://consumer-payments-fe-qa.creditacceptance.com/';
            this.jonEnvironment= 'qa';
        }else if( this.hostname.includes('dr')){
            this.awsURL = 'https://wwwbucketdr.static.creditacceptance.com';
            this.guestPaymentURL = 'http://consumer-payments-fe.ue1.qa-k8s.cac.cloud/';
            this.jonEnvironment= 'dr';
        }else {
            this.awsURL = 'https://wwwbucket.static.creditacceptance.com';
            this.guestPaymentURL = 'https://consumer-payments-fe.creditacceptance.com/';
            this.jonEnvironment= 'prod';
        }
    }
  }

  getGuestPaymentURL(){
    return this.guestPaymentURL;
  }

  getAWSBucketURL(){
    return this.awsURL;
  }

  fetchAWSData(url: string) : Observable<any> {
    return this.http.get(this.getAWSBucketURL+url);
  }


  getAWSData(url: string) {
    return fetch(this.awsURL+url)
      .then(response => response.json())
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }


  getAWSJsonData<t extends any>(url: string): Observable<t> {
    return from(this.getAWSData(url).then((json) =>
        JSON.parse(
          JSON.stringify(json).replace(
            /AWS_BUCKET_URL/g,
            this.getAWSBucketURL(),
          ),
        ),
      )
    )
  }

  postAWSData(url: string, data: any) {
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  putAWSData(url: string, data: any) {
    return fetch(url, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  deleteAWSData(url: string) {
    return fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  fetchGraphQLData(url: string, query: any, variables: any) {
    return fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query,
        variables,
      }),
    });
  }

  getJONEnv() {
    return this.jonEnvironment;
  }
}
