import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DEALER_HOW_IT_WORKS } from 'app/constants/aws.constants';
import { AWSService } from 'app/services/aws.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'dealer-how-it-works',
  templateUrl: './dealer-how-it-works.component.html',
  styleUrls: ['./dealer-how-it-works.component.scss']
})
export class DealerHowItWorksComponent implements OnInit {
  subscription: Subscription[] = [];

  url = DEALER_HOW_IT_WORKS;
  dealerHowItWorksData : any = {};
  heroImageInfo : any ;
  anchorDeviders : any;
  stepsData: any;
  contentBlock : any ;
  contentBlockTwo : any ;
  contentBlockThree : any ;
  contentBlockFour : any ;
  videoData : any;
  articleData : any;
  footerDisclaimer : any;

  isMobileViewDisplay : boolean = false;
  isBrowser : boolean = false;
  overLineBgColor: string = "#0F7D9C";
  bottonBgColor: string = "#FFB800";
  headerColor: string = "#171714";
  disclaimerColor: string = '#171714';
  isCurvedImage = true;
  dealerReviewsButton : any = {};

  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {

    if(this.isBrowser){
      
      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);
        this.dealerHowItWorksData = json;
        
        this.heroImageInfo = this.dealerHowItWorksData.CacHeroImageComponent;
        this.anchorDeviders = this.dealerHowItWorksData.CacJumpLinkComponent;
        this.stepsData = this.dealerHowItWorksData.CacStepsComponent;
        this.contentBlock = this.dealerHowItWorksData.CacContentBlockComponent;
        this.contentBlockTwo = this.dealerHowItWorksData.CacContentBlockTwoComponent;
        this.contentBlockThree = this.dealerHowItWorksData.CacContentBlockThreeComponent;
        this.contentBlockFour = this.dealerHowItWorksData.CacContentBlockFourComponent;
        this.videoData = this.dealerHowItWorksData.CacVideoComponent;
        this.articleData = this.dealerHowItWorksData.CacArticleComponent;
        this.footerDisclaimer = this.dealerHowItWorksData.CacFooterDisclaimer;
        this.dealerReviewsButton = this.dealerHowItWorksData.DealerReviewsButton;
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }



}
