import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { PAGE_NOT_FOUND_JSON_URL } from 'app/constants/aws.constants';
import { AWSService } from 'app/services/aws.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {

  subscription: Subscription[] = [];

  url = PAGE_NOT_FOUND_JSON_URL;

  PageNotFoundJSONData:any;

  heroImageInfo: any = {};
  bottonBgColor: string = "#ffb800";
  overLineBgColor: string = "#2B4361";
  overLineTextColor: string = "#FFFFFF";
  headerColor: string = "#FFFFFF";
  disclaimerColor: string = "#2B4361";
  isCurvedImage = false;
  isBrowser: boolean = false;
  isMobileViewDisplay: boolean = false;
  quickLinksId404: string = "quickLinksId404";
  quickLinks404: any = [];
  cardData : any ;

  constructor(private http: HttpClient, private responsive: BreakpointObserver,private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);

    //Add no robots meta tag
    let meta = document.createElement('meta');
    meta.name = "robots";
    meta.content = "noindex";
    document.getElementsByTagName('head')[0].appendChild(meta);

    //Add title and description
    let title = document.createElement('title');
    title.innerText = "Page Not Found | Credit Acceptance";
    document.getElementsByTagName('head')[0].appendChild(title);

    let description = document.createElement('meta');
    description.name = "description";
    description.content = "The page you are looking for does not exist.";
    document.getElementsByTagName('head')[0].appendChild(description);
  }

  ngOnInit(): void {
   
    if(this.isBrowser){

      this.awsService.getAWSData(this.url).then(json => {

        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);

        this.PageNotFoundJSONData = json;
        this.heroImageInfo = this.PageNotFoundJSONData.CacHeroImageComponent;
        this.quickLinks404 = this.PageNotFoundJSONData.quickLinksCustomerComponent;
        this.cardData = this.PageNotFoundJSONData.CacAcceptanceCardComponent;

      });
    }
    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }
}
