<app-header></app-header>
<div class="container components-margin">
    <cac-header [data]='headerData'></cac-header>
</div>
<cac-credit-introduction [information]="introData"></cac-credit-introduction>
<div class="mb-30">
    <div [ngClass]= "{'container content-section': !isMobileViewDisplay}">
        <div *ngIf="videoData" class="solid-line">
            <cac-iframe [sourceUrl]='videoData?.videoURL' height='auto' width='100%' ariaLabel="{{videoData?.videoTitle}}"></cac-iframe>
        </div>
    </div>
</div>
<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>