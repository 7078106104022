<app-header [showOnlyLogo]="true"></app-header>
<div [ngClass]= "{'mob-height': mobileView}" class="sub-components-margin">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [bottonBgColor]="bottonBgColor"
        [overLineBgColor]="overLineBgColor" [overLineTextColor]="overLineTextColor" [isCurvedImage]="isCurvedImage"></cac-hero-image>
</div>
<cac-credit-introduction [information]="introData"></cac-credit-introduction>
<div class="container sub-components-margin" *ngIf="tabbedContentData">
    <cac-header [data]="tabbedContentData.header"></cac-header>
    <div class="py-5"><cac-tabbed-content [data]="tabbedContentData" ></cac-tabbed-content></div>
</div>
<div class="container" *ngIf="twoTabbedContentData">
    <cac-header [data]="twoTabbedContentData.header"></cac-header>
    <div class="py-5"><cac-tabbed-content [data]="twoTabbedContentData" ></cac-tabbed-content></div>
</div>
<div *ngIf="testimonialData" [ngClass]="!mobileView ? 'container' : 'mobile-container'" >
    <cac-testimonial [data]="testimonialData"></cac-testimonial>
</div>
<div class="components-margin">
    <cac-numbers-speak [creditNumSpeak]="numSpeakData"></cac-numbers-speak>
</div>
<div *ngIf="footerDisclaimer.disclaimers" class="container">
    <ng-container *ngFor="let disclaimer of footerDisclaimer.disclaimers; let i = index">
        <p role="note" attr.aria-label="{{disclaimer}}" class="footer-links text-decoration-none text-transform-none m-0 pt-3">
            {{disclaimer}}
          </p>
    </ng-container>
</div>
<app-footer></app-footer>