<app-header></app-header>
<div [ngClass]="mobileView ? 'sub-components-margin mob-height' : 'sub-components-margin'" >
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [bottonBgColor]="bottonBgColor"
        [overLineBgColor]="overLineBgColor" [isCurvedImage]="isCurvedImage"></cac-hero-image>
</div>
<div [ngClass]="!mobileView ? 'container' : 'mobile-container'">
    <div *ngIf='!mobileView'>
        <div class="components-margin">
            <cac-acceptance-card [data]="cardData" [displayStacked]="true"></cac-acceptance-card>
        </div>
    </div>
    <div *ngIf='mobileView'>
        <cac-acceptance-card [data]="cardData" [displayStacked]="false"></cac-acceptance-card>
    </div>
</div>
<div class='components-margin payment-box-shadow'>
</div>
<div class="container make-payment-faqs">
    <div class="row" id="payment-faqs">
        <cac-header [data]='makePaymentData?.mainHeader'></cac-header>
        <div *ngFor="let payment of makePaymentData?.paymentOption">
            <h3 class="components-margin no-fee-payment-option">{{payment.subHeader}}</h3>
            <div class="sub-components-margin body-description" [innerHTML]="payment.subHeaderDesc"></div>
            <cac-faqs [data]="payment" [isFaqQuestionBold]="false"></cac-faqs>
            <div class="container sub-components-margin faq-border"></div>
        </div>
    </div>
</div>
<div class="container components-margin">
    <cac-faqs [data]="faqData" [isFaqQuestionBold]="true"></cac-faqs>
</div>

<div class="components-margin perks-shadow">
    <cac-perks [data]="perksData" [leftToRight]="leftToRight" [displayStoreImg]="displayStoreImg"></cac-perks>
</div>

<app-sitemap [isMobileView]="mobileView"></app-sitemap>
<app-footer></app-footer>