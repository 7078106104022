<app-header></app-header>
<div [ngClass]="!mobileView ? 'container' : 'mobile-container'">
    <div [ngClass]="!mobileView ? 'components-margin' : 'mobile-components-margin'" >
        <div class='row' [ngClass]="!mobileView ? 'container' : 'mobile-container'">
            <div id="payment-calculator" class="col-md-6 col-12" >
                <div class='row g-0' [ngClass]="{ 'title-padding' : mobileView}">
                    <h3 class='title'>
                       {{calculatorTitle}} 
                    </h3>
                    <div class="col-12 mb-3 required-fields-label">
                        *Required fields
                    </div>
                    <div class="col-11 mb-3 description">
                        {{calculatorSubTitle}}
                    </div>
                </div>

                <form [formGroup]="calculatorForm" id='monthlyCalculator'>
                    <div class='row g-0 w-100 d-flex'>
                        <div class='text-number-width'>
                            <div class=" number-circle">1</div>
                        </div>
                        <div class="calc-label select-input-width">
                            Choose your type of vehicle
                            <span class='required-fields'>*</span>
                        </div>
                        <div class=" cac-custom-select-box ">
                            <select id="typeOfAuto" formControlName="typeOfAuto" class="cac-select-box"
                                aria-labelledby="typeOfAuto" name="typeOfAuto" (change)="updateAdobeAnalytics($event)">
                                <option value="New">New</option>
                                <option value="Used" selected>Used</option>
                            </select>
                        </div>
                    </div>
                    <div id="autoPrice-div" class='row g-0 w-100 d-flex'>
                        <div class='text-number-width'>
                            <div class=" number-circle">2</div>
                        </div>
                        <div class=' calc-label' [ngClass]='monthly?"calc-text-label":"calc-text-label-auto"'>
                            <div class="form-group"
                                [class.validation-error]="f.autoPrice.touched && f.autoPrice.errors">
                                {{calculatorTxt}}<span class='required-fields'>*</span>
                            </div>
                        </div>
                        <div class='amount' [ngClass]='monthly?"text-input-width":"text-input-width-auto"' >
                            <input id="autoPrice" name="autoPrice" required type="text" formControlName="autoPrice"
                                maxLength="6" class="form-control autoPrice "
                                [class.field-error]="f.autoPrice.touched && f.autoPrice.errors" pattern="^[0-9,.]*$"
                                [value]="f.autoPrice.value.toString().replace(',','') | number"
                                attr.aria-label="{{calculatorTxt}}" (focusout)="onFocusOutAutoPrice($event);updateAdobeAnalytics($event)" #priceRef (click)="selectText(priceRef)"/>

                            <div *ngIf="f.autoPrice.touched && f.autoPrice.errors && f.autoPrice.errors.required"
                                role="alert" aria-atomic="true" class="validation-error">
                                {{this.validationErrorText}}
                            </div>
                            <div *ngIf="f.autoPrice.touched && f.autoPrice.errors && f.autoPrice.errors.pattern"
                                role="alert" aria-atomic="true" class="validation-error">
                                {{this.invalidValueErrorText}}
                            </div>
                        </div>
                    </div>
                    <div id="downPayment-div" class="row g-0">
                        <div class='text-number-width'>
                            <div class=" number-circle">3</div>
                        </div>
                        <div class="calc-label down-payment-label" [ngClass]='monthly?"calc-text-label":"calc-text-label-auto"'
                            [class.validation-error]="f.downPayment.touched && f.downPayment.errors">
                            Down payment<span class='required-fields'>*</span> <br>
                            (include cash and trade-in value)                         
                        </div>
                        <div class='amount' [ngClass]='monthly?"text-input-width":"text-input-width-auto"'>
                            <input id="downPayment" name="downPayment" required type="text" maxLength="6"
                                formControlName="downPayment" class="form-control downPayment"
                                [class.field-error]="f.downPayment.touched && f.downPayment.errors"
                                aria-label="Down payment" pattern="^[0-9,.]*$"
                                [value]="f.downPayment.value.toString().replace(',','') | number"
                                (focusout)="onFocusOutDownPayment($event);updateAdobeAnalytics($event)" #paymentRef (click)="selectText(paymentRef)"/>

                            <div *ngIf="f.downPayment.touched && f.downPayment.errors && f.downPayment.errors.required"
                                role="alert" aria-atomic="true" class="validation-error">
                                Please fill in down payment amount
                            </div>
                            <div *ngIf="f.downPayment.touched && f.downPayment.errors && f.downPayment.errors.pattern"
                                role="alert" aria-atomic="true" class="validation-error">
                                Please enter valid down payment.
                            </div>
                        </div>
                    </div>
                    <div class='row g-0 w-100 d-flex'>
                        <div class='text-number-width'>
                            <div class=" number-circle">4</div>
                        </div>
                        <div class="calc-label select-input-width">
                            State<span class='required-fields'>*</span>
                        </div>
                        <div class=" cac-custom-select-box">
                            <select formControlName="state" id="state" class="cac-select-box" aria-labelledby="state"
                                required (change)="updateAdobeAnalytics($event)">
                                <option *ngFor="let usState of usStates" [value]="usState.value">
                                    {{usState.label}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class='row g-0 w-100 d-flex' id="creditScore-div">
                        <div class='text-number-width'>
                            <div class=" number-circle">5</div>
                        </div>
                        <div class="calc-label select-input-width">
                            Credit score<span class='required-fields'>*</span>(used to estimate interest rate)
                        </div>
                        <div class="cac-custom-select-box">
                            <select formControlName="creditScore" id="creditScore" class="cac-select-box"
                                aria-labelledby="creditScore" (change)="onFocusOutCreditScore($event);updateAdobeAnalytics($event)" required>
                                <option *ngFor="let creditScore of creditScores" [value]="creditScore.range">
                                    {{creditScore.label}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div id="interestRate-div" class="row g-0">
                        <div class='text-number-width'>
                            <div class=" number-circle">6</div>
                        </div>
                        <div class="calc-label" [ngClass]='monthly?"calc-text-label":"calc-text-label-auto"'>
                            <div [class.validation-error]="f.interestRate.touched && f.interestRate.errors">
                                Interest rate<span class='required-fields'>*</span>
                            </div>
                        </div>
                        <div [ngClass]='monthly?"text-input-width":"text-input-width-auto"'>
                            <input id="interestRate" name="interestRate" type="text" maxLength="6"
                                formControlName="interestRate" class="form-control interestRate"
                                [class.field-error]="f.interestRate.touched && f.interestRate.errors"
                                (change)="transformCredit($event)" aria-labelledby="interestRate"
                                pattern="^[.]?[0-9][\.\d]*(,\d+)?[%]?$" [value]="f.interestRate.value"
                                aria-label="Interest rate" #interestRef (focusout)="updateAdobeAnalytics($event)" (click)="selectText(interestRef)" />

                            <div *ngIf="f.interestRate.touched && f.interestRate.errors && f.interestRate.errors.required"
                                role="alert" aria-atomic="true" class="validation-error">
                                Please fill in interest rate.
                            </div>
                            <div *ngIf="f.interestRate.touched && f.interestRate.errors && f.interestRate.errors.pattern"
                                role="alert" aria-atomic="true" class="validation-error">
                                Please enter valid interest rate.
                            </div>
                        </div>
                    </div>
                    <div id="lengthOfLoan-div" class="row g-0 w-100 d-flex">
                        <div class='text-number-width'>
                            <div class=" number-circle">7</div>
                        </div>
                        <div class="calc-label select-input-width">
                            <div class="form-group">
                                Length of loan<span class='required-fields'>*</span>(in months)
                            </div>
                        </div>
                        <div class="cac-custom-select-box">
                            <select formControlName="lengthOfLoan" id="lengthOfLoan" class="cac-select-box"
                                aria-labelledby="lengthOfLoan" required (change)="updateAdobeAnalytics($event)">
                                <option *ngFor="let loanLength of loanLengths" [value]="loanLength.value">
                                    {{loanLength.label}}
                                </option>
                            </select>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf='mobileView' class='mobile-scroll'>
                <a role="link" class="btn btn-primary"
                aria-label="SEE RESULTS" (click)='onScrollClick()'>SEE RESULTS</a>
            </div>
            <div *ngIf='mobileView' class='mobile-content'> </div>
            <div id="calc-summary" class="col-md-5 col-12 card">
                <img src="assets/images/calculator/result-bg.webp" class="card-img calculator-img summary-padding"
                    alt="Estimated Monthly Payment">
                <div class='payment-summary'>
                    <div class="payment-summary-text">{{paymentLabel}}</div>
                    <div class="payment-amount" [ngClass]="calculatedPrice>9999 ? 'pymnt-lrg-amt' : ''">
                        ${{calculatedPrice | number:'1.0'}}
                    </div>
                </div>
                <div class='disclaimer'>
                    <span>*This tool assists with estimating a monthly payment or selling price of an auto that fits
                        your budget. Information provided for illustrative purposes only and is not an application
                        for credit. Amounts are estimates and do not include taxes, title or registration fees, or
                        other dealer-imposed fees. Financing terms are not guaranteed as they will be based on your
                        individual circumstances at the time of application.</span>
                </div>
                <div class='calculator-links'>
                    <span>
                        <a type="button" role="link" href="/get-prequalified#/" class="btn btn-primary"
                            aria-label="GET PREQUALIFIED" tabindex="0">GET
                            PREQUALIFIED</a>
                    </span>
                    <!-- <span class='find-a-car-button'>
                        <a type="button" role="link" href="#" class="btn cac-card-button-secondary"
                            aria-label="FIND A CAR" tabindex="1">FIND A CAR</a>
                    </span> -->
                </div>
            </div>
           
            <div *ngIf='mobileView' class='mobile-content'> </div>
        </div>
    </div>
</div>

<app-sitemap [isMobileView]="mobileView"></app-sitemap>
<app-footer></app-footer>