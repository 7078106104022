import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { LONZELL_WEBB_JSON_URL } from 'app/constants/aws.constants';
import {AWSService} from 'app/services/aws.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-lonzell-webb',
  templateUrl: './lonzell-webb.html',
  styleUrls: ['./lonzell-webb.scss']
})
export class LonzellWebbComponent implements OnInit {

  subscription: Subscription[] = [];

  url = LONZELL_WEBB_JSON_URL;

  LonzellJSONData:any;
  headerData:any;
  isBrowser : boolean = false;
  isMobileViewDisplay : boolean = false;
  introData : any;
  videoData : any;
  
  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if(this.isBrowser){

      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);

        this.LonzellJSONData = json;
        this.headerData = this.LonzellJSONData.CacVideoReview.header;
        this.introData = this.LonzellJSONData.CacVideoReview.CacCreditIntroductionReview;
        this.videoData = this.LonzellJSONData.CacVideoComponent;

      });
    }
    
    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }

}
