import { Component, Input, OnInit } from '@angular/core';
import { numbersSpeak } from 'app/models/numbersSpeak';

@Component({
  selector: 'app-numbers-speak',
  templateUrl: './numbers-speak.component.html',
  styleUrls: ['./numbers-speak.component.scss']
})
export class NumbersSpeakComponent implements OnInit {
  @Input() isMobileView: boolean = false;
  @Input() creditNumSpeak: numbersSpeak = new numbersSpeak;
  constructor() { }

  ngOnInit(): void {}

}
