import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { SEO_JSON_URL } from 'app/constants/aws.constants';
import { AWSService } from './aws.service';
import { filter, map } from 'rxjs';

let routerPath = '';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  seoData: any = {};
  seoPageData: { title:string,description:string } = { title:"",description:"" };
  isBrowser: boolean = false;

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private awsService: AWSService,
    @Inject(PLATFORM_ID) platformId: Object,
    private router: Router,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        routerPath = window.location.pathname;
        this.seoPageData = this.seoData[routerPath];
        return this.seoPageData;
      })
    ).subscribe((seoPageDate: { title:string,description:string}) => {
      if (seoPageDate) {
        this.titleService.setTitle(seoPageDate?.title);
        this.metaService.updateTag({ name: 'description', content: seoPageDate?.description });
        this.metaService.updateTag({ name: 'canonical', content: window.location.href.replace(window.location.origin, 'https://www.creditacceptance.com') });
        this.metaService.updateTag({ property: 'og:title', content: seoPageDate?.title });
        this.metaService.updateTag({ property: 'og:description', content: seoPageDate?.description });
      }
    });
  }

  initializeSEOData() {
    if (this.isBrowser) {
      this.awsService.getAWSData(SEO_JSON_URL).then((json) => {
        this.seoData = json;
        this.setInitialPageSEOData();
      });
    }
  }

  setInitialPageSEOData() {
    routerPath = window.location.pathname;
    this.seoPageData = this.seoData[routerPath];

    this.metaService.addTag({
      content: 'text/html; charset=UTF-8',
      httpEquiv: 'content-type',
    });
    this.metaService.addTag({
      name: 'facebook-domain-verification',
      content: 'mvxyneyuqg2hec8wzlwa6aluhfbrya',
    });

    if (this.seoPageData) {
      this.metaService.addTag({
        content: this.seoPageData.description,
        name: 'description',
      });
      this.titleService.setTitle(this.seoPageData.title);
      this.metaService.addTag({
        name: 'canonical',
        content: window.location.href.replace(
          window.location.origin,
          'https://www.creditacceptance.com'
        ),
      });

      this.addOpenGraphMetaTags();
    }
  }

  setDynamicPageSEOData(title: string, description: string, url?: string) {
    this.titleService.setTitle(title);
    

    this.updateOrAddTag('name="description"', { name: 'description', content: description });
    this.updateOrAddTag('name="canonical"', {
      name: 'canonical',
      content: url
        ? `https://www.creditacceptance.com/${url}`
        : 'https://www.creditacceptance.com',
    });
    this.updateOrAddTag('property="og:title"', { property: 'og:title', content: title });
    this.updateOrAddTag('property="og:description"', {
      property: 'og:description',
      content: description
    });
  }

  setNoRobotsSEODataTag() {
    this.updateOrAddTag('name="robots"', { name: 'robots', content: 'noindex, nofollow' }); // Added so google crawlers don't crawl the page

  }


  private addOpenGraphMetaTags() {
    this.metaService.addTags([
      { property: 'og:title', content: this.seoPageData.title },
      { property: 'og:description', content: this.seoPageData.description },
    ]);
  }

  private updateOrAddTag(attrSelector: string, tag: MetaDefinition) {
    if (this.metaService.getTag(attrSelector)) {
      this.metaService.updateTag(tag);
    } else {
      this.metaService.addTag(tag);
    }
  }
}
