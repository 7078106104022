import { Component, Input, OnInit } from '@angular/core';
import { bannerImage } from 'app/models/bannerImage';

@Component({
  selector: 'app-hero-image',
  templateUrl: './hero-image.component.html',
  styleUrls: ['./hero-image.component.scss']
})
export class HeroImageComponent implements OnInit {

  @Input() isMobileView: boolean = false;
  @Input() heroImageInfo: bannerImage = new bannerImage;

  constructor() { }

  ngOnInit(): void {
  }

}
