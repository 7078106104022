<app-header></app-header>
<span>
  <div
    class="container"
    [ngClass]="isMobileViewDisplay ? 'container-shadow' : 'mb-60'"
  >
    <div class="row bad-form">
      <div class="col-lg-7 col-12">
        <ng-container *ngIf="!dataAvailable; else showWidget">
          <div class="loader-container">
            <div class="skeleton-first-row">
              <div class="skeleton-background">
                <div class="background-masker btn-divide-left"></div>
              </div>
            </div>
            <div class="skeleton-second-row">
              <div class="skeleton-background">
                <div class="background-masker btn-divide-left"></div>
              </div>
            </div>
            <div class="skeleton-third-row">
              <div class="skeleton-background">
                <div class="background-masker btn-divide-left"></div>
              </div>
            </div>
          </div>
          
        </ng-container>
        <ng-template #showWidget>
          <join-our-network-form
          [environment]="environment"
          (successData)="handleSuccess($event)"
        ></join-our-network-form>
        </ng-template>
      </div>
      <!-- <div class="col-md-1"></div> -->
      <div *ngIf="!isMobileViewDisplay" class="col-md-5 col-12 my-4">
        <div>
          <img class="imgStyle" src="{{ heroImgURL }}" alt="HeroImage" />
        </div>
        <cac-key-points [data]="keypointData"></cac-key-points>
      </div>
    </div>
  </div>
  <app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
  <app-footer></app-footer>
</span>
