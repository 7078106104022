<app-header></app-header>

<div [ngClass]= "{'mob-height': isMobileViewDisplay}" class="sub-components-margin bottom-shadow">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [bottonBgColor]="bottonBgColor"
        [overLineBgColor]="overLineBgColor" [isCurvedImage]="isCurvedImage"></cac-hero-image>
</div>
<div [ngClass]="!isMobileViewDisplay ? 'container' : 'mobile-container'">
    <div id="customersDiv" [ngClass]="!isMobileViewDisplay ? 'contact-us-component' : ''">
        <cac-contacts *ngIf='customerContactData' [data]='customerContactData'> </cac-contacts>
    </div>
    <div class='components-margin'>
        <app-quick-links [isMobileView]="isMobileViewDisplay" [quickLinks]="quickLinksCustomer" [quickLinksId]="quickLinksCustomer"></app-quick-links>
    </div>
    <div class='components-margin' id="dealersDiv">
        <cac-contacts *ngIf='dealerContactData' [data]='dealerContactData'> </cac-contacts>
    </div>
    <div class='components-margin'>
        <app-quick-links [isMobileView]="isMobileViewDisplay" [quickLinks]="quickLinksDealer" [quickLinksId]="quickLinksDealer"></app-quick-links>
    </div>
    <div class='components-margin' id="generalInfoDiv">
        <cac-header [data]='title'></cac-header>
        <div class="row sub-components-margin">
            <div [ngClass]="isMobileViewDisplay ? 'col-12' : 'col-6'">
                <cac-iframe [sourceUrl]='iframeSrc' [height]='iframeHeight' [width]='iframeWidth'
                    [ariaLabel]="ariaLabel"></cac-iframe>
            </div>
            <div [ngClass]="isMobileViewDisplay ? 'col-12 pb-60 bottom-shadow' : 'col-6'">
                <cac-holiday-hours [data]="holidayData"></cac-holiday-hours>
            </div>
        </div>
    </div>
    <app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
    <app-footer></app-footer>