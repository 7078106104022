import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import {
  NavigationEnd,
  Router,
  Scroll
} from '@angular/router';
import { filter, delay } from 'rxjs/operators';
import { WebHomeAppConstants } from './constants/constants';
import { Subscription } from 'rxjs/internal/Subscription';
import { LocalService } from './services/local.service';
import { UTMModel } from './models/utmModel';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { isScullyGenerated } from '@scullyio/ng-lib';
import { SEOService } from './services/seoService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  locationPath: string = WebHomeAppConstants.EMPTY_STRING;
  subscriptions: Subscription[] = [];
  isBrowser: boolean = false;
  isServer: boolean = false;

  hostLocation = window.location.host;
  gtm = false;
  googleTagManagerId = '';
  googleAnalyticsId = '';
  noScriptTag = '';
  fullStoryId = '';

  constructor(
    private router: Router,
    private localService: LocalService,
    @Inject(PLATFORM_ID) platformId: Object,
    private readonly appSeo: SEOService,
    private viewportScroller: ViewportScroller
  ) {
    this.appSeo.initializeSEOData();
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);

    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {

        var navigationPath = event.url.replace(/^\/+/g, '');
        var sub_property = '';
        var sub_sub_property = '';

        if (navigationPath == '') {
          sub_property = 'home';
        } else {
          sub_property = navigationPath.split('/')[0];
          if (typeof navigationPath.split('/')[1] !== 'undefined') {
            sub_sub_property = navigationPath.split('/')[1];
          }
        }

        let i = ((window as any).cacAnalytics = {
          property: 'www',
          sub_property: sub_property,
          sub_sub_property: sub_sub_property,
          page_title: document.title.toLocaleLowerCase(),
          user_id: '',
          br_language: navigator.language,
          web_lang: document.documentElement.lang,
          campaign_id: '',
          internal_cmp_id: '',
          page_url: window.location.href,
          is_spa: 'true',
          event: 'cac-page-view',
          event_type: 'cac-page-view',
        });
        if (!event.url.includes('/car-buyers/dealer-finder')) {
          (window as any)?.adobeDataLayer?.push(i);
        }
       
      }
    });

    this.locationPath = window.location.pathname.replace(
      '/',
      WebHomeAppConstants.EMPTY_STRING
    );

    //UTM code

    let userSourceURL: string = '';
    if (window.location.href.toLowerCase().includes('utm_source')) {
      userSourceURL = window.location.href;
    }

    let sourceStoredInLocal: UTMModel[] = JSON.parse(
      localStorage.getItem('userSource') || '[]'
    );

    if (sourceStoredInLocal.length) {
      var diffInDays = this.localService.getDiffInDays(
        sourceStoredInLocal[0]?.time
      );
      if (diffInDays > 270) {
        sourceStoredInLocal = [];
        this.localService.setData(
          'userSource',
          JSON.stringify(sourceStoredInLocal)
        );
      }
    }

    let encodeURl = btoa(userSourceURL);

    if (userSourceURL) {
      var currentTime = new Date().toISOString();
      sourceStoredInLocal.push({
        url: encodeURl,
        time: currentTime,
        updated: true,
      });

      this.localService.setData(
        'userSource',
        JSON.stringify(sourceStoredInLocal)
      );
    }
  }

  ngOnInit(): void {
    //Below code added for Safari browser/mobile as back button does not referesh the page
    let isSafari = navigator.userAgent.includes('Safari');
    if (isSafari) {
      window.addEventListener(
        'pageshow',
        function (evt) {
          if (evt.persisted) {
            window.location.reload();
          }
        },
        false
      );
    }

    /**
     * attempt to scroll to the x,y coordinates Angular already scrolled too after a delay in case the page wasn't fully
     * loaded (image loading, http calls, etc.) when Angular automatically scrolled the page originally to correct scroll
     * location if necessary
     */
    this.router.events.pipe(
      filter(event => event instanceof Scroll && event.routerEvent instanceof NavigationEnd),
      delay(1000)
    ).subscribe((event) => {
      const { anchor, position } = event as Scroll;
      if (anchor) {
        this.viewportScroller.scrollToAnchor(anchor);
      } else {
        this.viewportScroller.scrollToPosition(position ?? [0, 0]);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngAfterViewInit() {
    if (this.hostLocation && this.hostLocation.indexOf('wwwtest') != -1) {
      this.gtm = true;
      this.googleTagManagerId = 'GTM-T3JGLB4';
      this.googleAnalyticsId = 'UA-120917412-2';
      this.fullStoryId = 'YZ5TJ'; //We do not have ID for Test for testing use QA FullStory ID
    } else if (this.hostLocation && this.hostLocation.indexOf('wwwqa') != -1) {
      this.gtm = true;
      this.googleTagManagerId = 'GTM-53N8ZWC';
      this.googleAnalyticsId = 'UA-2602405-3';
      this.fullStoryId = 'YZ5TJ';
    }
    if (this.hostLocation === 'www.creditacceptance.com') {
      this.gtm = true;
      this.googleTagManagerId = 'GTM-5ZCB74P';
      this.googleAnalyticsId = 'UA-2602405-4';
      this.fullStoryId = 'YZ5JA';
    } else {
      //Below code for testing for Local and S3 hosting
      this.gtm = true;
      this.googleTagManagerId = 'GTM-T3JGLB4';
      this.googleAnalyticsId = 'UA-120917412-2';
      this.fullStoryId = 'YZ5TJ'; //We do not have ID for Test for testing use QA FullStory ID
    }

    if (isScullyGenerated()) {
      this.loadAdobeAnalytics();
      this.loadGoogleTagManagerScript();
      this.loadFullStoryScript();
      this.loadGoogleAnalyticsScript();
      //this.loadWidget();
      this.loadGoogleCaptcha();
    }
  }

  loadGoogleCaptcha() {
    let recaptchaScript = document.createElement('script');
    recaptchaScript.type = 'text/javascript';
    recaptchaScript.async = false;

    recaptchaScript.src = 'https://www.google.com/recaptcha/api.js';
    document.head.appendChild(recaptchaScript);
  }

  loadAdobeAnalytics() {
    let adobeScriptElement = document.createElement('script');
    adobeScriptElement.type = 'text/javascript';
    adobeScriptElement.async = false;

    if (this.hostLocation === 'www.creditacceptance.com') {
      adobeScriptElement.src =
        'https://assets.adobedtm.com/ad9123205592/67641f4a9897/launch-fc986eef9273.min.js';
    } else {
      adobeScriptElement.src =
        'https://assets.adobedtm.com/ad9123205592/67641f4a9897/launch-b238893bfd09-staging.min.js';
    }
    document.head.appendChild(adobeScriptElement);
  }

  loadGoogleTagManagerScript() {
    if (this.gtm) {
      if (this.googleTagManagerId != '') {
        this.noScriptTag =
          "<noscript aria-hidden='true'><iframe src='https://www.googletagmanager.com/ns.html?id=" +
          this.googleTagManagerId +
          "' height='0' width='0' style='display:none;visibility:hidden'></iframe></noscript>";
        (function (w: any, d: any, s: any, l: any, i: any) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', this.googleTagManagerId);
      }
    }
  }

  loadGoogleAnalyticsScript() {
    if (this.googleAnalyticsId != '') {
      let googleAnalyticsScriptElement = document.createElement('script');
      googleAnalyticsScriptElement.type = 'text/javascript';
      googleAnalyticsScriptElement.id = 'googleAnalyticsScript';
      googleAnalyticsScriptElement.dataset.sennaTrack = 'permanent';
      googleAnalyticsScriptElement.innerHTML =
        "if(gtm){(function(i, s, o, g, r, a, m) {i['GoogleAnalyticsObject'] = r;i[r] = i[r] || function() {var arrayValue = i[r].q || [];i[r].q = arrayValue;(i[r].q).push(arguments);};i[r].l = 1 * new Date();a = s.createElement(o); m = s.getElementsByTagName(o)[0];a.async = 1;a.src = g;m.parentNode.insertBefore(a, m);})(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');ga('create', googleAnalyticsId, 'auto');ga('send', 'pageview');}";
      document.head.appendChild(googleAnalyticsScriptElement);
    }
  }

  loadFullStoryScript() {
    if (this.fullStoryId != '') {
      let fullStoryScriptElement = document.createElement('script');
      fullStoryScriptElement.type = 'text/javascript';
      fullStoryScriptElement.innerHTML =
        "var uuid = localStorage.getItem('uuid');if(uuid == null){uuid = crypto.randomUUID();localStorage.setItem('uuid',uuid);}" +
        "window['_fs_debug'] = false;window['_fs_host'] = 'fullstory.com';window['_fs_script'] = 'edge.fullstory.com/s/fs.js';window['_fs_org'] = fullStoryId;" +
        "window['_fs_namespace'] = 'FS';+(function(m,n,e,t,l,o,g,y){if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window[\"_fs_namespace\"].');} return;}" +
        "g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;" +
        "y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};" +
        'g.anonymize=function(){g.identify(!!0)};g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};g.log = function(a,b){g("log",[a,b])};' +
        'g.consent=function(a){g("consent",!arguments.length||a)};g.identifyAccount=function(i,v){o=\'account\';v=v||{};v.acctId=i;g(o,v)};g.clearUserCookie=function(){};' +
        "g.setVars=function(n, p){g('setVars',[n,p]);};g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};" +
        "g._v=\"1.3.0\";})(window,document,window['_fs_namespace'],'script','user');if(uuid != null) {FS.identify(uuid);}";
      document.head.appendChild(fullStoryScriptElement);
    }
  }
}
