<div *ngIf="accordionItems" class="container">
  <div class="accordion" [id]="accordionId">
    <div class="accordion-item bg-transparent border-0" *ngFor="let item of accordionItems; let i = index" tabindex="0" (keypress)="toggleAccordion(i)">
      <div *ngIf="!item?.childMenu" [class]="'row py-3 ' + borderType" [id]="'heading' + i">
        <a [ngClass]="titleClass ? titleClass : 'sitemap-links'" role="link"  role="button" appAnchorClick
          [linkInfo]="item"><span class="px-4">{{item?.title}}</span></a>
      </div>
      <div *ngIf="item?.childMenu" [class]="'row py-3 ' + borderType" [id]="'heading' + i" (click)="toggleAccordion(i)"> 
        <div class="col-auto px-0" >
          <img class="w-100"  [src]="!isExpanded[i] ? 'assets/images/common/plus_icon.svg' : 'assets/images/common/minus.svg'" [alt]="!isExpanded[i] ? 'Plus Icon' : 'Minus Icon'" height="25px" loading="lazy">
        </div>
        <div class="col-auto">
          <span [ngClass]="titleClass"> {{item.title}} </span>
        </div>
      </div>
      <div [id]="'collapse' + i" class="accordion-collapse" [ngClass]="{'collapse' : !isExpanded[i]}" data-bs-parent=`#accordionId`>
        <div class="accordion-body pb-0">
          <app-links-sections [links]="item" [titleClass]="linksClass ?  linksClass : titleClass"></app-links-sections>
        </div>
      </div>
    </div>
  </div>
</div>