import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { CAPS_MAINTENANCE_JSON_URL } from 'app/constants/aws.constants';
import { AWSService } from 'app/services/aws.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'caps-maintenance',
  templateUrl: './caps-maintenance.component.html',
  styleUrls: ['./caps-maintenance.component.scss']
})
export class CapsMaintenanceComponent {


  isMobileViewDisplay : boolean = false;
  isBrowser : boolean = false;
  subscription: Subscription[] = [];
  url = CAPS_MAINTENANCE_JSON_URL;
  content : string = '';

  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);

    let meta = document.createElement('meta');
    meta.name = "robots";
    meta.content = "noindex";
    document.getElementsByTagName('head')[0].appendChild(meta);
  }

  ngOnInit(): void {
    
    if(this.isBrowser){
      
      this.awsService.getAWSData(this.url).then(json => {
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        
        json = JSON.parse(json);
        this.content = json.content;
        
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }

}
