import { TabToggleInfo } from "app/models/toggle_tabs"
import { Approval } from "./approval"
import { FullSpectrum } from "./full-spectrum"
import { Speed } from "./speed"

export const makeThingsPossible: TabToggleInfo = {
  heading:["Así es cómo hacemos todo esto posible."],
  tabInformation: [
    {
      tabTitle: "APROBACIÓN",
      active: true,
      tabId:"make_things_approval_tab",
      tabContent: Approval
    },
    {
      tabTitle: "AMPLIO ESPECTRO",
      active: false,
      tabId:"make_things_full_spectrum_tab",
      tabContent: FullSpectrum
    },
    {
      tabTitle: "VELOCIDAD",
      active: false,
      tabId:"make_things_speed_tab",
      tabContent: Speed
    }
  ]
}
