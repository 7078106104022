<div *ngIf="quickLinks"  [ngClass]="isMobileView ? 'bb-dotted-secondary-brown': ''" id="{{quickLinksId}}" aria-label="Quick Links Section">
  <h5 class="pt-2" aria-label="Quick Links">Quick Links</h5>
  <div class="row">
    <nav>
      <ul class="gap-3 list-unstyled"
        [ngClass]="isMobileView ? 'two-columns quick-links-mobile': 'four-columns justify-content-start d-flex flex-wrap align-items-center'">
        <li [ngClass]="isMobileView ? 'col-6 mb-3': 'gap-3 col-auto'" id="{{qLinks?.id}}"
          *ngFor="let qLinks of quickLinks; let i = index"
          (touchenter)="onHoverAction(i, true)" (touchleave)="onHoverAction(i, false)"
          (mouseover)="onHoverAction(i, true)" (mouseleave)="onHoverAction(i, false)">
          <a class="d-block quick-links p-2 text-decoration-none" role="link" 
            attr.aria-label="{{qLinks?.ariaLabel}}" attr.aria-labelledby="{{qLinks?.id}}"
            appAnchorClick [linkInfo]="qLinks">
            <div class="pb-1">
              <img [id]="qLinks?.imgId" data-src="{{getIcon(i)}}" [alt]="qLinks?.title" class="quick-link-img">
            </div>
            <div>
              {{qLinks?.title}}
            </div>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
