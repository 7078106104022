import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CAR_BUYERS_RESOURCE_JSON_URL } from 'app/constants/aws.constants';
import {AWSService} from 'app/services/aws.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-car-buyes-resource',
  templateUrl: './car-buyes-resource.component.html',
  styleUrls: ['./car-buyes-resource.component.scss']
})
export class CarBuyersResourceComponent implements OnInit {

  subscription: Subscription[] = [];

  url = CAR_BUYERS_RESOURCE_JSON_URL;

  carBuyersJSONData:any;

  isBrowser : boolean = false;
  isMobileViewDisplay : boolean = false;
  heroImageInfo : any = {};
  bottonBgColor: string = "#FFB800";
  overLineBgColor: string = "#FA6400";
  overLineTextColor: string = "#171714";
  headerColor: string = "#FFFFFF";
  disclaimerColor: string = "#FFFFFF";
  isCurvedImage = false;
  introData : any;
  videoData : any;
  articleData : any;
  videoDataThree : any;
  cardData : any;
  artileDataTwo : any;
  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if(this.isBrowser){

      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);

        this.carBuyersJSONData = json;
        this.heroImageInfo = this.carBuyersJSONData.CacHeroImageComponent;
        this.introData = this.carBuyersJSONData.CacCreditIntroductionCarBuyers;
        this.artileDataTwo = this.carBuyersJSONData.CacArticleTwoComponent;
        this.videoData = this.carBuyersJSONData.CacVideoBlockComponent;
        this.videoDataThree = this.carBuyersJSONData.CacVideoThreeColumn;
        this.cardData = this.carBuyersJSONData.CacCardComponent;
        this.articleData = this.carBuyersJSONData.CacArticleComponent;
      });
    }
    
    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }

}
