import { cardDetails } from "app/models/cardDetails";

export const Speed: cardDetails = {
  "title":["Usted necesita velocidad.", "Y nosotros tenemos maneras de acelerar el proceso."],
  "id":"tab_csitoerm",
  "sectionName":"CustomerTabInfo",
  "description": [
    "No hay nada que pueda detenernos cuando se trata de ayudarle a cerrar ventas más rápidamente:"
  ],
  "points": [
    'Aprobaciones en menos de 30 segundos.',
    'Reduzca el tiempo que transcurre entre la venta y la firma del contrato con CAPS®',
    'Fondos el mismo día: usted puede recibir los fondos antes de que el cliente salga del concesionario conduciendo el vehículo, lo que le ayuda recibir más dinero, más pronto.'
  ],
  "buttonName": "INICIAR SESIÓN EN CAPS",
  "imgUrl":"assets/images/grow-your-dealership/users-toggle/make-things-posisble/img_speed-img@2x.png",
  "imgAlt":"Dealer Speed",
  "ariaLabel":"Dealer Speed Section",
  "buttonId": "dealer_appr_login_caps",
  "link":"/dealers/caps-redirect"
}
