export const footerDisclaimer = [{
  disc_text: "Loans made or arranged pursuant to a California Finance Lenders Law license",
  id:"cali_reserve_disc"
}, {
  disc_text: "© DYANAMIC_CURRENT_YEAR Credit Acceptance Corporation. All rights reserved.",
  id:"rights_reserve_disc",
  }
]

export const DealerPoolDisclaimer = {
  disc_text: "*Each pool represents a subset of your total portfolio of contracts and serves as a mechanism to help accelerate expected Portfolio Profit, and to give you the opportunity to receive an advance on that future profit in the form of a Portfolio Profit Express Payment. In certain circumstances, some dealers may be eligible for an earlier Portfolio Profit Express check.",
  id:"dealer_pool_disc"
}

export const eligibleDealerDisclaimer = {
  disc_text: "**For eligible dealers.",
  id:"eligible_dealer_disc",
}
export const remoteDealerDisclaimer = {
  disc_text: "***Remote customer signing is not available in California and Nevada.",
  id: "remote_signing_disc"
}


