<div *ngIf="heroImageInfo" class="card no-animation border-0 text-white p-0">
  <img [src]="isMobileView ? heroImageInfo.mobileImgurl : heroImageInfo.imgUrl" class="card-img object-fit-cover" alt="{{heroImageInfo.imgAlt}}" height="470px">
  <div class="container card-img-overlay ps-4 pe-0 d-flex flex-wrap align-items-center" [ngClass]="isMobileView ? 'justify-content-center': 'justify-content-start'">
    <div [ngClass]="isMobileView ? 'col-12' : 'col-6'">
      <div class="border-2 border-bottom text-black" attr.aria-label="{{heroImageInfo.overlineTitle}}">
        <span class="px-2 pb-1 pt-2 bgc-credit-white overline">{{heroImageInfo.overlineTitle}}</span>
      </div>
      <div *ngIf="heroImageInfo.heading" class="py-3">
        <ng-container *ngFor="let heading of heroImageInfo.heading">
          <div class="h1 text-white" attr.aria-label="{{heading}}" [innerHTML]="heading"></div>
        </ng-container>
      </div>
      <a *ngIf="heroImageInfo.buttonName && heroImageInfo.buttonId" role="button" class="btn btn-primary" appAnchorClick 
        id="{{heroImageInfo.buttonId}}" attr.aria-label="{{heroImageInfo.buttonName}}" [linkInfo]="heroImageInfo">
        {{heroImageInfo.buttonName}}
      </a>
      <ng-container *ngFor="let disc of heroImageInfo.disclaimers">
        <div *ngIf="disc" class="py-3 w-75 hero-image-disclaimer" [ngClass]="isMobileView ? 'pe-3': 'pe-0'">
          <span attr.aria-label="{{disc}}" [innerHTML]="disc"></span>
        </div>
      </ng-container>
    </div>
  </div>
</div>