<ng-container *ngIf="data | async as data">
    <app-header [showOnlyLogo]="true"></app-header>
    <!-- hero banner start -->
    <div
      class="hero-image d-flex align-items-center"
      [ngStyle]="{
        'background-image':
          'url(' +
          (!data.isMobile
            ? data.heroBanner.image.src
            : data.heroBanner.image.mobileSrc) +
          ')',
      }"
    >
      <div class="container mt-5">
        <div class="row mb-4">
          <div [class]="data.isMobile ? 'col-12 ps-4' : 'col-6'">
            <h1 class="text-white" [ngClass]="{'h1-mobile': data.isMobile}" [ngStyle]="{'line-height': '70px'}">{{ data.heroBanner.heading }}</h1>
          </div>
        </div>
        <div class="row">
        <div [class]= "data.isMobile ? 'd-flex justify-content-start ps-4 pb-3' : 'col-8'">
          <p class="text-white">
            {{ data.heroBanner.disclaimer }}
          </p>
        </div>
      </div>
    </div>
  </div>
    <!-- hero banner end -->

    <!-- steps header start -->
    <div
      class="steps-header d-flex flex-column align-items-center py-5"
    >
        <h2
          class="text-center"
          [ngClass]="{'h2-mobile': data.isMobile}"
          [ngStyle]="{ 'max-width': data.isMobile ? '90%' : '60%', 'padding': '25px 0' }"
        >
          {{ data.stepsHeader.heading }}
        </h2>
    </div>
    <!-- steps header end -->
     
    <!-- no ssn no itin steps start -->
    <div
      [class]="data.isMobile ? 'flex-column' : 'flex-row'"
      class="d-flex flex-fill"
    >
      <div
        *ngFor="let step of data.noSsnNoItinSteps"
        class="no-ssn-no-itin-steps-container d-flex flex-column align-items-center justify-content-start pb-4 flex-fill"
        [class.px-5]="!data.isMobile"
        [ngClass]="{
          'indigo-background': step.backgroundColor === 'indigo',
          'orange-background': step.backgroundColor === 'orange',
          'yellow-background': step.backgroundColor === 'yellow',
        }"
      >
        <img
          [src]="step.iconSrc"
          alt="step number icon"
          [class]="!data.isMobile ? 'icon-desktop mb-5' : 'my-4'"
        />
        <h5
          [class]="step.whiteText ? 'text-white' : 'text-black'"
          class="mb-4 text-center fw-bold"
          [ngStyle]="{ 'width': data.isMobile ? '100%' : '70%' }"
        >
          {{ step.instruction }}
        </h5>
      </div>
    </div>
    <!-- no ssn no itin steps end  -->
  
    <!-- main body start -->
    <div
      class="background-image"
      [ngStyle]="{
        'background-image':
          'url(' +
          (data.isMobile
            ? data.bodyBackgroundImg.mobileSrc
            : data.bodyBackgroundImg.src) +
          ')',
      }"
    >
      <div class="main-body" [class.container]="!data.isMobile">
        <!-- edge for tough market start -->
        <div class="row edge-for-tough-market components-padding">
          <div
            *ngIf="!data.isMobile"
            class="col-6 d-flex justify-content-center align-items-center pe-5"
          >
            <img
              [src]="data.edgeForToughMarket.image.src"
              [alt]="data.edgeForToughMarket.image.alt"
              class="img-fluid"
            />
          </div>
          <div [class]="!data.isMobile ? 'col-6 ps-5' : 'col-12'">
            <h2 class="mb-5" [ngClass]="{'h2-mobile': data.isMobile}">{{ data.edgeForToughMarket.heading }}</h2>
            <ng-container
              *ngTemplateOutlet="
                unstyledList;
                context: { listItems: data.edgeForToughMarket.reasons }
              "
            ></ng-container>
          </div>
        </div>
        <!-- edge for tough market end -->
  
      <!-- main body templates -->
      <ng-template #unstyledList let-listItems="listItems">
        <ul class="list-unstyled">
          <li
            *ngFor="let item of listItems; let last = last"
            class="pb-5 mb-5 d-flex align-items-center"
            [class.border-bottom-dark]="!last"
          >
            <img [src]="item.iconSrc" alt="icon image" class="me-3 icon-size"/>
            <div>
                <h5 class="fw-bold">{{ item.title }}</h5>
                <span class="mb-0">{{ item.text }}</span>
            </div>
          </li>
        </ul>
      </ng-template>
    </div>
  </div>
    <!-- main body end -->
  
    <!-- get started start -->
    <div
      class="get-started d-flex flex-column align-items-center py-5"
    >
      <h3
        class="text-center pb-3"
        [ngStyle]="{ 'max-width': data.isMobile ? '66%' : '33%' }"
      >
        {{ data.getStarted.heading }}
      </h3>
      <ng-container *ngTemplateOutlet="capsLoginButton" />
    </div>
    <!-- get started end -->
  
    <!-- FAQ section start -->
    <div class="faqs components-padding pb-5">
      <div [class]="data.isMobile ? 'mobile-container' : 'container'">
        <cac-faqs [data]="data.pageFaqs" [isFaqQuestionBold]="true"></cac-faqs>
      </div>
    </div>
    <!-- FAQ section end -->
  
    <!-- Sitemap and Footer Section -->
    <div class="row">
      <app-sitemap [isMobileView]="data.isMobile"></app-sitemap>
      <app-footer></app-footer>
    </div>
  
    <!-- universal templates -->
    <ng-template #capsLoginButton let-class="class">
      <a
        appAnchorClick
        [linkInfo]="{ link: data.capsLoginButton.href }"
        [href]="data.capsLoginButton.href"
        [class]="'btn btn-primary' + (class ? ' ' + class : '')"
        [attr.aria-label]="data.capsLoginButton.text"
        >{{ data.capsLoginButton.text }}</a
      >
    </ng-template>