<app-header></app-header>
<div [ngClass]="{'mob-height': isMobileViewDisplay}" class="sub-components-margin">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [bottonBgColor]="bottonBgColor"
        [overLineBgColor]="overLineBgColor" [overLineTextColor]="overLineTextColor" [isCurvedImage]="isCurvedImage"
        [disclaimerColor]="disclaimerColor">
    </cac-hero-image>
</div>

<!-- education section -->
<div class='intro'>
    <cac-credit-introduction [information]="educationIntro"></cac-credit-introduction>
</div>
<div class="container container-border" *ngIf="educationVideo">
    <div class="container-width">
        <!-- <iframe id="vimeoPlayerEducation" src="https://vimeo.com/showcase/7021158/embed" allowfullscreen frameborder='0' title="vimeoPlayerEducation">
        </iframe> -->
        <iframe id="vimeoPlayerEducation" [src]="educationVideo" allowfullscreen frameborder='0' title="vimeoPlayerEducation" (load)="loadIframe('vimeoPlayerEducation', true)">
        </iframe>
    </div>
    <div class="video-title">
        <h5 class="text-alignment-h5">{{ videoTitleEducation }}</h5>
    </div>
    <div class="body-description">
        <p class="text-alignment-p">{{ videoDescriptionEducation }}</p>
    </div>
</div>

<!-- finance section -->
<div class='intro'>
    <cac-credit-introduction [information]="financingIntro"></cac-credit-introduction>
</div>
<div class="container container-border" *ngIf="financingVideo">
    <div class="container-width">
        <!-- <iframe id="vimeoPlayerFinance" src="https://vimeo.com/showcase/6749685/embed" allowfullscreen frameborder='0' title="vimeoPlayerFinance">
        </iframe>-->
        <iframe id="vimeoPlayerFinance" [src]='financingVideo' allowfullscreen frameborder='0' title="vimeoPlayerFinance" (load)="loadIframe('vimeoPlayerFinance', false)">
        </iframe>
    </div>
    <div class="video-title">
        <h5 class="text-alignment-h5">{{ videoTitleFinance }}</h5>
    </div>
    <div class="body-description">
        <p class="text-alignment-p">{{ videoDescriptionFinance }}</p>
    </div>
</div>

<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>



