<section *ngIf="siteMapAllLinks" id="sitemapSection" aria-label="Page Sitemap Section">
  <div class="row pb-5 px-3" *ngIf="siteMapAllLinks">
    <cac-site-map [accordionItems]="siteMapAllLinks"></cac-site-map>   
  </div>
</section>

<ng-template #siteMapLinkSec let-content="content">
  <div *ngIf="content.title" class="py-3">
    <span class="sitemap-title" [innerHTML]="content.title"></span>
  </div>
  <div *ngIf="content" class="py-3 bt-dotted-indigo">
    <app-links-sections [links]="content"></app-links-sections>
  </div>
</ng-template>
