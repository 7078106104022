import { cardDetails } from "app/models/cardDetails";

export const Approval: cardDetails = {
  "title":["Aprobamos un tipo de cliente. El suyo."],
  "id":"dealer_approval_tab",
  "sectionName":"Approval Dealer",
  "description": [
    "Puede contar con nosotros para ayudarle a obtener aprobación financiera para el 100 % de sus clientes gracias a unos criterios de aprobación flexibles, entre los que se incluyen:",
  ],
  "points": [
    'Múltiples préstamos abiertos para compras de vehículos',
    'Todo tipo de ingresos',
    'Capítulo 7 de bancarrota',
    'Capítulo 13 de bancarrota (con aprobación judicial/fiduciaria)'
  ],
  "buttonName": "INICIAR SESIÓN EN CAPS",
  "imgUrl":"assets/images/grow-your-dealership/users-toggle/make-things-posisble/approval-img@2x.png",
  "imgAlt":"Dealer Approvals",
  "ariaLabel":"Dealer Approval Section",
  "buttonId": "dealer_appr_login_caps",
  "link":"/dealers/caps-redirect"
}
