import { creditLinks } from "app/models/credit_links";

export const topHeader: creditLinks[] =
  [
    
  {
    "title": "Customers",
    "id": "customers",
    "ariaLabel": "customers",
    "link":"",
    "childMenu": [
      {
        "title": "Customers Overview",
        "id": "layout_23",
        "ariaLabel": "Customers Overview",
        "link": "/customers"
      },
      {
        "title": "Make a Payment",
        "id": "layout_24",
        "ariaLabel": "Make a Payment",
        "link": "/customers/make-a-payment"
      },
      {
        "title": "Credit Acceptance Reviews",
        "id": "layout_25",
        "ariaLabel": "Credit Acceptance Reviews",
        "link": "/customers/reviews"
      },
      {
        "title": "Express Lane Blog",
        "id": "layout_29",
        "ariaLabel": "Express Lane Blog",
        "link": "/car-buyers/express-lane"
      },
    {
        "title": "Customer FAQ",
        "id": "layout_28",
        "ariaLabel": "Customer FAQ",
        "link": "/customers/faq"
      },
      {
        "title": "Financial Education",
        "id": "layout_425",
        "ariaLabel": "Financial Education",
        "link": "/car-buyers/express-lane/financial-education"
      },
      {
        "title": "COVID-19 Response",
        "id": "layout_597",
        "ariaLabel": "COVID-19 Response",
        "link": "/coronavirus-response"
      },
      {
        "title": "Car shopping? Start here.",
        "id": "layout_5977",
        "ariaLabel": "Car shopping? Start here.",
        "link": "/car-buyers",
        "showLineSeparator" : true
      },
      {
        "title": "How Financing Works",
        "id": "layout_26",
        "ariaLabel": "How Financing Works",
        "link": "/car-buyers/how-it-works"
      },
      {
        "title": "Your Credit Approval",
        "id": "layout_27",
        "ariaLabel": "Your Credit Approval",
        "link": "/your-credit-approval"
      },
      {
        "title": "Car Buyer Videos",
        "ariaLabel": "Car Buyer Videos",
        "id": "layout_289",
        "link": "/consumer-video-page"
      },
      {
        "title": "Car Buyer Resources",
        "id": "layout_294",
        "ariaLabel": "Car Buyer Resources",
        "link": "/car-buyers/resources"
      },
      {
        "title": "Monthly Car Payment Calculator",
        "id": "layout_556",
        "ariaLabel": "Monthly Car Payment Calculator",
        "link": "/car-buyers/monthly-payment-calculator"
      },
    ]
  },
  {
    "title": "Dealers",
    "id": "dealers",
    "ariaLabel": "Dealers",
    "link": "/dealers",
    "childMenu": [
      {
        "title": "Dealers Overview",
        "id": "layout_39",
        "ariaLabel": "Dealers Overview",
        "link": "/dealers"
      },
      {
        "title": "CAPS® Login",
        "id": "layout_42",
        "ariaLabel": "CAPS® Login",
        "link": "/dealers/caps-redirect"
      },
      {
        "title": "Join Our Network",
        "id": "layout_41",
        "ariaLabel": "Join Our Network",
        "link": "/dealers/join-our-network"
      },
      {
        "title": "Auto Finance Programs",
        "id": "layout_44",
        "ariaLabel": "Auto Finance Programs",
        "link": "/dealers/how-it-works"
      },
      {
        "title": "Dealer Reviews",
        "id": "layout_43",
        "ariaLabel": "Dealer Reviews",
        "link": "/dealers/reviews"
      },
      {
        "title": "Dealer Resources",
        "id": "layout_295",
        "ariaLabel": "Dealer Resources",
        "link": "/dealers/resources"
      },
      {
        "title": "Dealer FAQ",
        "id": "layout_51",
        "ariaLabel": "Dealer FAQ",
        "link": "/dealers/faq"
      },
      {
        "title": "Dealer Insights",
        "ariaLabel": "Dealer Insights",
        "id": "layout_50",
        "link": "/dealers/the-lot"
      }
    ]
  }];