import { cardDetails } from "app/models/cardDetails";

export const infoEducationInfo: cardDetails[] = [
  {
    "imgUrl":"assets/images/info-education/informative-vid-img-1920@2x.webp",
    "imgAlt":"Information Videos Image",
    "title":["Informative Videos"],
    "description":["Check out our “Financing through Credit Acceptance” and “Car Buyer Credit Awareness Series” videos."],
    "buttonName":"Watch Videos",
    "link":"/consumer-video-page"
  },
  {
    "imgUrl":"assets/images/info-education/financial-education-img-1920@2x.webp",
    "imgAlt":"financial education image",
    "title":["Financial Education"],
    "description":["ExtraCredit by Credit Acceptance is a financial wellness resource to help you take control of your finances."],
    "buttonName":"Learn More",
    "link":"/car-buyers/express-lane/financial-education"
  }
]