import { cardDetails } from "app/models/cardDetails";

export const hearFromDealers: cardDetails[] = [
  {
    "avatarIcon":"assets/images/grow-your-dealership/hear-from-dealers/user3.webp",
    "imgAlt":"Credit FAQ",
    "userDetails": {
      "name":"Joshua Gomez",
      "position":"Propietario",
      "corporateName":"Joshua Motors",
      "Address": "Vineland, NJ"
    },
    "cardHeight": "950px",
    "cardMobileHeight": "875px",
    "link":"/dealer-review-joshua-motors",
    "buttonName":"Más >",
    "description":["Utilizamos a Credit Acceptance para la mayoría de nuestros clientes. No me di cuenta del concesionario de Credit Acceptance hasta que recibí medio millón de dólares y mi inventario pasó de 4 a unos 240 carros y 11 empleados. Podemos vender vehículos porque Credit Acceptance nos brinda la oportunidad de hacerlo. El cliente consigue un buen carro y obtiene una buena calificación financiera, y nosotros cambiamos la vida de muchas personas. Este programa es una de esas oportunidades que solo se presentan una vez en la vida y es la mejor decisión que jamás he tomado."]
  },
  {
    "avatarIcon":"assets/images/grow-your-dealership/hear-from-dealers/user1.webp",
    "imgAlt":"Austin Vann Dealer Avatar Icon",
    "userDetails": {
      "name":"Austin Vann",
      "position":"Gerente de Ventas",
      "corporateName":"Steve Landers Auto",
      "Address": "Little Rock, AR"
    },
    "cardHeight": "950px",
    "cardMobileHeight": "875px",
    "link":"/dealer-review-steve-landers-auto",
    "buttonName":"Más >",
    "description":["Hemos tenido un éxito tremendo trabajando con Credit Acceptance. Con ellos, nuestro grupo automovilístico ha prosperado enormemente y hemos aumentado nuestro volumen de ventas de vehículos usados en más de un 10 % debido a Credit Acceptance."],
  },
  {
    "avatarIcon":"assets/images/grow-your-dealership/hear-from-dealers/user2.webp",
    "imgAlt":"Credit FAQ",
    "userDetails": {
      "name":"Dan Reel",
      "position":"Propietario",
      "corporateName":"Reel's Auto Sales, LLC",
      "Address": "Orwell, OH"
    },
    "cardHeight": "950px",
    "cardMobileHeight": "875px",
    "link":"/dealer-review-reels-auto-sales",
    "buttonName":"Más >",
    "description":["Desde que nos registramos con Credit Acceptance, nuestro negocio ha experimentado un crecimiento enorme de un 25 % anual. También hemos podido doblar el tamaño de nuestro concesionario."],
  }
]
