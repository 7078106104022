import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, AfterViewInit, PLATFORM_ID } from '@angular/core';
import { AWSService } from 'app/services/aws.service';
import { Subscription } from 'rxjs';
import { CONSUMER_VIDEOS_JSON_URL } from 'app/constants/aws.constants';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ChangeDetectorRef } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import Player from '@vimeo/player';
@Component({
  selector: 'app-consumer-videos',
  templateUrl: './consumer-videos.component.html',
  styleUrls: ['./consumer-videos.component.scss'],
})
export class ConsumerVideosComponent implements OnInit{

  subscription: Subscription[] = [];

  url = CONSUMER_VIDEOS_JSON_URL;
  consumerVideoJSONData: any;
  isMobileViewDisplay: boolean = false;
  isBrowser: boolean = false;

  // Properties for the hero component
  heroImageInfo: any = {};
  headerColor: string = "#FFFFFF";
  bottonBgColor: string = "#ffb800";
  overLineBgColor: string = "#2B4361";
  overLineTextColor: string = "#FFFFFF";
  disclaimerColor: string = "#FFFFFF";
  isCurvedImage = false;

  // Safe URLs for the video component
  educationVideo!: SafeResourceUrl;
  financingVideo!: SafeResourceUrl;

  // Titles and descriptions for the videos
  videoTitleEducation: string = '';
  videoTitleFinance: string = '';
  videoDescriptionEducation: string = '';
  videoDescriptionFinance: string = '';

  // Introduction components
  educationIntro: any;
  financingIntro: any;

  constructor(
    private http: HttpClient,
    private responsive: BreakpointObserver,
    private awsService: AWSService,
    @Inject(PLATFORM_ID) platformId: Object,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {
    // Check if the platform is a browser
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      // Fetch data from AWS
      this.awsService.getAWSData(this.url).then(json => {
        // Replace placeholders in the JSON data
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);

        // Assign JSON data to component properties
        this.consumerVideoJSONData = json;
        this.heroImageInfo = this.consumerVideoJSONData.CacHeroImageComponent;
        this.educationIntro = this.consumerVideoJSONData.CacCreditIntroductionCarBuyerComponent;
        this.financingIntro = this.consumerVideoJSONData.CacCreditIntroductionCarBuyerComponent2;
        this.educationVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.consumerVideoJSONData.Videoaddress.address);
        this.financingVideo = this.sanitizer.bypassSecurityTrustResourceUrl(this.consumerVideoJSONData.Videoaddress2.address);
      });
    }

    // Observe screen size changes to toggle mobile view
    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
        this.isMobileViewDisplay = result.matches ? true : false;
        this.applyTruncation();
      })
    );
  }
  applyTruncation() {
    //const maxLength = this.isMobileViewDisplay ? 140 : 1000;
    //this.videoDescriptionEducation = this.truncateDescription(this.videoDescriptionEducation, maxLength);
    //this.videoDescriptionFinance = this.truncateDescription(this.videoDescriptionFinance, maxLength);
  }
  
  //remove urls from description
  sanitizeDescription(description: string): string {
    // Match URLs starting with http, https, www, or a domain name with .com, .net, etc., and remove paths as well
    const urlPattern = /(?:https?:\/\/|www\.)[^\s]+|(?:[a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}\/[^\s]*/g;
    return description.replace(urlPattern, '');
  }
  truncateDescription(description: string, maxLength: number): string {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + '...';
    }
    return description;
  }
  loadVimeoPlayer(player: Player, isEducation: boolean) {
    player.on('loaded', () => {
      player.getVideoId().then(videoId => {
        fetch(`https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${videoId}`).then(response => response.json()).then(data => {
          if(isEducation){
            this.videoTitleEducation = data.title;
            this.videoDescriptionEducation = data.description;
          } else {
            this.videoTitleFinance = data.title;
            this.videoDescriptionFinance = data.description;
          }
        });
      });
    });
  }
  loadIframe(id: string, isEducation: boolean) {
    const iframe = document.getElementById(id) as HTMLIFrameElement;
    //Check iframe src 
    if(iframe.src === ''){
      return;
    }
    const player = new Player(iframe);
    this.loadVimeoPlayer(player, isEducation);
}
}
