import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, inject, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { AWSService } from '../../services/aws.service';
import { isPlatformBrowser } from '@angular/common';
import { BIG_CHECK_CAMPAIGN_JSON_URL } from '../../constants/aws.constants';
import { BigCheckCampaignPageModel } from './data.model';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-big-check-campaign',
  templateUrl: './big-check-campaign.component.html',
  styleUrls: ['./big-check-campaign.component.scss'],
})
export class BigCheckCampaignComponent implements OnInit {
  private readonly metaService = inject(Meta);
  private readonly responsive = inject(BreakpointObserver);
  private readonly awsService = inject(AWSService);
  private readonly platformId = inject(PLATFORM_ID);
  private readonly isBrowser: boolean = isPlatformBrowser(this.platformId);
  data!: Observable<BigCheckCampaignPageModel>;

  ngOnInit(): void {
    this.metaService.addTag({ name: 'robots', content: 'noindex' });

    if (this.isBrowser) {
      this.data = combineLatest({
        bigCheckCampaignResource: this.awsService
          .getAWSData(BIG_CHECK_CAMPAIGN_JSON_URL)
          .then((json) =>
            JSON.parse(
              JSON.stringify(json).replace(
                /AWS_BUCKET_URL/g,
                this.awsService.getAWSBucketURL(),
              ),
            ),
          ),
        breakpointState: this.responsive.observe([
          Breakpoints.XSmall,
          Breakpoints.Small,
        ]),
      }).pipe(
        map(
          ({
            bigCheckCampaignResource,
            breakpointState: { matches: isMobile },
          }) => ({
            isMobile,
            ...bigCheckCampaignResource,
          }),
        ),
      );
    }
  }
}
