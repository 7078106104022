import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { DealerFinderService } from '../dealer-finder.service';
import { FeatureFlagService } from '../../../services/feature-flag.service';
import { EFeatureFlag } from '../../../services/feature-flags.types';
import { take } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { DealerListItem } from 'app/page-component/dealer-finder/interfaces/dealer-list-item.interface';

@Component({
  selector: 'app-dealer-finder-list',
  templateUrl: './dealer-finder-list.component.html',
  styleUrls: ['./dealer-finder-list.component.scss'],
})
export class DealerFinderListComponent implements OnInit {
  private readonly featureFlagService = inject(FeatureFlagService);
  private readonly dealerFinderService = inject(DealerFinderService);
  private readonly document = inject(DOCUMENT);

  @Input() dealerFinderResultsList: DealerListItem[];
  @Input() awsS3content: any;

  @Output() elementClicked = new EventEmitter<{
    dealerId: string;
    type: string;
  }>();

  @ViewChildren('card') cards: QueryList<ElementRef>;

  websiteLinksAndDisclosureEnabled = false;

  ngOnInit(): void {
    this.dealerFinderService.markerIconClicked$.subscribe((index) =>
      this.scrollToCard(index)
    );

    this.featureFlagService
      .isEnabled(EFeatureFlag.WEBSITE_LINKS_AND_DISCLOSURE)
      .pipe(take(1))
      .subscribe({
        next: (isEnabled) =>
          (this.websiteLinksAndDisclosureEnabled = isEnabled),
      });
  }

  onCardMouseEnter(i: number): void {
    const activeElements = this.cards
      .toArray()
      .filter(
        (element) => element.nativeElement === this.document.activeElement
      );

    if (activeElements.length) {
      activeElements.forEach((element) => element.nativeElement.blur());
    }

    this.dealerFinderService.emitSearchListEventChanged('mouseenter', i);
  }

  onCardMouseLeave(): void {
    this.dealerFinderService.emitSearchListEventChanged('mouseleave', 0);
  }

  scrollToCard(i: number): void {
    const card = this.cards.toArray()[i];

    if (card) {
      card.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      card.nativeElement.focus({ preventScroll: true });
    }
  }

  onClickEvent(dealerId: string, type: string) {
    this.elementClicked.emit({ dealerId, type });
  }
}
