import { CapsDealOptimizerComponent } from "app/components/caps-deal-optimizer/caps-deal-optimizer.component";
import { cardDetails } from "app/models/cardDetails";

export const DealOptimizer: cardDetails = {
  "title":["When inventory moves off your lot, your cash flow moves a lot."],
  "id":"deal_optimizer_tab_title",
  "sectionName":"Dealer Optimizer Tab",
  "description": [
    "CAPS lets you structure and deliver the deal that’s best for you and your customer by proposing multiple, specific deals for each customer, 24/7/365."
  ],
  "buttonName": "Login to Caps",
  "buttonId":"deal-optimizer-btn",
  "link":"/dealers/caps-redirect",
  "imgUrl":"assets/images/grow-your-dealership/users-toggle/ways-business-grow/img_deal-optim-img@2x.png",
  "imgAlt":"Dealers Optimizer Image",
  "ariaLabel":"Dealers Optimizer Tab Section",
  "outletComponent": CapsDealOptimizerComponent
}