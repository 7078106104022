import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { dealerGrowBanner } from 'app/data/grow-your-dealership-sp/banner-image';
import { growYOurDealerCardTxtImg } from 'app/data/grow-your-dealership-sp/card-text-img-overlay';
import { dealerHomeNumberSpeak } from 'app/data/grow-your-dealership-sp/dealersNumSpeak';
import { hearFromDealers } from 'app/data/grow-your-dealership-sp/hear-from-dealers';
import { Approval } from 'app/data/grow-your-dealership-sp/how-make-things-possible/approval';
import { makeThingsPossible } from 'app/data/grow-your-dealership-sp/how-make-things-possible/tabInfo';
import { growYourDealerIntro } from 'app/data/grow-your-dealership-sp/introduction';
import { PortFolioPurchaseDetailsSp } from "../../data/portfolio-purchase-details-sp";
import { DealOptimizer } from 'app/data/grow-your-dealership-sp/ways-for-business-grow/deal-optimizer';
import { waysBusinessGrow } from 'app/data/grow-your-dealership-sp/ways-for-business-grow/tabInfo';
import { bannerImage } from 'app/models/bannerImage';
import { cardDetails } from 'app/models/cardDetails';
import { IntroDescription } from 'app/models/credit_accp_Intro_desc';
import { creditLinks } from 'app/models/credit_links';
import { numbersSpeak } from 'app/models/numbersSpeak';
import { TabToggleInfo } from 'app/models/toggle_tabs';
import {map, Observable, Subscription} from 'rxjs';
import { DealerPoolDisclaimer, eligibleDealerDisclaimer, footerDisclaimer } from 'app/data/footer/footer_disclaimer_sp';
import { footerNavigationLinks } from "./footer-content";
import {ContentService} from "../../services/content.service";
@Component({
  selector: 'app-grow-your-dealership-sp',
  templateUrl: './grow-your-dealership-sp.component.html',
  styleUrls: ['./grow-your-dealership-sp.component.scss']
})
export class GrowYourDealershipSpComponent implements OnInit {
  subscription: Subscription[] = [];
  mobileView$!: Observable<boolean>;
  dealerNumSpeak: numbersSpeak = dealerHomeNumberSpeak;
  waysBusinessGrow: TabToggleInfo = waysBusinessGrow;
  makeThingsPossible: TabToggleInfo = makeThingsPossible;
  intro: IntroDescription = growYourDealerIntro;
  waysGrowContent: cardDetails = DealOptimizer;
  makeThingContent: cardDetails = Approval;
  cardTxtImgDetails: cardDetails[] = growYOurDealerCardTxtImg;
  hearFromDealers: cardDetails[] = hearFromDealers;
  portfolioPurchaseDetails: cardDetails[] = PortFolioPurchaseDetailsSp;
  portfolioPurchaseCapsLoginGetStarted: Partial<creditLinks> = {
    title: "Inicie sesión en CAPS para empezar >",
    ariaLabel: "Inicie sesión en CAPS para empezar",
  }
  hearInfo: creditLinks= {
    id: "hear_from_more_dealers",
    title: "Oiga lo que dicen otros concesionarios >",
    link: "/dealers/reviews",
    ariaLabel: "Oiga lo que dicen otros concesionarios"
  };
  capsLoginGetStarted: creditLinks= {
    id: "login_to_caps_grow_your_dealership_btn",
    title: "Inicie sesión en CAPS para empezar >",
    link: "/dealers/caps-redirect",
    ariaLabel: "Inicie sesión en CAPS para empezar"
  };
  hearInfoSectionTitle: string = 'Vea por qué a los concesionarios les gusta trabajar con nosotros';
  heroImageInfo:bannerImage = dealerGrowBanner;
  dealerPoolDisc: any = DealerPoolDisclaimer;
  eligibleDisc: any = eligibleDealerDisclaimer;
  footerDisclaimer = footerDisclaimer;
  footerNavigationLinks = footerNavigationLinks;
  footerImages!: creditLinks[]

  constructor(private readonly responsive: BreakpointObserver, private readonly contentService: ContentService) {}

  ngOnInit() {
    this.footerImages = this.contentService.getFooterImages();
    //Add no robots meta tag
    let meta = document.createElement('meta');
    meta.name = "robots";
    meta.content = "noindex";
    document.getElementsByTagName('head')[0].appendChild(meta);
    this.mobileView$ = this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(
      map(result => result.matches)
    )
  }
}
