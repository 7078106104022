import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CUSTOMER_REVIEWS_JSON_URL } from 'app/constants/aws.constants';
import { AWSService } from 'app/services/aws.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'customer-reviews',
  templateUrl: './customer-reviews.component.html',
  styleUrls: ['./customer-reviews.component.scss']
})
export class CustomerReviewsComponent implements OnInit, AfterViewInit {
  subscription: Subscription[] = [];

  url = CUSTOMER_REVIEWS_JSON_URL;
  customerReviewsData : any = {};
  heroImageInfo : any ;
  contentBlock : any ;
  videoBlock : any ;
  testimonials : any;
  faqs : any

  isMobileViewDisplay : boolean = false;
  isBrowser : boolean = false;
  leftToRight: boolean = false;
  overLineBgColor: string = "#2B4361";
  bottonBgColor: string = "#C4EDFA";
  headerColor: string = "#FCFCFA";
  isCurvedImage = true;

  parameter: string | null = null;
  fragment: string | null = null;

  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object, private route: ActivatedRoute, private router: Router) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {

    if(this.isBrowser){
      
      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        // json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, "http://localhost:4200"+this.awsService.getAWSBucketURL());
        json = JSON.parse(json);
        this.customerReviewsData = json;
        
        this.heroImageInfo = this.customerReviewsData.CacHeroImageComponent;
        this.contentBlock = this.customerReviewsData.CacContentBlockComponent;
        this.videoBlock = this.customerReviewsData.CacVideoBlockComponent;
        this.testimonials = this.customerReviewsData.CACTestimonialComponent;
        this.faqs = this.customerReviewsData.CacFaqsComponent
        
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );

    this.route.queryParams.subscribe(params => {
      this.parameter = params ['videoId'];
    });

    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    });
  }

  ngAfterViewInit() {

    setTimeout (() => {
      this.checkName();
    }, 1000);
  }

  checkName() {
    let videoReviews: Element | null;

    if (window.innerWidth <= 959) {
      videoReviews = document.querySelector('.carousel-thumbnails-mobile');
    } else {
      videoReviews = document.querySelector('.carousel-thumbnails');
    }

    if (videoReviews) {
      if (!this.clickVideo(videoReviews)) {
        const carouselRight = document.querySelector('.right') as HTMLElement;

        if (carouselRight) {
          carouselRight.click();
          
          this.waitForCarouselClick(videoReviews, () => {
            this.clickVideo(videoReviews!);
          });
        }
      }
    }
  }

  waitForCarouselClick(videoReviews: Element, callback: () => void) {
    const observer = new MutationObserver(() => {
      observer.disconnect();
      callback();
    });

    observer.observe(videoReviews, {childList: true, subtree: true});
  }

  clickVideo(videoReviews: Element): boolean {
    const children = videoReviews.children;

    for (let i = 0; i < children.length; i++) {
      const imgThumbnail = children[i].querySelector('img');
      const videoId = imgThumbnail?.getAttribute('id');

        if (videoId && this.parameter && videoId === this.parameter) {
          if (imgThumbnail instanceof HTMLElement) {
            imgThumbnail.click();
            return true;
          }
        }
      }
      return false;
    }
  }
