import { Component, Input, OnInit } from '@angular/core';
import { IntroDescription } from 'app/models/credit_accp_Intro_desc';

@Component({
  selector: 'app-credit-introduction',
  templateUrl: './credit-introduction.component.html',
  styleUrls: ['./credit-introduction.component.scss']
})
export class CreditIntroductionComponent implements OnInit {
  @Input() isMobileView: boolean = false;
  @Input() information: IntroDescription = new IntroDescription;
  constructor() { }

  ngOnInit(): void {
  }

}
