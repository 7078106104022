import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dealer-finder-search',
  templateUrl: './dealer-finder-search.component.html',
  styleUrls: ['./dealer-finder-search.component.scss'],
})
export class DealerFinderSearchComponent implements OnInit {
  @Input() content: any;
  @Input() errors: { serviceFailure: string } | null;
  @Input()
  set serviceError(errorDetected: boolean) {
    this.serviceErrorDetected = errorDetected;
    if (errorDetected && this.errors?.serviceFailure.includes('reCAPTCHA')) {
      this.autoCompleteFormControl.disable();
    }
  }

  @Input() initialSearchValue$!: Observable<string>;

  @Output() addressChanged = new EventEmitter<string>();

  @ViewChild('userInput', { static: true }) userInput!: ElementRef;

  constructor(private readonly ngZone: NgZone) {}

  serviceErrorDetected = false;

  exclamation = 'assets/images/icn_exclamation_sign.svg';

  autoCompleteFormControl = new FormControl('', [Validators.required]);

  ngOnInit(): void {
    this.resetZoom();
    this.initialSearchValue$.subscribe((initialSearchValue) => {
      this.autoCompleteFormControl.setValue(initialSearchValue ?? '');
    });
    this.initializeAutocomplete();
  }

  initializeAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.userInput.nativeElement,
      {
        types: [
          'locality',
          'postal_code',
          'street_address',
        ],
        componentRestrictions: { country: ['US'] },
        fields: ['formatted_address', 'name'],
      }
    );
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place = autocomplete.getPlace();
        const address = place.formatted_address ?? place['name'];
        // Emit address to parent component
        this.addressChanged.emit(`${address ?? ''}`);
      });
    });
  }

  searchDealers(address: string) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    // Emit address to parent component
    this.addressChanged.emit(address);
  }

  clearInput(): void {
    this.autoCompleteFormControl.setValue('');
  }
  resetZoom(): void {
    const viewport = document.querySelector('meta[name="viewport"]');
    if (viewport) {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
    }
  }
}
