import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { Component, ElementRef, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DealerFinderSearchResponse } from 'app/page-component/dealer-finder/interfaces/dealer-finder-search-response';
import {
  Subject,
  Observable,
  map,
  switchMap,
  catchError,
  of,
  tap,
  take,
  merge,
  shareReplay,
  exhaustMap,
} from 'rxjs';
import { DealerFinderService } from '../dealer-finder.service';
import { RecaptchaV3Service } from '../../../services/recaptcha-v3.service';
import {
  DealerFinderRxjsDataResp,
  DealerFinderS3Data,
} from '../dealer-finder.types';

@Component({
  selector: 'app-dealer-finder-content',
  templateUrl: './dealer-finder-content.component.html',
  styleUrls: ['./dealer-finder-content.component.scss'],
})
export class DealerFinderContentComponent {
  private readonly handleDealerSearchError =
    (): Observable<DealerFinderSearchResponse> => {
      this.showServiceError = true;
      return of({
        totalCount: 0,
      }) as Observable<DealerFinderSearchResponse>;
    };

  @Input() awsS3Data: DealerFinderS3Data;
  @Input() showData: boolean | undefined;
  @Input() trackAdobeAnalytics: (
    totalCount: number,
    title: string,
    searchAddress: string,
    serviceError: string,
    lastRecordGeoDistance: number,
    dealerIds: string[],
    city: string,
    state: string,
    geoCodeStatus: string,
    geoServiceFailure: string
  ) => void;
  @Input() trackAdobeClickEvent: (
    additionalEventName: string,
    eventLabel: string,
    eventUrl: string,
    dealerId: string
  ) => void;
  @Input() setSEOMetaTags: (
    city: string,
    state: string
  ) => void;

  showServiceError: boolean = false;
  recaptchaError$: Observable<{ serviceFailure: string }>;

  typedInAddress: string = '';

  headerCity: string;
  headerState: string;

  isLocationParam: boolean;

  addressSelected$ = new Subject<string>();

  get header(): string | null {
    return this.headerCity && this.headerState
      ? `${this.headerCity}, ${this.headerState}`
      : null;
  }

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly dealerFinderService: DealerFinderService,
    private readonly el: ElementRef,
    private readonly location: Location,
    private readonly titleService: Title,
    private readonly responsive: BreakpointObserver,
    private readonly recaptchaV3Service: RecaptchaV3Service
  ) {
    this.recaptchaError$ = this.recaptchaV3Service.recaptchaError$.pipe(
      map((errorMessage) => {
        this.showServiceError = true;
        return { serviceFailure: errorMessage };
      })
    );
  }

  routeChangedDealerFinderData$: Observable<DealerFinderSearchResponse> =
    this.activatedRoute.paramMap.pipe(
      map((res) => {
        const locationParam = res.get('location');

        this.isLocationParam = locationParam ? true : false;

        const address = locationParam ?? 'MI-Southfield';

        return address.toLowerCase();
      }),
      exhaustMap((address) => {
        return this.dealerFinderService
          .searchDealers(address)
          .pipe(catchError(this.handleDealerSearchError));
      }),
      tap((res) => {
        if (res.city && res.state && this.isLocationParam) {
          this.headerState = res.state;
          this.headerCity = res.city;

          this.updateLocation(res.city, res.state);
        }
      }),
      this.applyDealerFinderData(),
      take(1),
      shareReplay(1)
    );

  googleAutoCompleteUpdated$: Observable<DealerFinderSearchResponse> =
    this.addressSelected$.asObservable().pipe(
      switchMap((address) => {
        return this.dealerFinderService
          .searchDealers(address)
          .pipe(catchError(this.handleDealerSearchError));
      }),
      tap((res) => {
        if (res.city && res.state) {
          this.headerState = res.state;
          this.headerCity = res.city;

          this.updateLocation(res.city, res.state);
        }
      }),
      this.applyDealerFinderData(true)
    );

  dealerFinderData$: Observable<DealerFinderSearchResponse> = merge(
    this.routeChangedDealerFinderData$,
    this.googleAutoCompleteUpdated$
  ).pipe(shareReplay(1));

  initialSearchValue$: Observable<string> = this.dealerFinderData$.pipe(
    map((res) => res.formattedAddress ?? '')
  );

  isMobileViewDisplay$: Observable<boolean> = this.responsive
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map((result) => result.matches));

  captureClickEvent(eventData: { dealerId: string; type: string }): void {
    //Get anchor elemnt by anchor id
    const anchor = this.el.nativeElement.querySelector(
      '#' + eventData.type + eventData.dealerId
    );

    //Get the link based on the type
    const link = anchor ? anchor.getAttribute('href') ?? '' : '';

    const [additional_events, eventLabel] = this.setEventProperties(
      eventData.type
    );

    if (!additional_events || !eventLabel) {
      return;
    }

    this.trackAdobeClickEvent(
      additional_events,
      eventLabel,
      link,
      eventData.dealerId
    );
  }

  contactNumberClicked(eventData: {
    additionalEventName: string;
    eventLabel: string;
    eventUrl: string;
    dealerId: string;
  }) {
    this.trackAdobeClickEvent(
      eventData.additionalEventName,
      eventData.eventLabel,
      eventData.eventUrl,
      eventData.dealerId
    );
  }

  addressChanged(address: string) {
    this.typedInAddress = address;
    this.addressSelected$.next(address);
  }

  // Helper functions
  private applyDealerFinderData(
    isSearchUpdate: boolean = false
  ): DealerFinderRxjsDataResp {
    return (source$: Observable<DealerFinderSearchResponse>) =>
      source$.pipe(
        tap((res) => {
          if (isSearchUpdate) {
            this.setSEOTags(res);
          }
        }),
        tap((res) => this.setAnalytics(res))
      );
  }

  private setSEOTags(res: DealerFinderSearchResponse) {
    if (res.city && res.state) {
      this.setSEOMetaTags(res.city, res.state);
    }
  }

  private setAnalytics(res: DealerFinderSearchResponse) {
    if (this.trackAdobeAnalytics) {
      this.trackAdobeAnalytics(
        res.totalCount,
        this.titleService.getTitle(),
        this.typedInAddress,
        this.showServiceError
          ? this.awsS3Data?.errors?.serviceFailure ?? ''
          : '',
        res.content
          ? res.content.length > 0
            ? res.content[res.content.length - 1].geoDistance
            : 0
          : 0,
        res.content ? res.content.map((dealer) => dealer.dealerId) : [],
        res.city ? res.city : '',
        res.state ? res.state : '',
        res.geoCodeStatus ? res.geoCodeStatus : '',
        this.awsS3Data?.errors?.geoServiceFailure ?? ''
      );
    }
  }

  private updateLocation(city: string, state: string) {
    const expectedLocation = `/car-buyers/dealer-finder/${state}-${city}${window.location.search}`;

    if (!this.location.isCurrentPathEqualTo(expectedLocation)) {
      this.location.replaceState(expectedLocation);
    }
  }

  private setEventProperties(eventDataType: string): string[] {
    if (eventDataType === 'phone') {
      return ['cac-dealer-phone-click', 'dealer phone # click'];
    } else if (eventDataType === 'direction') {
      return ['cac-dealer-directions-click', 'dealer directions click'];
    } else if (eventDataType === 'website') {
      return ['cac-dealer-website-click', 'dealer website click'];
    } else if (eventDataType === 'prequal') {
      return ['cac-dealer-prequal-click', 'get prequalified button'];
    } else {
      return ['', ''];
    }
  }
}
