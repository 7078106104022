import { cardDetails } from "app/models/cardDetails";

export const dealersTabInfo: cardDetails = {
  "title":["What’s possible? It’s more like, what isn’t? "],
  "id":"dealers_tab",
  "sectionName":"DealerInfo",
  "description": [
    "At Credit Acceptance, we’re dedicated to doing everything possible to help you get your customers into a vehicle and keep them coming back.",
    "Credit Acceptance has the expertise and commitment to provide you with tools to help you grow your business, such as"
  ],
  "points": [
    'Ability to approve up to 100% of your customers',
    'Approvals in 30 seconds or less',
    'Remote customer signings — driver and co-signer',
    'Same day funding (to increase your cash flow)'
  ],
  "buttonName": "Enroll Now",
  "imgUrl":"assets/images/users-toggle/dealer-tab-img-1920@2x.png",
  "imgAlt":"Dealers Image",
  "ariaLabel":"Dealer Tab Section",
  "buttonId": "join_our_network_tab_link",
  "link":"/dealers/join-our-network"
} 