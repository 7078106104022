export class CalculatorConstants{

    public static usStates = [
          { value: 'AL', label: 'Alabama' },
          { value: 'AK', label: 'Alaska' },
          { value: 'AZ', label: 'Arizona' },
          { value: 'AR', label: 'Arkansas' },
          { value: 'CA', label: 'California' },
          { value: 'CO', label: 'Colorado' },
          { value: 'CT', label: 'Connecticut' },
          { value: 'DE', label: 'Delaware' },
          { value: 'DC', label: 'District of Columbia' },
          { value: 'FL', label: 'Florida' },
          { value: 'GA', label: 'Georgia' },
          { value: 'HI', label: 'Hawaii' },
          { value: 'ID', label: 'Idaho' },
          { value: 'IL', label: 'Illinois' },
          { value: 'IN', label: 'Indiana' },
          { value: 'IA', label: 'Iowa' },
          { value: 'KS', label: 'Kansas' },
          { value: 'KY', label: 'Kentucky' },
          { value: 'LA', label: 'Louisiana' },
          { value: 'ME', label: 'Maine' },
          { value: 'MD', label: 'Maryland' },
          { value: 'MA', label: 'Massachusetts' },
          { value: 'MI', label: 'Michigan' },
          { value: 'MN', label: 'Minnesota' },
          { value: 'MS', label: 'Mississippi' },
          { value: 'MO', label: 'Missouri' },
          { value: 'MT', label: 'Montana' },
          { value: 'NE', label: 'Nebraska' },
          { value: 'NV', label: 'Nevada' },
          { value: 'NH', label: 'New Hampshire' },
          { value: 'NJ', label: 'New Jersey' },
          { value: 'NM', label: 'New Mexico' },
          { value: 'NY', label: 'New York' },
          { value: 'NC', label: 'North Carolina' },
          { value: 'ND', label: 'North Dakota' },
          { value: 'OH', label: 'Ohio' },
          { value: 'OK', label: 'Oklahoma' },
          { value: 'OR', label: 'Oregon' },
          { value: 'PA', label: 'Pennsylvania' },
          { value: 'RI', label: 'Rhode Island' },
          { value: 'SC', label: 'South Carolina' },
          { value: 'SD', label: 'South Dakota' },
          { value: 'TN', label: 'Tennessee' },
          { value: 'TX', label: 'Texas' },
          { value: 'UT', label: 'Utah' },
          { value: 'VT', label: 'Vermont' },
          { value: 'VA', label: 'Virginia' },
          { value: 'WA', label: 'Washington' },
          { value: 'WV', label: 'West Virginia' },
          { value: 'WI', label: 'Wisconsin' },
          { value: 'WY', label: 'Wyoming' }
      ];
  
      public static creditScores = [
          { value: '<600', label: 'Less than 600 (Poor)', range: "range1" },
          { value: '600–649', label: '600-649 (Fair)', range: "range2" },
          { value: '650–679', label: '650-679 (Average)', range: "range3" },
          { value: '680–720', label: '680-720 (Good)', range: "range4" },
          { value: '720+', label: '720+ (Excellent)', range: "range5" },
          { value: 'CustomRate', label: 'I don\'t know my credit', range: "range6" }
      ];
  
      public static loanLengths = [
          { value: '36', label: '36' },
          { value: '48', label: '48' },
          { value: '60', label: '60' },
          { value: '72', label: '72' }
      ];
  
  
      public static data1: any = {
  
          range1: {
              "AR": {
                  Used: 16.99,
                  New: 16.99
              },
              "ME": {
                  Used: 17.99,
                  New: 17.99
              },
              "NE": {
                  Used: 17.99,
                  New: 17.99
              },
              "RI": {
                  Used: 17.99,
                  New: 17.99
              },
              "CT": {
                  Used: 18.99,
                  New: 18.99
              },
              "VT": {
                  Used: 19.99,
                  New: 19.99
              },
              "CO": {
                  Used: 20.99,
                  New: 20.99
              },
              "MA": {
                  Used: 20.99,
                  New: 20.99
              },
              "OK": {
                  Used: 20.99,
                  New: 20.99
              },
              "PA": {
                  Used: 20.99,
                  New: 20.99
              },
              "WY": {
                  Used: 20.99,
                  New: 20.99
              },
              "Other": {
                  Used: 22.99,
                  New: 22.99
              }
          },
          range2: {
              "AR": {
                  Used: 16.99,
                  New: 15.99
              },
              "ME": {
                  Used: 17.99,
                  New: 15.99
              },
              "NE": {
                  Used: 17.99,
                  New: 15.99
              },
              "RI": {
                  Used: 17.99,
                  New: 15.99
              },
              "CT": {
                  Used: 17.99,
                  New: 15.99
              },
              "VT": {
                  Used: 17.99,
                  New: 15.99
              },
              "CO": {
                  Used: 17.99,
                  New: 15.99
              },
              "MA": {
                  Used: 17.99,
                  New: 15.99
              },
              "OK": {
                  Used: 17.99,
                  New: 15.99
              },
              "PA": {
                  Used: 17.99,
                  New: 15.99
              },
              "WY": {
                  Used: 17.99,
                  New: 15.99
              },
              "Other": {
                  Used: 17.99,
                  New: 15.99
              }
  
          },
          range3: {
              "AR": {
                  Used: 16.99,
                  New: 14.99
              },
              "ME": {
                  Used: 16.99,
                  New: 14.99
              },
              "NE": {
                  Used: 16.99,
                  New: 14.99
              },
              "RI": {
                  Used: 16.99,
                  New: 14.99
              },
              "CT": {
                  Used: 16.99,
                  New: 14.99
              },
              "VT": {
                  Used: 16.99,
                  New: 14.99
              },
              "CO": {
                  Used: 16.99,
                  New: 14.99
              },
              "MA": {
                  Used: 16.99,
                  New: 14.99
              },
              "OK": {
                  Used: 16.99,
                  New: 14.99
              },
              "PA": {
                  Used: 16.99,
                  New: 14.99
              },
              "WY": {
                  Used: 16.99,
                  New: 14.99
              },
              "Other": {
                  Used: 16.99,
                  New: 14.99
              }
  
          },
          range4: {
              "AR": {
                  Used: 15.99,
                  New: 13.99
              },
              "ME": {
                  Used: 15.99,
                  New: 13.99
              },
              "NE": {
                  Used: 15.99,
                  New: 13.99
              },
              "RI": {
                  Used: 15.99,
                  New: 13.99
              },
              "CT": {
                  Used: 15.99,
                  New: 13.99
              },
              "VT": {
                  Used: 15.99,
                  New: 13.99
              },
              "CO": {
                  Used: 15.99,
                  New: 13.99
              },
              "MA": {
                  Used: 15.99,
                  New: 13.99
              },
              "OK": {
                  Used: 15.99,
                  New: 13.99
              },
              "PA": {
                  Used: 15.99,
                  New: 13.99
              },
              "WY": {
                  Used: 15.99,
                  New: 13.99
              },
              "Other": {
                  Used: 15.99,
                  New: 13.99
              }
  
          },
          range5: {
              "AR": {
                  Used: 14.99,
                  New: 12.99
              },
              "ME": {
                  Used: 14.99,
                  New: 12.99
              },
              "NE": {
                  Used: 14.99,
                  New: 12.99
              },
              "RI": {
                  Used: 14.99,
                  New: 12.99
              },
              "CT": {
                  Used: 14.99,
                  New: 12.99
              },
              "VT": {
                  Used: 14.99,
                  New: 12.99
              },
              "CO": {
                  Used: 14.99,
                  New: 12.99
              },
              "MA": {
                  Used: 14.99,
                  New: 12.99
              },
              "OK": {
                  Used: 14.99,
                  New: 12.99
              },
              "PA": {
                  Used: 14.99,
                  New: 12.99
              },
              "WY": {
                  Used: 14.99,
                  New: 12.99
              },
              "Other": {
                  Used: 14.99,
                  New: 12.99
              }
          },
          range6: {
              "AR": {
                  Used: 0,
                  New: 0
              },
              "ME": {
                  Used: 0,
                  New: 0
              },
              "NE": {
                  Used: 0,
                  New: 0
              },
              "RI": {
                  Used: 0,
                  New: 0
              },
              "CT": {
                  Used: 0,
                  New: 0
              },
              "VT": {
                  Used: 0,
                  New: 0
              },
              "CO": {
                  Used: 0,
                  New: 0
              },
              "MA": {
                  Used: 0,
                  New: 0
              },
              "OK": {
                  Used: 0,
                  New: 0
              },
              "PA": {
                  Used: 0,
                  New: 0
              },
              "WY": {
                  Used: 0,
                  New: 0
              },
              "Other": {
                  Used: 0,
                  New: 0
              }
          }
      };
  }