import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FeatureFlagService } from "../../../services/feature-flag.service";
import { EFeatureFlag } from "../../../services/feature-flags.types";
import { take } from "rxjs";

@Component({
  selector: 'app-dealer-finder-prequalified',
  templateUrl: './dealer-finder-prequalified.component.html',
  styleUrls: ['./dealer-finder-prequalified.component.scss']
})
export class DealerFinderPrequalifiedComponent implements OnInit {
  private readonly featureFlagService = inject(FeatureFlagService);

  @Input() totalCount: number;
  @Input() awsS3Disclaimer: any;
  @Input() awsS3Content: any;

  @Output() elementClicked = new EventEmitter<{
    dealerId: string;
    type: string;
  }>();

  websiteLinksAndDisclosureEnabled = false;

  ngOnInit(): void {
    this.featureFlagService.isEnabled(EFeatureFlag.WEBSITE_LINKS_AND_DISCLOSURE).pipe(
      take(1),
    ).subscribe({
      next: isEnabled => this.websiteLinksAndDisclosureEnabled = isEnabled
    });
  }

  onClickEvent(dealerId: string, type: string) {
    this.elementClicked.emit({ dealerId, type });
  }
}
