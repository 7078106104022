<nav *ngIf="links.childMenu" class="px-0">
  <ul class="mb-0 mb-md-3 list-unstyled">
    <li class= "pb-2"  id="{{sitemapChildMenu?.title}}" *ngFor="let sitemapChildMenu of links?.childMenu">
      <div *ngIf='sitemapChildMenu?.showLineSeparator' class='border-sparator'>
      </div>
      <a [ngClass]="titleClass ? titleClass : 'sitemap-links'" role="link" attr.aria-label="{{sitemapChildMenu?.ariaLabel}}"
        id="{{sitemapChildMenu?.id}}" role="button" appAnchorClick
        [linkInfo]="sitemapChildMenu"><span>{{sitemapChildMenu?.title}}</span></a>
    </li>
  </ul>
</nav>
