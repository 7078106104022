<app-header></app-header>
<div [ngClass]= "{'mob-height': isMobileViewDisplay}" class="sub-components-margin">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [bottonBgColor]="bottonBgColor"
        [overLineBgColor]="overLineBgColor" [isCurvedImage]="isCurvedImage"></cac-hero-image>
</div>
<div [ngClass]="isMobileViewDisplay ? 'mobile-container' : ''">
    <div class='components-margin'>
        <cac-content-block [data]="contentBlock" [leftToRight]="true" [isDefaultColor]="true"></cac-content-block>
    </div>
</div>
<div class="container">
    <h1 [ngClass]="isMobileViewDisplay ? 'h1-margin-mobile' : 'h1-margin'"> {{videoComponentTitle}}</h1>
</div>

<div class="container container-border" *ngIf="videoData">
    <div class="container-width">
        <iframe id="videoIframe" [src]="videoData" frameborder="0" title="videoIframe"  allowfullscreen (load)="loadIframe('videoIframe')"></iframe>
    </div>
    <div class="video-title">
        <h5 class="text-alignment-h5">{{ videoTitle}}</h5>
    </div>
    <div class="body-description">
        <p class="text-alignment-p">{{ videoDescription }}</p>
    </div>
</div>

<div [ngClass]="isMobileViewDisplay ? 'mobile-container' : 'container'">
    <div class="components-margin">
        <cac-faqs [data]="faqs"></cac-faqs>
    </div>
</div>
<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>
