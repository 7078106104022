import { cardDetails } from "app/models/cardDetails";

export const Accessories: cardDetails = {
  "title":["Dealer-installed accessories can help drive your vehicle sales."],
  "id":"dealer_accessories_title",
  "sectionName":"Delar Accessories Section",
  "description": [
    "Offering and financing dealer-installed accessories are great ways to give customers the option to add valuable products that will increase their satisfaction with the vehicle and help you sell more cars. Optional dealer-installed accessories may include:"
  ],
  "points": [
    'Window tint',
    'Running boards',
    'Roof rack',
    'Trailer tow package'
  ],
  "buttonName": "login to caps",
  "imgUrl":"assets/images/grow-your-dealership/users-toggle/ways-business-grow/img_accessories-img@2x.png",
  "imgAlt":"Dealers Accessories Image",
  "ariaLabel":"Dealer Tab Section",
  "buttonId": "dealer_accessories_caps_link",
  "link":"/dealers/caps-redirect"
}