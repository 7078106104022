export const SEO_JSON_URL = "/www/seo/seo.json"
export const CONTACT_US_JSON_URL: string = "/www/contact-us/data/contactUs.json";
export const MAKE_PAYMENT_JSON_URL: string = "/www/make-a-payment/data/makePayment.json";
export const GUEST_PAYMENT_JSON_URL: string = "/www/guest-payment/data/guestPayment.json";
export const DEALER_REVIEWS_JSON_URL: string = "/www/dealer-reviews/data/dealerReviews.json";
export const DEALER_BLOGS_JSON_URL: string = "/www/dealer-blogs/data/dealerBlogs.json";
export const DEALER_FAQS_JSON_URL: string = "/www/dealer-faqs/data/dealerFaqs.json";
export const CUSTOMER_REVIEWS_JSON_URL: string = "/www/customer-reviews/data/customerReviews.json";
export const CUSTOMER_BLOGS_JSON_URL: string = "/www/customer-blogs/data/customerBlogs.json";
export const CUSTOMER_EDUCATION_JSON_URL: string = "/www/customer-career/data/customerEducation.json";
export const ESG_JSON_URL: string = "/www/esg/data/esg.json";
export const ABOUT_US_JSON_URL: string = "/www/about-us/data/aboutUs.json";
export const LICENSE_TO_DRIVE_JSON_URL: string = "/www/license-to-drive/data/licenseToDrive.json";
export const DEALER_EDUCATION_JSON_URL: string = "/www/dealer-education/data/dealerEducation.json";
export const CUSTOMER_FAQS_JSON_URL: string = "/www/customer-faqs/data/customerFaqs.json";
export const CAREERS_CORPORATE_LEGAL_JSON_URL: string = "/www/careers-corporate-legal/data/careersCorporateLegal.json";
export const CAREERS_SALES_JSON_URL: string = "/www/careers-sales/data/careersSales.json";
export const CAREERS_CALL_CENTER_JSON_URL: string = "/www/careers-call-center/data/careersCallCenter.json";
export const CAREERS_FINANCE_JSON_URL: string = "/www/careers-finance/data/careersFinance.json";
export const CAREERS_ENGINEERING_JSON_URL: string = "/www/careers-engineering/data/careersEngineering.json";
export const CAREERS_MARKETING_JSON_URL: string = "/www/careers-marketing/data/careersMarketing.json";
export const CAREERS_PRODUCT_JSON_URL: string = "/www/careers-product/data/careersProduct.json";
export const CAREERS_LANDING_JSON_URL: string = "/www/careers-landing/data/careersLanding.json";
export const CAREERS_HR_JSON_URL: string = "/www/careers-hr/data/careersHr.json";
export const CAREERS_ANALYTICS_JSON_URL: string = "/www/careers-analytics/data/careersAnalytics.json";
export const CAREERS_STRATEGIC_JSON_URL: string = "/www/careers-strategic/data/careersStrategic.json";
export const CUSTOMERS_HOME_JSON_URL: string = "/www/customers/data/customersHome.json";
export const DEALER_HOW_IT_WORKS: string = "/www/dealer-how-it-works/data/dealerHowItWorks.json";
export const CUSTOMER_HOW_IT_WORKS: string = "/www/customer-how-it-works/data/customerHowItWorks.json";
export const START_MY_JOURNEY_JSON_URL : string = "/www/start-my-journey/data/startMyJourney.json";
export const DEALER_HOME_JSON_URL : string = "/www/dealers/data/dealersHome.json";
export const YOUR_CREDIT_APPROVAL_JSON_URL : string = "/www/your-credit-approval/data/yourCreditApproval.json";
export const DEALERS_PURCHASE_PROGRAM_JSON_URL : string = "/www/dealers-purchase-program/data/dealerPurchaseProgram.json";
export const SEARCH_JSON_URL : string = "/www/search/data/search.json";
export const PAGE_NOT_FOUND_JSON_URL : string = "/www/page-not-found/data/pageNotFound.json";
export const MAINTENANCE_JSON_URL : string = "/www/maintenance/data/maintenance.json";
export const DEALER_ESIGNATURE_JSON_URL : string = "/www/dealer-esignature/data/dealerEsignature.json";
export const TERMS_OF_USE_JSON_URL : string = "/www/terms-of-use/data/termsOfUse.json";
export const NYC_LANGUAGE_JSON_URL : string = "/www/nyc-language/data/nycLanguage.json";
export const WEB_ACCESSIBILITY_JSON_URL : string = "/www/web-accessibility/data/webAccessibility.json";
export const PRIVACY_JSON_URL : string = "/www/privacy/data/privacy.json";
export const WEBSITE_USER_AGREEMENT_JSON_URL  : string = "/www/website-agreement/data/websiteAgreement.json";
export const PORTFOLIO_LANDING_50_JSON_URL : string = "/www/portfolio-landing/data/portfolioLanding.json";
export const PORTFOLIO_LANDING_100_JSON_URL : string = "/www/portfolio-landing/data/portfolioLanding100.json";
export const GLOSSARY_JSON_URL : string = "/www/glossary/data/glossary.json";
export const COMMUNITY_JSON_URL = "/www/community/data/community.json";
export const CAR_BUYERS_RESOURCE_JSON_URL = "/www/car-buyers-resource/data/carBuyersResource.json";
export const ONLINE_VERIFICATION__JSON_URL = "/www/online-verification/data/onlineVerification.json";
export const JOSHUA_MOTORS_JSON_URL = "/www/joshua-motors/data/joshuaMotors.json";
export const STEVE_LANDERS_JSON_URL = "/www/lander-auto-sales/data/landerAutoSales.json";
export const MAGIC_CITY_MOTORS_JSON_URL = "/www/magic-city-motors/data/magicCityMotors.json";
export const BELL_AUTO_JSON_URL = "/www/bell-auto-sales/data/bellAutoSales.json";
export const AUTO_MAX_JSON_URL = "/www/automax/data/automax.json";
export const ZEIGLER_AUTO_JSON_URL:string = "/www/zeigler-auto/data/zeiglerAuto.json";
export const WEBSMART_AUTO_JSON_URL:string = "/www/websmart-auto/data/websmartAuto.json";
export const RIDE_RITE_AUTO_JSON_URL:string = "/www/ride-rite-auto/data/rideRiteAuto.json";
export const TWINS_AUTO_SALES_JSON_URL:string = "/www/twins-auto-sales/data/twinsAutoSales.json";
export const CONSUMER_VIDEOS_JSON_URL: string = "/www/consumer-videos/data/consumerVideos.json";
export const DEALER_VIDEOS_JSON_URL: string = "/www/dealer-videos/data/dealerVideos.json";
export const LONZELL_WEBB_JSON_URL = "/www/lonzell-webb/data/lonzellWebb.json";
export const REELS_AUTO_SALES_JSON_URL = "/www/reels-auto-sales/data/reelsAutoSales.json";
export const PREMIER_AUTOMOTIVE_JSON_URL = "/www/premier-automotive/data/premierAutomotive.json";
export const MOORE_STEWART_FORD_JSON_URL = "/www/moore-stewart-ford/data/mooreStewartFord.json";
export const JOIN_OUR_NETWORK_JSON_URL : string = "/www/join-network/data/joinNetworkKeyPoints.json";
export const JOIN_NETWORK_THANK_YOU_PAGE_JSON_URL : string = "/www/join-network-thank-you/data/joinNetworkThankYou.json";
export const AUTH_EXCEPTION_JSON_URL: string = "/www/auth-exception/data/authException.json";
export const COVID_RESPONSE_JSON_URL: string = "/www/covid-response/data/covidResponse.json";
export const CAPS_MAINTENANCE_JSON_URL : string = "/www/caps-maintenance/data/capsMaintenance.json";
export const CAPS_REDIRECT_JSON_URL : string = "/www/caps-redirect/data/capsRedirect.json";
export const AUTO_SALES_BLOG_JSON_URL : string = "/www/blogs/auto-sales/data/autoSales.json";
export const IMPROVE_CAR_SALES_BLOG_JSON_URL : string = "/www/blogs/improve-car-sales/data/improveCarSales.json";
export const RECONDITIONING_PROCESS_BLOG_JSON_URL : string = "/www/blogs/reconditioning-process/data/reconditioningProcess.json";
export const AUTOMOTIVE_CRM_BLOG_JSON_URL : string = "/www/blogs/automotive-crm/data/automotiveCrm.json";
export const BOOST_AUTO_SALES_BLOG_JSON_URL : string = "/www/blogs/boost-auto-sales/data/boostAutoSales.json";
export const FACTORS_TO_CONSIDER_BLOG_JSON_URL : string = "/www/blogs/factors-to-consider/data/factorsToConsider.json";
export const FIRST_TIME_DRIVERS_BLOG_JSON_URL : string = "/www/blogs/first-time-drivers/data/firstTimeDrivers.json";
export const CREDIT_SCORE_MYTHS_BLOG_JSON_URL : string = "/www/blogs/credit-score-myths/data/creditScoreMyths.json";
export const CAR_BUYING_MISTAKES_BLOG_JSON_URL : string = "/www/blogs/car-buying-mistakes/data/carBuyingMistakes.json";
export const QUESTIONS_TO_ASK_WHEN_BUYING_BLOG_JSON_URL : string = "/www/blogs/questions-to-ask-when-buying/data/questionsToAskWhenBuying.json";
export const BUILD_CREDIT_HISTORY_BLOG_JSON_URL : string = "/www/blogs/build-credit-history/data/buildCreditHistory.json";
export const THINGS_TO_KNOW_BLOG_JSON_URL : string = "/www/blogs/things-to-know/data/thingsToKnow.json";
export const ADVANTAGES_OF_HYBRID_CARS_BLOG_JSON_URL : string = "/www/blogs/advantages-of-hybrid-cars/data/advantagesOfHybridCars.json";
export const UNDERSTANDING_SIMPLE_INTREST_BLOG_JSON_URL : string = "/www/blogs/understanding-simple-intrest-car-financing/data/understandingSimpleIntrestCarFinancing.json";
export const BIG_CHECK_CAMPAIGN_JSON_URL = "/www/big-check-campaign/data/bigCheckCampaignResource.json";
export const BUY_HERE_PAY_HERE_CAMPAIGN_JSON_URL = "/www/buy-here-pay-here-campaign/data/buyHerePayHereCampaignResource.json";
export const DEALER_FINDER_JSON_URL = "/www/dealer-finder/data/dealerFinder.json";
export const CONSUMER_PAID_CHANNEL_LANDING_JSON_URL = "/www/consumer-paid-channel-landing/data/consumerPaidChannelLanding.json";

export const LEGACY_3_EFFECTIVE_WAYS_TO_IMPROVE_YOUR_CAR_SALES_URL : string = "/www/blogs/legacy/data/3-effective-ways-to-improve-your-car-sales.json";

export const WHY_CHOOSE_AN_ENROLLED_DEALER_JSON_URL : string = "/www/blogs/legacy/data/why-choose-an-enrolled-dealer.json";
export const GET_APPROVED_FOR_NEXT_CAR_JSON_URL : string = "/www/blogs/legacy/data/get-approved-for-next-car.json";
export const CAR_GIFT_IDEAS_JSON_URL : string = "/www/blogs/legacy/data/car-gift-ideas.json";
export const FOODS_IN_CAR_WITHOUT_MESS_JSON_URL : string = "/www/blogs/legacy/data/foods-in-car-without-mess.json";
export const SIX_ROAD_TRIP_ROUTES_FOR_THE_SUMMER_JSON_URL : string = "/www/blogs/legacy/data/6-road-trip-routes-for-the-summer.json";
export const CAR_FINANCING_TIPS_JSON_URL : string = "/www/blogs/legacy/data/car-financing-tips.json";
export const BEST_TIMES_OF_THE_YEAR_TO_PURCHASE_A_VEHICLE_JSON_URL : string = "/www/blogs/legacy/data/best-times-of-the-year-to-purchase-a-vehicle.json";
export const BUYING_A_CAR_OUT_OF_STATE_WHAT_TO_KNOW_JSON_URL : string = "/www/blogs/legacy/data/buying-a-car-out-of-state-what-to-know.json";
export const BUYING_A_CAR_WITH_BAD_CREDIT_OR_NO_CREDIT_JSON_URL : string = "/www/blogs/legacy/data/buying-a-car-with-bad-credit-or-no-credit.json";
export const BUYING_A_CAR_AFTER_BANKRUPTCY_JSON_URL : string = "/www/blogs/legacy/data/buying-a-car-after-bankruptcy.json";
export const BUYING_CAR_WITH_MULTIPLE_REPOSSESSIONS_JSON_URL : string = "/www/blogs/legacy/data/buying-car-with-multiple-repossessions.json";
export const BUYING_CAR_NO_DOWN_PAYMENT_JSON_URL : string = "/www/blogs/legacy/data/buying-car-no-down-payment.json";
export const CAR_BUYING_DOS_AND_DONTS_JSON_URL : string = "/www/blogs/legacy/data/car-buying-dos-and-donts.json";
export const CHECKING_HISTORY_REPORT_ON_A_USED_CAR_JSON_URL : string = "/www/blogs/legacy/data/checking-history-report-on-a-used-car.json";
export const COSIGNER_JSON_URL : string = "/www/blogs/legacy/data/cosigner.json";
export const CAR_LOAN_PROOF_OF_INCOME_JSON_URL : string = "/www/blogs/legacy/data/car-loan-proof-of-income.json";
export const DO_TAXES_AFFECT_YOUR_CREDIT_SCORE_JSON_URL : string = "/www/blogs/legacy/data/do-taxes-affect-your-credit-score.json";
export const GAS_VS_ELECTRIC_CAR_FACTS_JSON_URL : string = "/www/blogs/legacy/data/gas-vs-electric-car-facts.json";
export const GOOD_CREDIT_HABITS_JSON_URL : string = "/www/blogs/legacy/data/good-credit-habits.json";
export const CAN_YOU_FINANCE_VEHICLE_WITH_NO_HARD_INQUIRY_JSON_URL : string = "/www/blogs/legacy/data/can-you-finance-vehicle-with-no-hard-inquiry.json";
export const HOW_TO_FIX_RUST_ON_CAR_JSON_URL : string = "/www/blogs/legacy/data/how-to-fix-rust-on-car.json";
export const HOW_TO_CHECK_CREDIT_SCORE_JSON_URL : string = "/www/blogs/legacy/data/how-to-check-credit-score.json";
export const DOES_CAR_INSURANCE_GO_UP_AFTER_ACCIDENT_JSON_URL : string = "/www/blogs/legacy/data/does-car-insurance-go-up-after-accident.json";
export const HOW_MUCH_TO_SPEND_ON_A_CAR_JSON_URL : string = "/www/blogs/legacy/data/how-much-to-spend-on-a-car.json";
export const HOW_OFTEN_SHOULD_YOU_CHANGE_YOUR_OIL_JSON_URL : string = "/www/blogs/legacy/data/how-often-should-you-change-your-oil.json";
export const HOW_OFTEN_SHOULD_YOU_GET_A_CAR_WASH_JSON_URL : string = "/www/blogs/legacy/data/how-often-should-you-get-a-car-wash.json";
export const HOW_OFTEN_SHOULD_YOU_GET_YOUR_CAR_DETAILED_JSON_URL : string = "/www/blogs/legacy/data/how-often-should-you-get-your-car-detailed.json";
export const HOW_OFTEN_SHOULD_YOU_GET_YOUR_TIRES_ROTATED_JSON_URL : string = "/www/blogs/legacy/data/how-often-should-you-get-your-tires-rotated.json";
export const CREDIT_FRAUD_DETECTION_JSON_URL : string = "/www/blogs/legacy/data/credit-fraud-detection.json";
export const HOW_TO_BUILD_YOUR_CREDIT_HISTORY_AFTER_COLLEGE_JSON_URL : string = "/www/blogs/legacy/data/how-to-build-your-credit-history-after-college.json";
export const HOW_TO_CALCULATE_MPG_JSON_URL : string = "/www/blogs/legacy/data/how-to-calculate-mpg.json";
export const HOW_TO_CHANGE_A_FLAT_TIRE_JSON_URL : string = "/www/blogs/legacy/data/how-to-change-a-flat-tire.json";
export const HOW_TO_CLEAN_ENGINE_BAY_JSON_URL : string = "/www/blogs/legacy/data/how-to-clean-engine-bay.json";
export const HOW_TO_DETERMINE_YOUR_CAR_BUDGET_JSON_URL : string = "/www/blogs/legacy/data/how-to-determine-your-car-budget.json";
export const WHAT_CARS_CAN_I_AFFORD_JSON_URL : string = "/www/blogs/legacy/data/what-cars-can-I-afford.json";
export const ESTIMATING_CAR_INSURANCE_JSON_URL : string = "/www/blogs/legacy/data/estimating-car-insurance.json";
export const USED_CAR_FINANCING_JSON_URL : string = "/www/blogs/legacy/data/used-car-financing.json";
export const BUDGETING_AND_CREDIT_SCORE_JSON_URL : string = "/www/blogs/legacy/data/budgeting-and-credit-score.json";
export const HOW_TO_IMPROVE_YOUR_CREDIT_ESTABLISHING_CREDIT_HISTORY_JSON_URL : string = "/www/blogs/legacy/data/how-to-improve-your-credit-establishing-credit-history.json";
export const HOW_TO_IMPROVE_YOUR_CREDIT_FIXING_ERRORS_ON_YOUR_CREDIT_REPORT_JSON_URL : string = "/www/blogs/legacy/data/how-to-improve-your-credit-fixing-errors-on-your-credit-report.json";
export const HOW_TO_IMPROVE_YOUR_CREDIT_PAYING_DOWN_DEBT_JSON_URL : string = "/www/blogs/legacy/data/how-to-improve-your-credit-paying-down-debt.json";
export const HOW_TO_INCREASE_YOUR_CHANCES_OF_GETTING_APPROVED_FOR_AUTO_FINANCING_JSON_URL : string = "/www/blogs/legacy/data/how-to-increase-your-chances-of-getting-approved-for-auto-financing.json";
export const PROTECTING_YOUR_CAR_FROM_SUN_JSON_URL : string = "/www/blogs/legacy/data/protecting-your-car-from-sun.json";
export const PREVENTING_FROST_ON_WINDSHIELD_JSON_URL : string = "/www/blogs/legacy/data/preventing-frost-on-windshield.json";
export const WHAT_TO_BRING_WHEN_TAILGATING_JSON_URL : string = "/www/blogs/legacy/data/what-to-bring-when-tailgating.json";
export const HOW_TO_NARROW_DOWN_CAR_CHOICES_JSON_URL : string = "/www/blogs/legacy/data/how-to-narrow-down-car-choices.json";
export const HOW_TO_PARALLEL_PARK_JSON_URL : string = "/www/blogs/legacy/data/how-to-parallel-park.json";
export const HOW_TO_PAY_OFF_YOUR_CAR_FASTER_JSON_URL : string = "/www/blogs/legacy/data/how-to-pay-off-your-car-faster.json";
export const PREPARING_CARS_FOR_WINTER_JSON_URL : string = "/www/blogs/legacy/data/preparing-cars-for-winter.json";
export const HOW_TO_PROTECT_YOUR_CAR_FROM_GETTING_STOLEN_JSON_URL : string = "/www/blogs/legacy/data/how-to-protect-your-car-from-getting-stolen.json";
export const HOW_TO_REBUILD_YOUR_CREDIT_AFTER_BANKRUPTCY_JSON_URL : string = "/www/blogs/legacy/data/how-to-rebuild-your-credit-after-bankruptcy.json";
export const HOW_TO_REBUILD_YOUR_CREDIT_AFTER_REPOSSESSION_JSON_URL : string = "/www/blogs/legacy/data/how-to-rebuild-your-credit-after-repossession.json";
export const HOW_TO_MAINTAIN_A_CAR_JSON_URL : string = "/www/blogs/legacy/data/how-to-maintain-a-car.json";
export const HOW_TO_REGISTER_YOUR_VEHICLE_JSON_URL : string = "/www/blogs/legacy/data/how-to-register-your-vehicle.json";
export const REMOVE_DENT_FROM_CAR_JSON_URL : string = "/www/blogs/legacy/data/remove-dent-from-car.json";
export const HOW_TO_SET_REALISTIC_FINANCIAL_GOALS_THAT_MAY_HELP_IMPROVE_YOUR_CREDIT_JSON_URL : string = "/www/blogs/legacy/data/how-to-set-realistic-financial-goals-that-may-help-improve-your-credit.json";
export const HOW_TO_TRADE_IN_YOUR_CAR_JSON_URL : string = "/www/blogs/legacy/data/how-to-trade-in-your-car.json";
export const IS_NOW_THE_RIGHT_TIME_FOR_YOU_TO_BUY_A_CAR_JSON_URL : string = "/www/blogs/legacy/data/is-now-the-right-time-for-you-to-buy-a-car.json";
export const KEY_INFORMATION_YOU_LL_FIND_IN_YOUR_CAR_MANUAL_JSON_URL : string = "/www/blogs/legacy/data/key-information-you-ll-find-in-your-car-manual.json";
export const LONGEST_LASTING_VEHICLES_JSON_URL : string = "/www/blogs/legacy/data/longest-lasting-vehicles.json";
export const MAINTAIN_OR_TRADE_EVALUATING_THE_COST_OF_REPAIRING_VS_TRADING_IN_YOUR_CAR_JSON_URL : string = "/www/blogs/legacy/data/maintain-or-trade-evaluating-the-cost-of-repairing-vs-trading-in-your-car.json";
export const ONLINE_CAR_SHOPPING_JSON_URL : string = "/www/blogs/legacy/data/online-car-shopping.json";
export const REBUILDING_YOUR_CREDIT_WITH_BAD_CREDIT_HISTORY_JSON_URL : string = "/www/blogs/legacy/data/rebuilding-your-credit-with-bad-credit-history.json";
export const SHOULD_I_BUY_A_NEW_OR_USED_CAR_JSON_URL : string = "/www/blogs/legacy/data/should-i-buy-a-new-or-used-car.json";
export const SHOULD_YOU_CHECK_YOUR_CREDIT_SCORE_BEFORE_PURCHASING_A_VEHICLE__JSON_URL : string = "/www/blogs/legacy/data/should-you-check-your-credit-score-before-purchasing-a-vehicle-.json";
export const SIGNS_YOU_RE_FALLING_INTO_A_BAD_CREDIT_CYCLE_JSON_URL : string = "/www/blogs/legacy/data/signs-you-re-falling-into-a-bad-credit-cycle.json";
export const WEIRD_CARS_JSON_URL : string = "/www/blogs/legacy/data/weird-cars.json";
export const HOW_CREDIT_WORKS_JSON_URL : string = "/www/blogs/legacy/data/how-credit-works.json";
export const THE_DIFFERENCE_BETWEEN_DIRECT_AND_INDIRECT_AUTO_FINANCING_JSON_URL : string = "/www/blogs/legacy/data/the-difference-between-direct-and-indirect-auto-financing.json";
export const THE_DIFFERENCE_BETWEEN_LEASING_AND_AUTO_FINANCI_1_JSON_URL : string = "/www/blogs/legacy/data/the-difference-between-leasing-and-auto-financi-1.json";
export const WINNING_A_CAR_AND_TAXES_JSON_URL : string = "/www/blogs/legacy/data/winning-a-car-and-taxes.json";
export const THE_RIGHT_CAR_FOR_YOUR_LIFESTY_JSON_URL : string = "/www/blogs/legacy/data/the-right-car-for-your-lifesty.json";
export const THE_ULTIMATE_GUIDE_TO_BUY_A_CAR_AND_IMPROVE_YOUR_CREDIT_JSON_URL : string = "/www/blogs/legacy/data/the-ultimate-guide-to-buy-a-car-and-improve-your-credit.json";
export const THINKING_OF_BUYING_A_NEW_OR_USED_CAR_LET_S_WEIGH_YOUR_OPTIONS_JSON_URL : string = "/www/blogs/legacy/data/thinking-of-buying-a-new-or-used-car-let-s-weigh-your-options.json";
export const TOP_CAR_SAFETY_FEATURES_FOR_TEEN_DRIVERS_JSON_URL : string = "/www/blogs/legacy/data/top-car-safety-features-for-teen-drivers.json";
export const DOG_FRIENDLY_CARS_JSON_URL : string = "/www/blogs/legacy/data/dog-friendly-cars.json";
export const TOP_ECO_FRIENDLY_CARS_JSON_URL : string = "/www/blogs/legacy/data/top-eco-friendly-cars.json";
export const TOP_FAMILY_CARS_AND_SUVS_JSON_URL : string = "/www/blogs/legacy/data/top-family-cars-and-suvs.json";
export const TRUNK_OR_TREAT_IDEAS_JSON_URL : string = "/www/blogs/legacy/data/trunk-or-treat-ideas.json";
export const UNDERSTANDING_YOUR_CAR_PAYMENT_INTEREST_BEARING_JSON_URL : string = "/www/blogs/legacy/data/understanding-your-car-payment-interest-bearing.json";
export const UNDERSTANDING_YOUR_CAR_PAYMENT_PRECOMPUTED_JSON_URL : string = "/www/blogs/legacy/data/understanding-your-car-payment-precomputed.json";
export const CAR_BUYING_RESOURCES_JSON_URL : string = "/www/blogs/legacy/data/car-buying-resources.json";
export const GOOD_FIRST_TIME_CARSS_JSON_URL : string = "/www/blogs/legacy/data/good-first-time-carss.json";
export const WHAT_DOCUMENTS_DO_I_NEED_TO_PURCHASE_A_VEHICLE_JSON_URL : string = "/www/blogs/legacy/data/what-documents-do-i-need-to-purchase-a-vehicle.json";
export const WHAT_FACTORS_AFFECT_MY_CAR_PAYMENTS_JSON_URL : string = "/www/blogs/legacy/data/what-factors-affect-my-car-payments.json";
export const WHAT_HAPPENS_IF_YOU_CRASH_A_CAR_DURING_A_TEST_DRIVE_JSON_URL : string = "/www/blogs/legacy/data/what-happens-if-you-crash-a-car-during-a-test-drive.json";
export const DIFFERENCE_BETWEEN_CREDIT_REPORT_AND_CREDIT_SCORE_JSON_URL : string = "/www/blogs/legacy/data/difference-between-credit-report-and-credit-score.json";
export const LENGTH_OF_EMPLOYMENT_FOR_CAR_FINANCING_JSON_URL : string = "/www/blogs/legacy/data/length-of-employment-for-car-financing.json";
export const DENIED_AUTO_FINANCING_WHAT_TO_DO_JSON_URL : string = "/www/blogs/legacy/data/denied-auto-financing-what-to-do.json";
export const ALL_WHEEL_OR_FRONT_WHEEL_DRIVE_JSON_URL : string = "/www/blogs/legacy/data/all-wheel-or-front-wheel-drive.json";
export const DIFFERENCE_BETWEEN_CAR_LOAN_AND_FINANCING_JSON_URL : string = "/www/blogs/legacy/data/difference-between-car-loan-and-financing.json";
export const WHAT_TO_KNOW_BEFORE_BUYING_A_CAR_JSON_URL : string = "/www/blogs/legacy/data/what-to-know-before-buying-a-car.json";
export const WHAT_IS_A_CAR_TITLE_JSON_URL : string = "/www/blogs/legacy/data/what-is-a-car-title.json";
export const HOW_TO_CHECK_MILEAGE_OF_A_CAR_JSON_URL : string = "/www/blogs/legacy/data/how-to-check-mileage-of-a-car.json";
export const CREDIT_HISTORY_JSON_URL : string = "/www/blogs/legacy/data/credit-history.json";
export const SELF_DRIVING_CARS_JSON_URL : string = "/www/blogs/legacy/data/self-driving-cars.json";
export const THREE_EFFECTIVE_WAYS_TO_IMPROVE_YOUR_CAR_SALES_JSON_URL : string = "/www/blogs/legacy/data/3-effective-ways-to-improve-your-car-sales.json";
export const THREE_WAYS_TO_IMPROVE_YOUR_CAR_DEALERSHIP_S_CASH_FLOW_JSON_URL : string = "/www/blogs/legacy/data/3-ways-to-improve-your-car-dealership-s-cash-flow.json";
export const FIVE_KEYS_TO_HAVING_A_SUCCESSFUL_INVENTORY_RECONDITIONING_PROCESS_JSON_URL : string = "/www/blogs/legacy/data/5-keys-to-having-a-successful-inventory-reconditioning-process.json";
export const BENEFITS_OF_HAVING_AN_AUTOMOTIVE_CRM_SYSTEM_AT_YOUR_DEALERSHIP_JSON_URL : string = "/www/blogs/legacy/data/benefits-of-having-an-automotive-crm-system-at-your-dealership.json";
export const BENEFITS_OF_WORKING_WITH_A_SUBPRIME_FINANCE_COMPANY_JSON_URL : string = "/www/blogs/legacy/data/benefits-of-working-with-a-subprime-finance-company.json";
export const CAR_BUYING_TRENDS_FOR_GEN_Z_JSON_URL : string = "/www/blogs/legacy/data/car-buying-trends-for-gen-z.json";
export const CAR_BUYING_TRENDS_FOR_MILLENNIALS_1_JSON_URL : string = "/www/blogs/legacy/data/car-buying-trends-for-millennials-1.json";
export const CAR_BUYING_TRENDS_FOR_RECENT_COLLEGE_GRADS_AND_STUDENTS_JSON_URL : string = "/www/blogs/legacy/data/car-buying-trends-for-recent-college-grads-and-students.json";
export const CAR_SELLING_101_HOW_TO_MAINTAIN_REPEAT_AND_REFERRAL_BUSINESS_FOR_YOUR_DEALERSH_1_JSON_URL : string = "/www/blogs/legacy/data/car-selling-101-how-to-maintain-repeat-and-referral-business-for-your-dealersh-1.json";
export const CREATIVE_WAYS_TO_BUILD_BRAND_AWARENESS_FOR_YOUR_DEALERSHIP_JSON_URL : string = "/www/blogs/legacy/data/creative-ways-to-build-brand-awareness-for-your-dealership.json";
export const DIFFERENT_TRENDS_OF_MALE_AND_FEMALE_CAR_BUYERS_JSON_URL : string = "/www/blogs/legacy/data/different-trends-of-male-and-female-car-buyers.json";
export const HOW_CAR_DEALERS_CAN_APPROVE_CUSTOMERS_WITH_BAD_CREDIT_OR_NO_CREDIT_JSON_URL : string = "/www/blogs/legacy/data/how-car-dealers-can-approve-customers-with-bad-credit-or-no-credit.json";
export const HOW_CAR_DEALERSHIPS_CAN_APPROVE_CAR_BUYERS_WITH_MULTIPLE_OPEN_AUTOS_OR_REPOSSESSIONS_JSON_URL : string = "/www/blogs/legacy/data/how-car-dealerships-can-approve-car-buyers-with-multiple-open-autos-or-repossessions.json";
export const HOW_DEALERSHIPS_CAN_APPROVE_CAR_BUYERS_WITH_BANKRUPTCY_OR_FORECLOSURE_JSON_URL : string = "/www/blogs/legacy/data/how-dealerships-can-approve-car-buyers-with-bankruptcy-or-foreclosure.json";
export const HOW_DEALERSHIPS_CAN_APPROVE_CAR_BUYERS_WITH_UNEMPLOYMENT_OR_FIXED_INCOME_JSON_URL : string = "/www/blogs/legacy/data/how-dealerships-can-approve-car-buyers-with-unemployment-or-fixed-income.json";
export const HOW_DOES_THE_CREDIT_ACCEPTANCE_PROGRAM_WORK__JSON_URL : string = "/www/blogs/legacy/data/how-does-the-credit-acceptance-program-work-.json";
export const HOW_OFFERING_SUBPRIME_AUTO_FINANCING_CAN_BENEFIT_BUY_HERE_PAY_HERE_DEALERS_JSON_URL : string = "/www/blogs/legacy/data/how-offering-subprime-auto-financing-can-benefit-buy-here-pay-here-dealers.json";
export const HOW_THE_CREDIT_ACCEPTANCE_PORTFOLIO_PROGRAM_CAN_HELP_YOUR_BHPH_DEALERSHIP_JSON_URL : string = "/www/blogs/legacy/data/how-the-credit-acceptance-portfolio-program-can-help-your-bhph-dealership.json";
export const HOW_THE_CREDIT_ACCEPTANCE_PORTFOLIO_PROGRAM_CAN_HELP_YOUR_FRANCHISE_DEALERSHIP_JSON_URL : string = "/www/blogs/legacy/data/how-the-credit-acceptance-portfolio-program-can-help-your-franchise-dealership.json";
export const HOW_THE_CREDIT_ACCEPTANCE_PORTFOLIO_PROGRAM_CAN_HELP_YOUR_INDEPENDENT_DEALERSHIP_JSON_URL : string = "/www/blogs/legacy/data/how-the-credit-acceptance-portfolio-program-can-help-your-independent-dealership.json";
export const HOW_TO_EQUIP_YOUR_CAR_DEALERSHIP_TO_MAINTAIN_COMPLIANCE_JSON_URL : string = "/www/blogs/legacy/data/how-to-equip-your-car-dealership-to-maintain-compliance.json";
export const HOW_TO_HELP_CAR_BUYERS_WITH_AUTO_FINANCING_JSON_URL : string = "/www/blogs/legacy/data/how-to-help-car-buyers-with-auto-financing.json";
export const HOW_TO_SECURE_FINANCING_OPTIONS_FOR_YOUR_CAR_DEALERSHIP_JSON_URL : string = "/www/blogs/legacy/data/how-to-secure-financing-options-for-your-car-dealership.json";
export const IMPROVING_CUSTOMER_RETENTION_AT_YOUR_DEALERSHIP_JSON_URL : string = "/www/blogs/legacy/data/improving-customer-retention-at-your-dealership.json";
export const INCREASING_USED_CAR_SALES_AT_YOUR_FRANCHISE_DEALERSHIP_JSON_URL : string = "/www/blogs/legacy/data/increasing-used-car-sales-at-your-franchise-dealership.json";
export const LISTENING_TO_UNDERSTAND_YOUR_CUSTOMERS_VS_LISTENING_TO_SELL_JSON_URL : string = "/www/blogs/legacy/data/listening-to-understand-your-customers-vs-listening-to-sell.json";
export const PREPARING_YOUR_CAR_DEALERSHIP_FOR_TAX_SEASON_IN_3_SIMPLE_STE_1_JSON_URL : string = "/www/blogs/legacy/data/preparing-your-car-dealership-for-tax-season-in-3-simple-ste-1.json";
export const PREPARING_YOUR_CAR_DEALERSHIP_FOR_TAX_SEASON_IN_3_SIMPLE_STEPS_JSON_URL : string = "/www/blogs/legacy/data/preparing-your-car-dealership-for-tax-season-in-3-simple-steps.json";
export const QUESTIONS_TO_ASK_CUSTOMERS_LOOKING_TO_BUY_A_CAR_JSON_URL : string = "/www/blogs/legacy/data/questions-to-ask-customers-looking-to-buy-a-car.json";
export const TOP_5_WAYS_YOUR_CAR_DEALERSHIP_CAN_GENERATE_LEADS_JSON_URL : string = "/www/blogs/legacy/data/top-5-ways-your-car-dealership-can-generate-leads.json";
export const WHAT_BHPH_DEALERS_SHOULD_KNOW_ABOUT_SUBPRIME_FINANCING_JSON_URL : string = "/www/blogs/legacy/data/what-bhph-dealers-should-know-about-subprime-financing.json";
export const WHY_LEAD_GENERATION_IS_IMPORTANT_FOR_YOUR_CAR_DEALERSHIP_JSON_URL : string = "/www/blogs/legacy/data/why-lead-generation-is-important-for-your-car-dealership.json";
export const WHY_YOUR_DEALERSHIP_WEBSITE_SHOULD_BE_MOBILE_FRIENDLY_JSON_URL : string = "/www/blogs/legacy/data/why-your-dealership-website-should-be-mobile-friendly.json";
export const WORKING_WITH_FIRST_TIME_CAR_BUYERS_WITH_NO_CREDIT_JSON_URL : string = "/www/blogs/legacy/data/working-with-first-time-car-buyers-with-no-credit.json";
export const WORKING_WITH_SELF_EMPLOYED_CAR_BUYERS_JSON_URL : string = "/www/blogs/legacy/data/working-with-self-employed-car-buyers.json";
export const CUSTOMER_CENTRIC_BLOG_JSON_URL : string = "/www/blogs/customer-centric/data/customerCentric.json"
export const CAR_INSURANCE_JSON_URL : string = "/www/blogs/legacy/data/car-insurance.json";
export const FINANCING_CAR_HELP_REBUILD_CREDIT_JSON_URL : string = "/www/blogs/legacy/data/financing-car-can-help-rebuild-credit.json";
export const HOW_IT_WORKS_GETTING_FINANCED_THROUGH_A_DEALER_ENROLLED_WITH_CREDITACCEPTANCE_JSON_URL : string = "/www/blogs/legacy/data/how-it-works-getting-financed-through-a-dealer-enrolled-with-credit-acceptance.json";
export const POSITIVE_TEAM_ATMOSPHERE_JSON_URL : string = "/www/blogs/legacy/data/positive-team-atmosphere.json";
export const SUBPRIME_CAR_FINANCING_JSON_URL : string = "/www/blogs/legacy/data/subprime-car-financing.json";
export const TRAIN_CAR_DEALERSHIP_STAFF_JSON_URL : string = "/www/blogs/legacy/data/train-car-dealership-staff.json";
export const WHAT_TO_KNOW_JSON_URL : string = "/www/blogs/legacy/data/what-to-know.json";
export const NO_SSN_NO_ITIN_CAMPAIGN_JSON_URL : string = "/www/no-ssn-no-itin-campaign/data/noSsnNoItinCampaign.json";
export const DEALER_PURCHASE_PROGRAM_FLAT_JSON_URL : string = "/www/dealers-purchase-program-flat/data/dealerPurchaseProgramFlat.json";

