import {
  CapsDealOptimizerSpComponent
} from "app/components/caps-deal-optimizer-sp/caps-deal-optimizer-sp.component";
import { cardDetails } from "app/models/cardDetails";

export const DealOptimizer: cardDetails = {
  "title":["Cuando renueve su inventario de vehículos más rápidamente, obtendrás más ingresos."],
  "id":"deal_optimizer_tab_title",
  "sectionName":"Dealer Optimizer Tab",
  "description": [
    "CAPS le permite estructurar y cerrar la venta que más le beneficia a usted y a su cliente a través de proponer varios tipos de tratos concretos para cada cliente, 24/7/365."
  ],
  "buttonName": "INICIAR SESIÓN EN CAPS",
  "buttonId":"deal-optimizer-btn",
  "link":"/dealers/caps-redirect",
  "imgUrl":"assets/images/grow-your-dealership/users-toggle/ways-business-grow/img_deal-optim-img@2x.png",
  "imgAlt":"Dealers Optimizer Image",
  "ariaLabel":"INICIAR SESIÓN EN CAPS",
  "outletComponent": CapsDealOptimizerSpComponent
}
