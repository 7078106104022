<app-header></app-header>
<div class="sub-components-margin">
    <cac-animated-hero-image [heroImageInfo]="heroImageInfo" 
    [bottonBgColor]="bottonBgColor" [overLineBgColor]="overLineBgColor" [overLineTextColor]="overLineTextColor" [headerColor]="headerColor" 
    [bottonBgColorToggle]="bottonBgColorToggle" [overLineBgColorToggle]="overLineBgColorToggle" [overLineTextColorToggle]="overLineTextColorToggle" [headerColorToggle]="headerColorToggle">
    </cac-animated-hero-image>
</div>
<div class="container sub-components-margin whiteColor">
    <div class="text-video-container">
        <div class="text-content">
            <h3 class="header">{{iframeContent.iframeHeader}}</h3>
            <p class="body-description">{{iframeContent.iframeDesc}}</p>
        </div>
        <div class="iframeVideo">
            <iframe src="https://player.vimeo.com/video/924725470?h=82a066df15&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="{{iframeContent.iframeTitle}}"></iframe>
        </div>
    </div>
</div>
<div class="skyColor content-center">
    <cac-credit-introduction [information]="flatsContent"></cac-credit-introduction>
</div>
<div class="whiteColor">
    <cac-credit-introduction [information]="introData"></cac-credit-introduction>
</div>
<div class="beigeColor">
    <cac-credit-introduction [information]="introData2"></cac-credit-introduction>
</div>
<div [ngClass]="{'container': true, 'components-margin': !isMobileViewDisplay, 'faq-padding': isMobileViewDisplay}">
    <cac-faqs [data]="faqData" [isFaqQuestionBold]="true"></cac-faqs>
</div>
<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>