<app-header></app-header>
<div class='bottom-shadow sub-components-margin' [ngClass]="{'mob-height': isMobileViewDisplay}">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [disclaimerColor]='disclaimerColor'
        [bottonBgColor]="bottonBgColor" [overLineBgColor]="overLineBgColor" [isCurvedImage]="isCurvedImage">
    </cac-hero-image>
</div>

<div [ngClass]="!isMobileViewDisplay ? 'container components-margin' : 'mobile-container sub-components-margin'">
    <cac-anchor-devider *ngIf="anchorDeviders" [data]="anchorDeviders"></cac-anchor-devider>
</div>

<div id='enrollment' *ngIf='stepsData' class='components-margin'>
    <div [ngClass]="!isMobileViewDisplay ? 'row' : ''">
        <div *ngIf='!isMobileViewDisplay' class='col-6'>
            <img alt="{{stepsData.imageAlt}}" src="{{stepsData.imageUrl}}" class='w-100'>
        </div>
        <div [ngClass]="!isMobileViewDisplay ? 'col-6 px-5' : 'col-12'">
            <cac-steps [data]="stepsData"></cac-steps>
        </div>
    </div>
</div>

<div [ngClass]="!isMobileViewDisplay ? 'container' : 'mobile-container'">
    <div id='programs' class='components-margin'>
        <cac-header data='To help grow your business, we put you in the driver’s seat with:'></cac-header>
    </div>
</div>

<div [ngClass]="!isMobileViewDisplay ? '' : 'mobile-container'">
    <div class='components-margin' *ngIf='contentBlock'>
        <cac-content-block [data]="contentBlock" [leftToRight]="false" [isDefaultColor]="true"></cac-content-block>
    </div>
    <div class='components-margin' *ngIf='contentBlockTwo'>
        <cac-content-block [data]="contentBlockTwo" [leftToRight]="true" [isDefaultColor]="true"></cac-content-block>
    </div>
    <div class='components-margin' *ngIf='contentBlockThree'>
        <cac-content-block [data]="contentBlockThree" [leftToRight]="false" [isDefaultColor]="true"></cac-content-block>
    </div>
    <div class='components-margin' *ngIf='contentBlockFour'>
        <cac-content-block [data]="contentBlockFour" [leftToRight]="true" [isDefaultColor]="true"></cac-content-block>
    </div>
</div>

<div id="dealerVideos" *ngIf="videoData" class="components-margin top-bottom-shadow">
    <video-block [data]="videoData"></video-block>
</div>

<div [ngClass]="!isMobileViewDisplay ? 'container' : 'mobile-container'">
    <div id="articles" *ngIf="articleData" class="components-margin">
        <cac-header [data]='articleData.header'></cac-header>
        <div class='components-margin'>
            <cac-articles [articleData]="articleData" [displayStacked]='false'></cac-articles>
        </div>
        <div class="mt-1">
            <a class="review-button" [href]="dealerReviewsButton.buttonLink"><span [innerHTML]="dealerReviewsButton.buttonTxt"></span> </a>
        </div>
    </div>
</div>

<div class="components-margin">
    <app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
</div>

<div *ngIf='footerDisclaimer' [ngClass]="!isMobileViewDisplay ? 'container' : 'mobile-container'">
    <div class='px-1 footer-links text-decoration-none text-transform-none m-0 pt-3'>
        {{footerDisclaimer.disclaimerText}}
    </div>
</div>
<app-footer></app-footer>