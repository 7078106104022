import { cardDetails } from "app/models/cardDetails";

export const Speed: cardDetails = {
  "title":["You have the need for speed.", "We have ways to accelerate the process."],
  "id":"tab_csitoerm",
  "sectionName":"CustomerTabInfo",
  "description": [
    "There’s no stopping us when it comes to helping you get your deals done quickly:"
  ],
  "points": [
    'Approvals in less than 30 seconds',
    'eContracting and remote customer signing*** so the deal closes anywhere, anytime',
    'Reduce time-to-contract using CAPS®',
    'Same-day funding — you can get funded before your customer drives off the lot, helping with your cash flow'
  ],
  "buttonName": "Login to CAPS",
  "imgUrl":"assets/images/grow-your-dealership/users-toggle/make-things-posisble/img_speed-img@2x.png",
  "imgAlt":"Dealer Speed",
  "ariaLabel":"Dealer Speed Section",
  "buttonId": "dealer_appr_login_caps",
  "link":"/dealers/caps-redirect"
}