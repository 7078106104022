<app-header></app-header>
<div [ngClass]="mobileView ? 'sub-components-margin mob-height' : 'sub-components-margin'" >
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [bottonBgColor]="bottonBgColor" [overLineTextColor]="overLineTextColor" [overLineBgColor]="overLineBgColor" [isCurvedImage]="isCurvedImage" [disclaimerColor]="disclaimerColor"></cac-hero-image>
</div>
<div>
  <cac-credit-introduction [information]="maintainceCredit"></cac-credit-introduction>
</div>
<div class='container'>
  <cac-acceptance-card [data]="cardData" [displayStacked]="true"></cac-acceptance-card>  
</div>
<app-sitemap [isMobileView]="mobileView"></app-sitemap>
<app-footer></app-footer>