<ng-container *ngIf="data | async as data">
  <app-header></app-header>
  <!-- Hero Banner Start  -->
  <div class="container-fluid">
    <div
      class="row d-flex align-items-center justify-content-center pb-4 hero-banner"
      [class.flex-column]="data.isMobile"
    >
      <ng-container
        *ngIf="data.isMobile; then heroText; else heroImage"
      ></ng-container>
      <ng-container
        *ngIf="data.isMobile; then heroImage; else heroText"
      ></ng-container>
    </div>
  </div>

  <ng-template #heroImage>
    <div class="d-flex justify-content-center" [class.col-6]="!data.isMobile">
      <img
        [src]="
          data.isMobile
            ? data.heroBanner.image.mobileSrc
            : data.heroBanner.image.src
        "
        class="d-block img-fluid hero-image"
        [alt]="data.heroBanner.image.alt"
      />
    </div>
  </ng-template>

  <ng-template #heroText>
    <div
      class="d-flex flex-column"
      [class]="
        data.isMobile
          ? 'align-items-center justify-content-center pt-4'
          : 'col-6 align-items-start'
      "
    >
      <h1 [ngClass]="{ 'text-center hero-banner-mobile-font': data.isMobile }">
        <span
          class="d-block"
          *ngFor="let heading of data.heroBanner.headings"
          >{{ heading }}</span
        >
      </h1>
    </div>
  </ng-template>

  <!-- Main Page Body Container -->
  <div
    class="container"
    [class]="data.isMobile ? 'px-3' : 'components-margin px-5'"
  >
    <!-- How it Works Start -->
    <div class="row" [class]="data.isMobile ? 'flex-column mb-5 mt-4' : 'my-5'">
      <ng-container
        *ngIf="data.isMobile; then enrollTodayButton"
      ></ng-container>
      <!-- Left Column (On Desktop) -->
      <div
        class="d-flex flex-column"
        [class]="data.isMobile ? 'col-12' : 'col-6'"
      >
        <div class="ratio ratio-16x9">
          <cac-iframe
            [width]="'100%'"
            [height]="'100%'"
            [sourceUrl]="data.howItWorks.videoSection.src"
            [ariaLabel]="data.howItWorks.videoSection.ariaLabel"
            frameborder="0"
          >
          </cac-iframe>
        </div>
        <p class="mt-3">
          {{ data.howItWorks.videoSection.description }}
        </p>
      </div>
      <!-- Right Column (On Desktop) -->
      <div [class]="data.isMobile ? 'col-12 mt-3' : 'col-6 ps-5'">
        <p class="mb-4" [class.fs-1]="data.isMobile">
          {{ data.howItWorks.contentSection.description }}
        </p>
        <ul class="list-unstyled mb-4">
          <li
            *ngFor="
              let point of data.howItWorks.contentSection.bulletPoints.points
            "
            class="d-flex align-items-top mb-4"
          >
            <img
              [src]="data.howItWorks.contentSection.bulletPoints.image.src"
              [alt]="data.howItWorks.contentSection.bulletPoints.image.alt"
              class="align-self-start me-2"
            />
            <span class="fw-bold">{{ point }}</span>
          </li>
        </ul>
        <p class="small">
          {{ data.howItWorks.contentSection.disclaimer }}
        </p>
        <ng-container
          *ngIf="!data.isMobile; then enrollTodayButton"
        ></ng-container>
      </div>

      <ng-template #enrollTodayButton>
        <div
          class="d-flex"
          [ngClass]="{ 'justify-content-center mb-4': data.isMobile }"
        >
          <a
            [href]="data.howItWorks.contentSection.button.href"
            class="btn btn-primary"
            >{{ data.howItWorks.contentSection.button.text }}</a
          >
        </div>
      </ng-template>
    </div>

    <!-- How It Works Diagram -->
    <div class="row text-center components-margin">
      <div>
        <div *ngIf="data.isMobile">
          <h2>{{ data.howItWorksDiagram.mobileCallout.mainTitle }}</h2>
          <h3 class="fw-bold">
            {{ data.howItWorksDiagram.mobileCallout.secondaryTitle }}
          </h3>
        </div>
        <img
          [src]="
            data.isMobile
              ? data.howItWorksDiagram.image.mobileSrc
              : data.howItWorksDiagram.image.src
          "
          class="img-fluid"
          [alt]="data.howItWorksDiagram.image.alt"
        />
      </div>
      <div *ngIf="data.isMobile" class="row mt-3">
        <div
          *ngFor="
            let column of data.howItWorksDiagram.mobileDiagramDescriptions
          "
          class="col-6"
        >
          <ng-container *ngFor="let section of column">
            <h4 class="d-flex align-content-start mb-0">{{ section.title }}</h4>
            <p class="text-start">{{ section.description }}</p>
          </ng-container>
        </div>
      </div>
      <h3 class="fw-bold mt-5">
        {{ data.howItWorksDiagram.description }}
      </h3>
      <div class="mt-2">
        <p class="small">{{ data.howItWorksDiagram.question }}</p>
      </div>
      <div class="mt-2">
        <a
          [href]="data.howItWorksDiagram.button.href"
          class="btn btn-primary"
          >{{ data.howItWorksDiagram.button.text }}</a
        >
      </div>
    </div>
    <!-- How It Works Diagram End -->

    <!-- What Other Dealers Say -->
    <div class="row components-margin">
      <video-two-col [data]="data.whatOtherDealersSayVideos"></video-two-col>
    </div>

    <!-- Sitemap and Footer Section -->
    <div class="row">
      <app-sitemap [isMobileView]="data.isMobile"></app-sitemap>
      <app-footer></app-footer>
    </div>
  </div>
</ng-container>
