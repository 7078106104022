import { IntroDescription } from "app/models/credit_accp_Intro_desc";

export const PromoIntroduction: IntroDescription = {
  heading:["Here’s how it works."],
  points:[
    'Submit an <span class="fw-bold">unlimited number</span> of contracts for the first 90 days',
    'Get paid an additional <span class="fw-bold">$200 per contract</span>',
    'Get funded <span class="fw-bold">same day</span>'
  ],
  content:["Started by a dealer for dealers over 50 years ago, Credit Acceptance knows the ins-and-outs of auto financing. And we know you only make money when cars move off of your lot and on to the road. Which is why we offer programs to help you approve every customer, move your inventory quickly, and grow your business along the way."],
}