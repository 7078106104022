import { bannerImage } from "app/models/bannerImage";

export const promoBannerDetails: bannerImage = {
  "imgUrl": "assets/images/promo/banner-image/banner.jpg",
  "mobileImgurl":"assets/images/common/orange-shape-bg-mobile@2x.webp",
  "imgAlt": "Banner Image",
  "buttonName": "Login to Caps",
  "buttonId":"work_deal_login_caps",
  "overlineTitle":"Don’t miss this exclusive offer",
  "heading": ['<span class="text-credit-indigo">Get paid an additional</span><span class="text-white"> $200 </span><span class="text-credit-indigo">per contract.</span>'],
  "link": "/dealers/caps-redirect"
}