import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { LICENSE_TO_DRIVE_JSON_URL } from 'app/constants/aws.constants';
import { AWSService } from 'app/services/aws.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-license-to-driver-landing',
  templateUrl: './license-to-driver-landing.component.html',
  styleUrls: ['./license-to-driver-landing.component.scss']
})
export class LicenseToDriverLandingComponent implements OnInit {

  subscription: Subscription[] = [];

  url = LICENSE_TO_DRIVE_JSON_URL;

  LicenseToDriveJSONData:any;

  mobileView : boolean = false;
  heroImageInfo : any = {};
  bottonBgColor: string = "#ffb800";
  overLineBgColor: string = "#FCFCFA";
  overLineTextColor: string = "#1b1b1b";
  headerColor: string = "#FFFFFF";
  isCurvedImage = false;
  isBrowser : boolean = false;
  introData : any = {};
  testimonialData : any;
  numSpeakData : any = {};
  footerDisclaimer : any = {};
  tabbedContentData : any;
  twoTabbedContentData : any;
  constructor(private http: HttpClient, private responsive: BreakpointObserver,private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);

    //Add no robots meta tag
    let meta = document.createElement('meta');
    meta.name = "robots";
    meta.content = "noindex";
    document.getElementsByTagName('head')[0].appendChild(meta);
  }

  ngOnInit(): void {
   
    if(this.isBrowser){

      this.awsService.getAWSData(this.url).then(json => {

        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);
        
        this.LicenseToDriveJSONData = json;
        
        this.heroImageInfo = this.LicenseToDriveJSONData.CacHeroImageComponent;
        this.introData = this.LicenseToDriveJSONData.CacCreditIntroductionComponent;
        this.testimonialData = this.LicenseToDriveJSONData.CacTestimonialComponent;
        this.numSpeakData = this.LicenseToDriveJSONData.CacNumbersSpeakComponent;
        this.footerDisclaimer = this.LicenseToDriveJSONData.CacfooterDisclaimer;
        this.tabbedContentData = this.LicenseToDriveJSONData.CacTabbedContentComponent;
        this.twoTabbedContentData = this.LicenseToDriveJSONData.CacTwoTabbedContentComponent;
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          if (result.matches) {
            this.mobileView = true;
          }
        })
    );
  }

}
