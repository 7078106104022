import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AWSService } from 'app/services/aws.service';
import { Subscription } from 'rxjs';
import { ONLINE_VERIFICATION__JSON_URL } from 'app/constants/aws.constants';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'online-verification',
  templateUrl: './online-verification.component.html',
  styleUrls: ['./online-verification.component.scss'],
})
export class OnlineVerificationComponent implements OnInit {

 

  subscription: Subscription[] = [];

  url = ONLINE_VERIFICATION__JSON_URL;

  OnlineVerificationJSON: any;

  heroImageInfo: any = {};
  bottonBgColor: string = "#ffb800";
  overLineBgColor: string = "#FA6400";
  overLineTextColor: string = "#171714";
  headerColor: string = "#FFFFFF";
  disclaimerColor: string = "#FFFFFF";
  isCurvedImage = false;
  isBrowser: boolean = false;
  mobileView: boolean = false;
  
  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService: AWSService, @Inject(PLATFORM_ID) platformId: Object, private router: Router) {
    this.isBrowser = isPlatformBrowser(platformId);

     //Add no robots meta tag
     let meta = document.createElement('meta');
     meta.name = "robots";
     meta.content = "noindex";
     document.getElementsByTagName('head')[0].appendChild(meta);
  }

  ngOnInit() {

    if (this.isBrowser) {

      this.awsService.getAWSData(this.url).then(json => {

        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);

        this.OnlineVerificationJSON = json;

        this.heroImageInfo = this.OnlineVerificationJSON.CacHeroImageComponent;

      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
        this.mobileView = result.matches ? true : false;
      })
    );


  }

  
}