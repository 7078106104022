export class bannerImage {
  overlineTitle?: string;
  heading?: string[];
  buttonName?: string;
  buttonId?:string;
  link?: string;
  isRebuildLink?: string;
  isExternalLink?: boolean;
  target?: string;
  isCustomerLink?: boolean;
  imgUrl?: string;
  mobileImgurl?: string;
  imgAlt?: string;
  disclaimers?: string[];
}