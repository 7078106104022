import { quickLink } from "app/models/quickLink";

export const quickLinks: quickLink[] = [
    {
      "title": "GET PRE-QUALIFIED",
      "id":"quick_get_prequalified",
      "imgUrl":"assets/images/quick-links/person-fontawesome-indigo.svg",
      "onHoverimgUrl": "assets/images/quick-links/person-fontawesome-eggshell-100.svg",
      "imgId":"get_prequalified_quick_img",
      "ariaLabel": "GET PRE-QUALIFIED",
      "link": "/get-prequalified#/"
    },
    {
      "title": "Account Sign In",
      "id":"loginLink",
      "imgUrl":"assets/images/quick-links/user.svg",
      "onHoverimgUrl": "assets/images/quick-links/user-hover.svg",
      "imgId":"sign_id_quick_img",
      "ariaLabel": "Account Sign In",
      "link":"/login",
      "isCustomerLink": true
    },
    {
      "title": "Make A Payment",
      "id":"layout_24",
      "ariaLabel": "Make A Payment",
      "imgUrl": "assets/images/quick-links/money-fontawesom-indigo-700.svg",
      "onHoverimgUrl": "assets/images/quick-links/money-fontawesom-eggshell100.svg",
      "imgId":"make_a_pay_quick_img",
      "link":"/customers/make-a-payment"
    },
    {
      "title": "CAPS Login",
      "id":"layout_42",
      "ariaLabel": "CAPS Login",
      "imgUrl": "assets/images/quick-links/caps_indigo.svg",
      "onHoverimgUrl": "assets/images/quick-links/caps_eggshell.svg",
      "imgId":"caps_login_quick_img",
      "link":"/dealers/caps-redirect"
    }]
