export class quickLink {
  title!: string;
  id!: string;
  link?: string;
  imgUrl!: string;
  onHoverimgUrl!: string;
  imgId?: string;
  ariaLabel!: string;
  isRebuildLink?: boolean;
  isExternalLink?: boolean;
  isCustomerLink?: boolean;
  target?: boolean; // set to true in case opens in new tab
  isHovered?: boolean;
}