import { cardDetails } from "app/models/cardDetails";

export const Approval: cardDetails = {
  "title":["We approve one kind of customer. Yours."],
  "id":"dealer_approval_tab",
  "sectionName":"Approval Dealer",
  "description": [
    "You can count on us to help you approve 100% of your customers with flexible approval criteria, including:",
  ],
  "points": [
    'Multiple open autos on bureau',
    'All income sources',
    'Bankruptcy Chapter 7',
    'Bankruptcy Chapter 13 (with Trustee/Court approval)'
  ],
  "buttonName": "Login to CAPS",
  "imgUrl":"assets/images/grow-your-dealership/users-toggle/make-things-posisble/approval-img@2x.png",
  "imgAlt":"Dealer Approvals",
  "ariaLabel":"Dealer Approval Section",
  "buttonId": "dealer_appr_login_caps",
  "link":"/dealers/caps-redirect"
}