<app-header></app-header>
<div [ngClass]= "{'mob-height': isMobileViewDisplay}" class="sub-components-margin">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [bottonBgColor]="bottonBgColor"
        [overLineBgColor]="overLineBgColor" [overLineTextColor]="overLineTextColor" [isCurvedImage]="isCurvedImage" [disclaimerColor]="disclaimerColor" ></cac-hero-image>
</div>
<cac-credit-introduction [information]="introData"></cac-credit-introduction>
<cac-credit-introduction [information]="introData_1"></cac-credit-introduction>

<div *ngIf="coursalData" class="container sub-components-margin">
    <cac-image-carousel [data]="coursalData"></cac-image-carousel>
</div>
<div class="container components-margin mb-30">
    <div [ngClass]= "{'content-section': !isMobileViewDisplay}">
        <div *ngIf="videoData" class="solid-line">
            <cac-iframe [sourceUrl]='videoData?.videoURL' height='auto' width='100%' ariaLabel="{{videoData?.videoTitle}}"></cac-iframe>
            <!--<iframe frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" src="https://player.vimeo.com/video/774442481" title="{{videoData?.videoTitle}}"></iframe>-->
            <div class="body-description body-description-align">{{videoData?.videoTitle}}</div>
        </div>
    </div>
</div>

<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>