import { bannerImage } from "app/models/bannerImage";

export const heroImageContent: bannerImage = {
  "imgUrl": "assets/images/banner-image/masthead-img-1920@2x.webp",
  "mobileImgurl":"assets/images/banner-image/img-masthead-img-mobile@2x.webp",
  "imgAlt": "Banner Image",
  "buttonName": "GET PRE-QUALIFIED",
  "buttonId":"hero_get_prequalified",
  "overlineTitle":"Welcome to Credit Acceptance.",
  "heading": ["We’re driving possibility."],
  "disclaimers":["See your monthly prequalified payment now."],
  "link": "/get-prequalified#/"
}