import { creditLinks } from "app/models/credit_links";

export const footerImages: creditLinks[] = [
    {
        "imgUrl": "assets/images/footer/facebook.svg",
        "title": "Facebook Icon",
        "id": "fb_icon",
        "isExternalLink":true,
        "ariaLabel":"facebook",
        "link": "https://www.facebook.com/Credit-Acceptance-Corporation-168678439842388/"
    },
    {
        "imgUrl": "assets/images/footer/linkedIn.svg",
        "title": "LinkedIn Icon",
        "id": "linkedin_icon",
        "isExternalLink":true,
        "ariaLabel":"linkedin",
        "link": "https://www.linkedin.com/company/credit-acceptance"
    },
    {
        "imgUrl": "assets/images/footer/vimeo.svg",
        "title": "Vimeo Icon",
        "id": "vimeo_icon",
        "isExternalLink":true,
        "ariaLabel":"vimeo icon",
        "link": "https://vimeo.com/creditacceptancecorp"
    },
    {
        "imgUrl": "assets/images/footer/glassdoor.svg",
        "title": "Glassdoor",
        "id": "glass_door",
        "isExternalLink":true,
        "ariaLabel": "Glassdoor icon",
        "link": "https://www.glassdoor.com/Overview/Working-at-Credit-Acceptance-EI_IE2194.11,28.htm"
    },
    {
        "imgUrl": "assets/images/footer/instagram.svg",
        "title": "Instagram",
        "id": "insta_icon",
        "isExternalLink":true,
        "ariaLabel":"Instagram Icon",
        "link": "https://www.instagram.com/creditacceptancecorp/"
    },
    {
        "imgUrl": "assets/images/footer/googleplay-badge.svg",
        "title": "Google Play",
        "id": "google_play_icon",
        "isExternalLink":true,
        "ariaLabel":"Google Play",
        "link": "https://play.google.com/store/apps/details?id=com.creditacceptance.customer&referrer=utm_source%3Dcacdotcom%26utm_medium%3Dfooter"
    },
    {
        "imgUrl": "assets/images/footer/App_Store_Badge.svg",
        "title": "App Store",
        "id": "app_store_icon",
        "isExternalLink":true,
        "ariaLabel":"app store",
        "link": "https://apps.apple.com/app/apple-store/id1600112247"
    },

]