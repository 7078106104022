import { Component, Input, OnInit } from '@angular/core';
import { cardDetails } from 'app/models/cardDetails';

@Component({
  selector: 'app-image-text-overlay',
  templateUrl: './image-text-overlay.component.html',
  styleUrls: ['./image-text-overlay.component.scss']
})
export class ImageTextOverlayComponent implements OnInit {
  @Input() isMobileView: boolean = false;
  @Input() details: cardDetails[] = [];
  constructor() { }

  ngOnInit(): void {
  }

}
