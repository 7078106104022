<app-header></app-header>
<div [ngClass]="isMobileViewDisplay ? 'sub-components-margin mob-height bottom-shadow' : 'sub-components-margin bottom-shadow'">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [bottonBgColor]="bottonBgColor"
        [overLineBgColor]="overLineBgColor" [isCurvedImage]="isCurvedImage"  [disclaimerColor]='disclaimerColor'></cac-hero-image>
</div>
<cac-numbers-speak [creditNumSpeak]="creditNumSpeakData"></cac-numbers-speak>
<div [ngClass]="!isMobileViewDisplay ? '' : 'mobile-container mobile-clr'">
    <div class='about-components-margin bottom-shadow'>
        <cac-content-block [data]="contentBlockData" [leftToRight]="false" [isDefaultColor]="false"></cac-content-block>
    </div>
</div>
<div [ngClass]="!isMobileViewDisplay ? '' : 'mobile-container'">
    <div class='components-margin'>
        <cac-content-block [data]="contentBlockRightData" [leftToRight]="true" [isDefaultColor]="true"></cac-content-block>
    </div>
</div>
<div [ngClass]="!isMobileViewDisplay ? 'container' : ''">
    <div class='components-margin'>
        <cac-award [data]="awardData"></cac-award>
    </div>
</div>
<div class='components-margin'>
    <cac-pride [data]="prideComponentData"></cac-pride>
</div>
<div [ngClass]="!isMobileViewDisplay ? 'container' : 'mobile-container'">
    <cac-acceptance-card *ngIf="cardData" [data]="cardData" [displayStacked]="true"></cac-acceptance-card>
</div>
<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>