import { Component, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { SEOService } from 'app/services/seoService';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AWSService } from '../../services/aws.service';
import { isPlatformBrowser } from '@angular/common';
import { combineLatest, map, Observable } from 'rxjs';
import {
  NoSsnNoItinCampaignData,
  NoSsnNoItinCampaignPageModel,
} from './data.interface';
import { NO_SSN_NO_ITIN_CAMPAIGN_JSON_URL } from '../../constants/aws.constants';

@Component({
  selector: 'app-no-ssn-no-itin-campaign',
  templateUrl: './no-ssn-no-itin-campaign.component.html',
  styleUrls: ['./no-ssn-no-itin-campaign.component.scss']
})
export class NoSsnNoItinCampaignComponent {
  private readonly seoService = inject(SEOService);
  private readonly responsive = inject(BreakpointObserver);
  private readonly awsService = inject(AWSService);
  private readonly isBrowser: boolean = isPlatformBrowser(inject(PLATFORM_ID));
  data!: Observable<NoSsnNoItinCampaignPageModel>;

  ngOnInit(): void {
    this.seoService.setNoRobotsSEODataTag();

    if (this.isBrowser) {
      this.data = combineLatest({
        noSsnNoItinCampaign:
          this.awsService.getAWSJsonData<NoSsnNoItinCampaignData>(
            NO_SSN_NO_ITIN_CAMPAIGN_JSON_URL,
          ),
        breakpointState: this.responsive.observe([
          Breakpoints.XSmall,
          Breakpoints.Small,
        ]),
      }).pipe(
        map(
          ({
            noSsnNoItinCampaign,
            breakpointState: { matches: isMobile },
          }) => ({
            isMobile,
            ...noSsnNoItinCampaign,
          }),
        ),
      );
    }
  }
}
