<app-header></app-header>
<div
  [ngClass]="
    isMobileViewDisplay
      ? 'sub-components-margin mob-height bottom-shadow'
      : 'sub-components-margin bottom-shadow'
  "
>
  <cac-hero-image
    [heroImageInfo]="heroImageInfo"
    [headerColor]="headerColor"
    [bottonBgColor]="bottonBgColor"
    [overLineBgColor]="overLineBgColor"
    [isCurvedImage]="isCurvedImage"
    [disclaimerColor]="disclaimerColor"
  ></cac-hero-image>
</div>

<div class="bottom-shadow thank-you-container">
<div
  *ngIf="CacThankYouMessageContent"
  [ngClass]="!isMobileViewDisplay ? 'container' : 'mobile-container'"
>
  <div class="components-margin body-description">
    <div class="thank-you-label">
      {{ CacThankYouMessageContent.label }}
    </div>
    <div class="thank-you-detail"><img  src='{{ CacThankYouMessageContent.icons.profileIconImgUrl }}'><span *ngIf='successResponse.name' class="thank-you-data"> <span class="mam-title"> {{successResponse.name}} </span><span>{{successResponse.title}}</span> </span> </div>
    <div class="thank-you-detail"><img  src='{{ CacThankYouMessageContent.icons.phoneIconImgUrl }}'> <span *ngIf='successResponse.phone' class="thank-you-data"><a href="tel:{{successResponse.phone.replaceAll('.','')}}" class="inline-hyper-link">{{successResponse.phone}}</a> </span></div>
    <div class="thank-you-detail"><img  src='{{ CacThankYouMessageContent.icons.emailIconImgUrl }}'> <span *ngIf='successResponse.email'class="thank-you-data"><a href="mailto:{{successResponse.email}}" class="inline-hyper-link">{{successResponse.email}}</a></span></div>
  </div>
</div>
</div>
<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>
