import { cardDetails } from "app/models/cardDetails";

export const FullSpectrum: cardDetails = {
  "title":["We’re driving possibility for all: ", "Full-spectrum financing."],
  "id":"make_thigns_full_spectrum_tab",
  "sectionName":"make things full spectrum tab",
  "description": [
    "With Credit Acceptance’s full-spectrum financing, enrolled dealers can offer new and used vehicle financing options to set every customer up for long term success."
  ],
  "points": [
    'No credit to prime credit options',
    'The opportunity to increase cash flow through higher front-end advances',
    'Ability to easily structure deals that meet customer’s down payment and monthly payment requirements'
  ],
  "buttonName": "Login to CAPS",
  "imgUrl":"assets/images/grow-your-dealership/users-toggle/make-things-posisble/full-spectrum.svg",
  "imgAlt":"Dealer Approvals",
  "ariaLabel":"Dealer Full Spectrum Section",
  "buttonId": "dealer_appr_login_caps",
  "link":"/dealers/caps-redirect"
}