<ng-container *ngIf="tabInfo">
  <div class="bgc-primary-white py-4">
    <div class="container px-4">
      <div class="pb-3" [ngClass]="isMobileView ? 'w-100' : 'w-75'">
        <div  *ngIf="tabInfo.heading" class="py-3 container" [ngClass]= "{ 'secondary-heading' : isMobileView}">
          <ng-container *ngFor="let heading of tabInfo.heading">
            <span class="h3">
                <span [ngClass]= "{ 'secondary-heading' : !isMobileView}" attr.aria-label="{{heading}}"> 
                    {{heading}}
                </span>
              </span>
          </ng-container>
        </div>
        <div *ngIf="tabInfo.content" class="body-description h-auto py-3 pe-2">
          <ng-container *ngFor="let content of tabInfo.content">
            <div class="pb-2" attr.aria-label="{{content}}" [innerHTML]="content"></div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="tabInfo.tabInformation && tabContent" class="bgc-credit-white">
    <nav class="container">
      <ul class="nav nav-tabs border-bottom-0 list-unstyled px-0">
        <li class="nav-item" *ngFor="let tab of tabInfo.tabInformation; let last = last; let first = first">
          <div class="nav-link btn border-0" [id]="tab.tabId" (click)="handleTab($event,tab)" [ngClass]="{
              'ms-2 me-0 ps-2 pe-1' : first && isMobileView,
              'mx-1 px-1': !first && !last && isMobileView,
              'mx-1 px-0' : last && isMobileView
            }">
            <span tabindex="0" class="pt-3 tab-title" attr.aria-labelledby="{{tab.tabId}}" attr.aria-label="{{tab.tabTitle}}" (keyup.enter)="handleTab($event,tab)"
              [ngClass]="{
                'bgc-credit-white border-start border-end' : tab.active,
                'active bgc-secondary-brown' : !tab.active,
                'px-4': !isMobileView,
                'px-2': isMobileView
              }" [innerHTML]="tab.tabTitle"></span>
          </div>
        </li>
      </ul>
    </nav>
    <div class="tab-content" [ngClass]="isMobileView ? 'mx-0' : 'mx-auto'">
      <div class="tab-pane active" [ngClass]="isMobileView ? '' : 'container'">
        <div class="py-4">
          <div class="row mx-0" [ngClass]="isMobileView ? 'pt-2' : 'pt-5'">
            <div [ngClass]="isMobileView ? 'col-12 order-2 pb-0 px-4' : 'col-6 order-1 pb-5'">
              <div class="tab-content-align" [ngClass]="isMobileView ? 'px-0' : 'px-3'">
                <ng-container *ngIf="tabContent.title">
                  <div class="py-3">
                    <ng-container *ngFor="let tabTitles of tabContent.title">
                      <div class="h4" attr.aria-label="{{tabTitles}}" [innerHTML]="tabTitles"></div>
                    </ng-container>
                  </div>
                </ng-container>
                <div class="body-description h-auto">
                  <ng-container *ngFor="let desc of tabContent?.description; let last = last">
                    <div *ngIf="desc" attr.aria-label="{{desc}}" [innerHTML]="desc"></div>
                    <br *ngIf="!last" />
                  </ng-container>
                  <nav class="pt-3" *ngIf="tabContent?.points">
                    <ul class="ps-3">
                      <li *ngFor="let pts of tabContent?.points" class="py-2">
                        <span [innerHTML]="pts"></span>
                      </li>
                    </ul>
                  </nav>
                </div>
                <ng-container *ngIf="tabContent.outletComponent">
                  <ng-container [ngComponentOutlet]="tabContent.outletComponent"></ng-container>
                </ng-container>
                <div *ngIf="tabContent.buttonName" class="card-actions px-0 d-flex justify-content-start">
                  <a type=button class="btn btn-primary" appAnchorClick id="{{tabContent.buttonId}}" role="link"
                    attr.aria-label="{{tabContent.buttonName}}" [linkInfo]="tabContent">{{tabContent.buttonName}}</a>
                </div>
              </div>
            </div>
            <div *ngIf="tabContent?.imgUrl" class="px-0" [ngClass]="isMobileView ? 'col-12 order-1' : 'col-6 order-2'">
              <img class="py-3" [ngClass]="isMobileView ? 'w-100 h-auto' : 'position-absolute'" height="383px"
                data-src="{{tabContent.imgUrl}}" alt="{{tabContent.imgAlt}}" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>