<div class="row" *ngIf="cardDetails && !loadOneCardSection">
  <div class="p-3 col-{{getColWidth()}}" *ngFor="let detail of cardDetails; let i= index" id=`{{sectionName}}_{{i}}`>
    <ng-container *ngTemplateOutlet="cardSection; context: {info: detail, index: i}"></ng-container>
  </div>
</div>

<ng-container *ngIf="loadOneCardSection && oneCardDetail">
  <ng-container  *ngTemplateOutlet="cardSection; context: {info: oneCardDetail, index: loadOneCardSectionIndex}"></ng-container>
</ng-container>

<ng-template #cardSection let-detail="info" let-i="index">
  <div [ngClass]="cardClasses" [ngStyle]="{'height': isMobileView ? detail.cardMobileHeight : detail.cardHeight}">
    <ng-container *ngIf="!detail.hideImgOnMobile">
      <div *ngIf="detail?.imgIcon" class="d-flex justify-content-center align-items-center py-3">
        <img data-src="{{detail?.imgIcon}}" id="{{sectionName}}_img_{{i}}" alt="{{detail?.imgAlt}}" loading="lazy" />
      </div>
      <div *ngIf="detail?.imgUrl">
        <img class="w-100" data-src="{{detail?.imgUrl}}" id="{{sectionName}}_img_{{i}}" alt="{{detail?.imgAlt}}" loading="lazy" />
      </div>
      <div *ngIf="detail?.avatarIcon" class="d-flex pt-4">
        <img width = "110px" height ="110px" id="{{sectionName}}_img_{{i}}" data-src="{{detail?.avatarIcon}}" alt="{{detail?.imgAlt}}" loading="lazy" />
      </div>
    </ng-container>
    <div class="card-body pt-2 pb-0 px-0">
      <div *ngIf="detail?.date" class="card-date py-3">
        <span attr.aria-label="{{detail?.date}}" id="{{sectionName}}_date_{{i}}">{{detail?.date | date: 'dd MMM yy'}}</span>
      </div>
      <ng-container *ngIf="detail?.title">
        <ng-container *ngFor="let detailTitle of detail?.title">
          <div class="h4 p-0 mb-2" id="{{sectionName}}_title_{{i}}" [innerHTML]="detailTitle"></div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="detail?.description">
        <ng-container *ngFor="let desc of detail?.description; let iDesc=index">
          <div *ngIf="desc" class="body-description card-description pe-3">
            <span attr.aria-label="{{desc}}" id="{{sectionName}}_description_{{i}}" [innerHTML]="desc"></span>
          </div>
        </ng-container>
      </ng-container>

      <nav class="body-description" *ngIf="detail?.points">
        <ul [ngClass]="pointsClass ? 'ps-0' : 'ps-3'">
          <li class="py-2" *ngFor="let pts of detail?.points" [ngClass]="pointsClass">
            <span [ngClass]="pointsClass ? 'px-2' : 'px-0'" [innerHTML]="pts"></span>
          </li>
        </ul>
      </nav>
      <div class="py-1" *ngIf="detail?.userDetails">
        <div *ngIf="detail?.userDetails.name" class="user-name" id="{{sectionName}}_customer_name_{{i}}" attr.aria-label="{{detail?.userDetails.name}}" [innerHTML]="detail?.userDetails.name"></div>
        <div *ngIf="detail?.userDetails.position ||  detail?.userDetails.corporateName" class="card-user-section">
          <span *ngIf="detail?.userDetails.position" class="user-details" id="{{sectionName}}_customer_position_{{i}}" attr.aria-label="{{detail?.userDetails.position}}" [innerHTML]="detail?.userDetails.position"></span>
          <span *ngIf="detail?.userDetails.corporateName">, </span>
          <span *ngIf="detail?.userDetails.corporateName" class="user-details" id="{{sectionName}}_customer_corporateName_{{i}}" attr.aria-label="{{detail?.userDetails.corporateName}}" [innerHTML]="detail?.userDetails.corporateName"></span>
          <span *ngIf="detail?.userDetails.corporateName">,</span>
        </div>
        <div *ngIf="detail?.userDetails?.Address" class="user-details" id="{{sectionName}}_customer_city_{{i}}"
          attr.aria-label="{{detail?.userDetails?.Address}}" [innerHTML]="detail?.userDetails?.Address">
          {{detail?.userDetails?.Address}}
        </div>
      </div>
      <ng-container *ngIf="detail.outletComponent">
        <ng-container [ngComponentOutlet]="detail.outletComponent"></ng-container>
      </ng-container>
      <div *ngIf="detail?.buttonName" class="py-2" [ngClass]="{'text-center w-auto p-3': !isPillTypeBtn}">
        <a type="button" role="link" class="{{buttonClass}}" id="{{detail.id}}"
          appAnchorClick attr.aria-label="{{detail?.buttonName}}"
          [linkInfo]="detail">{{detail?.buttonName}}</a>
      </div>
    </div>
  </div>
</ng-template>
