<div class="article-fl">
  <div class="pt-2">Here’s how it works:</div>
  <div class="d-flex justify-content-start py-3">
    <div class="ms-1 me-2">
      <img src="assets/images/common/arrow-file.svg" alt="arrow-file" loading="lazy">
    </div>
    <div class="ms-1">
      <span>Just submit 5 contracts in a month. </span>
    </div>
  </div>
  <div class="d-flex justify-content-start py-3">
    <div class="ms-1 me-2">
      <img src="assets/images/common/money.svg" alt="money" loading="lazy">
    </div>
    <div class="ms-1">
      <span>Get paid an additional $150 on every contract the following month. </span>
    </div>
  </div>

</div>
