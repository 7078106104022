import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  constructor() { }

  public getData(key:string){
    return localStorage.getItem(key);
  }

  public setData(key:string, value:string){
    localStorage.setItem(key,value);
  }

  public removeData(key:string):any{
    localStorage.removeItem(key);
  }

  public clearData(){
    localStorage.clear();
  }

 public getDiffInDays(firstRecordDate: Date) {
    let currentDay: any = new Date();
    let comparisonDate : any = new Date(firstRecordDate);
    const diffTime = Math.abs(currentDay - comparisonDate);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}


}
