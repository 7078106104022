import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {AWSService} from 'app/services/aws.service';
import { isPlatformBrowser } from '@angular/common';
import { WEBSMART_AUTO_JSON_URL} from 'app/constants/aws.constants';

@Component({
  selector: 'app-websmart-auto',
  templateUrl: './websmart-auto.component.html',
  styleUrls: ['./websmart-auto.component.scss']
})
export class WebsmartAutoComponent implements OnInit{
  subscription: Subscription[] = [];

  url = WEBSMART_AUTO_JSON_URL
  websmartAutoJSONData:any;
  headerData:any;
  isBrowser : boolean = false;
  isMobileViewDisplay : boolean = false;
  introData : any;
  videoData : any;

  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  ngOnInit(): void {
    if(this.isBrowser){

      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);

        this.websmartAutoJSONData = json;
        this.headerData = this.websmartAutoJSONData.CacVideoReview.header;
        this.introData = this.websmartAutoJSONData.CacVideoReview.CacCreditIntroductionReview;
        this.videoData = this.websmartAutoJSONData.CacVideoComponent;

      });
    }
    
    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }
}
