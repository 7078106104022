import { Component, Input, OnInit } from '@angular/core';
import { cardDetails } from 'app/models/cardDetails';
import { creditLinks } from 'app/models/credit_links';

@Component({
  selector: 'app-hear-from-people',
  templateUrl: './hear-from-people.component.html',
  styleUrls: ['./hear-from-people.component.scss']
})
export class HearFromPeopleComponent implements OnInit {
  @Input() isMobileView: boolean = false;
  @Input() hearFromPeople: cardDetails[] = [];
  @Input() hearFromPeoplebottonBtnLink: creditLinks = new creditLinks;
  @Input() hearFromPeoplesectionTitle: string= '';
  constructor() { }

  ngOnInit(): void {
  }

}
