<app-header></app-header>
<div [ngClass]= "{'mob-height': isMobileViewDisplay}" class="sub-components-margin bottom-shadow">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [bottonBgColor]="bottonBgColor"
        [overLineBgColor]="overLineBgColor" [isCurvedImage]="isCurvedImage"></cac-hero-image>
</div>

<div *ngIf='!isMobileViewDisplay' >
    <div [ngClass]="i==1? 'components-margin' : 'esg-components-margin bottom-shadow'" *ngFor="let contentBlockData of contentBlock; let i = index">
        <cac-content-block [data]="contentBlockData" [leftToRight]="contentBlockData.leftToRight"
            [isDefaultColor]="contentBlockData.isDefaultColor"></cac-content-block>
    </div>
</div>

<div *ngIf='isMobileViewDisplay'>
    <div class='mobile-container' [ngClass]=" i!=1? 'mobile-clr esg-components-margin bottom-shadow' : 'components-margin esg-components-padding'"
        *ngFor="let contentBlockData of contentBlock; let i = index">
        <cac-content-block [data]="contentBlockData" [leftToRight]="contentBlockData.leftToRight"
            [isDefaultColor]="contentBlockData.isDefaultColor"></cac-content-block>
    </div>
</div>


<div [ngClass]="!isMobileViewDisplay ? 'container' : 'mobile-container'">
    <div class="components-margin">
            <cac-acceptance-card [data]="cardData" [displayStacked]="true"></cac-acceptance-card>
    </div>
</div>
<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>