import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CreditMaterialModule } from './modules/credit-material.module';
import { SharedModule } from './modules/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { GoogleMapsModule } from '@angular/google-maps';

import { CacheInterceptor } from './interceptors/cache.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CapsDealOptimizerComponent } from './components/caps-deal-optimizer/caps-deal-optimizer.component';
import { CapsDealOptimizerSpComponent } from './components/caps-deal-optimizer-sp/caps-deal-optimizer-sp.component';
import { GrowYourDealershipComponent } from './page-component/grow-your-dealership/grow-your-dealership.component';
import { HomePageComponent } from './page-component/home-page/home-page.component';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { PromoComponent } from './page-component/promo/promo.component';
import { PromoRewardsComponent } from './page-component/promo-rewards/promo-rewards.component';
import { WorkTheDealComponent } from './page-component/work-the-deal/work-the-deal.component';
import { CacCustomersHomeComponent } from './page-component/cac-customers-home/cac-customers-home.component';

import { CacAngularSharedComponentsModule } from '@credit-acceptance/cac-angular-shared-components';
import { ContactUsComponent } from './page-component/contact-us/contact-us.component';
import { GuestPaymentComponent } from './page-component/guest-payment/guest-payment.component';
import { MakeAPaymentComponent } from './page-component/make-a-payment/make-a-payment.component';
import { DealerReviewsComponent } from './page-component/dealer-reviews/dealer-reviews.component';
import { CustomerEducation } from './page-component/customer-education/customer-education.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerReviewsComponent } from './page-component/customer-reviews/customer-reviews.component';
import { MonthlyCalculatorComponent } from './page-component/monthly-payment-calculator/monthly-payment-calculator.component';
import { AffordabilityCalculatorComponent } from './page-component/affordability-calculator/affordability-calculator.component';
import { EsgComponent } from './page-component/esg/esg.component';
import { AboutUsComponent } from './page-component/about-us/about-us.component';
import { DealerBlogsComponent } from './page-component/dealer-blogs/dealer-blogs.component';
import { CustomerBlogsComponent } from './page-component/customer-blogs/customer-blogs.component';
import { LicenseToDriverLandingComponent } from './page-component/license-to-driver-landing/license-to-driver-landing.component';
import { DealerEducation } from './page-component/dealer-education/dealer-education.component';
import { DealerFaqsComponent } from './page-component/dealer-faqs/dealer-faqs.component';
import { CustomerFaqs } from './page-component/customer-faqs/customer-faqs.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { CareersCorporateLegalComponent } from './page-component/careers-corporate-legal/careers-corporate-legal.component';
import { CareersSalesComponent } from './page-component/careers-sales/careers-sales.component';
import { CareersFinanceComponent } from './page-component/careers-finance/careers-finance.component';
import { CareersCallCenterComponent } from './page-component/careers-call-center/careers-call-center.component';
import { CareersMarketingComponent } from './page-component/careers-marketing/careers-marketing.component';
import { CareersEngineeringComponent } from './page-component/careers-engineering/careers-engineering.component';
import { CareersProductComponent } from './page-component/careers-product/careers-productcomponent';
import { CareersLandingComponent } from './page-component/careers-landing/careers-landing.component';
import { CareersStrategicComponent } from './page-component/careers-stategic/careers-strategic.component';
import { CareersHrComponent } from './page-component/careers-hr/careers-hr.component';
import { CareersAnalyticsComponent } from './page-component/careers-analytics/careers-analytics.component';
import { DealerHowItWorksComponent } from './page-component/dealer-how-it-works/dealer-how-it-works.component';
import { StartMyJourneyComponent } from './page-component/start-my-journey/start-my-journey.component';
import { DealerHome } from './page-component/dealers/dealer-home.component';
import { CustomerHowItWorksComponent } from './page-component/customer-how-it-works/customer-how-it-works.component';
import { YourCreditApprovalComponent } from './page-component/your-credit-approval/your-credit-approval.component';
import { DealersPurchaseProgramComponent } from './page-component/dealers-purchase-program/dealers-purchase-program.component';
import { SearchComponent } from './page-component/search/search.component';
import { PageNotFoundComponent } from './page-component/page-not-found/page-not-found.component';
import { MaintenanceComponent } from './page-component/maintenance/maintenance.component';
import { TermsOfUseComponent } from './page-component/terms-of-use/terms-of-use.component';
import { NycLanguageComponent } from './page-component/nyc-language/nyc-language.component';
import { WebAccessibilityComponent } from './page-component/web-accessibility/web-accessibility.component';
import { DealerEsignatureComponent } from './page-component/dealer-esignature/dealer-esignature.component';
import { PrivacyComponent } from './page-component/privacy/privacy.component';
import { WebsiteUserAgreementComponent } from './page-component/website-user-agreement/website-user-agreement.component';
import { PortfolioLandingComponent } from './page-component/portfolio-landing/portfolio-landing.component';
import { GlossaryComponent } from './page-component/glossary/glossary.component';
import { CommunityComponent } from './page-component/community/community.component';
import { CarBuyersResourceComponent } from './page-component/car-buyers-resource/car-buyes-resource.component';
import { OnlineVerificationComponent } from './page-component/online-verification/online-verification.component';
import { JoshuaMotorsComponent } from './page-component/joshua-motors/joshua-motors.component';
import { SteveLandersComponent } from './page-component/steve-landers-auto/steve-landers-auto.component';
import { MagicCityMotorsComponent } from './page-component/magic-city-motors/magic-city-motors.component';
import { BellAutoSalesComponent } from './page-component/bell-auto/bell-auto.component';
import { AutoMaxComponent } from './page-component/automax/automax.component';
import { ConsumerVideosComponent } from './page-component/consumer-videos/consumer-videos.component';
import { DealerVideosComponent } from './page-component/dealer-videos/dealer-videos.component';
import { LonzellWebbComponent } from './page-component/lonzell-webb/lonzell-webb.component';
import { JoinNetworkComponent } from './page-component/join-our-network/join-our-network.component';
import { JoinNetworkThankYouComponent } from './page-component/join-our-network-thank-you/join-our-network-thank-you.component';
import { ReelsAutoSalesComponent } from './page-component/reels-auto-sales/reels-auto-sales.component';
import { PremierAutomotiveComponent } from './page-component/premier-automotive/premier-automotive.component';
import { ZeiglerAutoComponent } from './page-component/zeigler-auto/zeigler-auto.component';
import { MooreStewartFordComponent } from './page-component/moore-stewart-ford/moore-stewart-ford.component';
import { WebsmartAutoComponent } from './page-component/websmart-auto/websmart-auto.component';
import { RideRiteAutoComponent } from './page-component/ride-rite-auto/ride-rite-auto.component';
import { CapsMaintenanceComponent } from './page-component/caps-maintenance/caps-maintenance.component';
import { TwinsAutoSalesComponent } from './page-component/twins-auto-sales/twins-auto-sales.component';
import { AuthExceptionComponent } from './page-component/auth-exception/auth-exception.component';
import { CovidResponseComponent } from './page-component/covid-response/covid-response.component';
import { CapsRedirectComponent } from './page-component/caps-redirect/caps-redirect.component';
import { DealerFinderComponent } from './page-component/dealer-finder/dealer-finder.component';
import { GrowYourDealershipSpComponent } from './page-component/grow-your-dealership-sp/grow-your-dealership-sp.component';
import { BigCheckCampaignComponent } from './page-component/big-check-campaign/big-check-campaign.component';
import { BuyHerePayHereCampaignComponent } from './page-component/buy-here-pay-here-campaign/buy-here-pay-here-campaign.component';
import {LegacyBlogArticleComponent} from "./page-component/legacy-blog-article/legacy-blog-article.component";
import { BlogArticleComponent } from './page-component/blogs/blog-article/blog-article.component';
import { DealerFinderListComponent } from './page-component/dealer-finder/dealer-finder-list/dealer-finder-list.component';
import { DealerFinderMapComponent } from './page-component/dealer-finder/dealer-finder-map/dealer-finder-map.component';
import { DealerFinderPrequalifiedComponent } from './page-component/dealer-finder/dealer-finder-prequalified/dealer-finder-prequalified.component';
import { DealerFinderSearchComponent } from './page-component/dealer-finder/dealer-finder-search/dealer-finder-search.component';
import { DealerFinderContentComponent } from './page-component/dealer-finder/dealer-finder-content/dealer-finder-content.component';
import {FeatureFlagService, initializeFeatureFlagService} from './services/feature-flag.service';
import { RecaptchaInterceptor } from './interceptors/recaptcha.interceptor';
import { DealerFinderNoResultsComponent } from './page-component/dealer-finder/dealer-finder-no-results/dealer-finder-no-results.component';
import { ConsumerPaidChannelLandingComponent } from "./page-component/consumer-paid-channel-landing/consumer-paid-channel-landing.component";
import { NoSsnNoItinCampaignComponent } from './page-component/no-ssn-no-itin-campaign/no-ssn-no-itin-campaign.component';
import { DealersPurchaseProgramFlatComponent } from './page-component/dealers-purchase-program-flat/dealers-purchase-program-flat.component';


@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    HowItWorksComponent,
    CapsDealOptimizerComponent,
    CapsDealOptimizerSpComponent,
    PromoComponent,
    GrowYourDealershipComponent,
    GrowYourDealershipSpComponent,
    PromoRewardsComponent,
    WorkTheDealComponent,
    CacCustomersHomeComponent,
    ContactUsComponent,
    GuestPaymentComponent,
    MakeAPaymentComponent,
    DealerReviewsComponent,
    DealerBlogsComponent,
    CustomerReviewsComponent,
    CustomerBlogsComponent,
    CustomerEducation,
    MonthlyCalculatorComponent,
    AffordabilityCalculatorComponent,
    EsgComponent,
    AboutUsComponent,
    LicenseToDriverLandingComponent,
    DealerEducation,
    DealerFaqsComponent,
    CustomerFaqs,
    CareersCorporateLegalComponent,
    CareersSalesComponent,
    CareersCallCenterComponent,
    CareersFinanceComponent,
    CareersMarketingComponent,
    CareersEngineeringComponent,
    CareersProductComponent,
    CareersLandingComponent,
    CareersStrategicComponent,
    CareersHrComponent,
    CareersAnalyticsComponent,
    DealerHowItWorksComponent,
    CustomerHowItWorksComponent,
    StartMyJourneyComponent,
    DealerHome,
    YourCreditApprovalComponent,
    DealersPurchaseProgramComponent,
    SearchComponent,
    PageNotFoundComponent,
    MaintenanceComponent,
    TermsOfUseComponent,
    NycLanguageComponent,
    WebAccessibilityComponent,
    WebsiteUserAgreementComponent,
    DealerEsignatureComponent,
    PrivacyComponent,
    PortfolioLandingComponent,
    GlossaryComponent,
    PortfolioLandingComponent,
    CommunityComponent,
    CarBuyersResourceComponent,
    OnlineVerificationComponent,
    JoshuaMotorsComponent,
    SteveLandersComponent,
    MagicCityMotorsComponent,
    BellAutoSalesComponent,
    AutoMaxComponent,
    ConsumerVideosComponent,
    DealerVideosComponent,
    LonzellWebbComponent,
    JoinNetworkComponent,
    JoinNetworkThankYouComponent,
    ZeiglerAutoComponent,
    WebsmartAutoComponent,
    RideRiteAutoComponent,
    ReelsAutoSalesComponent,
    PremierAutomotiveComponent,
    TwinsAutoSalesComponent,
    ZeiglerAutoComponent,
    MooreStewartFordComponent,
    AuthExceptionComponent,
    CovidResponseComponent,
    CapsMaintenanceComponent,
    CapsRedirectComponent,
    DealerFinderComponent,
    DealerFinderListComponent,
    DealerFinderMapComponent,
    DealerFinderPrequalifiedComponent,
    DealerFinderSearchComponent,
    DealerFinderContentComponent,
    DealerFinderNoResultsComponent,
    BigCheckCampaignComponent,
    BuyHerePayHereCampaignComponent,
    BlogArticleComponent,
    LegacyBlogArticleComponent,
    ConsumerPaidChannelLandingComponent,
    NoSsnNoItinCampaignComponent,
    DealersPurchaseProgramFlatComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CreditMaterialModule,
    HttpClientModule,
    SharedModule,
    CacAngularSharedComponentsModule,
    FormsModule,
    ScullyLibModule,
    GoogleMapsModule,
  ],
  providers: [
    Meta,
    TitleCasePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeFeatureFlagService,
      deps: [FeatureFlagService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RecaptchaInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
