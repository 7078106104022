import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditMaterialModule } from './credit-material.module';

import { AcceptanceCardComponent } from 'app/shared/acceptance-card/acceptance-card.component';
import { CarouselImplComponent } from 'app/shared/carousel-impl/carousel-impl.component';
import { NumbersSpeakComponent } from 'app/shared/numbers-speak/numbers-speak.component';
import { UsersToggleComponent } from 'app/shared/users-toggle/users-toggle.component';
import { SkipToContentComponent } from 'app/shared/skip-to-content/skip-to-content.component';
import { HeroImageComponent } from 'app/shared/hero-image/hero-image.component';
import { LinksSectionsComponent } from 'app/shared/links-sections/links-sections.component';
import { FooterComponent } from 'app/shared/footer/footer.component';
import { HeaderComponent } from 'app/shared/header/header.component';
import { SitemapComponent } from 'app/shared/sitemap/sitemap.component';
import { AccordionComponent } from 'app/shared/accordion/accordion.component';

import { AnchorClickDirective } from 'app/directives/anchor-click.directive';
import { QuickLinksComponent } from 'app/shared/quick-links/quick-links.component';
import { ImageTextOverlayComponent } from 'app/shared/image-text-overlay/image-text-overlay.component';
import { CreditIntroductionComponent } from 'app/shared/credit-introduction/credit-introduction.component';
import { HearFromPeopleComponent } from 'app/shared/hear-from-people/hear-from-people.component';
import { PortfolioPurchaseComponent } from 'app/shared/portfolio-purchase/portfolio-purchase.component';
import { CacAngularSharedComponentsModule } from '@credit-acceptance/cac-angular-shared-components';
import { AddressRestriction } from 'app/directives/address.directive';

@NgModule({
  declarations: [
    AcceptanceCardComponent,
    NumbersSpeakComponent,
    UsersToggleComponent,
    CarouselImplComponent,
    AnchorClickDirective,
    AddressRestriction,
    SkipToContentComponent,
    HeroImageComponent,
    FooterComponent,
    SitemapComponent,
    HeaderComponent,
    AccordionComponent,
    LinksSectionsComponent,
    QuickLinksComponent,
    ImageTextOverlayComponent,
    CreditIntroductionComponent,
    HearFromPeopleComponent,
    PortfolioPurchaseComponent
  ],
  imports: [
    CommonModule,
    CreditMaterialModule,
    CacAngularSharedComponentsModule
  ],
  exports: [
    AcceptanceCardComponent,
    NumbersSpeakComponent,
    UsersToggleComponent,
    CarouselImplComponent,
    AnchorClickDirective,
    SkipToContentComponent,
    HeroImageComponent,
    FooterComponent,
    SitemapComponent,
    HeaderComponent,
    AccordionComponent,
    LinksSectionsComponent,
    QuickLinksComponent,
    ImageTextOverlayComponent,
    CreditIntroductionComponent,
    HearFromPeopleComponent,
    PortfolioPurchaseComponent,
    AddressRestriction
  ]
})
export class SharedModule { }
