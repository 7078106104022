import { Component, OnInit, PLATFORM_ID, Inject  } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { CAREERS_MARKETING_JSON_URL } from 'app/constants/aws.constants';
import {AWSService} from 'app/services/aws.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';


@Component({
  selector: 'app-careers-marketing',
  templateUrl: './careers-marketing.component.html',
  styleUrls: ['./careers-marketing.component.scss']
})
export class CareersMarketingComponent implements OnInit {

  
  subscription: Subscription[] = [];

  url = CAREERS_MARKETING_JSON_URL;
  
  CareersMarketingJSONData:any;
  
  heroImageInfo : any = {};
  bottonBgColor: string = "#ffb800";
  overLineBgColor: string = "#171714";
  headerColor: string = "#FFFFFF";
  disclaimerColor: string = "#171714";
  ariaLabel: string = "Careers Corporate Legal";
  isMobileViewDisplay : boolean = false;
  isCurvedImage = true;
  isBrowser : boolean = false;
  isServer : boolean = false;
  perksData : any = {};
  contentBlockData : any = {};
  prideComponentData : any = {};
  cacAdBlockTwoDataData : any = {};
  
  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, private transferState : TransferState , @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }

  ngOnInit(): void {
  
    if(this.isBrowser){
    
      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);
        
        this.CareersMarketingJSONData = json;
        this.heroImageInfo = this.CareersMarketingJSONData.CacHeroImageComponent;
        this.perksData = this.CareersMarketingJSONData.CacPerksComponent;
        this.contentBlockData = this.CareersMarketingJSONData.CacContentBlockComponent;
        this.cacAdBlockTwoDataData = this.CareersMarketingJSONData.CacAdBlockTwoComponent;
        this.prideComponentData = this.CareersMarketingJSONData.CacPrideComponent;
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          if (result.matches) {
            this.isMobileViewDisplay = true;
          }
        })
    );
  }

}
