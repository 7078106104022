import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {AWSService} from 'app/services/aws.service';
import { isPlatformBrowser } from '@angular/common';
import { COVID_RESPONSE_JSON_URL } from 'app/constants/aws.constants';

@Component({
  selector: 'app-covid-response',
  templateUrl: './covid-response.component.html',
  styleUrls: ['./covid-response.component.scss']
})
export class CovidResponseComponent implements OnInit{
  subscription: Subscription[] = [];
  isBrowser : boolean = false;
  isMobileViewDisplay : boolean = false;

  url = COVID_RESPONSE_JSON_URL;
  covidResponseJSONData:any;
  headerData: any
  textBlockData1: any
  textBlockData2: any
  textBlockData3: any
  
  constructor(private responsive: BreakpointObserver, private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    //Add no robots meta tag
    let meta = document.createElement('meta');
    meta.name = "robots";
    meta.content = "noindex";
    document.getElementsByTagName('head')[0].appendChild(meta);
  }
  ngOnInit(): void {
    if(this.isBrowser){

      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);

        this.covidResponseJSONData = json;
        this.headerData = this.covidResponseJSONData.heading;
        this.textBlockData1 = this.covidResponseJSONData.CacCreditIntroductionReview1;
        this.textBlockData2 = this.covidResponseJSONData.CacCreditIntroductionReview2;
        this.textBlockData3 = this.covidResponseJSONData.CacCreditIntroductionReview3;
      });
    }
    
    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }

}
