import { cardDetails } from "app/models/cardDetails";

export const articles: cardDetails[]= [
  {
    "title":["What documents do I need to purchase a vehicle?"],
    "description": ["So you’re gearing up to purchase a vehicle and have been cruising down your list of car-buying to-dos. You’ve set a budget, narrowed down your options, and now you’re ready to take your soon-to-be ride for a test spin and, hopefully, off the lot."],
    "buttonName": "more >",
    "link":"/blog/consumer/what-documents-do-i-need-to-purchase-a-vehicle-",
    "date":"05/20/2023",
    "imgUrl":"assets/images/articles/doc-blog-img@3x.webp",
    "imgAlt":"What documents do I need to purchase a vehicle",
  },
  {
    "title":["Get financed through an enrolled dealer."],
    "description": ["It’s no secret that having a poor credit history can make getting approved for financing difficult. Credit Acceptance believes everyone deserves a second chance, which is why we enable dealers enrolled on our program to approve everyone, including customers with bad credit history or no credit history at all. Here’s how the program works."],
    "buttonName": "more >",
    "link":"/blog/consumer/how-it-works-getting-financed-through-a-dealer-enrolled-with-credit-acceptance",
    "date":"05/18/2005",
    "imgUrl":"assets/images/articles/enrolled-dealer-blog@2x.webp",
    "imgAlt":"enrolled-dealer-blog Image",
  },
  {
    "title":["How to pay off your financing faster."],
    "description": ["When purchasing a vehicle, most car buyers are focused on getting approved for a car loan or auto financing. With auto lenders and finance companies making credit to buy a car more accessible to borrowers, it’s possible for car financing terms to stretch out for years."],
    "buttonName": "more >",
    "link":"/blog/consumer/how-to-pay-off-your-car-financing-faster",
    "date":"11/21/2011",
    "imgUrl":"assets/images/articles/patoff-img-blog@2x.webp",
    "imgAlt":"How to pay off your financing faster image",
  }

] 
