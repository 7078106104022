import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dealer-finder-no-results',
  template: `
    <div class="sub-components-margin">
      <div #noResults
        style="font-family: Acceptance Regular;"
        class="body-description"
        [innerHTML]="noDealersFound"
      ></div>
    </div>
  `,
})
export class DealerFinderNoResultsComponent implements AfterViewInit {
  @Input() noDealersFound: any;

  @Output() elementClicked = new EventEmitter<{
    additionalEventName: string,
    eventLabel: string,
    eventUrl: string,
    dealerId: string,
  }>(); 

  @ViewChild('noResults') noResults: ElementRef;


  ngAfterViewInit(): void {
    // find href element
    const anchor = this.noResults.nativeElement.querySelector('a');

    anchor?.addEventListener('click', () => {
      this.elementClicked.emit({
        additionalEventName: 'cac-support-phone-click',
        eventLabel: 'cac customer support phone click',
        eventUrl: anchor.getAttribute('href') ?? '',
        dealerId: ''
      });
    });
  }
}
