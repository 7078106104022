import { cardDetails } from "app/models/cardDetails";

export const Accessories: cardDetails = {
  "title":["Los accesorios instalados por el concesionario pueden ayudarle a crecer sus ventas de vehículos."],
  "id":"dealer_accessories_title",
  "sectionName":"Delar Accessories Section",
  "description": [
    "Ofrecer y financiar los accesorios instalados por el concesionario es una muy buena manera de dar la opción a los clientes de añadir productos de valor que incrementarán su satisfacción con el vehículo y de ayudarlo a usted a vender más vehículos. Los accesorios opcionales instalados por el concesionario pueden incluir:"
  ],
  "points": [
    'Ventanas polarizadas',
    'Estribos',
    'Portaequipajes de techo',
    'Paquete de remolque'
  ],
  "buttonName": "INICIAR SESIÓN EN CAPS",
  "imgUrl":"assets/images/grow-your-dealership/users-toggle/ways-business-grow/img_accessories-img@2x.png",
  "imgAlt":"Dealers Accessories Image",
  "ariaLabel":"Dealer Tab Section",
  "buttonId": "dealer_accessories_caps_link",
  "link":"/dealers/caps-redirect"
}
