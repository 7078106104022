import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { AWSService } from 'app/services/aws.service';
import { JOIN_NETWORK_THANK_YOU_PAGE_JSON_URL } from 'app/constants/aws.constants';
import { ContentService } from 'app/services/content.service';
import { Router } from '@angular/router';

@Component({
  selector: 'join-our-network-thank-you',
  templateUrl: './join-our-network-thank-you.component.html',
  styleUrls: ['./join-our-network-thank-you.component.scss'],
})
export class JoinNetworkThankYouComponent {
  subscription: Subscription[] = [];

  url = JOIN_NETWORK_THANK_YOU_PAGE_JSON_URL;

  ThankYouJSONData: any;

  heroImageInfo: any = {};
  bottonBgColor: string = '#171714';
  overLineBgColor: string = '#FA6400';
  headerColor: string = '#FFFFFF';
  disclaimerColor: string = '#FCFCFA';
  ariaLabel: string = 'Thank You';
  isMobileViewDisplay: boolean = false;
  isCurvedImage = false;
  isBrowser: boolean = false;
  isServer: boolean = false;
  CacThankYouMessageContent: any ;
  userName: string = '';
  successResponse: any;

  constructor(
    private http: HttpClient,
    private responsive: BreakpointObserver,
    private awsService: AWSService,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Object,
    private contentService: ContentService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }

  ngOnInit(): void {
    this.resetZoom();
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    this.successResponse = this.contentService.getBecomeADealerReponse();

    if (this.successResponse) {
      this.updateNameFormat();
      this.updatePhoneNumber();
     
      if (this.isBrowser) {
        this.awsService.getAWSData(this.url).then((json) => {
          json = JSON.stringify(json).replace(
            /AWS_BUCKET_URL/g,
            this.awsService.getAWSBucketURL()
          );
          json = JSON.parse(json);

          this.ThankYouJSONData = json;
          this.heroImageInfo = this.ThankYouJSONData.CacHeroImageComponent;

          this.CacThankYouMessageContent =
            this.ThankYouJSONData.CacThankYouMessageContent;
        });
      }
    } else {
      this.router.navigate(['/dealers/join-our-network']);
    }

    this.subscription.push(
      this.responsive
        .observe([Breakpoints.XSmall, Breakpoints.Small])
        .subscribe((result) => {
          if (result.matches) {
            this.isMobileViewDisplay = true;
          }
        })
    );
  }

  updateNameFormat(): void {
    if (this.successResponse && this.successResponse.name) {
      this.successResponse.name = this.successResponse.name.split(',').map((part: string) => part.trim()).reverse().join(' ');
    }
  }

  updatePhoneNumber(): void {
    if (this.successResponse && this.successResponse.phone) {
      // Remove all non-numeric characters
      let phone = this.successResponse.phone.replace(/\D/g, '');
      // Prepend '1' if not a 10-digit number
      phone = phone.replace(/\D/g, '');
      if (phone.length == 10) {
        phone = '1' + phone;
      }

      // Format as 1.###.###.####
      this.successResponse.phone = phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1.$2.$3.$4');
    }
  }

  resetZoom(): void {
    const viewport = document.querySelector('meta[name="viewport"]');
    if (viewport) {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=yes');
    }
  }

}
