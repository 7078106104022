import { inject, Injectable, OnDestroy } from '@angular/core';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { Subject, Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, filter, mergeMap, switchMap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService implements OnDestroy {
  private readonly document = inject(DOCUMENT);
  private readonly flagsSubject = new Subject<string>();
  private readonly initialized = new BehaviorSubject<boolean>(false);

  private ldClient!: LDClient.LDClient;

  async initializeClient() {
    const defaultContext = { anonymous: true };

    if (!this.ldClient) {
      this.ldClient = LDClient.initialize(
        this.getClientSideId(),
        defaultContext,
        {
          streaming: true,
        }
      );

      try {
        await this.ldClient.waitForInitialization();
        this.initialized.next(true);

        this.ldClient.on('change', (changes) => {
          Object.keys(changes).forEach((flagKey) => {
            this.flagsSubject.next(flagKey);
          });
        });
      } catch (err) {
        console.error('LaunchDarkly client failed to initialize:', err);
      }
    }
  }

  watchEnabledFlag(
    flagName: string,
    defaultValue = false
  ): Observable<boolean> {
    return this.flagsSubject.pipe(
      filter((key) => key === flagName),
      mergeMap(() => this.isEnabled(flagName, defaultValue))
    );
  }

  isEnabled(flagName: string, defaultValue = false): Observable<boolean> {
    return this.initialized.asObservable().pipe(
      filter((isInitalized) => isInitalized),
      switchMap(() =>
        of(this.ldClient.variation(flagName, defaultValue) as boolean)
      ),
      catchError((error) => {
        console.error(`Error evaluating feature flag '${flagName}':`, error);
        return of(false);
      })
    );
  }

  ngOnDestroy() {
    if (this.ldClient) {
      this.ldClient.close();
    }
    this.flagsSubject.complete();
  }

  private getClientSideId(): string {
    const hostname = this.document.defaultView?.location.hostname ?? '';
    switch (true) {
      case hostname.includes('localhost'):
      case hostname.includes('dev'):
        return '673f40dae362b108834bbfdf';
      case hostname.includes('test'):
        return '673f40b7bedae00878e73b5a';
      case hostname.includes('qa'):
        return '673f411b27e1d40892067620';
      default: // Default returns the key used for production
        return '673f40b7bedae00878e73b5b';
    }
  }
}

export function initializeFeatureFlagService(
  featureFlagService: FeatureFlagService
): () => Promise<void> {
  return () => featureFlagService.initializeClient();
}
