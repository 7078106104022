import { TabToggleInfo } from "app/models/toggle_tabs"
import { DealOptimizer } from "./deal-optimizer"
import { Accessories } from "./accessories"

export const waysBusinessGrow: TabToggleInfo = {
  heading:["More ways to grow your business."],
  tabInformation: [
    {
      tabTitle: "CAPS®",
      active: true,
      tabId:"deal_optimizer_tab",
      tabContent: DealOptimizer
    },
    {
      tabTitle: "Accessories",
      active: false,
      tabId:"accessories_tab",
      tabContent: Accessories
    }
  ]
}
