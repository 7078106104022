import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import {map, Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-caps-deal-optimizer-sp',
  templateUrl: './caps-deal-optimizer-sp.component.html',
  styleUrls: ['./caps-deal-optimizer-sp.component.scss']
})
export class CapsDealOptimizerSpComponent implements OnInit {
  isMobileView$!: Observable<boolean>

  constructor(private responsive: BreakpointObserver){}

  ngOnInit() {
    this.isMobileView$ = this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(
      map(result => result.matches)
    )
  }
}
