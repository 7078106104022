<app-header></app-header>
<div class='bottom-shadow' [ngClass]="{'mob-height': isMobileViewDisplay}" class="sub-components-margin">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [disclaimerColor]='disclaimerColor'
        [bottonBgColor]="bottonBgColor" [overLineBgColor]="overLineBgColor" [isCurvedImage]="isCurvedImage">
    </cac-hero-image>
</div>
<div [ngClass]="isMobileViewDisplay ? 'mobile-container' : 'container'">
    <div class="faq-search" [ngClass]='{"d-flex" : !isMobileViewDisplay}'>
        <div class='search-label'>
            Find Answers To Your Questions
        </div>
        <div class='search-box'>
            <input id="customerFaqSearch" class='cac-text-box search-icon' type="text" aria-label="customerFaqSearch"
                [(ngModel)]="searchKeyword" #searchText (click)="selectText(searchText)" (keyup.enter)="search()" (input)="checkEmptyKeyword()"/>
            <button class='btn btn-primary search-button' (click)="search()">Submit</button>
        </div>
    </div>

    <div *ngIf='searchKeyword!="" && showResults'>
        <div class="search-results-border">
        </div>
        <div class="search-results">
            We found {{filteredFaqs.faqs.length}} results for "{{searchDisplayKeyword}}"
        </div>
    </div>

    <div>
        <cac-faqs [data]="filteredFaqs"></cac-faqs>
    </div>

    <div *ngIf='searchKeyword!="" && showResults'>
        <div class="search-results-border pt-3">
        </div>
    </div>
</div>

<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>