import { Injectable } from '@angular/core';
import { quickLinks } from '../data/header/quick_links';
import { topHeader } from "../data/header/top_header";
import { topSideHeader } from '../data/header/top_side_header';
import { creditLinks } from '../models/credit_links';
import { footerLinks } from '../data/footer/footer_links';
import { footerImages } from '../data/footer/footer_images';
import { siteMapLinks } from '../data/sitemap/sitemap_links';
import { articles } from '../data/articles/articles';
import { quickLink } from 'app/models/quickLink';


@Injectable({
  providedIn: 'root'
})
export class ContentService {

  becomeADealerSuccessReponse: any;

  jonWidgetLoaded: boolean = false;
  
  constructor() { }

  getTopNavigationLinks(): creditLinks[] {
    return topHeader;
  }

  getQuickLinks(): quickLink[] {
    return quickLinks;
  }

  getTopSideHeaderLinks(): creditLinks[] {
    return topSideHeader;
  }

  getFooterLinks(): creditLinks[] {
    return footerLinks;
  }

  getFooterImages() : creditLinks[] {
    return footerImages;
  }

  getSiteMapLinks() : creditLinks[]{
    return siteMapLinks;
  }

  getArticles() {
    return articles;
  }

  setBecomeADealerReponse(successReposne: any) {
    this.becomeADealerSuccessReponse = successReposne;
  }
  
  getBecomeADealerReponse() {
    return this.becomeADealerSuccessReponse;
  }

  setWidgetLoaded() {
    this.jonWidgetLoaded = true;
  }

  isWidgetLoaded() {
    return this.jonWidgetLoaded;
  }

}
