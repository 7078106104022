<div class="bgc-credit-white py-4">
  <div class="container px-4">
    <div *ngIf="!isMobileView">
      <div class="row" >
        <ng-container *ngFor="let detail of details;let i = index">
          <div class="col-6">
            <div class="py-5">
              <span class="h3"><span attr.aria-label="{{titles[i]}}" class="text-nowrap secondary-heading">{{titles[i]}}</span></span>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="card-group">
          <ng-container *ngFor="let detail of details;let i = index">
              <div class= "card card-expands rounded-0 me-4">
                <app-acceptance-card [loadOneCardSection]="true" [oneCardDetail]="detail" [isMobileView]="isMobileView" [sectionName]="'portfolio-prog'" [pointsClass]="'decimal-list'"></app-acceptance-card>
              </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="isMobileView">
      <div class="row">
        <ng-container *ngFor="let detail of details;let i = index">
          <div class = "p-3 col-12">
            <div class="py-5">
              <span class="h3"><span attr.aria-label="{{titles[i]}}" class="secondary-heading">{{titles[i]}}</span></span>
            </div>
            <app-acceptance-card [loadOneCardSection]="true" [oneCardDetail]="detail" [isMobileView]="isMobileView" [sectionName]="'portfolio-prog'" [cardClasses]="'card card-expands rounded-0'" [pointsClass]="'decimal-list'"></app-acceptance-card>
          </div>
        </ng-container>
      </div>
    </div>

    <div *ngIf="capsLoginGetStartedConfig" class="text-center px-4" [ngClass]="isMobileView ? 'py-3' : 'py-5'">
      <a class="indigo-link" attr.aria-label="{{capsLoginGetStartedConfig.ariaLabel}}" role="link"
         id="{{capsLoginGetStartedConfig.id}}" appAnchorClick [linkInfo]="capsLoginGetStartedConfig">{{capsLoginGetStartedConfig.title}}</a>
    </div>
  </div>
</div>
