import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { COMMUNITY_JSON_URL } from 'app/constants/aws.constants';
import {AWSService} from 'app/services/aws.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss']
})
export class CommunityComponent implements OnInit {

  subscription: Subscription[] = [];

  url = COMMUNITY_JSON_URL;

  CommunityJSONData:any;

  isBrowser : boolean = false;
  isMobileViewDisplay : boolean = false;
  heroImageInfo : any = {};
  bottonBgColor: string = "#FFB800";
  overLineBgColor: string = "#2B4361";
  overLineTextColor: string = "#FFFFFF";
  headerColor: string = "#FFFFFF";
  disclaimerColor: string = "#2B4361";
  isCurvedImage = false;
  introData : any;
  videoData : any;
  introData_1 : any;
  coursalData : any;

  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if(this.isBrowser){

      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);

        this.CommunityJSONData = json;
        this.heroImageInfo = this.CommunityJSONData.CacHeroImageComponent;
        this.introData = this.CommunityJSONData.CacCreditIntroductionCommunity;
        this.introData_1 = this.CommunityJSONData.CacCreditIntroductionCommunity_1;
        this.coursalData = this.CommunityJSONData.CacCoursalImgComponent;
        this.videoData = this.CommunityJSONData.CacVideoComponent;

      });
    }
    
    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }

}
