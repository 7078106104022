<ng-container *ngIf="data | async as data">
  <app-header></app-header>

  <div class="container">

    <div class="row">
      <div class="d-flex flex-column" [class]="data.isMobile ? 'col-12' : 'col-8'">
        <div class="row d-flex align-items-center justify-content-center hero-banner"
          [class.flex-column]="data.isMobile">
          <div class="d-flex justify-content-between align-items-center flex-column flex-lg-row">
            <div [ngClass]="{ 'text-center hero-banner-mobile-font': data.isMobile, 'col-lg-8 pb-5': !data.isMobile }">
              <h1 class="hero-description ps-3 mt-3">{{ data.heroBanner.headingDescription }}</h1>
            </div>
            <div class="col-lg-4 text-center">
              <img [src]="data.isMobile ? data.heroBanner.image.mobileSrc : data.heroBanner.image.defaultSrc"
                [class]="data.isMobile ? 'd-block img-fluid hero-image-mobile' : 'd-block img-fluid'" [alt]="data.heroBanner.image.alt" />
            </div>
          </div>
        </div>

        <div class="row" [class]="data.isMobile ? 'flex-column mt-4 px-3' : 'my-4'">
          <div class="mb-3">
            <p class="content-description">
              {{ data.howItWorks.contentSection.description1 }}
            </p>

            <p class="content-description">
              {{ data.howItWorks.contentSection.description2 }}
            </p>
          </div>
          <div class="d-flex flex-column" [class]="data.isMobile ? 'col-12' : 'col-6'">


            <p class="video-description">
              {{ data.howItWorks.videoSection.description }}
            </p>

            <div class="ratio ratio-16x9">
              <cac-iframe [width]="'100%'" [height]="'100%'" [sourceUrl]="data.howItWorks.videoSection.src"
                [ariaLabel]="data.howItWorks.videoSection.ariaLabel" frameborder="0">
              </cac-iframe>
            </div>
          </div>

          <div [class]="data.isMobile ? 'col-12 mt-3' : 'col-6'">
            <ul class="list-unstyled mb-4">
              <li *ngFor="let point of data.howItWorks.contentSection.details" class="d-flex align-items-top">
                <img [src]="data.howItWorks.contentSection.icon" [alt]="data.howItWorks.contentSection.iconAlt"
                  class="align-self-start me-2" />
                <!-- Flex container for text, stacking heading and content vertically -->
                <div style="display: flex; flex-direction: column;">
                  <p class="detail-heading">{{ point.heading }}</p>
                  <p class="detail-content">{{ point.content }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div [class]="data.isMobile ? 'col-12' : 'col-4 ps-5'">
        <join-our-network-form [environment]="environment"
          (successData)="handleSuccess($event)"></join-our-network-form>
      </div>

    </div>

    <div class="row">
      <app-sitemap [isMobileView]="data.isMobile"></app-sitemap>
      <div class="container px-4">
        <b class="m-0 pt-3">{{ data.howItWorks.contentSection.disclosure.heading }}</b>
        <p class="footer-links text-decoration-none text-transform-none">{{ data.howItWorks.contentSection.disclosure.description }}</p>
      </div>
      <app-footer></app-footer>
    </div>

  </div>
</ng-container>