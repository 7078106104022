import { Component, OnInit, PLATFORM_ID, Inject  } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { DEALER_EDUCATION_JSON_URL } from 'app/constants/aws.constants';
import {AWSService} from 'app/services/aws.service';
import { TransferState } from '@angular/platform-browser';



@Component({
  selector: 'app-dealer-education',
  templateUrl: './dealer-education.component.html',
  styleUrls: ['./dealer-education.component.scss']
})
export class DealerEducation implements OnInit {

  
  subscription: Subscription[] = [];

  url = DEALER_EDUCATION_JSON_URL;
  
  DealerEducationJSONData:any;
  
  heroImageInfo : any = {};
  bottonBgColor: string = "#C4EDFA";
  overLineBgColor: string = "#B34700";
  headerColor: string = "#171714";
  disclaimerColor: string = "#171714";
  isMobileViewDisplay : boolean = false;
  isCurvedImage = true;
  isBrowser : boolean = false;
  isServer : boolean = false;
  anchorDeviders : any;
  videoData : any;
  articleData : any;
  testimonialData : any = {};
  dealerReviewsButton : any = {};
  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, private transferState : TransferState , @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }

  ngOnInit(): void {
  
    
    if(this.isBrowser){
    
      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);
        
        this.DealerEducationJSONData = json;
        this.heroImageInfo = this.DealerEducationJSONData.CacHeroImageComponent;
        this.anchorDeviders = this.DealerEducationJSONData.CacJumpLinkComponent;
        this.videoData = this.DealerEducationJSONData.CacVideoComponent;
        this.articleData = this.DealerEducationJSONData.CacArticleComponent;
        this.testimonialData = this.DealerEducationJSONData.CacTestimonialComponent;
        this.dealerReviewsButton = this.DealerEducationJSONData.DealerReviewsButton;
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }

}
