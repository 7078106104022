<app-header></app-header>
<div  [ngClass]= "{'mob-height': isMobileViewDisplay}" class="sub-components-margin">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [disclaimerColor]='disclaimerColor' [bottonBgColor]="bottonBgColor"
        [overLineBgColor]="overLineBgColor" [isCurvedImage]="isCurvedImage"></cac-hero-image>
</div>

<div class="components-margin">
    <cac-tabbed-content *ngIf="tabbedContentBlock" [data]="tabbedContentBlock"></cac-tabbed-content>
</div>

<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>