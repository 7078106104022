import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { DealerSearchPayload } from 'app/page-component/dealer-finder/interfaces/dealer-search-payload.interface';
import { Observable } from 'rxjs';
import { DealerFinderSearchResponse } from 'app/page-component/dealer-finder/interfaces/dealer-finder-search-response';

@Injectable({
  providedIn: 'root',
})
export class DealerManagementService {
  private readonly http = inject(HttpClient);

  searchDealers(
    searchDealerPayload: DealerSearchPayload,
    reCaptchaToken: string | null,
  ): Observable<DealerFinderSearchResponse> {
    return this.http.post<DealerFinderSearchResponse>(
      `${this.setDomain()}/dealer-finder/search`,
      searchDealerPayload,
      this.generateRecaptchaHeader(reCaptchaToken),
    );
  }

  private setDomain() {
    const hostName = window.location.hostname;

    if (
      hostName.includes(
        'cac-dev-portal-code-www-use2.s3-website.us-east-2.amazonaws.com',
      )
    ) {
      return 'https://www-dealer-finder-app.ue2.dev-k8s.cac.cloud';
    } else if (hostName.includes('test')) {
      return 'https://www-dealer-finder-app.ue2.test-k8s.cac.cloud';
    } else if (hostName.includes('qa')) {
      return 'https://www-dealer-finder-app.ue2.qa-k8s.cac.cloud';
    } else if (hostName.includes('localhost')) {
      return 'http://localhost:3000';
    } else {
      return 'https://www-dealer-finder-app.ue2.k8s.cac.cloud'
    }
  }

  private generateRecaptchaHeader(reCaptchaToken: string | null) {
    return {
      headers: new HttpHeaders(
        reCaptchaToken ? { 'X-Recaptcha-Token': reCaptchaToken } : undefined,
      ),
    };
  }
}
