import { cardDetails } from "app/models/cardDetails";

export const PortFolioPurchaseDetailsSp: cardDetails[] = [
  {
    title: ["El Portfolio Program le brinda la oportunidad de generar futuros ingresos."],
    description: ['Le ofrecemos 3 modos diferentes de ganar:'],
    points: [
      'Avance para cada venta financiada',
      'Portfolio Profit Express (PPE, por sus siglas en inglés): se cierre limitar un conjunto* a 50 o 100 contratos (reciba un pago de PPE)',
      'Portfolio Profit: la posibilidad de recibir el 80 % de los cobros netos en todos los contratos.'
    ],
    sectionName: "PortFolio Program"
  },
  {
    title: ["El Purchase Program** ofrece un avance único más alto:"],
    description: ['los concesionarios usan este programa cuando desean obtener un avance más alto en el momento de financiamiento, o en concesionarios con planes de pago más tradicionales:'],
    points: [
      'Opciones competitivas de financiamiento para vehículos nuevos',
      'Hasta 84 meses para vehículos nuevos'
    ],
    sectionName: "Purchase Program"
  }
]
