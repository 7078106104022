import { cardDetails } from "app/models/cardDetails";

export const customerTabInfo: cardDetails = {
  "title":["Get the “yes” before you get the car: Pre-qualify for financing."],
  "id":"tab_csitoerm",
  "sectionName":"CustomerTabInfo",
  "description": [
    "Did you know you can get pre-qualified for auto financing regardless of your credit history? Our process only takes a few moments.",
    "Once you get the thumbs up that you pre-qualify, we’ll match you to as many as three dealerships in the Credit Acceptance network in your area so you can find a dealer you like and a vehicle you love."
  ],
  "buttonName": "Get Pre-qualified",
  "buttonId":"customer_prequalified",
  "link":"/get-prequalified#/",
  "imgUrl":"assets/images/users-toggle/suonsumer-tab-img-1920@2x.webp",
  "imgAlt":"Customers Image",
  "ariaLabel":"customer Tab Section"
}