import { Component, Input, OnInit } from '@angular/core';
import { creditLinks } from 'app/models/credit_links';

@Component({
  selector: 'app-links-sections',
  templateUrl: './links-sections.component.html',
  styleUrls: ['./links-sections.component.scss']
})
export class LinksSectionsComponent implements OnInit {
  @Input() titleClass: string= '';
  @Input() links: creditLinks = new creditLinks;
  constructor() { }

  ngOnInit(): void {
  }

}
