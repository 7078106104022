import { Component, Input, OnInit } from '@angular/core';
import { quickLink } from 'app/models/quickLink';
import { ContentService } from 'app/services/content.service';

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss']
})
export class QuickLinksComponent implements OnInit {
  @Input() isMobileView: boolean = false;
  @Input() quickLinks: quickLink[] = [];
  @Input() quickLinksId: string = "";

  constructor(private contentService: ContentService) { }

  ngOnInit(): void {
    if(quickLink.length == 0){
      this.quickLinks = this.contentService.getQuickLinks();
      this.quickLinks.forEach((quickLink) => {
        quickLink.isHovered = false;
      })
    }
  }

  getIcon(i: number) {
    const img = this.quickLinks[i]?.isHovered ? this.quickLinks[i]?.onHoverimgUrl : this.quickLinks[i]?.imgUrl;
    return img;
  }
  onHoverAction(i: number, isHovered: boolean) {
    this.quickLinks[i].isHovered =  isHovered;
  }
}
