import { Component, Input, OnInit } from '@angular/core';
import { cardDetails } from 'app/models/cardDetails';

@Component({
  selector: 'app-acceptance-card',
  templateUrl: './acceptance-card.component.html',
  styleUrls: ['./acceptance-card.component.scss']
})
export class AcceptanceCardComponent implements OnInit {

  @Input() cardDetails: cardDetails[] = [];
  @Input() isMobileView: boolean = false;
  @Input() sectionName: string = '';
  @Input() cardClasses: string = '';
  @Input() isPillTypeBtn: boolean = false;
  @Input() buttonClass: string = '';
  @Input() loadOneCardSection: boolean = false;
  @Input() loadOneCardSectionIndex: number = 0;
  @Input() oneCardDetail: cardDetails = new cardDetails;
  @Input() pointsClass: string = '';

  constructor() { }

  ngOnInit(): void {}

  getColWidth(): number {
    return this.isMobileView ? 12 : 12 / Math.floor(this.cardDetails.length);
  }
}
