<div
  class="dealer-finder sub-components-margin" role="main"
  [ngClass]="(isMobileViewDisplay$ | async) ? 'mobile-container' : 'container'"
  id="quick-link-section"
>
 <ng-container *ngIf="showData; else systemErrorDisplay">
  <div class="row">
    <h2
      class="col mb-2 h1-mobile"
    >
      {{ header ? header + ' Credit Acceptance Network Dealers' : awsS3Data?.content?.title }}
    </h2>
  </div>
  <!---DEALER FINDER AUTO-COMPLETE SEARCH SECTION -->
  <app-dealer-finder-search
    [content]="awsS3Data?.content"
    [errors]="(recaptchaError$ | async) || null"
    [serviceError]="showServiceError"
    [initialSearchValue$]="initialSearchValue$"
    (addressChanged)="addressChanged($event)"
  ></app-dealer-finder-search>
  <ng-container *ngIf="this.showServiceError">
    <div class="search__service-error">
      {{awsS3Data?.errors?.serviceFailure}}
    </div>
  </ng-container>
  <!---DEALER FINDER DATA LIST AND GOOGLE MAP SECTION -->
  <ng-container
    *ngIf="
      !this.showServiceError && (dealerFinderData$ | async) as dealerFinderData
    "
  >
    <div *ngIf="dealerFinderData.geoCodeStatus == 'OK'; else geoSeviceNoData">
      <div *ngIf="dealerFinderData.totalCount; else noData">
        <div class="dealer-finder__dealers-text sub-components-margin">
          <h6>
            Nearest {{ dealerFinderData.totalCount }} dealers within
            {{
              dealerFinderData.content[dealerFinderData.totalCount - 1]
                .geoDistance | number : "1.1-1"
            }}
            miles
          </h6>
        </div>
        <div class="dealer-finder__locations sub-components-margin">
          <!---DEALER FINDER DATA LIST -->
          <div [ngClass]="!(isMobileViewDisplay$ | async) ? 'col-4' : 'col-12'">
            <app-dealer-finder-list
              [awsS3content]="awsS3Data?.content"
              [dealerFinderResultsList]="dealerFinderData.content"
              (elementClicked)="captureClickEvent($event)"
            ></app-dealer-finder-list>
          </div>
          <div *ngIf="!(isMobileViewDisplay$ | async)" class="col-8">
            <!---DEALER FINDER GOOGLE MAP -->
            <app-dealer-finder-map
              [markers]="dealerFinderData.markers"
              [geoCenter]="dealerFinderData.geoCenter"
              [zoom]="dealerFinderData.zoom"
            ></app-dealer-finder-map>
          </div>
        </div>
      </div>
      <ng-template #noData>
        <!-- If dealerFinderResult lentgh 0 then show error message-->
        <app-dealer-finder-no-results
          [noDealersFound]="awsS3Data?.content?.noDealerFound"
          (elementClicked)="contactNumberClicked($event)"
        ></app-dealer-finder-no-results>
      </ng-template>
    </div>
    <ng-template #geoSeviceNoData>
      <div class="search__service-error">
        {{awsS3Data?.errors?.geoServiceFailure}}
      </div>
    </ng-template>
  </ng-container>
  
  <div *ngIf="dealerFinderData$ | async as dealerFinderData">
    <!---DEALER FINDER PREQUALIFIED BUTTON -->
    <app-dealer-finder-prequalified
      [totalCount]="dealerFinderData.totalCount"
      [awsS3Content]="awsS3Data?.content"
      [awsS3Disclaimer]="awsS3Data?.disclaimer"
      (elementClicked)="captureClickEvent($event)"
    ></app-dealer-finder-prequalified>
  </div>
 </ng-container>
 <ng-template #systemErrorDisplay>
  <div class="dealer-finder__error-display">
    <h2>
      We apologize for the inconvience. We're unable to <br>
      complete your request at this time. <br>
      Please check back later.
    </h2>
    <button class="btn btn-primary" routerLink="/">GO TO THE HOMEPAGE</button>    
  </div>
</ng-template>
</div>

<app-sitemap
  [isMobileView]="(isMobileViewDisplay$ | async) ?? false"
></app-sitemap>
