import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  catchError,
  combineLatest,
  from,
  map,
  of,
  Subscription,
  take,
} from 'rxjs';
import { DealerFinderService } from './dealer-finder.service';
import { AWSService } from 'app/services/aws.service';
import { DEALER_FINDER_JSON_URL } from 'app/constants/aws.constants';
import { SEOService } from 'app/services/seoService';
import { RecaptchaV3Service } from '../../services/recaptcha-v3.service';
import {
  IdleMonitorService,
  ScullyRoutesService,
  isScullyGenerated,
  isScullyRunning,
} from '@scullyio/ng-lib';
import { DealerFinderS3Data } from './dealer-finder.types';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dealer-finder',
  templateUrl: './dealer-finder.component.html',
})
export class DealerFinderComponent implements OnInit, OnDestroy {
  private maxRetries = 3;

  googleAPIScriptIsLoaded?: boolean = undefined;
  awsS3DataIsLoaded?: boolean = undefined;

  awsS3Data: DealerFinderS3Data;

  routingDataSetSubscription: Subscription;

  get isDataLoaded(): boolean {
    return (
      this.googleAPIScriptIsLoaded !== undefined &&
      this.awsS3DataIsLoaded !== undefined
    );
  }

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly dealerFinderService: DealerFinderService,
    private readonly awsService: AWSService,
    private readonly seoService: SEOService,
    private readonly recaptchaV3Service: RecaptchaV3Service,
    private readonly idleMonitor: IdleMonitorService,
    private readonly scullyRoutes: ScullyRoutesService
  ) {}

  ngOnInit(): void {
    this.seoService.setNoRobotsSEODataTag();
    this.setRoutingData();
    this.loadGoogleMapsAPI();
    this.loadAWSS3Data();
    this.recaptchaV3Service.loadRecaptchaScript();
  }

  loadGoogleMapsAPI() {
    if (isScullyGenerated()) {
      this.idleMonitor.idle$.subscribe(() => {
        this.setGoogleScriptTag();
      });
    } else if (!isScullyGenerated() && !isScullyRunning()) {
      this.setGoogleScriptTag();
    }
  }

  loadAWSS3Data() {
    this.awsService;
    from(this.awsService.getAWSData(DEALER_FINDER_JSON_URL))
      .pipe(
        map((res: any) => {
          res = JSON.stringify(res).replace(
            /AWS_BUCKET_URL/g,
            this.awsService.getAWSBucketURL()
          );

          res = JSON.parse(res);

          const awsS3Data: DealerFinderS3Data =
            res && res.CacDealerFinderComponent
              ? {
                  disclaimer: res.CacDealerFinderComponent.disclaimer,
                  content: res.CacDealerFinderComponent.content,
                  errors: res.CacDealerFinderComponent.errors,
                }
              : null;

          return awsS3Data;
        }),
        catchError((error) => {
          console.error('Error:', error);
          return of(null);
        })
      )
      .subscribe((data: DealerFinderS3Data) => {
        if (data) {
          this.awsS3DataIsLoaded = true;
          this.awsS3Data = data;
        } else {
          this.awsS3DataIsLoaded = false;
        }
      });
  }

  setRoutingData() {
    this.routingDataSetSubscription = combineLatest([
      this.scullyRoutes.available$,
      this.activatedRoute.paramMap.pipe(take(1)),
    ]).subscribe(([scullyRoutes, activatedRouteParams]) => {
      const params = activatedRouteParams.get('location');

      if (params && params.includes('-')) {
        const [state, city] = params.split('-');

        const availablePaths = scullyRoutes.map((route) =>
          route.route.toLowerCase()
        );

        if (
          availablePaths.includes(
            `/car-buyers/dealer-finder/${state.toLowerCase()}-${city.toLowerCase()}`
          )
        ) {
          this.setSEOMetaTags(city, state);
        }
      }
    });
  }

  trackAdobeAnalytics(
    totalCount: number,
    title: string,
    searchAddress: string,
    serviceError: string,
    lastRecordGeoDistance: number,
    dealerIds: string[],
    city: string,
    state: string,
    geoCodeStatus: string,
    geoServiceFailure: string
  ): void {
    (window as any).adobeDataLayer = (window as any).adobeDataLayer || [];
    let cacAnalytics: { [key: string]: any } = {};
    let searchRangeMiles =
      lastRecordGeoDistance > 0
        ? parseFloat(lastRecordGeoDistance.toString()).toFixed(1)
        : 0;

    cacAnalytics.sub_property = 'car-buyers';
    cacAnalytics.sub_sub_property = 'dealer-finder';
    cacAnalytics.user_id = '';
    cacAnalytics.br_language = navigator.language;
    cacAnalytics.web_lang = document.documentElement.lang;
    cacAnalytics.campaign_id = '';
    cacAnalytics.internal_cmp_id = '';
    cacAnalytics.page_url = window.location.href;
    cacAnalytics.is_spa = 'true';
    cacAnalytics.page_title = title;
    cacAnalytics.search_term = searchAddress;
    cacAnalytics.search_results_count = totalCount;
    cacAnalytics.action_label = '';
    cacAnalytics.action_section = '';
    cacAnalytics.action_url = '';
    cacAnalytics.additional_events = '';
    cacAnalytics.action_label = '';
    cacAnalytics.event = 'cac-page-view';
    cacAnalytics.event_type = 'cac-page-view';
    cacAnalytics.dealer_id = '';
    cacAnalytics.error_msg =
      geoCodeStatus === 'ZERO_RESULTS' || city === ''
        ? geoServiceFailure
        : serviceError;
    cacAnalytics.dealer_list = dealerIds;
    cacAnalytics.search_range_miles = searchRangeMiles;
    cacAnalytics.google_search_city = city;
    cacAnalytics.google_search_state = state;

    (window as any).adobeDataLayer.push(cacAnalytics);
  }

  trackAdobeClickEvent(
    additionalEventName: string,
    eventLabel: string,
    eventUrl: string,
    dealerId: string
  ): void {
    (window as any).adobeDataLayer = (window as any).adobeDataLayer || [];
    let cacAnalytics: { [key: string]: any } = {};

    cacAnalytics.additional_events = additionalEventName;
    cacAnalytics.event = 'cac-action-trigger';
    cacAnalytics.event_type = 'cac-action-trigger';
    cacAnalytics.action_section = 'dealer finder';
    cacAnalytics.action_label = eventLabel;
    cacAnalytics.action_url = eventUrl;
    cacAnalytics.dealer_id = dealerId;
    cacAnalytics.dealer_list = [];
    cacAnalytics.search_term = '';
    cacAnalytics.search_range_miles = '';
    cacAnalytics.search_results_count = '';
    cacAnalytics.google_search_city = '';
    cacAnalytics.google_search_state = '';

    (window as any).adobeDataLayer.push(cacAnalytics);
  }

  setSEOMetaTags(city: string, state: string) {
    const updatedCity = city.includes('+') ? city.split('+').join(' ') : city;

    this.seoService.setDynamicPageSEOData(
      `${updatedCity}, ${state} Credit Acceptance Network Dealers`,
      `View dealers in the Credit Acceptance network in ${updatedCity}, ${state}, and get prequalified online.`,
      `car-buyers/dealer-finder/${state}-${city}`
    );
  }

  ngOnDestroy(): void {
    this.recaptchaV3Service.removeRecaptcha();
    this.routingDataSetSubscription.unsubscribe();
  }

  private setGoogleScriptTag(retries: number = 0) {
    const newScript = document.createElement('script');
    newScript.src = this.dealerFinderService.setDealerFinderGoogleAPIScript();
    newScript.async = false;

    newScript.onload = () => {
      this.googleAPIScriptIsLoaded = true;
    };

    newScript.onerror = () => {
      console.error(`Script failed to load`);
      if (retries < this.maxRetries) {
        console.log(`Retrying... (${retries + 1}/${this.maxRetries})`);
        this.setGoogleScriptTag(retries + 1);
      } else {
        console.error(`Max retries reached for script`);
        this.googleAPIScriptIsLoaded = false;
      }
    };
    document.head.appendChild(newScript);
  }
}
