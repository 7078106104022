import { creditLinks } from "./credit_links";

export class cardDetails {
  title?: string[];
  id?: string;
  link?: string;
  imgUrl?: string;
  imgAlt?: string;
  avatarIcon?: string;
  imgIcon?: string;
  onHoverimgUrl?: string;
  mobileImgurl?: string;
  hideImgOnMobile?: boolean = false;
  imgId?: string;
  ariaLabel?: string;
  childMenu?: creditLinks[];
  isRebuildLink?: boolean;
  isExternalLink?: boolean;
  isCustomerLink?: boolean;
  target?: boolean; // set to true in case opens in new tab
  userDetails?: UserDetails;
  buttonName?: string;
  buttonId?: string;
  description?: string[];
  date?: string;
  points?: string[];
  sectionName?: string;
  outletComponent?: any;
  cardHeight?: string;
  cardMobileHeight?: string;
}

export class UserDetails {
  name?: string;
  position?: string;
  corporateName?: string;
  Address?: string
}