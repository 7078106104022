import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {

  getGoogleMapAPIUrl() {
    return `https://maps.googleapis.com/maps/api/js?key=${this.setGoogleMapApiKey()}&libraries=places`
  }


  private setGoogleMapApiKey() {
    const hostName = window.location.hostname;

    if (hostName.includes('cac-dev-portal-code-www-use2.s3-website.us-east-2.amazonaws.com')) {
      return "AIzaSyCbApsi6fdGxK6xGbVhdGb7ZmF80nKfv4Q";
    }
    else if (hostName.includes('test')) {
      return "AIzaSyCbApsi6fdGxK6xGbVhdGb7ZmF80nKfv4Q";
    }
    else if (hostName.includes('qa')) {
      return "AIzaSyB6b82FKj7j9Hcs0y7EdFYpWOlYKVrFMRk";
    } 
    else if (hostName.includes('localhost')) {
      return "AIzaSyBjbsb4QuJVnNywyKum03PiUV97M9N75EU"; // local development
    }
    else {
      return "AIzaSyDXZ2tmGhfyCpCf3NLdRWraC6OtWzg_7Nk"; // production
    }
  }
}
