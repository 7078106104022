import { bannerImage } from "app/models/bannerImage";

export const dealerGrowBanner: bannerImage = {
  "imgUrl": "assets/images/grow-your-dealership/banner-image/banner.jpg",
  "mobileImgurl":"assets/images/common/orange-shape-bg-mobile@2x.webp",
  "imgAlt": "Banner Image",
  "buttonName": "Login to CAPS",
  "buttonId":"grow-your_dealer_login_caps",
  "overlineTitle":"Welcome to Credit Acceptance.",
  "heading": ["You sell more cars. Win.", "You make more money. Win. Win."],
  "link": "/dealers/caps-redirect"
}