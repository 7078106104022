import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { creditLinks } from 'app/models/credit_links';
import { ContentService } from 'app/services/content.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription, take } from 'rxjs';
import { MatButton } from '@angular/material/button';
import { MatMenuTrigger } from '@angular/material/menu';
import { isScullyGenerated } from '@scullyio/ng-lib';
import { FeatureFlagService } from 'app/services/feature-flag.service';
import { EFeatureFlag } from 'app/services/feature-flags.types';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('outsideClick') outsideClick: ElementRef | undefined;
  @ViewChild(MatMenuTrigger) prevButtonTrigger: MatMenuTrigger | undefined;
  @Input() showOnlyLogo: boolean = false;
  topNavigationLinks: creditLinks[] = [];
  getTopSideHeaderLinks: creditLinks[] = [];
  getQuickLinks: creditLinks[] = [];
  subscription: Subscription[] = [];
  signInLink: creditLinks = new creditLinks();
  mobileView: boolean = false;
  showHamburger: boolean = false;
  showNavMenuItems: boolean = false;
  enteredButton: boolean = false;
  isMatMenuOpen: boolean = false;
  isScullyGenerated: boolean = false;

  constructor(
    private readonly contentService: ContentService,
    private readonly ren: Renderer2,
    private readonly responsive: BreakpointObserver,
    private readonly featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.featureFlagService
      .isEnabled(EFeatureFlag.DEALER_FINDER_PAGE_ENABLED)
      .pipe(take(1))
      .subscribe((isEnabled) => {
        this.topNavigationLinks = this.contentService.getTopNavigationLinks();

        const customerLinks = this.topNavigationLinks[0];

        if (isEnabled && customerLinks) {
          const dealerFinder = {
            title: 'See Dealers Near You',
            id: 'layout_756',
            ariaLabel: 'See Dealers Near You',
            link: '/car-buyers/dealer-finder',
          };

          const exists = customerLinks.childMenu?.find(link => link.title === dealerFinder.title);

          if (!exists) {
            customerLinks.childMenu?.push(dealerFinder);
          }
        }
      });

    this.getTopSideHeaderLinks = this.contentService.getTopSideHeaderLinks();
    this.getQuickLinks = this.contentService.getQuickLinks();
    this.signInLink = this.getQuickLinks[1];
    this.subscription.push(
      this.responsive
        .observe([Breakpoints.XSmall, Breakpoints.Small])
        .subscribe((result) => {
          this.mobileView = result.matches ? true : false;
          this.showHamburger = result.matches ? true : false;
        })
    );
    this.ren.listen('window', 'click', (e: Event) => {
      if (
        this.mobileView &&
        this.showNavMenuItems &&
        !this.outsideClick?.nativeElement.contains(e.target)
      ) {
        this.showNavItems();
      }
    });

    if (isScullyGenerated()) {
      this.isScullyGenerated = true;
    }
  }

  menuAction(menuOpen: boolean) {
    this.isMatMenuOpen = menuOpen;
  }

  showNavItems() {
    this.showNavMenuItems = !this.showNavMenuItems;
    this.showHamburger = !this.showHamburger;
  }

  buttonEnter(trigger: MatMenuTrigger) {
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        trigger.openMenu();
        return;
      }
      this.enteredButton = true;
      this.prevButtonTrigger = trigger;
      !this.isMatMenuOpen ? trigger.openMenu() : '';
    });
  }

  buttonLeave(trigger: MatMenuTrigger, button: MatButton) {
    setTimeout(() => {
      if ((this.enteredButton && !this.isMatMenuOpen) || !this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(
          button['_elementRef'].nativeElement,
          'cdk-focused'
        );
        this.ren.removeClass(
          button['_elementRef'].nativeElement,
          'cdk-program-focused'
        );
        return;
      }
      this.enteredButton = false;
    }, 50);
  }

  ngOnDestroy() {
    this.subscription.forEach((sub) => sub.unsubscribe());
  }
}
