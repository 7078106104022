import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { TransferState } from '@angular/platform-browser';
import { DEALER_HOME_JSON_URL } from 'app/constants/aws.constants';
import { AWSService } from 'app/services/aws.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-start-my-journey',
  templateUrl: './dealer-home.component.html',
  styleUrls: ['./dealer-home.component.scss']
})
export class DealerHome implements OnInit {

  subscription: Subscription[] = [];

  url = DEALER_HOME_JSON_URL;
  
  DealerHomeJSONData:any;
  
  heroImageInfo : any = {};
  bottonBgColor: string = "#ffb800";
  overLineTextColor: string = "#1b1b1b";
  headerColor: string = "#FFFFFF";
  ariaLabel: string = "Start My Journey";
  isMobileViewDisplay : boolean = false;
  isCurvedImage = false;
  isBrowser : boolean = false;
  isServer : boolean = false;
  introData : any = {};
  dataProofPoint : any;
  contentStackedData : any;
  perksData : any = {};
  cardData : any = {};
  cardCapsData : any = {};
  testimonialData : any;
  contentBlockData : any = {};
  introCarData : any = {};
  constructor(private http: HttpClient, private responsive: BreakpointObserver, private awsService : AWSService, private transferState : TransferState , @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }

  ngOnInit(): void {
  
    if(this.isBrowser){
    
      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);
        
        this.DealerHomeJSONData = json;
        this.heroImageInfo = this.DealerHomeJSONData.CacHeroImageComponent;
        this.introData = this.DealerHomeJSONData.CacCreditIntroductionComponent;
        this.cardData = this.DealerHomeJSONData.CacAcceptanceCardComponent;
        this.cardCapsData = this.DealerHomeJSONData.CapsAcceptanceCardComponent;
        this.perksData = this.DealerHomeJSONData.CacPerksComponent;
        this.dataProofPoint = this.DealerHomeJSONData.CacProofpointContentComponent;
        this.testimonialData = this.DealerHomeJSONData.CacTestimonialComponent;
        this.introCarData = this.DealerHomeJSONData.CacCreditIntroductionDealerHomeComponent;
        this.contentStackedData = this.DealerHomeJSONData.CacContentStackedComponent;
        this.contentBlockData = this.DealerHomeJSONData.CacContentBlockComponent;
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          if (result.matches) {
            this.isMobileViewDisplay = true;
          }
        })
    );
  }

}
