import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'affordability-calculator',
  templateUrl: './affordability-calculator.component.html',
  styleUrls: ['./affordability-calculator.component.scss']
})
export class AffordabilityCalculatorComponent implements OnInit {

  constructor() { }
  data : any;
  ngOnInit(): void {

  }

}
