<app-header></app-header>
<div [ngClass]="isMobileViewDisplay ? 'sub-components-margin mob-height bottom-shadow' : 'sub-components-margin bottom-shadow'">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [bottonBgColor]="bottonBgColor"
        [overLineBgColor]="overLineBgColor" [isCurvedImage]="isCurvedImage"  [disclaimerColor]='disclaimerColor'></cac-hero-image>
</div>
<div [ngClass]="!isMobileViewDisplay ? '' : 'mobile-container'">
    <div class='components-margin'>
        <cac-content-block [data]="contentBlockData" [leftToRight]="false" [isDefaultColor]="true"></cac-content-block>
    </div>
</div>
<div [ngClass]="!isMobileViewDisplay ?  'components-margin perks-shadow' : ''">
    <cac-perks [data]="perksData" [leftToRight]="true" [displayStoreImg]="false"></cac-perks>
</div>
<div class='components-margin'>
    <cac-credit-introduction [information]="introData"></cac-credit-introduction>
</div>
<div class='components-margin'>
    <cac-pride [data]="prideComponentData"></cac-pride>
</div>
<div class='components-margin container-fluid background-skyblue'>
    <cac-ad-block-two [data]="cacAdBlockTwoDataData"></cac-ad-block-two>
</div>
<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>