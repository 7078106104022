import { creditLinks } from "app/models/credit_links";

export const siteMapLinks: creditLinks[] = [
	{
		"title": "Customers",
		"id": "site_map_customers",
		"ariaLabel": "Customers",
		"childMenu": [
			{
				"title": "Get Pre-Qualified",
				"id": "yui_patched_v3_18_1_1_1682608398084_81",
				"link": "/get-prequalified#/",
				"ariaLabel": "Get Pre-Qualified link"
			},
			{
				"title": "Account Sign In",
				"id": "yui_patched_v3_18_1_1_1682608398084_83",
				"ariaLabel": "Account Sign In",
				"link": "/login"
			},
			{
			  "title": "Customers Overview",
			  "id": "site_map_layout_23",
			  "ariaLabel": "Customers Overview",
			  "link": "/customers"
			},
			{
			  "title": "Make a Payment",
			  "id": "site_map_layout_24",
			  "ariaLabel": "Make a Payment",
			  "link": "/customers/make-a-payment"
			},
			{
			  "title": "Credit Acceptance Reviews",
			  "id": "site_map_layout_25",
			  "ariaLabel": "Credit Acceptance Reviews",
			  "link": "/customers/reviews"
			},
			{
			  "title": "Express Lane Blog",
			  "id": "site_map_layout_29",
			  "ariaLabel": "Express Lane Blog",
			  "link": "/car-buyers/express-lane"
			},
			{
			  "title": "Customer FAQ",
			  "id": "site_map_layout_28",
			  "ariaLabel": "Customer FAQ",
			  "link": "/customers/faq"
			},
		  {
			  "title": "Financial Education",
			  "id": "site_map_layout_425",
			  "ariaLabel": "Financial Education",
			  "link": "/car-buyers/express-lane/financial-education"
			},
			{
			  "title": "COVID-19 Response",
			  "id": "site_map_layout_597",
			  "ariaLabel": "COVID-19 Response",
			  "link": "/coronavirus-response"
			},
			{
			  "title": "Car shopping? Start here.",
			  "id": "site_map_layout_5977",
			  "ariaLabel": "Car shopping? Start here.",
			  "link": "/car-buyers"
			},
			{
			  "title": "How Financing Works",
			  "id": "site_map_layout_26",
			  "ariaLabel": "How Financing Works",
			  "link": "/car-buyers/how-it-works"
			},
			{
			  "title": "Your Credit Approval",
			  "id": "site_map_layout_27",
			  "ariaLabel": "Your Credit Approval",
			  "link": "/your-credit-approval"
			},
			{
			  "title": "Car Buyer Videos",
			  "ariaLabel": "Car Buyer Videos",
			  "id": "site_map_layout_289",
			  "link": "/consumer-video-page"
			},
			{
			  "title": "Car Buyer Resources",
			  "id": "site_map_layout_294",
			  "ariaLabel": "Car Buyer Resources",
			  "link": "/car-buyers/resources"
			},
			{
			  "title": "Monthly Car Payment Calculator",
			  "id": "site_map_layout_556",
			  "ariaLabel": "Monthly Car Payment Calculator",
			  "link": "/car-buyers/monthly-payment-calculator"
			},
		  ]
	},
	{
		"title": "Dealers",
		"id": "site_map_dealers",
		"ariaLabel": "Dealers",
		"childMenu": [
			{
			  "title": "Dealers Overview",
			  "id": "site_map_layout_39",
			  "ariaLabel": "Dealers Overview",
			  "link": "/dealers"
			},
			{
			  "title": "CAPS® Login",
			  "id": "site_map_layout_42",
			  "ariaLabel": "CAPS® Login",
			  "link": "/dealers/caps-redirect"
			},
			{
			  "title": "Join Our Network",
			  "id": "site_map_layout_41",
			  "ariaLabel": "Join Our Network",
			  "link": "/dealers/join-our-network"
			},
			{
			  "title": "Auto Finance Programs",
			  "id": "site_map_layout_44",
			  "ariaLabel": "Auto Finance Programs",
			  "link": "/dealers/how-it-works"
			},
			{
			  "title": "Dealer Reviews",
			  "id": "site_map_layout_43",
			  "ariaLabel": "Dealer Reviews",
			  "link": "/dealers/reviews"
			},
			{
			  "title": "Dealer Resources",
			  "id": "site_map_layout_295",
			  "ariaLabel": "Dealer Resources",
			  "link": "/dealers/resources"
			},
			{
			  "title": "Dealer FAQ",
			  "id": "site_map_layout_51",
			  "ariaLabel": "Dealer FAQ",
			  "link": "/dealers/faq"
			},
			{
			  "title": "Dealer Insights",
			  "ariaLabel": "Dealer Insights",
			  "id": "site_map_layout_50",
			  "link": "/dealers/the-lot"
			},
			{
				"title": "Dealer Videos",
				"ariaLabel": "Dealer Videos",
				"id": "site_map_layout_5084",
				"link": "/dealers/videos"
			  }
		  ]
	},
	{
		"title": "About Us",
		"id": "site_map_about_us",
		"ariaLabel": "About Us",
		"childMenu": [
			{
				"title": "About Us",
				"id": "site_map_yui_patched_v3_18_1_1_1682609342676_141",
				"link": "/about",
				"ariaLabel": "About Us Link"
			},
			{
				"title": "Investors",
				"id": "site_map_yui_patched_v3_18_1_1_1680615435907_149",
				"link": "http://www.ir.creditacceptance.com",
				"ariaLabel": "Investors link",
				"isExternalLink": true
			},
			{
				"title": "Environmental, Social & Governance",
				"id": "site_map_yui_patched_v3_18_1_1_1680615435907_153",
				"link": "/about/esg",
				"ariaLabel": "Environmental, Social & Governance"
			},
		],
	},
	{
		"title": "Careers",
		"id": "site_map_careers",
		"ariaLabel": "Careers",
		"childMenu": [
			{
				"title": "Job Search",
				"id": "site_map_yui_patched_v3_18_1_1_1680615435907_155",
				"link": "/careers",
				"ariaLabel": "Job Search",
			},
			{
				"title": "Community",
				"id": "site_map_yui_patched_v3_18_1_1_1680615435907_151",
				"link": "/careers/community",
				"ariaLabel": "Community link"
			}
		]
	}
]