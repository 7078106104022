import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import { RecaptchaV3Service } from '../services/recaptcha-v3.service';

@Injectable()
export class RecaptchaInterceptor implements HttpInterceptor {
  constructor(private recaptchaService: RecaptchaV3Service) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        error: (error) => {
          if (
            error.status === 400 &&
            error.error?.message?.includes('reCAPTCHA')
          ) {
            this.recaptchaService.setRecaptchaError(error.error.message);
          }
        }
      })
    );
  }
}
