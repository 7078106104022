import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { YOUR_CREDIT_APPROVAL_JSON_URL } from 'app/constants/aws.constants';
import {AWSService} from 'app/services/aws.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-your-credit-approval',
  templateUrl: './your-credit-approval.component.html',
  styleUrls: ['./your-credit-approval.component.scss']
})
export class YourCreditApprovalComponent implements OnInit {

  subscription: Subscription[] = [];

  url = YOUR_CREDIT_APPROVAL_JSON_URL;

  YourCreditApprovalJSONData:any;

  isBrowser : boolean = false;
  isMobileViewDisplay : boolean = false;
  heroImageInfo : any = {};
  bottonBgColor: string = "#ffb800";
  overLineBgColor: string = "#FCFCFA";
  overLineTextColor: string = "#1b1b1b";
  headerColor: string = "#FFFFFF";
  disclaimerColor: string = "#FFFFFF";
  isCurvedImage = false;
  introData : any = {};
  introData2 : any = {};
  introData3 : any = {};
  contentBlockRightData : any = {};
  videoData : any;

  constructor(private responsive: BreakpointObserver, private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    
  }

  ngOnInit(): void {
    if(this.isBrowser){

      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);

        this.YourCreditApprovalJSONData = json;
        this.heroImageInfo = this.YourCreditApprovalJSONData.CacHeroImageComponent;
        this.introData = this.YourCreditApprovalJSONData.CacCreditIntroductionCarBuyerComponent;
        this.contentBlockRightData = this.YourCreditApprovalJSONData.CacContentBlockComponentRight;
        this.introData2 = this.YourCreditApprovalJSONData.CacCreditIntroductionCarBuyerComponent2;
        this.introData3 = this.YourCreditApprovalJSONData.CacCreditIntroductionCarBuyerComponent3;
        this.videoData = this.YourCreditApprovalJSONData.CacVideoBlockComponent;
      });
    }
    
    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }

}
