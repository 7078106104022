import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Component, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { BUY_HERE_PAY_HERE_CAMPAIGN_JSON_URL } from 'app/constants/aws.constants';
import { AWSService } from 'app/services/aws.service';
import { Observable, combineLatest, map } from 'rxjs';
import { BuyHerePayHereCampaignPageModel } from './data.model';
import { Router } from '@angular/router';
import { ContentService } from 'app/services/content.service';
import { isScullyGenerated } from '@scullyio/ng-lib';

@Component({
  selector: 'app-buy-here-pay-here-campaign',
  templateUrl: './buy-here-pay-here-campaign.component.html',
  styleUrls: ['./buy-here-pay-here-campaign.component.scss']
})
export class BuyHerePayHereCampaignComponent implements OnInit {
  private readonly metaService = inject(Meta);
  private readonly responsive = inject(BreakpointObserver);
  private readonly awsService = inject(AWSService);
  private readonly platformId = inject(PLATFORM_ID);
  private readonly isBrowser: boolean = isPlatformBrowser(this.platformId);
  private readonly contentService = inject(ContentService);
  private readonly router = inject(Router);
  data!: Observable<BuyHerePayHereCampaignPageModel>;
  environment: string = '';
  dataAvailable : boolean = false;

  ngOnInit(): void {
    this.metaService.addTag({ name: 'robots', content: 'noindex' });

    if (this.isBrowser) {
      this.environment = this.awsService.getJONEnv();
      if(!this.contentService.isWidgetLoaded()) {
        this.loadWidget();
        this.dataAvailable=true;
      } else {
          this.dataAvailable=true;
      }
      this.data = combineLatest({
        buyHerePayHereCampaignResource: this.awsService
          .getAWSData(BUY_HERE_PAY_HERE_CAMPAIGN_JSON_URL)
          .then((json) =>
            JSON.parse(
              JSON.stringify(json).replace(
                /AWS_BUCKET_URL/g,
                this.awsService.getAWSBucketURL(),
              ),
            ),
          ),
        breakpointState: this.responsive.observe([
          Breakpoints.XSmall,
          Breakpoints.Small,
        ]),
      }).pipe(
        map(
          ({
            buyHerePayHereCampaignResource,
            breakpointState: { matches: isMobile },
          }) => ({
            isMobile,
            ...buyHerePayHereCampaignResource,
          }),
        ),
      );
    }
  }
  loadWidget() {

    if (isScullyGenerated()) {
    let widgetElement = document.createElement("script");
    widgetElement.type = "text/javascript";
    widgetElement.async = false;

    let  hostname = window.location.hostname;
    let url: string = '';
        
    if( hostname.includes('localhost')){
      url = "https://cac-lab-portal-code-rebranding-use2.s3.us-east-2.amazonaws.com";
    }else if( hostname.includes('test')){
      url = "https://s3.us-east-2.amazonaws.com/wwwbucket-join-network.teststatic.creditacceptance.com";
    }else if( hostname.includes('qa')){
      url = "https://wwwbucket-join-network.qastatic.creditacceptance.com";
    }else {
      url = "https://wwwbucket-join-network.static.creditacceptance.com";
    }
    if(this.environment && this.environment!='') {
      widgetElement.src = url + "/join-our-network-widget.js";
      document.head.appendChild(widgetElement);
      this.contentService.setWidgetLoaded();
      this.dataAvailable = true;
    }
  }

  }

  handleSuccess(event: Event) {
    const customEvent = event as CustomEvent;
    this.contentService.setBecomeADealerReponse(customEvent.detail);
    this.router.navigate(['/dealers/join-our-network/confirmation-thank-you']);
  }
}
