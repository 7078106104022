<app-header></app-header>
<div class="container">
    <div class="header">
        <h1>{{headerData?.header}}</h1>
        <p>{{headerData?.subheader}}</p>
    </div>
    <cac-credit-introduction [information]="textBlockData1"></cac-credit-introduction>
    <cac-credit-introduction [information]="textBlockData2"></cac-credit-introduction>
    <cac-credit-introduction [information]="textBlockData3"></cac-credit-introduction>
    <p></p>
</div>
<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>
