import { creditLinks } from "app/models/credit_links";

export const topSideHeader: creditLinks[] =
  [
    {
    "title": "ABOUT US",
    "id": "layout_4",
    "ariaLabel": "ABOUT US",
    "link": "/about"
  },
  {
    "title": "Careers",
    "id": "layout_57",
    "ariaLabel": "Careers",
    "link": "/careers"
  },
  {
    "title": "Contact Us",
    "id": "layout_5",
    "ariaLabel": "Contact Us",
    "link": "/about/contact-us"
  },
  {
    "title": "Get Pre-Qualified",
    "id": "header_cpreq",
    "ariaLabel": "GET PRE-QUALIFIED",
    "link": "/get-prequalified#/"
  }];
