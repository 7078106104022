<app-header></app-header>
<div [ngClass]="{'mob-height': isMobileViewDisplay}" class="sub-components-margin">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [isCurvedImage]="isCurvedImage" [headerColor]="headerColor"
        [overLineTextColor]="overLineTextColor"></cac-hero-image>
</div>
<div class='components-margin'>
    <cac-credit-introduction [information]="introData"></cac-credit-introduction>
</div>
<div>
    <div [ngClass]="!isMobileViewDisplay ? 'container ' : 'mobile-container '">
        <cac-acceptance-card *ngIf="cardData" [data]="cardData" [displayStacked]="true"></cac-acceptance-card>
    </div>
</div>
<div>
    <div [ngClass]="!isMobileViewDisplay ? 'container components-margin' : 'mobile-container '">
        <cac-acceptance-card *ngIf="cardCapsData" [data]="cardCapsData" [displayStacked]="true"></cac-acceptance-card>
    </div>
</div>
<div class="components-margin perks-shadow" id="app">
    <cac-perks *ngIf="perksData" [data]="perksData" [leftToRight]="true" [displayStoreImg]="false"></cac-perks>
</div>
<div *ngIf="dataProofPoint" class='bottom-shadow'>
    <cac-proofpoint-module [data]="dataProofPoint"></cac-proofpoint-module>
</div>
<div [ngClass]="isMobileViewDisplay ? 'mobile-container' : 'container'">
    <div class='components-margin' *ngIf="testimonialData">
        <cac-testimonial [data]="testimonialData"></cac-testimonial>
    </div>
</div>
<div *ngIf='contentStackedData' class='components-margin'>
    <cac-credit-introduction [information]="introCarData"></cac-credit-introduction>
    <cac-content-stacked [data]="contentStackedData"></cac-content-stacked>
</div>
<div [ngClass]="!isMobileViewDisplay ? 'contactUs' : 'mobile-container contactUs'">
    <div class='components-margin'>
        <cac-content-block [data]="contentBlockData" [leftToRight]="false" [isDefaultColor]="true"></cac-content-block>
    </div>
</div>
<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>