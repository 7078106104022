<app-header></app-header>
<div [ngClass]= "{'mob-height': isMobileViewDisplay}" class="sub-components-margin">
    <cac-hero-image [heroImageInfo]="heroImageInfo" [headerColor]="headerColor" [bottonBgColor]="bottonBgColor"
        [overLineBgColor]="overLineBgColor" [overLineTextColor]="overLineTextColor" [isCurvedImage]="isCurvedImage" [disclaimerColor]="disclaimerColor" ></cac-hero-image>
</div>
<div class='components-margin whiteColor'>
    <cac-credit-introduction [information]="introData"></cac-credit-introduction>
</div>
<div class="skyColor">
    <cac-credit-introduction [information]="introData2"></cac-credit-introduction>
</div>
<div>
    <cac-content-block [data]="contentBlockRightData" [leftToRight]="true" [isDefaultColor]="true"></cac-content-block>
</div>
<div class=''>
    <video-block *ngIf='videoData' [data]="videoData"></video-block>
</div>
<div class='whiteColor'>
    <cac-credit-introduction [information]="introData3"></cac-credit-introduction>
</div>
<app-sitemap [isMobileView]="isMobileViewDisplay"></app-sitemap>
<app-footer></app-footer>