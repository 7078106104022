import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CAREERS_LANDING_JSON_URL } from 'app/constants/aws.constants';
import { AWSService } from 'app/services/aws.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'careers-landing',
  templateUrl: './careers-landing.component.html',
  styleUrls: ['./careers-landing.component.scss']
})
export class CareersLandingComponent implements OnInit {
  subscription: Subscription[] = [];

  url = CAREERS_LANDING_JSON_URL;
  careersLandingData : any = {};
  heroImageInfo : any ;
  keyPointsTwo : any ;
  contentBlock : any ;
  departments : any ;
  perksData: any;
  videoData : any;
  awardData: any;
  content : string = '';

  isMobileViewDisplay : boolean = false;
  isBrowser : boolean = false;
  overLineBgColor: string = "#171714";
  bottonBgColor: string = "#FFB800";
  headerColor: string = "#FFFFFF";
  disclaimerColor: string = '#171714';
  isCurvedImage = true;


  constructor(private responsive: BreakpointObserver, private awsService : AWSService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {

    if(this.isBrowser){
      
      this.awsService.getAWSData(this.url).then(json => {
        
        json = JSON.stringify(json).replace(/AWS_BUCKET_URL/g, this.awsService.getAWSBucketURL());
        json = JSON.parse(json);
        this.careersLandingData = json;
        
        this.heroImageInfo = this.careersLandingData.CacHeroImageComponent;
        this.keyPointsTwo = this.careersLandingData.CacKeyPointTwoComponent;
        this.contentBlock = this.careersLandingData.CacContentBlockComponent; 
        this.departments = this.careersLandingData.CacDepartmentsComponent;
        this.perksData = this.careersLandingData.CacPerksComponent;
        this.videoData = this.careersLandingData.CacVideoComponent;
        this.awardData = this.careersLandingData.CacAwardComponent;
        this.content = json.content;
      });
    }

    this.subscription.push(
      this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
          this.isMobileViewDisplay = result.matches ? true : false;
        })
    );
  }


}
