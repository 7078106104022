import { Component, Input } from '@angular/core';
import { cardDetails } from 'app/models/cardDetails';
import { TabToggleInfo, tabInformation } from 'app/models/toggle_tabs';

@Component({
  selector: 'app-users-toggle',
  templateUrl: './users-toggle.component.html',
  styleUrls: ['./users-toggle.component.scss']
})
export class UsersToggleComponent {
  @Input() isMobileView: boolean = false;
  @Input() tabInfo!: TabToggleInfo;
  @Input() tabContent!: cardDetails | undefined;

  constructor() { }
  handleTab(event:Event, tab: tabInformation) {
    event?.stopPropagation();
    if(this.tabInfo.tabInformation) {
      this.tabInfo.tabInformation.forEach(tab => tab.active = false);
      tab.active = true;
      this.tabContent = tab.tabContent;
    }
  }
}
