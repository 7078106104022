export class creditLinks {
  title!: string;
  id!: string;
  link?: string;
  imgUrl?: string;
  onHoverimgUrl?: string;
  imgId?: string;
  ariaLabel!: string;
  childMenu?: creditLinks[];
  isRebuildLink?: boolean;
  isExternalLink?: boolean;
  isCustomerLink?: boolean;
  target?: boolean; // set to true in case opens in new tab
  showInMobileOnly?: boolean;
  showLineSeparator?: boolean
}