import { Component, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AWSService } from '../../services/aws.service';
import { isPlatformBrowser } from '@angular/common';
import { combineLatest, map, Observable } from 'rxjs';
import {
  ConsumerPaidChannelLandingData,
  ConsumerPaidChannelLandingPageModel,
} from './data.interface';
import { CONSUMER_PAID_CHANNEL_LANDING_JSON_URL } from '../../constants/aws.constants';

@Component({
  selector: 'app-consumer-paid-channel-landing',
  templateUrl: './consumer-paid-channel-landing.component.html',
  styleUrls: ['./consumer-paid-channel-landing.component.scss'],
})
export class ConsumerPaidChannelLandingComponent implements OnInit {
  private readonly metaService = inject(Meta);
  private readonly responsive = inject(BreakpointObserver);
  private readonly awsService = inject(AWSService);
  private readonly isBrowser: boolean = isPlatformBrowser(inject(PLATFORM_ID));
  data!: Observable<ConsumerPaidChannelLandingPageModel>;

  ngOnInit(): void {
    this.metaService.addTag({ name: 'robots', content: 'noindex' });

    if (this.isBrowser) {
      this.data = combineLatest({
        consumerPaidChannelLanding:
          this.awsService.getAWSJsonData<ConsumerPaidChannelLandingData>(
            CONSUMER_PAID_CHANNEL_LANDING_JSON_URL,
          ),
        breakpointState: this.responsive.observe([
          Breakpoints.XSmall,
          Breakpoints.Small,
        ]),
      }).pipe(
        map(
          ({
            consumerPaidChannelLanding,
            breakpointState: { matches: isMobile },
          }) => ({
            isMobile,
            ...consumerPaidChannelLanding,
          }),
        ),
      );
    }
  }
}
